import React, { memo, useCallback } from "react";
import { SectionProps } from "../Section";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { Grid, Typography } from "@material-ui/core";

import {
	ContactInfoOptions,
	ContactInformation,
	ErrorPageContent
} from "@ploy-ui/core";
import {
	ContactInfo,
	ContactInfoResource,
	ApplicationSignerResource,
	CustomerRole,
	CustomerType
} from "@ploy-lib/rest-resources/";
import { useAppLoad } from "../../appLoadContext";
import { FormattedMessage } from "react-intl";
import { useVariableData } from "@ploy-lib/calculation";
import { useInvalidator, useResource } from "@rest-hooks/core";
import { ErrorHandler } from "@ploy-lib/core";
import { useField, useFormikContext } from "formik";
import {
	SigningDocumentResource,
	useResourceWithInvalidate
} from "@ploy-lib/rest-resources";

addRegisteredSectionLayout({
	name: "UpdateContactInfoSection",
	displayName: "UpdateContactInfoSection",
	settings: {
		editableOptions: { showUpdateSupplier: true, showUpdateCoSigner: true }
	}
});

addRegisteredSectionLayout({
	name: "UpdateSupplierContactPersonInfoSection",
	displayName: "UpdateSupplierContactPersonInfoSection",
	settings: {}
});

const UpdateContactInfoSectionInternal = memo(
	(
		props: SectionProps & {
			showUpdateCustomer?: boolean;
			options?: {
				supplier?: ContactInfoOptions;
				customer?: ContactInfoOptions;
				cosigner?: ContactInfoOptions;
			};
		}
	) => {
		const {
			className,
			showUpdateSupplier = false,
			onClick,
			showUpdateCoSigner = false,
			showUpdateCustomer = true,
			options
		} = props;

		const { applicationNumber } = useAppLoad();

		const contactInfo =
			useResource(
				ContactInfoResource.detailAll(),
				applicationNumber ? { applicationNumber } : null
			) ?? {};

		const {
			[CustomerRole.Customer]: customer,
			[CustomerRole.CoSigner]: cosigner,
			[CustomerRole.Supplier]: supplier
		} = contactInfo;

		const { value: showUpdateSupplierForCustomer } = useVariableData<boolean>(
			"Customer",
			"HideUpdateSupplier"
		);

		const formik = useFormikContext();

		const invalidateSigners = useInvalidator(ApplicationSignerResource.list());

		const onSuccess = useCallback(
			(info: ContactInfo) => {
				if (
					info.type === CustomerType.Private &&
					info.role === CustomerRole.Customer
				)
					invalidateSigners({
						vendor: false,
						applicationNumber
					});

				formik.setFieldValue(`${info.role}.PhoneHome`, info.phoneHome);
				formik.setFieldValue(`${info.role}.PhoneMobile`, info.phoneMobile);
				formik.setFieldValue(`${info.role}.Email`, info.email);
				formik.setFieldValue(`${info.role}.ContactPerson`, info.contactPerson);
				formik.setFieldValue(
					`${info.role}.ContactPersonEmail`,
					info.contactPersonEmail
				);
			},
			[invalidateSigners, applicationNumber, formik]
		);

		return (
			<Grid container spacing={2} className={className} onClick={onClick}>
				{applicationNumber &&
				showUpdateSupplier &&
				showUpdateSupplierForCustomer &&
				supplier ? (
					<>
						<Grid item xs={12}>
							<Typography variant="h6" paragraph>
								<FormattedMessage
									id="dealer.change.contact.information.header.supplier"
									description="Contact information header supplier"
									defaultMessage="Leverandør"
								/>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<ContactInformation
								contactInfo={supplier}
								onUpdateContactInfo={onSuccess}
								applicationNumber={applicationNumber}
								{...options?.supplier}
							/>
						</Grid>
					</>
				) : null}
				{applicationNumber && showUpdateCustomer && customer ? (
					<>
						<Grid item xs={12}>
							<Typography variant="h6" paragraph>
								<FormattedMessage
									id="dealer.change.contact.information.header.customer"
									description="Contact information header"
									defaultMessage="Kunde"
								/>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<ContactInformation
								contactInfo={customer}
								onUpdateContactInfo={onSuccess}
								applicationNumber={applicationNumber}
								{...options?.customer}
							/>
						</Grid>
					</>
				) : null}

				{applicationNumber &&
				cosigner &&
				cosigner.type !== CustomerType.NotSet &&
				showUpdateCoSigner ? (
					<>
						<Grid item xs={12}>
							<Typography variant="h6" paragraph>
								<FormattedMessage
									id="dealer.change.contact.information.header.coSigner"
									description="Contact information header"
									defaultMessage="Medsøker"
								/>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<ContactInformation
								contactInfo={cosigner}
								onUpdateContactInfo={onSuccess}
								applicationNumber={applicationNumber}
								{...options?.cosigner}
							/>
						</Grid>
					</>
				) : (
					<></>
				)}
			</Grid>
		);
	}
);

const UpdateContactInfoSection = (props: SectionProps) => (
	<ErrorHandler
		fallback={e => (
			<ErrorPageContent
				className={props.className}
				onClick={props.onClick}
				header={
					<span>{UpdateContactInfoSection.displayName} failed to render.</span>
				}
			/>
		)}
	>
		<UpdateContactInfoSectionInternal {...props} />
	</ErrorHandler>
);

UpdateContactInfoSection.displayName = "DployUpdateContactInfoSection";

const emptyArray = [];

const UpdateSupplierContactPersonInfoSection = (props: SectionProps) => {
	const { applicationNumber } = useAppLoad();
	const documents =
		useResourceWithInvalidate(
			SigningDocumentResource.list(),
			applicationNumber
				? {
						applicationNumber
				  }
				: null
		) ?? emptyArray;

	const supplierVendorDocumentsAreSigned = documents
		.filter(document => document.vendorMustSign)
		.every(document => document.signed);

	return (
		<ErrorHandler
			fallback={e => (
				<ErrorPageContent
					className={props.className}
					onClick={props.onClick}
					header={
						<span>
							{UpdateSupplierContactPersonInfoSection.displayName} failed to
							render.
						</span>
					}
				/>
			)}
		>
			<UpdateContactInfoSectionInternal
				{...props}
				showUpdateCustomer={false}
				showUpdateSupplier={true}
				options={{
					supplier: {
						disabled: supplierVendorDocumentsAreSigned,
						showCustomerInfo: false
					}
				}}
			/>
		</ErrorHandler>
	);
};

UpdateSupplierContactPersonInfoSection.displayName =
	"DployUpdateSupplierContactPersonInfoSection";

export { UpdateContactInfoSection, UpdateSupplierContactPersonInfoSection };
