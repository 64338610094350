import React, { useState, useEffect } from "react";

import { useResource } from "@rest-hooks/core";
import { DriverResource } from "@ploy-lib/rest-resources";
import { useVariableData } from "@ploy-lib/calculation";
import {
	DployAutocomplete,
	useFieldToTextField,
	DployFormControl
} from "@ploy-ui/form-fields";
import {
	Grid,
	Dialog,
	FormLabel,
	makeStyles,
	Card,
	CardHeader,
	IconButton,
	CardContent
} from "@material-ui/core";
import { Edit, Close } from "@material-ui/icons";
import { PendingButton } from "@ploy-ui/core";
import { FormattedMessage } from "react-intl";
import { DriverForm } from "./DriverForm";

export const DriverSelect = props => {
	const { form, field, label, className, multiple, ...rest } = props;

	const { autoComplete, ...textFieldProps } = useFieldToTextField(props as any);

	const { name } = field;
	const { setFieldValue } = form;

	const { value: customerId } = useVariableData("Customer", "CustomerId");
	const [currentCustomer, setCurrentCustomer] = useState(customerId);

	const drivers =
		useResource(
			DriverResource.list(),
			customerId &&
				!Number.isNaN(Number(customerId)) &&
				Number(customerId) !== 0
				? { customerId }
				: null
		) || [];

	const { value: selectedDriverNumber } = useVariableData("Main", "Driver");

	const selectedDriver = drivers.find(x => x.driverId === selectedDriverNumber);

	const [selected, setSelected] = useState(selectedDriver);

	useEffect(() => {
		//reset selectedDriver on customerId change
		if (currentCustomer !== customerId) {
			setCurrentCustomer(customerId);
			setFieldValue(name, 0);
			setSelected(undefined);
		}
	}, [currentCustomer, customerId, name, setFieldValue]);

	const [dialogIsOpen, setDialogIsOpen] = useState(false);

	const classes = useStyles(props);

	const message = (
		<FormattedMessage
			id="form.driver.editDriver"
			description="Card header title for drivers editor"
			defaultMessage="Rediger førere"
		/>
	);

	const [requestClose, setRequestClose] = useState(false);

	const closeRequest = {
		closeCallback: () => {
			setDialogIsOpen(false);
			setRequestClose(false);
		},
		cancelCallback: () => setRequestClose(false)
	};

	return (
		<>
			<Dialog open={dialogIsOpen} PaperProps={{ style: { width: "100%" } }}>
				<Card>
					<CardHeader
						className={classes.mainThemeBackground}
						action={
							<IconButton
								color="inherit"
								onClick={() => {
									setRequestClose(true);
								}}
							>
								<Close />
							</IconButton>
						}
						title={message}
					/>
					<CardContent className={classes.cardContent}>
						<DriverForm
							requestClose={requestClose ? closeRequest : undefined}
							customerId={customerId}
							selected={selected}
							onDriverSaved={driver => {
								setSelected(driver);
								setFieldValue(name, driver.driverId);
								setDialogIsOpen(false);
							}}
						/>
					</CardContent>
				</Card>
			</Dialog>
			<Grid container wrap="nowrap">
				<Grid item xs={9}>
					<DployAutocomplete
						{...(rest as unknown)}
						{...textFieldProps}
						items={drivers}
						getItemLabel={driver => driver.firstname + " " + driver.lastname}
						value={selected ?? null}
						onChange={(e, val) => {
							setSelected(val ?? undefined);
							val ? setFieldValue(name, val.driverId) : setFieldValue(name, 0);
						}}
						disableClearable={!rest.clearable}
						defaultValue={undefined}
					/>
				</Grid>
				<Grid item xs={3}>
					<DployFormControl
						className={classes.editButtonContainer}
						margin="normal"
					>
						{label && <FormLabel> </FormLabel>}
						<PendingButton
							pending={false}
							variant="outlined"
							size="large"
							onClick={() => {
								setDialogIsOpen(true);
							}}
							disabled={Number(customerId) === 0}
							success={false}
							className={classes.editButton}
							fullWidth
						>
							<Edit />
						</PendingButton>
					</DployFormControl>
				</Grid>
			</Grid>
		</>
	);
};

const useStyles = makeStyles(theme => ({
	editButton: {
		padding: 0,
		minWidth: 0
	},
	editButtonContainer: {
		width: "100%"
	},
	cardContent: {
		maxHeight: "80vh",
		overflow: "auto"
	},
	mainThemeBackground: {
		background: theme.palette.primary.main,
		color: theme.palette.primary.contrastText
	}
}));
