import React, { useState } from "react";
import Section, { SectionProps } from "../Section";
import { Grid, Typography, Icon, CardActionArea } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { useVariableData } from "@ploy-lib/calculation";
import {
	FormattedMessage,
	injectIntl,
	WrappedComponentProps
} from "react-intl";

import { useAppLoad } from "../../appLoadContext";
import { ApplicationStatus } from "@ploy-lib/types";

import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles(theme => ({
	fullWidth: {
		width: "100%"
	},
	card: {
		backgroundColor: "#fff",
		borderRadius: 8,
		paddingTop: 8,
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: 16,
		[theme.breakpoints.down("sm")]: {
			paddingTop: 8,
			paddingLeft: 0,
			paddingRight: 0
		}
	},
	headerItem: {
		padding: "0px 12px"
	},
	header: {
		borderBottom: "3px solid #FACDAA",
		paddingBottom: 6,
		marginBottom: 24,
		alignItems: "center",
		display: "flex"
	},
	editDiv: {
		display: "flex",
		alignItems: "center",
		padding: "0px 4px",
		margin: "4px 4px",
		borderRadius: 4
	},
	editIconButton: {
		marginLeft: 8,
		color: "black"
	}
}));

addRegisteredSectionLayout({
	name: "LockAndEditableSection",
	displayName: "LockAndEditableSection",
	settings: {}
});

const LockAndEditableSection = injectIntl(
	(props: SectionProps & WrappedComponentProps) => {
		const { className, onClick, fields, sectionTitle } = props;
		const classes = useStyles();

		const { applicationStatus, settings } = useAppLoad();
		const [sectionIsEditable, setSectionIsEditable] = useState(false);

		const IconButton = sectionIsEditable ? SaveIcon : EditIcon;

		return (
			<Grid
				container
				spacing={3}
				className={clsx(className, classes.card)}
				onClick={onClick}
			>
				<Grid
					container
					direction="row"
					justify="space-between"
					className={classes.header}
				>
					<Grid item className={classes.headerItem}>
						<Typography variant="h5">{sectionTitle}</Typography>
					</Grid>
					<Grid item className={classes.headerItem}>
						<CardActionArea
							onClick={() => setSectionIsEditable(!sectionIsEditable)}
							className={classes.editDiv}
						>
							<Grid item>
								<Typography variant="h6">
									{sectionIsEditable ? "Lagre" : "Rediger"}
								</Typography>
							</Grid>
							<Grid item className={classes.editIconButton}>
								<IconButton />
							</Grid>
						</CardActionArea>
					</Grid>
				</Grid>
				<Section
					{...props}
					sectionTitle={undefined}
					literal={!sectionIsEditable}
				/>
			</Grid>
		);
	}
);

LockAndEditableSection.displayName = "DployLockAndEditableSection";

export { LockAndEditableSection };
