import React from "react";
import { useIntl } from "react-intl";
import ContentListItem from "../../ContentListItem";
import { RouterListItem } from "@ploy-ui/core";
import {
	ApplicationInfoFilter,
	ApplicationInfoResource,
	ID,
	useResourceWithInvalidate
} from "@ploy-lib/rest-resources";
import { Divider } from "@material-ui/core";
import { DashboardContentProps } from "../../types";
import { useFormatMessage } from "../../hooks/useMessageFormatter";

export interface TaskFilter {
	id: ID;
	name: string;
	filter: ApplicationInfoFilter;
	primaryMessage: string;
	secondaryMessage?: string;
	iconClass?: string;
	icon?: React.ReactElement;
}

export interface TaskListItemProps
	extends Pick<
			DashboardContentProps,
			"setApplicationsSearchParams" | "applicationsSearchPath"
		>,
		TaskFilter {
	hideDivider?: boolean;
	showEmptyResult?: boolean;
	inset?: boolean;
}

export function TaskListItem(props: TaskListItemProps) {
	const {
		id,
		name,
		filter,
		applicationsSearchPath,
		setApplicationsSearchParams,
		primaryMessage,
		secondaryMessage,
		showEmptyResult,
		...rest
	} = props;

	const results = useResourceWithInvalidate(ApplicationInfoResource.list(), {
		...filter,
		pageSize: 1
	});

	const formatMessage = useFormatMessage();

	const messages = {
		primary: primaryMessage
			? formatMessage(primaryMessage, { amount: results.recordCount })
			: primaryMessage,
		secondary: secondaryMessage
			? formatMessage(secondaryMessage, { amount: results.recordCount })
			: secondaryMessage
	};

	const onClick =
		setApplicationsSearchParams && (() => setApplicationsSearchParams(filter));

	if (results.recordCount <= 0 && !showEmptyResult) return null;

	let listItem = <ContentListItem {...messages} {...rest} />;

	if (onClick)
		listItem = (
			<ContentListItem {...messages} {...rest} onClick={onClick} button />
		);

	if (applicationsSearchPath)
		listItem = (
			<ContentListItem
				{...messages}
				{...rest}
				onClick={onClick}
				button
				component={RouterListItem}
				to={applicationsSearchPath}
			/>
		);

	return (
		<>
			{listItem}
			<Divider />
		</>
	);
}
