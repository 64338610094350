import { BaseCamelCasedResource } from "./BaseResource";
import { apiResourceUrl } from "@ploy-lib/core";

export interface WholesaleLimitDetail {
	customerNumber: string;
	source: string;
	applicationNumber: string;
	product: string;
	carModel: string;
	regNo: string;
	serial: string;
	prodNo: string;
	loanAmount: string;
	openDate: Date;
}

export class WholesaleLimitDetailResource extends BaseCamelCasedResource
	implements WholesaleLimitDetail {
	readonly customerNumber: string;
	readonly source: string;
	readonly applicationNumber: string;
	readonly product: string;
	readonly carModel: string;
	readonly regNo: string;
	readonly serial: string;
	readonly prodNo: string;
	readonly loanAmount: string;
	readonly openDate: Date;
	pk() {
		return `${this.applicationNumber}`;
	}

	static urlRoot = apiResourceUrl("wholesaleLimit/{vendorId}/detail");
}
