import { CellProps, HeaderProps, Hooks } from "react-table";
import "../react-table-config";

import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(theme => ({
	checked: {},
	root: {
		"&$checked": {
			color: theme.palette.secondary.contrastText
		}
	},
	colorSecondary: {
		color: theme.palette.secondary.contrastText
	}
}));
export function useSelectionColumn<T extends object = {}>(hooks: Hooks<T>) {
	hooks.visibleColumns.push(columns => [
		// Let's make a column for selection
		{
			id: "selection",
			disableGroupBy: true,
			disableSortBy: true,
			disableFilters: true,
			disableGlobalFilter: true,
			disableResizing: true,
			disableEditing: true,
			Header,
			Cell
		},
		...columns
	]);
}

// The header can use the table's getToggleAllRowsSelectedProps method
// to render a checkbox
function Header<T extends object = {}>({
	getToggleAllRowsSelectedProps
}: HeaderProps<T>) {
	const classes = useStyles();
	return (
		<div>
			<Checkbox
				classes={{
					root: classes.root,
					checked: classes.checked,
					colorSecondary: classes.colorSecondary
				}}
				{...getToggleAllRowsSelectedProps()}
			/>
		</div>
	);
}

// The cell can use the individual row's getToggleRowSelectedProps method
// to the render a checkbox
function Cell<D extends object, V = any>({
	row,
	onRowSelected,
	isEditing
}: CellProps<D, V>) {
	return (
		<div>
			<Checkbox
				{...row.getToggleRowSelectedProps()}
				disabled={isEditing}
				onChange={e => {
					onRowSelected(row.id, row.index, e.target.checked);
					row.toggleRowSelected(e.target.checked);
				}}
			/>
		</div>
	);
}
