import React from "react";
import { SectionProps } from "../Section";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import clsx from "clsx";
import {
	makeStyles,
	Theme,
	Grid,
	Typography,
	createStyles
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useAppLoad } from "../../appLoadContext";

addRegisteredSectionLayout({
	name: "DocumentStatusSection",
	displayName: "DocumentStatusSection",
	settings: {}
});

const DocumentStatusSection = (props: SectionProps) => {
	const { className, onClick } = props;
	const classes = useStyles(props);

	const { documentStatusDescription } = useAppLoad();

	return (
		<>
			<Grid
				container
				className={clsx(className, classes.root)}
				onClick={onClick}
			>
				<Grid
					item
					xs
					className={clsx(classes.container, classes.headerContainer)}
				>
					<Typography variant="body1" className={classes.header}>
						<FormattedMessage
							id="form.document-status-section.header"
							description="Header for document status section on post process"
							defaultMessage="Dokumentstatus"
						/>
					</Typography>
				</Grid>
				<Grid
					item
					xs
					className={clsx(classes.container, classes.itemContainer)}
				>
					<Typography variant="body2">{documentStatusDescription}</Typography>
				</Grid>
			</Grid>
		</>
	);
};

const useStyles = makeStyles(
	(theme: Theme) =>
		createStyles({
			root: {
				height: theme.spacing(8),
				paddingLeft: theme.spacing(1),
				paddingRight: theme.spacing(1)
			},
			header: {
				textTransform: "uppercase"
			},
			headerContainer: {
				justifyContent: "flex-start"
			},
			itemContainer: {
				justifyContent: "flex-end"
			},
			container: {
				display: "flex",
				alignItems: "center"
			}
		}),
	{ name: "DployDocumentStatusSection" }
);

DocumentStatusSection.displayName = "DployDocumentStatusSection";

export { DocumentStatusSection };
