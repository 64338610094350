import React from "react";
import { FormattedNumber, FormattedDate, FormattedTime } from "react-intl";
import { NumericDployColumn, DatetimeDployColumn } from "./types";

export const getNumberRenderer = <RowData extends object>(
	column: NumericDployColumn<RowData>,
	format?: string
) => (row: RowData) => {
	let value = column.field && row[column.field as string];

	if (column.type === "percent") value = value / 100;

	return Number.isNaN(Number(value)) ? null : (
		<FormattedNumber value={value} format={format} {...column.formatOptions} />
	);
};

export const getDateRenderer = <RowData extends object>(
	column: DatetimeDployColumn<RowData>,
	format?: string
) => (row: RowData) => {
	let value = column.field && row[column.field as string];

	return value ? (
		<FormattedDate value={value} format={format} {...column.formatOptions} />
	) : null;
};

export const getTimeRenderer = <RowData extends object>(
	column: DatetimeDployColumn<RowData>,
	format?: string
) => (row: RowData) => {
	let value = column.field && row[column.field as string];

	return !value ? null : (
		<FormattedTime value={value} format={format} {...column.formatOptions} />
	);
};
