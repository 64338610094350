import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import {
	Cell,
	Column,
	ColumnInstance,
	HeaderGroup,
	IdType,
	Row,
	useGlobalFilter,
	useGroupBy,
	useSortBy,
	useRowSelect,
	useTable,
	usePagination,
	CellProps
} from "react-table";
import "./react-table-config";
import "./types";
import { useIntl } from "react-intl";
import { Formik, useField, useFormikContext } from "formik";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import AddIcon from "@material-ui/icons/Add";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { SectionProps } from "../../../Section";
import { TableToolbar } from "./TableToolbar";
import { DataProviderProps } from "../TableDataProvider";
import messages from "../messages";
import { useEditableActionsColumn, useSelectionColumn } from "./table-plugins";
import { mapTableTypeToMessageId, toFakeCamelCase } from "../utils";
import { DployFormControl, SelectField } from "@ploy-ui/form-fields";
import { ErrorDisplay, TableColumnOptionSource } from "@ploy-lib/types";
import { EditingSchemaField, SimpleTextLiteral } from "./TableFields";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { RowActions } from "./types";
import { standardValidation, requiredValidation } from "../tableSchema";
import { tableSchemas } from "../tableSchema";
import clsx from "clsx";

const getItemLabel = (item: any, optionSource?: TableColumnOptionSource) => {
	let keyLabelToReturn: any;
	if (typeof item === "object") {
		keyLabelToReturn = item?.key
			? item.key
			: item?.[optionSource?.key ?? "key"];
	} else {
		keyLabelToReturn = item;
	}
	return keyLabelToReturn?.toString();
};

const getItemValue = (item: any, optionSource?: TableColumnOptionSource) => {
	let itemValueToReturn: any;
	if (typeof item === "object") {
		itemValueToReturn = item?.value
			? item.value
			: item?.[optionSource?.value ?? "value"];
	} else {
		itemValueToReturn = item;
	}
	return itemValueToReturn?.toString();
};

const useStyles = makeStyles(theme => ({
	divider: {
		backgroundColor: "lightgrey",
		width: "4px",
		padding: "0px"
	},
	transparent: {
		color: theme.palette.text.primary,
		backgroundColor: "transparent !important",
		border: "0px"
	},
	lowContrastText: {
		color: theme.palette.text.disabled
	}
}));

export function FormTable<T extends object>(
	props: SectionProps & DataProviderProps<T>
) {
	const {
		tableSchema,
		rows: tableData,
		tableColumns,
		sectionTitle: title,
		tableFilter,
		saveElement,
		deleteElement,
		emptyElement,
		mergeColumnValues,
		submitOnRowChange,
		emptyTableMessage,
		borderlessTable,
		collapsedSection,
		fullWidth
	} = props;

	const schema = tableSchema ? tableSchemas?.[tableSchema] : undefined;
	const metaData = schema?.metaData;

	// const [multiselect, setMultiselect] = useState(props.multiselect);
	// const [isEditable, setIsEditable] = useState(props.tableIsEditable);

	const { submitForm } = useFormikContext();
	const classes = useStyles();

	const [submittingRowIds, setSubmittingRowIds] = useState([] as number[]);

	const canEdit = props.tableIsEditable && !props.literal;
	const canAdd = props.canAddNewRows && props.tableIsEditable && !props.literal;
	const canDelete =
		props.canDeleteRow && props.tableIsEditable && !props.literal;

	const intl = useIntl();
	const [editingRowIds, setEditingRowIds] = useState(
		{} as Record<IdType<T>, boolean>
	);

	const skipPageResetRef = React.useRef(false);
	useEffect(() => {
		// After the table has updated, always remove the flag
		skipPageResetRef.current = false;
	});

	const isEditing = Object.values(editingRowIds).some(x => x);
	const [isSelecting, setIsSelecting] = useState(false);

	const [newRow, setNewRow] = useState(null as T | null);
	const data = useMemo(
		() => (newRow != null ? [...tableData, newRow] : tableData),
		[tableData, newRow]
	);

	const setRowEditing = useCallback(
		(id, editing) => {
			var newEditingRowIds = { ...editingRowIds };
			if (editing === true) newEditingRowIds[id as IdType<T>] = true;
			else {
				newEditingRowIds[id as IdType<T>] = false;
				if (data.length - 1 === id) setNewRow(null);
			}
			setEditingRowIds(newEditingRowIds);
		},
		[editingRowIds, setEditingRowIds, setNewRow, data.length]
	);

	const onRowSave = useCallback<RowActions<T>["onRowSave"]>(
		(id, index, values) => {
			skipPageResetRef.current = true;
			var newRows = [...tableData];
			newRows[index] = { ...newRows[index], ...values, edited: true };
			if (saveElement) saveElement(newRows);
			if (submitOnRowChange && !isSelecting) {
				setSubmittingRowIds(state => [...state, index]);
				submitForm().then(() =>
					setSubmittingRowIds(state => state.filter(s => s === index))
				);
			}
		},
		[tableData, saveElement, submitOnRowChange, isSelecting, submitForm]
	);

	const onRowDelete = useCallback<RowActions<T>["onRowDelete"]>(
		(id, index) => {
			skipPageResetRef.current = true;
			if (deleteElement) deleteElement(data, index);
		},
		[deleteElement, data]
	);

	const onRowSelected = useCallback<RowActions<T>["onRowSelected"]>(
		(id, index, value) => {
			skipPageResetRef.current = true;
			var rows = [...data];
			rows[index] = { ...rows[index], selected: value, edited: true };
			if (saveElement) saveElement(rows);
		},
		[saveElement, data]
	);

	const handleAddRowClick = useCallback(
		(cell?: Cell<T>) => {
			const newRow = emptyElement();

			if (cell) {
				(newRow as any)[cell.column.id] = cell.row.values[cell.column.id];
			}

			if (metaData) {
				Object.keys(metaData).forEach(fieldName => {
					if (metaData?.[fieldName]?.defaultValue) {
						(newRow as any)[fieldName] = metaData[fieldName].defaultValue;
					}
				});
			}

			setRowEditing(data.length, true);
			setNewRow(newRow);
		},
		[emptyElement, metaData, setRowEditing, data.length]
	);

	const columns = useMemo(() => {
		let columns = [] as Column<T>[];
		columns.push({
			id: "index",
			accessor: (_row, i) => i + 1
		} as Column<T>);
		columns.push(
			...(tableColumns?.map(
				(tc): Column<T> => {
					const name = toFakeCamelCase(tc.name);
					const messageId = `${mapTableTypeToMessageId(tc.tableType)}.${name}`;
					const isDivider = name === "divider";

					const schemaName = getSchemaName(name);
					const aggregateFooter = metaData?.[schemaName]?.aggregateFooter;
					const schemaAggregate = metaData?.[schemaName]?.aggregate;
					const aggregate = schemaAggregate
						? schemaAggregate
						: tc.showSum
						? rows =>
								rows.reduce((sum, row) => (row?.[schemaName] ?? 0) + sum, 0)
						: undefined;

					return {
						id: name,
						accessor: name as keyof T,
						disableGlobalFilter: !tableFilter?.includes(name),
						defaultCanGroupBy: tc.isGroupingColumn,
						disableSortBy: true,
						aggregate: aggregate,
						canEdit: tc.editable,
						optionValues: tc.optionValues,
						groupLabel: tc.groupLabel,
						isDivider: isDivider,
						Header: tc.label
							? tc.label
							: messages[messageId]
							? intl.formatMessage(messages[messageId])
							: tc.name,
						Aggregated: ({ row }) => {
							if (aggregate) {
								const value = aggregate(row.leafRows.map(lr => lr.original));
								return (
									<SimpleTextLiteral
										value={value ?? ""}
										fieldMetadata={metaData?.[schemaName]}
									/>
								);
							}
							return null;
						},
						Footer: ({ data }) => {
							if (tc.isGroupingColumn && tc.footerGroupLabel)
								return (
									<Typography
										variant="subtitle2"
										style={{ fontWeight: "inherit" }}
									>
										{tc.footerGroupLabel}
									</Typography>
								);
							if (aggregate || aggregateFooter) {
								const value = aggregateFooter?.(data) ?? aggregate(data);
								return (
									<SimpleTextLiteral
										value={value ?? ""}
										fieldMetadata={metaData?.[schemaName]}
									/>
								);
							}
							return null;
						},
						Editing: ({ row, column, form }) => {
							const [field, meta, helpers] = useField(`${column.id}`);
							const error = !!form?.errors?.[column.id];

							return tc?.optionSource ||
								(tc?.optionValues && tc?.optionValues?.length > 0) ? (
								<SelectField
									variant="standard"
									size="small"
									field={field}
									form={form}
									meta={meta}
									error={error}
									errorDisplay={ErrorDisplay.AlwaysNoMessage}
									disableClearable={tc.isMultipleSelect}
									searchable
									multiple={tc.isMultipleSelect}
									items={
										tc.optionValues
											?.filter(item => item.key || item.value)
											.sort((a, b) => a.key.localeCompare(b.key)) || []
									}
									getItemLabel={(item?: any) =>
										getItemLabel(item, tc.optionSource)
									}
									getItemValue={(item?: any) =>
										getItemValue(item, tc.optionSource)
									}
									fullWidth
									minWidthFocused={200}
								/>
							) : field ? (
								<EditingSchemaField
									field={field}
									onChangeHandler={helpers.setValue}
									error={error}
									fieldMetadata={metaData?.[schemaName]}
								/>
							) : null;
						},
						Cell: ({ value, row, cell, isFirstInGroup }: CellProps<T>) => {
							var displayValueFunc = metaData?.[name]?.displayValue;
							var displayValue = value;
							if (displayValueFunc)
								displayValue =
									displayValueFunc?.(
										value,
										row.original,
										row.cells,
										cell.isPlaceholder,
										isFirstInGroup
									) ?? value;
							return (
								<>
									<SimpleTextLiteral
										value={displayValue ?? ""}
										items={tc.optionValues}
										getItemLabel={(item?: any) =>
											getItemLabel(item, tc.optionSource)
										}
										getItemValue={(item?: any) =>
											getItemValue(item, tc.optionSource)
										}
										multiple={tc.isMultipleSelect}
										hrefLink={tc.hrefLink?.replace("{{value}}", value)}
										fieldMetadata={metaData?.[schemaName]}
									/>

									{canAdd && cell.isPlaceholder && isFirstInGroup && (
										<IconButton
											color="secondary"
											onClick={() => handleAddRowClick(cell)}
										>
											<AddIcon />
										</IconButton>
									)}
								</>
							);
						}
					};
				}
			) ?? [])
		);

		return columns;
	}, [canAdd, handleAddRowClick, intl, metaData, tableColumns, tableFilter]);

	const initialState = useMemo(() => {
		const hiddenCols: string[] = [];
		hiddenCols.push("index");
		if (!props.multiselect) hiddenCols.push("selection");
		if (props.literal || !props.tableIsEditable) hiddenCols.push("editing");

		const initialGroupBy = columns
			.filter(c => c.defaultCanGroupBy)
			.map(c => c.id as string);

		return {
			pageSize: 25,
			pageIndex: 0,
			groupBy: initialGroupBy,
			// STOPS SORTING GROUPS! Anoyingly grouping sorts rows without consent
			sortBy: [{ id: "index" }],
			hiddenColumns: hiddenCols
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const disableGlobalFilter = (tableFilter?.length ?? 0) === 0;

	const {
		getTableProps,
		headerGroups,
		footerGroups,
		page,
		prepareRow,
		state,
		setGlobalFilter,
		visibleColumns,
		rowsById,
		deleteRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex }
	} = useTable<T>(
		{
			columns,
			data: data,
			initialState: initialState,
			disableGlobalFilter,
			expandSubRows: false,
			autoResetPage: !skipPageResetRef.current,
			autoResetExpanded: !skipPageResetRef.current,
			autoResetGroupBy: !skipPageResetRef.current,
			autoResetSelectedRows: !skipPageResetRef.current,
			autoResetSortBy: !skipPageResetRef.current,
			autoResetFilters: !skipPageResetRef.current,
			autoResetRowState: !skipPageResetRef.current,
			editingRowIds,
			submittingRowIds,
			isEditing,
			isSelecting,
			canAdd,
			canEdit,
			canDelete,
			setRowEditing,
			onRowDelete,
			onRowSelected,
			schema,
			pageCount: -1
		},
		useGlobalFilter,
		useGroupBy,
		useSortBy,
		// useExpanded,
		// useExpandedFooter,
		usePagination,
		useRowSelect,
		useSelectionColumn,
		useEditableActionsColumn
	);

	useEffect(() => {
		setIsSelecting(Object.values(state.selectedRowIds).some(x => x));
	}, [state.selectedRowIds]);

	const handleDeleteSelected = useCallback(() => {
		const rowsToRemove = Object.entries(state.selectedRowIds)
			.filter(([_, selected]) => selected)
			.map(([id]) => rowsById[id])
			// Remove the higher index first, otherwise the indexes will change
			.sort((a, b) => b.index - a.index);

		for (const row of rowsToRemove) {
			deleteRow(row.id);
		}
	}, [deleteRow, rowsById, state.selectedRowIds]);

	const rowsToRender = mergeColumnValues
		? page
		: page.flatMap(x => [...x.subRows, x]);

	// useEffect(() => {
	// 	console.log(rowsToRender);
	// 	console.log(footerGroups);
	// }, [rowsToRender, footerGroups]);

	if (emptyTableMessage && rowsToRender.length === 0) {
		return (
			<Typography
				variant="body1"
				className={clsx({ [classes.lowContrastText]: collapsedSection })}
			>
				{emptyTableMessage}
			</Typography>
		);
	}
	return (
		<DployFormControl fullWidth={fullWidth} margin="normal">
			{disableGlobalFilter && !props.multiselect ? null : (
				<TableToolbar
					searchValue={state.globalFilter}
					onSearch={disableGlobalFilter ? undefined : setGlobalFilter}
					title={title}
					numSelected={
						Object.values(state.selectedRowIds ?? {}).filter(Boolean).length
					}
					onDelete={handleDeleteSelected}
				/>
			)}
			<TableContainer>
				<Table {...getTableProps()}>
					<TableHead>
						{headerGroups.map(headerGroup => (
							<TableRow {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<TableCell
										{...column.getHeaderProps(column.getSortByToggleProps?.())}
										className={clsx({
											[classes.divider]: column.isDivider,
											[classes.transparent]: borderlessTable
										})}
									>
										{column.isDivider ? null : (
											<>
												{column.render("Header")}
												{column.canSort ? (
													<TableSortLabel
														active={column.isSorted}
														// react-table has a unsorted state which is not treated here
														direction={column.isSortedDesc ? "desc" : "asc"}
													/>
												) : null}
											</>
										)}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableHead>
					<TableBody>
						{rowsToRender
							// .filter(row => row.subRows.length !== 1)
							.map((row, idx, arr) => {
								const previous = idx > 0 ? arr[idx - 1] : undefined;
								const next = idx < arr.length - 1 ? arr[idx + 1] : undefined;
								if (idx === 0) prepareRow(row);
								if (next) prepareRow(next);
								if (!mergeColumnValues && row.subRows.length === 1) return null;

								const isFirstInGroup =
									previous === undefined ||
									(previous.isGrouped && !row.isGrouped);

								const rowAggregatingAbove =
									previous &&
									(row.subRows?.includes(previous) ||
										row.leafRows?.includes(previous));

								const rowAggregatingBelow =
									next &&
									(row.subRows?.includes(next) || row.leafRows?.includes(next));

								return [
									rowAggregatingBelow && idx !== 0 && (
										<TableRow key={`${row.getRowProps().key}-spacing_above`}>
											<TableCell colSpan={visibleColumns.length} />
										</TableRow>
									),
									<FormTableRow<T>
										key={row.getRowProps().key}
										metaData={metaData}
										row={row}
										saveRow={onRowSave}
										isFirstInGroup={isFirstInGroup}
										mergeColumnValues={mergeColumnValues ?? false}
										canAdd={(canAdd && isFirstInGroup) ?? false}
										onClickAdd={handleAddRowClick}
										editingRowIds={editingRowIds}
										aggregating={
											rowAggregatingAbove
												? "above"
												: rowAggregatingBelow
												? "below"
												: undefined
										}
										borderlessTable={borderlessTable ?? false}
										isReadOnlyRowFunc={schema?.isReadOnlyRow}
									/>,
									rowAggregatingAbove && idx !== arr.length - 1 && (
										<TableRow key={`${row.getRowProps().key}-spacing_below`}>
											<TableCell colSpan={visibleColumns.length} />
										</TableRow>
									)
								];
							})}

						<FormTableFooterRow
							rows={rowsToRender}
							visibleColumns={visibleColumns}
							footerGroups={footerGroups}
							canAdd={canAdd ?? false}
							onClickAdd={handleAddRowClick}
							borderlessTable={borderlessTable ?? false}
							tableHasMergedValuesInRows={mergeColumnValues ?? false}
						/>
					</TableBody>
				</Table>
			</TableContainer>
			<TableNavigation
				canPreviousPage={canPreviousPage}
				canNextPage={canNextPage}
				pageOptions={pageOptions}
				pageCount={pageCount}
				gotoPage={gotoPage}
				nextPage={nextPage}
				previousPage={previousPage}
				setPageSize={setPageSize}
				pageIndex={pageIndex}
			/>
		</DployFormControl>
	);
}

const TableNavigation = (props: any) => {
	const {
		canPreviousPage,
		canNextPage,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		pageIndex
	} = props;
	if (!canPreviousPage && !canNextPage) return null;
	return (
		<div style={{ textAlign: "center" }}>
			<Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
				<FirstPageIcon />
			</Button>
			<Button onClick={() => previousPage()} disabled={!canPreviousPage}>
				<ChevronLeftIcon />
			</Button>
			{pageCount > 0 ? pageIndex + 1 : 0} / {pageCount}
			<Button onClick={() => nextPage()} disabled={!canNextPage}>
				<ChevronRightIcon />
			</Button>
			<Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
				<LastPageIcon />
			</Button>
		</div>
	);
};

function FormTableFooterRow<T extends object>(props: {
	rows: Row<T>[];
	visibleColumns: ColumnInstance<T>[];
	footerGroups: HeaderGroup<T>[];
	canAdd: boolean;
	onClickAdd: () => void;
	borderlessTable: boolean;
	tableHasMergedValuesInRows: boolean;
}) {
	const {
		rows,
		canAdd,
		onClickAdd,
		footerGroups,
		visibleColumns,
		borderlessTable,
		tableHasMergedValuesInRows
	} = props;
	const showFooterCells = rows.filter(row => row.subRows.length > 0).length > 1;
	// ATM we only use the first footerGroup.
	// Expand this component if more footer groups are needed
	const footerGroup = footerGroups[0];
	const rowClasses = useRowClasses();

	return (
		<>
			{showFooterCells && !tableHasMergedValuesInRows ? (
				<TableRow>
					<TableCell colSpan={visibleColumns.length} />
				</TableRow>
			) : null}
			{!canAdd && !showFooterCells ? null : (
				<TableRow {...footerGroup.getFooterGroupProps()}>
					{footerGroup.headers.map((column, index) => {
						if (column.id !== "editing" && !showFooterCells) return null;
						return (
							<TableCell
								{...column.getFooterProps()}
								className={clsx({ [rowClasses.borderless]: borderlessTable })}
								style={{
									border: showFooterCells ? undefined : "0",
									borderTop: showFooterCells ? "solid 2px black" : undefined,
									fontWeight: "bold"
								}}
							>
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									{column.id === "editing" ? (
										<IconButton onClick={() => onClickAdd()} color="secondary">
											<AddIcon />
										</IconButton>
									) : (
										column.render("Footer")
									)}
								</Box>
							</TableCell>
						);
					})}
				</TableRow>
			)}
		</>
	);
}

const useRowClasses = makeStyles(theme => ({
	aggregatingAbove: {
		borderTop: "solid 2px black"
	},
	aggregatingBelow: {
		borderBottom: "solid 2px black"
	},
	divider: {
		backgroundColor: "lightgrey",
		width: "4px",
		padding: "0px"
	},
	grouped: {
		fontWeight: "bold"
	},
	readOnlyRow: {
		color: "lightGrey"
	},
	borderless: {
		border: "0px !important"
	}
}));

function FormTableRow<T extends object>(props: {
	metaData?: Record<string, any>;
	row: Row<T>;
	saveRow: RowActions<T>["onRowSave"];
	editingRowIds: Record<IdType<T>, boolean>;
	isFirstInGroup: boolean;
	canAdd: boolean;
	mergeColumnValues: boolean;
	onClickAdd: (cell?: Cell<T>) => void;
	aggregating?: "above" | "below";
	borderlessTable: boolean;
	isReadOnlyRowFunc?: (rowData: any, cells: any) => boolean;
}) {
	const {
		metaData,
		row,
		editingRowIds,
		aggregating,
		isFirstInGroup,
		mergeColumnValues,
		saveRow,
		borderlessTable,
		isReadOnlyRowFunc
	} = props;

	const rowClasses = useRowClasses();

	const editing = editingRowIds[row.id];
	const [editValues, setEditValues] = useState(row.original);
	const validationSchema = metaData
		? Yup.object().shape(
				Object.entries(metaData).reduce(
					(newObj, [key, value]: [string, any]) => {
						var validation = standardValidation[value.fieldType];
						// Consider moving isRequired to interface designer as column option
						if (value.isRequired)
							validation = validation.concat(
								requiredValidation[value.fieldType]
							);
						// Consider validatorFunctions to be configurable in interface designer as column option
						if (value.validatorSchemas)
							validation = validation.concat(
								Object.values(value.validatorSchemas)[0]
							);
						newObj[key] = validation;
						return newObj;
					},
					{}
				)
		  )
		: undefined;

	useEffect(() => {
		if (editing) setEditValues(row.original);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editing, setEditValues]);

	return (
		<Formik
			initialValues={{
				...editValues
			}}
			enableReinitialize
			onSubmit={values => {
				saveRow(row.id, row.index, values as T);
			}}
			validationSchema={validationSchema}
			validateOnChange={false}
			validateOnBlur={false}
		>
			{form => (
				<TableRow {...row.getRowProps()}>
					{row.cells.map((cell, cellIdx) => {
						const isGrouping =
							cell.isGrouping ??
							row.id.split(">").includes(`${cell.column.id}:${cell.value}`);

						const isGroupAggregate = row.isGrouped && !isGrouping;

						const cellAggregatingAbove =
							aggregating === "above" &&
							// !cell.column.disableGroupBy &&
							(isGroupAggregate || cell.isAggregated || cell.isGrouped);

						const cellAggregatingBelow =
							aggregating === "below" &&
							// !cell.column.disableGroupBy &&
							(isGroupAggregate || cell.isAggregated || cell.isGrouped);

						// if tc.isEditableFunction TBA in interface designer column options
						// MVP solution. Select first function, if any
						const isReadOnlyRow =
							isReadOnlyRowFunc?.(row.original, row.cells) ?? false;
						const isEditableFunctions =
							metaData?.[cell.column.id]?.isEditableFunctions;
						const isEditableFunction: any = isEditableFunctions
							? Object.values(isEditableFunctions)[0]
							: () => true;
						const isEditable =
							cell.column.canEdit &&
							!isReadOnlyRow &&
							isEditableFunction(form.values, row.cells);
						const isEditing =
							editing && !cell.column.disableEditing && isEditable;

						const isDivider = cell.column.isDivider;

						return (
							<TableCell
								{...cell.getCellProps()}
								className={clsx({
									[rowClasses.aggregatingAbove]: cellAggregatingAbove,
									[rowClasses.aggregatingBelow]: cellAggregatingBelow,
									[rowClasses.divider]: isDivider,
									[rowClasses.grouped]: row.isGrouped,
									[rowClasses.readOnlyRow]: isReadOnlyRow,
									[rowClasses.borderless]: borderlessTable
								})}
							>
								<Box
									display="flex"
									justifyContent={
										cell.column.id?.toLowerCase() === "editing"
											? "center"
											: "space-between"
									}
									alignItems="center"
								>
									{isDivider ? null : cell.isGrouped && mergeColumnValues ? (
										cell.render("Cell")
									) : cell.isGrouped ? (
										cell.column.groupLabel ? (
											<Typography
												variant="subtitle2"
												style={{ fontWeight: "inherit" }}
											>
												{cell.column.groupLabel}
											</Typography>
										) : null
									) : cell.isAggregated ? (
										cell.render("Aggregated")
									) : (
										<>
											{isEditing
												? cell.render("Editing", { form })
												: cell.render("Cell", {
														isFirstInGroup
												  })}
										</>
									)}
								</Box>
							</TableCell>
						);
					})}
				</TableRow>
			)}
		</Formik>
	);
}
FormTableRow.displayName = "FormTableRow";

const getSchemaName = (name: string) => {
	const nameArray = name.split(":");
	return toFakeCamelCase(nameArray[nameArray.length - 1]);
};
