import React from "react";

import {
	useStyles,
	appActionsTheme,
	listSummaryHeaderTheme,
	mainHeaderTheme,
	mainTheme,
	footerTheme
} from "./styles";
import { TemplatePageProps, LayoutPage } from "../../../types";
import { addRegisteredLayout, PanelSettings } from "../layoutDescriptions";
import { Grid, Paper } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Panel } from "../../../panel";
import { TemplatePanel } from "@ploy-lib/types";
import { LoginResource } from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";
import { makeStyles } from "@material-ui/core/styles";

export type ListPanels = {
	mainHeader: TemplatePanel;
	main: TemplatePanel;
	summaryHeader: TemplatePanel;
	actions: TemplatePanel;
	appActions: TemplatePanel;
	header: TemplatePanel;
	footer: TemplatePanel;
};
export type ListPanelKeys = keyof ListPanels;
export type ListPage = LayoutPage<ListPanelKeys, ListPanels>;

const mainPanelSettings: PanelSettings = {
	editableOptions: {
		title: false,
		elevation: true,
		square: true
	}
};

const panelSettings: Record<ListPanelKeys, PanelSettings> = {
	header: {},
	summaryHeader: {
		defaultOptions: {
			literal: true
		}
	},
	mainHeader: {},
	main: mainPanelSettings,
	actions: {},
	appActions: {},
	footer: {}
};

addRegisteredLayout({
	name: "List",
	displayName: "List",
	panels: panelSettings,
	hideFunctionButtons: true
});

addRegisteredLayout({
	name: "CenteredList",
	displayName: "CenteredList",
	panels: panelSettings,
	hideFunctionButtons: true
});

export interface ListProps extends TemplatePageProps<ListPage> {
	centered: boolean;
}

export const List = (props: ListProps) => {
	const { panels, hideFunctionButtons, centered } = props;

	const classes = useStyles(props);

	const login = useResource(LoginResource.status(), {});

	const {
		header,
		summaryHeader,
		mainHeader,
		main,
		actions,
		appActions,
		footer
	} = panels;

	return (
		<Grid container spacing={3} justify={centered ? "center" : undefined}>
			<Grid item xs={12} md={8}>
				<Panel transparent fullWidth justify="flex-end" {...header} />
			</Grid>
			{!login.isInternal && !hideFunctionButtons && (
				<Grid item xs={12} md={8}>
					<MuiThemeProvider theme={appActionsTheme}>
						<Panel transparent fullWidth {...appActions} justify="flex-end" />
					</MuiThemeProvider>
				</Grid>
			)}
			<Grid item xs={12} md={8}>
				<Paper elevation={0}>
					{summaryHeader && (
						<MuiThemeProvider theme={listSummaryHeaderTheme}>
							<Panel
								fullWidth
								className={classes.summaryHeader}
								{...summaryHeader}
							/>
						</MuiThemeProvider>
					)}
				</Paper>
			</Grid>
			<Grid item xs={12} md={8}>
				{mainHeader && (
					<MuiThemeProvider theme={mainHeaderTheme}>
						<Panel {...mainHeader} />
					</MuiThemeProvider>
				)}
			</Grid>
			<Grid item xs={12} md={8}>
				{main && (
					<MuiThemeProvider theme={mainTheme}>
						<Panel {...main} />
					</MuiThemeProvider>
				)}
			</Grid>
			<Grid item xs={12} md={8}>
				{actions && <Panel transparent justify="center" {...actions} />}
			</Grid>
			<Grid item xs={12} md={8}>
				{footer && (
					<MuiThemeProvider theme={footerTheme}>
						<Panel transparent fullWidth justify="flex-end" {...footer} />
					</MuiThemeProvider>
				)}
			</Grid>
		</Grid>
	);
};

export const CenteredList = (props: ListProps) => (
	<List {...props} centered={true} />
);
