import React from "react";
import { useFormikValueOnChange } from "./hooks";
import {
	TextFieldProps as FormikTextFieldProps,
	fieldToTextField
} from "formik-material-ui";
import { Slider } from "@material-ui/core";
import { identityRecordOfFieldEditorOptions } from "./utils";
import { IntlNumberFieldProps } from "./helpers/IntlNumberField";
import { NumberFieldProps } from "./NumberFields";

export type SliderProps = {
	max?: number;
	min?: number;
	step?: number;
	color?: ColorType;
};

const colors = ["primary", "secondary", "default"] as const;

type ColorType = typeof colors[number];

export function SliderField(props: NumberFieldProps & SliderProps) {
	const onChange = useFormikValueOnChange(props);

	const numberProps = fieldToTextField(
		props as FormikTextFieldProps
	) as IntlNumberFieldProps;

	return (
		<Slider
			color={props.color || "secondary"}
			step={props.step}
			min={props.min || 0}
			max={props.max || 100}
			value={numberProps.value || 0}
			onChange={(event, value) =>
				onChange(typeof value === "number" ? value : value[0] || 0)
			}
		/>
	);
}

export const EditorSliderFields = identityRecordOfFieldEditorOptions({
	SliderField: {
		editableOptions: {
			color: colors
		}
	}
});
