import { useMemo, useEffect } from "react";
import { usePageState } from "../PageContext";
import { LayoutPage } from "../types";
import { InjectedFormikProps, setIn, connect } from "formik";
import { isNotLiteral, isTouchyLiteralOrNotLiteral } from "../utils";
import { getFieldName } from "@ploy-lib/core";
import { usePages } from "../pagesContext";
import { TemplateField, TemplateSection } from "@ploy-lib/types";
import {
	useTemplateSectionIsVisible,
	useTemplateFieldIsVisible
} from "../hooks";

export const TouchVisibleFieldsOnPreviousPages = connect(({ formik }) => {
	const pages = usePages();
	const { step } = usePageState();

	const previousPages = useMemo(() => pages.slice(0, step), [pages, step]);

	const fieldIsVisible = useTemplateFieldIsVisible();
	const sectionIsVisible = useTemplateSectionIsVisible();
	const { touched, setTouched } = formik;
	useEffect(
		() =>
			setTouched(
				touchAllVisibleFields(
					touched,
					previousPages,
					fieldIsVisible,
					sectionIsVisible
				)
			),
		[fieldIsVisible, sectionIsVisible, previousPages, setTouched, touched]
	);

	return null;
});

export function touchAllVisibleFields(
	touched: InjectedFormikProps<{}, any>["touched"],
	pages: LayoutPage[],
	fieldIsVisible: (field: TemplateField) => boolean,
	sectionIsVisible: (section: TemplateSection) => boolean
) {
	const paths = pages
		.flatMap(page => Object.values(page.panels))
		.filter(isNotLiteral)
		.flatMap(panel => panel.sections || [])
		.filter(isNotLiteral)
		.filter(sectionIsVisible)
		.flatMap(section => section.fields)
		.filter(isTouchyLiteralOrNotLiteral)
		.filter(fieldIsVisible)
		.map(field => getFieldName(field));

	return paths.reduce((t, p) => setIn(t, p, true), touched);
}
