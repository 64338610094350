import React, { useEffect, memo, Suspense } from "react";
import clsx from "clsx";
import { PaymentPlan, PendingButton } from "@ploy-ui/core";
import { SectionProps } from "../Section";
import { useServiceHandler } from "../../hooks";
import { Typography, CircularProgress } from "@material-ui/core";
import { useVariableData } from "@ploy-lib/calculation";
import { makeStyles } from "@material-ui/core/styles";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { defineMessages, useIntl } from "react-intl";

const useStyles = makeStyles(
	{
		root: {
			textAlign: "center",
			"&&": {
				maxWidth: "100%"
			}
		},
		overlayProgress: {
			width: "100%",
			zIndex: 1000
		}
	},
	{ name: "DployPaymentPlanSection" }
);

addRegisteredSectionLayout({
	name: "PaymentPlanSection",
	displayName: "PaymentPlanSection",
	settings: {}
});

const messages = defineMessages({
	print: {
		id: "ploy-ui.template-form.paymentplansection.button.print",
		description: "Print button",
		defaultMessage: "Skriv ut"
	},
	error: {
		id: "ploy-ui.template-form.paymentplansection.error",
		description: "Errormessage for paymentplan error",
		defaultMessage: "En feil har oppstått."
	}
});
const PaymentPlanSection = memo((props: SectionProps) => {
	const { className, onClick } = props;

	const intl = useIntl();

	const classes = useStyles(props);

	// Figure out a way to configure this, if necessary
	const service = {
		namespace: "Calculator",
		service: "CalculatePaymentPlan"
	};

	const conditionsResult = useVariableData(
		"Calculator",
		"service_Conditions_result"
	);
	const [handler, pending, result] = useServiceHandler(service);

	useEffect(() => {
		if (typeof handler === "function") handler();
	}, [handler, conditionsResult]);

	const { payments = [] } =
		result && result.data && result.data.payments
			? result.data
			: { payments: [] };
	return (
		<div className={clsx(classes.root, className)} onClick={onClick}>
			{pending && (
				<CircularProgress key="pending" className={classes.overlayProgress} />
			)}

			<Suspense
				fallback={
					pending ? null : (
						<CircularProgress
							key="pending"
							className={classes.overlayProgress}
						/>
					)
				}
			>
				<PaymentPlan payments={payments} />
				{!pending && payments.length > 0 && (
					<div style={{ marginTop: 10, float: "right" }}>
						<PendingButton
							onClick={() => {
								window.open(
									window.location.origin +
										"/PrintView?view=PaymentPlan%3FSesInstName=",
									"_blank"
								);
							}}
							variant={"contained"}
							color={"primary"}
						>
							{intl.formatMessage(messages.print)}
						</PendingButton>
					</div>
				)}
			</Suspense>

			{!pending && payments.length <= 0 && (
				<Typography color="error" className={props.className}>
					{intl.formatMessage(messages.error)}
				</Typography>
			)}
		</div>
	);
});

PaymentPlanSection.displayName = "DployPaymentPlanSection";

export { PaymentPlanSection };
