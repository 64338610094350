import React, { useState } from "react";
import ContentBox from "../../ContentBox";

import EmojiEventsIcon from "@material-ui/icons/EmojiEventsOutlined";
import {
	CompetitionResource,
	CompetitionResult as CompetitionResultType
} from "@ploy-lib/rest-resources";
import { DployAutocomplete } from "@ploy-ui/form-fields";
import { DployColumn } from "@ploy-ui/core";
import { FormattedNumber, FormattedMessage } from "react-intl";
import { Grid, Box, Tooltip } from "@material-ui/core";
import { GridComponent } from "../types";
import { usePrefetchResources } from "../usePrefetchResources";
import { CompetitionResult } from "./CompetitionResult";

export const allColumns: DployColumn<CompetitionResultType>[] = [
	{
		defaultSort: "asc",
		field: "position",
		type: "numeric",
		width: 20,
		headerStyle: {
			padding: 0,
			paddingLeft: 10
		},
		cellStyle: {
			paddingLeft: 0,
			paddingRight: 0,
			textAlign: "center"
		},
		title: (
			<FormattedMessage
				id="dploy.competitions.position.header"
				defaultMessage="Plass"
			/>
		)
	},
	{
		field: "salesPerson",
		title: (
			<FormattedMessage
				id="dploy.competitions.salesPerson.header"
				defaultMessage="Navn"
			/>
		)
	},
	{
		field: "score",
		type: "numeric",
		//	Uncomment and reinsert this when merging to master where it's fixed:
		// cellStyle: {
		// 	textAlign: "center"
		// },
		// headerStyle: {
		// 	textAlign: "center"
		// },
		title: (
			<FormattedMessage
				id="dploy.competitions.score.header"
				defaultMessage="Poeng"
			/>
		)
	},
	{
		field: "contracts",
		type: "numeric",
		title: (
			<FormattedMessage
				id="dploy.competitions.contracts.header"
				defaultMessage="Antall"
			/>
		)
	},
	{
		field: "loanAmount",
		type: "numeric",
		title: (
			<FormattedMessage
				id="dploy.competitions.loanAmount.header"
				defaultMessage="Volum"
			/>
		),
		render: row =>
			row.loanAmount && (
				<FormattedNumber value={row.loanAmount} format="currency" />
			)
	},
	{
		field: "vendor",
		title: (
			<FormattedMessage
				id="dploy.competitions.vendor.header"
				defaultMessage="Forhandler"
			/>
		),
		render: row => (
			<Tooltip title={row.vendor} enterDelay={500} leaveDelay={200}>
				<Box whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
					{row.vendor}
				</Box>
			</Tooltip>
		),
		cellStyle: {
			maxWidth: 1
		}
	}
];

export interface CompetitionsProps {
	visibleColumns?: (keyof CompetitionResultType)[];
	scrollable?: boolean | "0" | "1";
}

const resources = (props: CompetitionsProps) =>
	[[CompetitionResource.list(), { visible: true }]] as const;

const Competitions: GridComponent<
	CompetitionsProps,
	ReturnType<typeof resources>,
	ReturnType<typeof resources>
> = props => {
	const { boxProps, visibleColumns, scrollable = false, placeholder } = props;

	const [competitions = []] = usePrefetchResources(
		Competitions,
		props,
		placeholder
	);

	const [first] = competitions;

	const [selected = first, setSelected] = useState(first);

	const competitionSelector = (
		<Grid container justify="space-between" alignItems="center" spacing={1}>
			<Grid item xs={12} sm={8} md={6}>
				<DployAutocomplete
					key="competition-selector"
					items={competitions}
					value={selected}
					onChange={(e, item) => setSelected(item ?? first)}
					getItemLabel={item => item.name}
					searchable={false}
					disableClearable
					AutocompleteProps={{
						getOptionSelected: (option, value) => option.pk() === value.pk()
					}}
				/>
			</Grid>
			<Grid item>
				{selected && (
					<Box p={1}>
						<FormattedMessage
							id="dploy.competition.info"
							defaultMessage="Gyldig fra {validFrom, date, full} til {validTo, date, full}"
							values={{
								validFrom: selected.starttime
									? new Date(selected.starttime)
									: null,
								validTo: selected.endtime ? new Date(selected.endtime) : null,
								name: selected.name,
								description: selected.description
							}}
						/>
					</Box>
				)}
			</Grid>
		</Grid>
	);

	return (
		<ContentBox
			{...boxProps}
			header={
				<FormattedMessage
					id="dploy.competitions.header"
					defaultMessage="Konkurranse"
				/>
			}
			avatar={<EmojiEventsIcon />}
			fallbackContent={
				<Box display="flex" flexDirection="column" overflow="hidden">
					{competitionSelector}
				</Box>
			}
		>
			<Box display="flex" flexDirection="column" overflow="hidden">
				{competitionSelector}
				{(selected || placeholder) && (
					<CompetitionResult
						visibleColumns={visibleColumns}
						competitionId={selected?.id}
						placeholder={placeholder}
						scrollable={
							typeof scrollable === "string" ? scrollable === "1" : scrollable
						}
					/>
				)}
			</Box>
		</ContentBox>
	);
};

Competitions.getPrefetch = resources;

Competitions.getVisibilityFetch = resources;

Competitions.getVisibility = (props, [competitions]) =>
	competitions?.length > 0;

Competitions.minHeight = 550;

Competitions.displayName = "DployDashboardCompetitions";

export default Competitions;
