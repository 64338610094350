import { ApplicationStatusId } from "@ploy-lib/types";
import { BaseCamelCasedResource } from "./BasePlainResource";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { EndpointExtraOptions } from "@rest-hooks/rest";

export interface RecentApplication {
	applicationNumber: string;
	customerName: string;
	productName: string;
	category: string;
	status: string;
	statusId: ApplicationStatusId;
	documentStatus: string;
	lastChanged: string;
}

export class RecentApplicationResource
	extends BaseCamelCasedResource
	implements RecentApplication {
	readonly applicationNumber = "";
	readonly customerName = "";
	readonly productName = "";
	readonly category = "";
	readonly status = "";
	readonly statusId = ApplicationStatusId.NotSet;
	readonly documentStatus = "";
	readonly lastChanged = new Date().toISOString();

	pk() {
		return this.applicationNumber;
	}

	static getEndpointExtra(): EndpointExtraOptions {
		return {
			...super.getEndpointExtra(),
			dataExpiryLength: 500,
			pollFrequency: 5000
		};
	}

	static urlRoot = legacyApiResourceUrl(
		"RecentApplications/GetRecentApplications"
	);
}
