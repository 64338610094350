import { ID } from "../types";
import { apiResourceUrl } from "@ploy-lib/core";
import { BaseResource } from "./BaseResource";

export interface AvailableFormField {
	availableFormFieldId?: ID;
	name: string;
	namespace: string;
}

export class FormFieldResource extends BaseResource
	implements AvailableFormField {
	readonly availableFormFieldId?: ID;
	readonly name: string;
	readonly namespace: string;

	pk() {
		return this.availableFormFieldId?.toString();
	}

	static urlRoot = apiResourceUrl("formfield");
}
