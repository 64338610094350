import { FieldEditorOptions, BaseFieldProps } from "./types";
import { IntlShape } from "react-intl";
import { FieldProps, getIn, FormikState } from "formik";
import { ErrorDisplay } from "@ploy-lib/types";

export function getFormatOptions<T extends {} = {}>(
	intl: IntlShape,
	formatType: string,
	premadeFormat?: string
): T | undefined {
	const optionsFromFormat =
		premadeFormat &&
		intl.formats[formatType] &&
		intl.formats[formatType][premadeFormat];

	if (premadeFormat && !optionsFromFormat)
		console.error(
			`[React Intl] No ${formatType} format named: ${premadeFormat}`
		);

	return optionsFromFormat;
}

export function formatToParts(
	formatter: Intl.NumberFormat,
	number?: number
): Intl.NumberFormatPart[] | undefined;
export function formatToParts(
	formatter: Intl.DateTimeFormat,
	date?: Date
): Intl.DateTimeFormatPart[] | undefined;
export function formatToParts(
	formatter: Intl.DateTimeFormat | Intl.NumberFormat,
	value?: Date | number
) {
	return typeof formatter.formatToParts === "function"
		? formatter.formatToParts(value as any)
		: undefined;
}

export const identity = i => i;

export function isNotNull<T>(x: T): x is NonNullable<T> {
	return x != null;
}

/**
 * Generates a record type with known names
 * @param data some object object hwere all values are @type FieldEditorOptions
 */
export function identityRecordOfFieldEditorOptions<K extends string>(
	data: Record<K, FieldEditorOptions>
): Record<K, FieldEditorOptions> {
	return data;
}

export function getFieldError<V>(
	errors: FormikState<V>["errors"],
	touched: FormikState<V>["touched"],
	name: string,
	errorDisplay: ErrorDisplay
): string | boolean | undefined {
	switch (errorDisplay) {
		case ErrorDisplay.Touched:
			if (getIn(touched, name)) return getIn(errors, name);
			return;
		case ErrorDisplay.AlwaysNoMessage:
			return !!getIn(errors, name);
		case ErrorDisplay.Always:
			return getIn(errors, name);
		case ErrorDisplay.Never:
		default:
			return;
	}
}

export function getFieldErrorProps(
	{
		field,
		form,
		errorDisplay
	}: Pick<BaseFieldProps & FieldProps, "field" | "form" | "errorDisplay">,
	oveerrideErrorDisplay?: ErrorDisplay
): { error: true; helperText: string | undefined } | undefined {
	const { name } = field;
	const { errors, touched } = form;

	const error = getFieldError(
		errors,
		touched,
		name,
		oveerrideErrorDisplay ?? errorDisplay ?? ErrorDisplay.Never
	);

	if (error)
		return {
			error: true,
			helperText: typeof error === "boolean" ? undefined : error
		};

	return;
}
