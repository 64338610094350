import { useCallback } from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { TemplateSection } from "@ploy-lib/types";
import { customSections } from "../section";
import { useTemplateFieldIsVisible } from "./useTemplateFieldIsVisible";

export function useTemplateSectionIsVisible() {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	const isTemplateFieldVisible = useTemplateFieldIsVisible({
		ignoreRoles: true
	});

	return useCallback(
		(section: TemplateSection) => {
			const {
				layout,
				fields,
				hideForMobile = false,
				hideSectionIfNoVisibleFields = false
			} = section;
			if (isMobile && hideForMobile) return false;

			const LayoutComponent = layout && customSections[layout];

			// Hide standard sections without visible fields
			const hasSomethingToRender = hideSectionIfNoVisibleFields
				? fields.some(isTemplateFieldVisible)
				: LayoutComponent != null ||
				  fields.some(isTemplateFieldVisible) ||
				  (!fields.some(z => z) && section.displaySectionTitle);
			if (!hasSomethingToRender) return false;

			const visibilityFilters = fields.filter(
				f => f.role === "visibilityFilter"
			);

			const inverseVisibilityFilters = fields.filter(
				f => f.role === "visibilityFilterInverse"
			);

			return (
				// Every "visibilityFilter" field must be visible
				visibilityFilters.every(isTemplateFieldVisible) &&
				// Every "visibilityFilterInverse" must be hidden
				!inverseVisibilityFilters.some(isTemplateFieldVisible)
			);
		},
		[isTemplateFieldVisible, isMobile]
	);
}
