import React, { useState, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DployTable, DployColumn } from "../DployTable";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { processPayments } from "./helpers";
import { Payment, AccumulatedPayment } from "./types";
import { FormattedMessage } from "react-intl";

const useStyle = makeStyles({
	root: {
		maxWidth: "100%"
	}
});

const padding = "4px 12px";

const paymentColumns: DployColumn<AccumulatedPayment>[] = [
	{
		title: (
			<FormattedMessage
				id="core.payment-plan.year.title"
				description="Payment plan year title"
				defaultMessage="År"
			/>
		),
		field: "year",
		type: "numeric",
		headerStyle: {
			padding
		},
		cellStyle: {
			padding
		}
	},
	{
		title: (
			<FormattedMessage
				id="core.payment-plan.term.title"
				description="Payment plan term title"
				defaultMessage="Termin"
			/>
		),
		field: "term",
		type: "numeric",
		headerStyle: {
			padding
		},
		cellStyle: {
			padding
		}
	},
	{
		title: (
			<FormattedMessage
				id="core.payment-plan.term-amount.title"
				defaultMessage="Terminbeløp"
				description="payment plan termAmount title"
			/>
		),
		field: "termAmount",
		type: "currency",
		headerStyle: {
			padding
		},
		cellStyle: {
			padding
		}
	},
	{
		title: (
			<FormattedMessage
				id="core.payment-plan.installment.title"
				defaultMessage="Avdrag"
				description="payment plan installment title"
			/>
		),
		field: "installment",
		type: "currency",
		headerStyle: {
			padding
		},
		cellStyle: {
			padding
		}
	},
	{
		title: (
			<FormattedMessage
				id="core.payment-plan.interest.title"
				defaultMessage="Rente"
				description="payment plan interest title"
			/>
		),
		field: "interest",
		type: "currency",
		headerStyle: {
			padding
		},
		cellStyle: {
			padding
		}
	},
	{
		title: (
			<FormattedMessage
				id="core.payment-plan.residual.title"
				defaultMessage="Restbalanse"
				description="payment plan residual title"
			/>
		),
		field: "residual",
		type: "currency",
		headerStyle: {
			padding
		},
		cellStyle: {
			padding
		}
	},
	{
		title: (
			<FormattedMessage
				id="core.payment-plan.extra.title"
				defaultMessage="Tilleggsp."
				description="payment plan extra title"
			/>
		),
		field: "extra",
		type: "currency",
		headerStyle: {
			padding
		},
		cellStyle: {
			padding
		}
	}
];

const hiddenCollapsed = ["year", "residual", "extra", "termAmount"];

export interface PaymentPlanProps {
	payments?: Payment[];
	collapsible?: boolean;
	initialCollapsed?: boolean;
	title?: string;
}

export const PaymentPlan = memo((props: PaymentPlanProps) => {
	let { collapsible = false, initialCollapsed = false, title } = props;

	const [year, setYear] = useState(1);
	const [collapsed, setCollapsed] = useState(initialCollapsed);

	const classes = useStyle(props);

	const { payments, years, accumulated } = processPayments(
		props.payments || []
	);

	const indexOfYear = years.findIndex(t => t.year === year);

	const total: AccumulatedPayment = {
		...accumulated,
		residual:
			accumulated.residual && accumulated.residual > 0
				? accumulated.residual
				: undefined,
		year: undefined,
		term: undefined
	};

	const data = [
		...years.slice(0, indexOfYear),
		...payments.filter(p => p.year === year),
		...years.slice(indexOfYear + 1, years.length),
		total
	];

	let columns = paymentColumns.filter(
		c => !c.field || accumulated[c.field] != null
	);

	columns = columns.map(col => {
		const { field } = col;
		return {
			...col,
			hidden: field ? !data.some(r => Number(r[field]) > 0) : false
		};
	});

	if (collapsed) {
		columns = columns.map(col => ({
			...col,
			hidden: col.field ? hiddenCollapsed.includes(col.field.toString()) : false
		}));
	}

	return (
		<div className={classes.root}>
			<DployTable<AccumulatedPayment>
				title={title}
				data={data}
				columns={columns}
				options={{
					draggable: false,
					toolbar: title != null || collapsible,
					showTitle: title != null,
					sorting: false,
					search: false,
					paging: false,
					rowStyle: row => ({
						backgroundColor: year === row.year ? "#EEE" : "#FFF",
						borderTop: row.term == null ? "2px solid #666" : ""
					})
				}}
				actions={
					collapsible
						? [
								{
									icon: () => (collapsed ? <ChevronRight /> : <ChevronLeft />),
									// tooltip: collapsed ? "Vis mer" : "Vis mindre",
									onClick: () => setCollapsed(!collapsed),
									isFreeAction: true
								}
						  ]
						: undefined
				}
				onRowClick={(_, row) => row && row.year != null && setYear(row.year)}
			/>
		</div>
	);
});
