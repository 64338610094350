import React from "react";
import { Redirect } from "@reach/router";
import {
	LoginResource,
	useResourceWithInvalidate,
	TokenLoginResource
} from "@ploy-lib/rest-resources";
import qs from "qs";

function TokenLogin(props) {
	const { basepath } = props;

	const queryString = window.location.search;
	const qsParams = qs.parse(queryString, {
		ignoreQueryPrefix: true,
		allowDots: true
	});

	useResourceWithInvalidate(
		TokenLoginResource.tokenLogin(),
		qsParams.token ? { token: qsParams.token } : null
	);

	useResourceWithInvalidate(LoginResource.status(), {});

	// if (loginData.isAuthenticated) return <Redirect noThrow to={returnUri} />;

	return (
		<Redirect noThrow to={`${basepath}/login`} returnUri={`${basepath}/`} />
	);
}

TokenLogin.displayName = "TokenLogin";

export default TokenLogin;
