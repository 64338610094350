import React, { Suspense, memo, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { ErrorHandler } from "@ploy-lib/core";
import { DashboardContentProps } from "./types";
import { useSnackbar, VariantType } from "notistack";
import { GridItem } from "./resources/DashboardGridResource";

import * as ContentComponents from "./content";
import { GridItemEditor } from "./GridItemEditor";
import { Box, IconButton } from "@material-ui/core";

import SettingsIcon from "@material-ui/icons/Settings";
import DeleteIcon from "@material-ui/icons/Delete";
import { PendingIconButton } from "@ploy-ui/core";
import ContentBox from "./ContentBox";

interface SnackbarMessageProps {
	children: React.ReactNode;
	variant?: VariantType;
}

export function SnackbarMessage({ children, variant }: SnackbarMessageProps) {
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		enqueueSnackbar(children, {
			variant
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
}

const ContentGridItemImpl = (
	props: GridItem & {
		component: Required<GridItem>["component"];
		editable?: boolean;
		onSave?: (item: GridItem) => Promise<any> | void;
		onRemove?: (id: GridItem["id"]) => Promise<any> | void;
	} & DashboardContentProps
) => {
	const {
		component,
		editable,
		onSave,
		onRemove,
		properties,
		id,
		...rest
	} = props;

	const Component = ContentComponents[component];

	if (!Component)
		throw new Error(
			`Dashboard component implementation not found: ${component}`
		);

	const [open, setOpen] = useState(false);

	let boxProps = { ...props.boxProps, ...properties.boxProps };
	if (editable && (onSave || onRemove)) {
		boxProps = {
			...boxProps,
			headerAction: (
				<Box display="flex">
					{onRemove && (
						<PendingIconButton size="small" onClick={() => onRemove(id)}>
							<DeleteIcon />
						</PendingIconButton>
					)}
					{onSave && (
						<IconButton size="small" onClick={() => setOpen(true)}>
							<SettingsIcon />
						</IconButton>
					)}
				</Box>
			)
		};
	}

	return (
		<>
			{onSave && (
				<GridItemEditor
					open={open}
					onClose={() => setOpen(false)}
					component={component}
					initialValues={properties}
					onSubmit={async values => {
						await onSave({ id, component, properties: values });
						setOpen(false);
					}}
				/>
			)}
			<ErrorHandler
				fallback={error => (
					<SnackbarMessage variant="error">
						<div>
							<Typography variant="subtitle2">
								An error occured in {component}
							</Typography>
							<Typography variant="caption">{String(error)}</Typography>
						</div>
					</SnackbarMessage>
				)}
			>
				<ErrorHandler
					fallback={error => (
						<Component
							{...rest}
							{...properties}
							id={id}
							boxProps={{ error, ...boxProps }}
						/>
					)}
				>
					<Suspense fallback={<ContentBox {...boxProps} loading />}>
						<Component {...rest} {...properties} id={id} boxProps={boxProps} />
					</Suspense>
				</ErrorHandler>
			</ErrorHandler>
		</>
	);
};

ContentGridItemImpl.displayName = "DployContentGridItem";

export const ContentGridItem = memo(
	ContentGridItemImpl
) as typeof ContentGridItemImpl;
