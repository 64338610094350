import { FieldType, TableColumnMetadata } from "./types";

export class DTDetailTableSchema {
	static metaData: Partial<Record<string, TableColumnMetadata>> = {
		outputInterest: {
			fieldType: FieldType.Percent
		},
		interestMargin: {
			fieldType: FieldType.Percent
		},
		commMarginFixed: {
			fieldType: FieldType.Percent,
			allowNegative: true
		},
		termFee: {
			fieldType: FieldType.Currency
		},
		estFeeTotal: {
			fieldType: FieldType.Currency
		},
		cond___Months___LE: {
			fieldType: FieldType.Number
		}
	};
}
