import { apiResourceUrl } from "@ploy-lib/core";
import { Entity } from "@rest-hooks/rest";
import { BaseCamelCasedResource } from "./BaseResource";

export class ApplicationLimitDetailEntity
	extends Entity
	implements ApplicationLimitDetail {
	readonly applicationId?: number = undefined;
	readonly applicationNumber?: string = undefined;
	readonly applicationStatus?: string = undefined;
	readonly objectGroup?: string = undefined;
	readonly objectCondition?: string = undefined;
	readonly limitApplicationNumber?: number = undefined;
	readonly limitGuid?: string = undefined;
	readonly selected?: boolean = undefined;

	pk(parent?: any, key?: string) {
		return this.applicationId?.toString();
	}
}

export interface ApplicationLimitDetail {
	readonly applicationId?: number;
	readonly applicationNumber?: string;
	readonly applicationStatus?: string;
	readonly objectGroup?: string;
	readonly objectCondition?: string;
	readonly limitApplicationNumber?: number;
	readonly limitGuid?: string;
	readonly selected?: boolean;
	readonly edited?: boolean;
}

export interface CustomerAgreementOption {
	readonly limitName?: string;
	readonly limitGuid?: string;
}

export class ApplicationLimitResource extends BaseCamelCasedResource {
	readonly customerId: number = 0;
	readonly limitGuid: string = "";
	readonly customerAgreements: CustomerAgreementOption[] = [];
	readonly applications?: ApplicationLimitDetailEntity[] = [];

	static schema = {
		applications: [ApplicationLimitDetailEntity]
	};

	pk() {
		return this.customerId.toString() || "";
	}

	static urlRoot = apiResourceUrl("applicationlimit");
}
