import takeWhile from "lodash/takeWhile";
import {
	FormTemplate,
	TemplatePage,
	TemplatePageDeprecated,
	TemplateSection
} from "@ploy-lib/types";
import {
	convertMultiColumn,
	convertFieldList,
	convertSummaryPage,
	convertCalculator,
	convertFieldListWithoutWizard
} from "./convertTemplateLayouts";
import { hasPanels } from "./utils";
import { convertPostProcessPage } from "./convertPostProcess";
import { v4 as uuidv4 } from "uuid";
import fromPairs from "lodash/fromPairs";

export function convertTemplate(
	template: FormTemplate,
	formContext: string | undefined
): FormTemplate<TemplatePage> {
	const originalPages = template.pages;

	const convertedPages: TemplatePage[] = [];

	for (let i = 0; i < originalPages.length; ++i) {
		const page = originalPages[i];
		if (hasPanels(page)) {
			convertedPages.push(page);
		} else if (formContext && formContext.toLowerCase() === "calculator") {
			switch (originalPages[i].layout) {
				case "FieldList":
				case "FieldListWithSidebar": {
					const pages = takeWhile(
						originalPages.slice(i),
						p =>
							!p.layout ||
							["FieldList", "FieldListWithSidebar"].includes(p.layout)
					);
					i += pages.length - 1;

					convertedPages.push(
						convertCalculator(pages as TemplatePageDeprecated[])
					);
					break;
				}
			}
		} else {
			switch (originalPages[i].layout) {
				case "FieldListMultiColumn": {
					let numberOfColumns = 0;
					for (const p of originalPages.slice(i)) {
						if (
							p.layout !== "FieldListMultiColumn" ||
							numberOfColumns >= 3 ||
							hasPanels(p)
						)
							break;
						numberOfColumns++;
					}

					const columns = originalPages.slice(i, numberOfColumns);
					i += columns.length - 1;

					convertedPages.push(
						convertMultiColumn(columns as TemplatePageDeprecated[])
					);

					break;
				}
				case "FieldList":
				case "FieldListWithSidebar": {
					convertedPages.push(convertFieldList(page as TemplatePageDeprecated));
					break;
				}
				case "SummaryPage": {
					convertedPages.push(
						convertSummaryPage(page as TemplatePageDeprecated)
					);
					break;
				}
				case "FieldListOnly":
				case "FieldListWithoutWizard": {
					convertedPages.push(
						convertFieldListWithoutWizard(page as TemplatePageDeprecated)
					);
					break;
				}
				case "PostProcessNew": {
					convertedPages.push(
						convertPostProcessPage(page as TemplatePageDeprecated)
					);
					break;
				}
			}
		}
	}

	//Map all sections and give them an id
	const convertedPagesWithID = convertedPages.map(
		(p): TemplatePage => ({
			...p,
			panels: fromPairs(
				Object.entries(p.panels).map(([panelname, panel]) => {
					const sections = (panel.sections || []).map(
						(s): TemplateSection => ({
							...s,
							formTemplateSectionId: s.formTemplateSectionId || uuidv4()
						})
					);

					return [
						panelname,
						{
							...panel,
							sections
						}
					];
				})
			)
		})
	);
	return {
		...template,
		pages: convertedPagesWithID
	};
}
