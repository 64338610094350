import React, { Suspense } from "react";
import {
	createMuiTheme,
	getContrastRatio,
	makeStyles,
	Theme,
	ThemeProvider
} from "@material-ui/core/styles";
import { color, bgcolor } from "@material-ui/system";
import clsx from "clsx";

import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";

import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import WarningIcon from "@material-ui/icons/Warning";
import Icon from "@material-ui/core/Icon";

export interface ContentBoxProps extends ContentCardProps {
	iconClass?: string;
	fallbackContent?: React.ReactNode;
	backgroundColor?: string;

	error?: Error | string;
	loading?: boolean;
}

function ContentBox(props: ContentBoxProps) {
	const {
		iconClass,
		avatar = iconClass && (
			<Icon fontSize="inherit" className={`fa fa-${iconClass}`} />
		),
		loading,
		error,
		backgroundColor,
		children,
		fallbackContent,
		headerAction,
		...rest
	} = props;

	let action = headerAction || <div />;

	if (loading) action = <CircularProgress size={24} />;
	else if (error && !headerAction)
		action = (
			<Tooltip title={typeof error === "string" ? error : error.message}>
				<WarningIcon color="error" />
			</Tooltip>
		);

	const themeOverride = (theme: Theme) => {
		const paper = bgcolor({ bgcolor: backgroundColor, theme } as any)
			?.backgroundColor;

		if (!paper) return theme;

		const switchDarkMode =
			getContrastRatio(paper, theme.palette.text.primary) < 3;

		if (switchDarkMode) {
			const { text, action, divider, type, ...palette } = theme.palette;

			return createMuiTheme({
				...theme,
				palette: {
					...palette,
					type: type === "dark" ? "light" : "dark",
					background: {
						paper
					}
				}
			});
		}

		return {
			...theme,
			palette: {
				...theme.palette,
				background: {
					...theme.palette.background,
					paper
				}
			}
		};
	};

	return (
		<ThemeProvider theme={themeOverride}>
			<Suspense
				fallback={
					<ContentCard
						avatar={avatar}
						headerAction={<CircularProgress size={24} />}
						{...rest}
					>
						{fallbackContent}
					</ContentCard>
				}
			>
				<ContentCard avatar={avatar} headerAction={action} {...rest}>
					{children}
				</ContentCard>
			</Suspense>
		</ThemeProvider>
	);
}

ContentBox.displayName = "ContentBox";
export default ContentBox;

const useStyles = makeStyles(
	theme => ({
		root: {
			"&:empty": {
				display: "none"
			},
			height: "100%",
			display: "flex",
			flexDirection: "column"
		},
		headerAction: {
			width: 48,
			height: 48,
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		},
		headerAvatar: {
			fontSize: 24,
			color: (props: ContentBoxProps) =>
				color({ color: props.headerColor, theme } as any)?.color
		},
		headerTitle: {
			color: (props: ContentBoxProps) =>
				color({ color: props.headerColor, theme } as any)?.color
		},
		headerSubheader: {
			color: (props: ContentBoxProps) =>
				color({ color: props.headerColor, theme } as any)?.color
		},
		header: {},
		content: {
			paddingTop: theme.spacing(1),
			flexGrow: 1,
			overflow: "hidden",
			display: "flex",
			flexDirection: "column",
			"& > *": {
				flexGrow: 1
			},
			position: "relative"
		},
		paddingGradient: {
			position: "absolute",
			bottom: 0,
			left: theme.spacing(1),
			right: theme.spacing(1),
			[theme.breakpoints.up("sm")]: {
				left: theme.spacing(2),
				right: theme.spacing(2)
			},
			height: theme.spacing(4),
			background: (props: ContentBoxProps) => `linear-gradient(
			  to bottom,
			  rgba(0,0,0,0),
			  ${theme.palette.background.paper} 100%
			)`,
			zIndex: 1,
			pointerEvents: "none"
		},
		actions: {
			justifyContent: "center"
		}
	}),
	{ name: "DashboardContentCard" }
);

export interface ContentCardProps {
	className?: string;
	classes?: Partial<ReturnType<typeof useStyles>>;
	header?: React.ReactNode;
	subheader?: React.ReactNode;
	avatar?: React.ReactNode;
	children?: React.ReactNode;
	media?: React.ReactNode;
	actions?: React.ReactNode;
	headerAction?: React.ReactNode;
	scrollGradient?: boolean;
	headerColor?: string;
}

function ContentCard(props: ContentCardProps) {
	const {
		className,
		header,
		subheader,
		avatar,
		children,
		media,
		actions,
		headerAction,
		scrollGradient
	} = props;

	const classes = useStyles(props);

	return (
		<Card className={clsx(className, classes.root)} elevation={1}>
			<CardHeader
				className={classes.header}
				classes={{
					action: classes.headerAction,
					avatar: classes.headerAvatar,
					title: classes.headerTitle,
					subheader: classes.headerSubheader
				}}
				avatar={avatar}
				title={header}
				subheader={subheader}
				action={headerAction}
			/>
			{media && <CardMedia>{media}</CardMedia>}
			<CardContent className={classes.content}>
				{children}
				{scrollGradient && <div className={classes.paddingGradient} />}
			</CardContent>
			{actions && (
				<CardActions className={classes.actions}>{actions}</CardActions>
			)}
		</Card>
	);
}
