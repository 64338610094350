import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";

import { AppSignatureInfo } from "./AppSignatureInfo";
import { AppSignerList } from "./AppSignerList";
import { FormattedMessage } from "react-intl";
import { AppSigningStatus } from ".";
import { TemplateSection } from "@ploy-lib/types";
import {
	BusinessGuarantorSignerResource,
	SigningDocumentResource,
	useResourceWithInvalidate
} from "@ploy-lib/rest-resources";

export interface ApplicationSigningProps {
	className?: string;
	applicationNumber?: string;
	signerMatrixVisible: boolean;
	selfEmployedCustomer: boolean;
	esignType: number;
	ssnServiceEnabled: boolean;
	externalUserCanNotAddSigners: boolean;
	requirePhoneOrEmail: boolean;
	showValidPeriod: boolean;
	canAddAndDeleteVendorSigners: boolean;
	canEditIndividualDocuments?: boolean;
	disableVendorSigner: boolean;
	hideVendorSigner: boolean;
	disableSignerEditing: boolean;
	mustSignDefaultChecked: boolean;
	onClick?: () => void;
	setSectionCompleted?: (
		id: TemplateSection["formTemplateSectionId"],
		completed: boolean
	) => void;
	formTemplateSectionId?: TemplateSection["formTemplateSectionId"];
	customerSignerMatrixReadOnly?: boolean;
	hideSignerList?: boolean;
	hideSignerEditing?: boolean;
	showAlternativeMessage?: boolean;
}

const emptyArray = [];

const ApplicationSigning = (props: ApplicationSigningProps) => {
	const {
		className,
		applicationNumber,
		signerMatrixVisible,
		onClick,
		selfEmployedCustomer,
		esignType,
		ssnServiceEnabled,
		setSectionCompleted,
		formTemplateSectionId,
		externalUserCanNotAddSigners,
		requirePhoneOrEmail,
		showValidPeriod,
		canAddAndDeleteVendorSigners,
		canEditIndividualDocuments = false,
		disableVendorSigner,
		hideVendorSigner,
		disableSignerEditing,
		customerSignerMatrixReadOnly = false,
		mustSignDefaultChecked = false,
		hideSignerList,
		hideSignerEditing,
		showAlternativeMessage
	} = props;

	const [editingCustomerSigner, setEditingCustomerSigner] = useState<boolean>(
		false
	);

	const [editingVendorSigner, setEditingVendorSigner] = useState<boolean>(
		false
	);

	const [currentGuarantorSigner, setCurrentGuarantorSigner] = useState<
		number | null
	>(null);

	const documents =
		useResourceWithInvalidate(
			SigningDocumentResource.list(),
			applicationNumber
				? {
						applicationNumber
				  }
				: null
		) ?? emptyArray;

	const guarantors =
		useResourceWithInvalidate(
			BusinessGuarantorSignerResource.list(),
			applicationNumber
				? {
						applicationNumber
				  }
				: null
		) ?? emptyArray;

	const customerDocuments = documents.filter(d => d.mustSign || d.mustRead);

	const vendorDocuments = documents.filter(
		d => d.vendorMustSign || d.vendorMustRead
	);

	const completed = documents.every(doc => doc.signed) || false;

	useEffect(() => {
		if (formTemplateSectionId)
			setSectionCompleted?.(formTemplateSectionId, completed);
	}, [completed, formTemplateSectionId, setSectionCompleted]);

	const showCustomerSignerMatrix =
		!hideSignerEditing &&
		signerMatrixVisible &&
		!editingVendorSigner &&
		!currentGuarantorSigner &&
		customerDocuments.length > 0;

	const showVendorSignerMatrix =
		!hideVendorSigner &&
		!hideSignerEditing &&
		!editingCustomerSigner &&
		!currentGuarantorSigner &&
		vendorDocuments.length > 0;

	const showGuarantorSignerMatrix =
		!hideSignerEditing &&
		!editingCustomerSigner &&
		!editingVendorSigner &&
		guarantors.length > 0 &&
		documents.filter(
			d =>
				d.guarantorMustSign !== undefined || d.guarantorMustRead !== undefined
		).length > 0;

	if (!applicationNumber) return null;

	return (
		<Grid container spacing={3} className={className} onClick={onClick}>
			{!editingCustomerSigner &&
				!editingVendorSigner &&
				!currentGuarantorSigner && (
					<>
						<Grid item xs={12}>
							<AppSigningStatus
								applicationNumber={applicationNumber}
								documents={documents}
							/>
						</Grid>
						<Grid item xs={12}>
							<AppSignatureInfo
								applicationNumber={applicationNumber}
								hideSignerList={hideSignerList}
							/>
						</Grid>
					</>
				)}
			{showCustomerSignerMatrix && (
				<Grid item xs={12}>
					<Typography variant="subtitle2">
						<FormattedMessage
							id="core.application-signing.select-customer-signers"
							defaultMessage="VELG SIGNERINGSPLIKTIGE FOR KUNDE"
						/>
					</Typography>
					<AppSignerList
						applicationNumber={applicationNumber}
						documents={customerDocuments}
						esignType={esignType}
						selfEmployedCustomer={selfEmployedCustomer}
						ssnServiceEnabled={ssnServiceEnabled}
						onEdit={setEditingCustomerSigner}
						requirePhoneOrEmail={requirePhoneOrEmail}
						canAddAndDelete={!externalUserCanNotAddSigners}
						canEditIndividualDocuments={canEditIndividualDocuments}
						disabled={disableSignerEditing || customerSignerMatrixReadOnly}
						mustSignDefaultChecked={mustSignDefaultChecked}
					/>
				</Grid>
			)}
			{showVendorSignerMatrix && (
				<Grid item xs={12}>
					<Typography variant="subtitle2">
						<FormattedMessage
							id="core.application-signing.select-vendor-signers"
							defaultMessage="VELG SIGNERINGSPLIKTIGE FOR FORHANDLER"
						/>
					</Typography>
					<AppSignerList
						applicationNumber={applicationNumber}
						documents={vendorDocuments}
						esignType={esignType}
						ssnServiceEnabled={ssnServiceEnabled}
						vendor
						onEdit={setEditingVendorSigner}
						requirePhoneOrEmail={requirePhoneOrEmail}
						showValidPeriod={showValidPeriod}
						canAddAndDelete={canAddAndDeleteVendorSigners}
						canEditIndividualDocuments={canEditIndividualDocuments}
						disabled={
							!disableVendorSigner ? disableVendorSigner : disableSignerEditing
						}
						mustSignDefaultChecked={mustSignDefaultChecked}
					/>
				</Grid>
			)}
			{showGuarantorSignerMatrix && (
				<Grid item xs={12}>
					<Typography variant="subtitle2">
						<FormattedMessage
							id="core.application-signing.select-guarantor-signers"
							defaultMessage="VELG SIGNERINGSPLIKTIGE FOR KAUSJONIST"
						/>
					</Typography>
					{guarantors.map(
						g =>
							(!currentGuarantorSigner ||
								g.customerId === currentGuarantorSigner) && (
								<>
									<Typography variant="overline">{g.customerName}</Typography>
									<AppSignerList
										applicationNumber={applicationNumber}
										documents={documents.filter(
											d => (g.groupsToSign?.indexOf(d.documentCode) ?? -1) >= 0
										)}
										esignType={esignType}
										ssnServiceEnabled={ssnServiceEnabled}
										onEdit={edit =>
											setCurrentGuarantorSigner(edit ? g.customerId : null)
										}
										requirePhoneOrEmail={requirePhoneOrEmail}
										showValidPeriod={showValidPeriod}
										canAddAndDelete={!externalUserCanNotAddSigners}
										canEditIndividualDocuments={canEditIndividualDocuments}
										mustSignDefaultChecked={mustSignDefaultChecked}
										guarantorId={g.customerId}
										disabled={false}
									/>
								</>
							)
					)}
				</Grid>
			)}
			{showAlternativeMessage && (
				<Grid item xs={12}>
					<Typography>
						<FormattedMessage
							id="core.application-signing.signers-editable-elsewhere"
							defaultMessage="For oppsett av signeringspliktige, gå til Portalen."
						/>
					</Typography>
				</Grid>
			)}
		</Grid>
	);
};

ApplicationSigning.displayName = "DployApplicationSigning";

export { ApplicationSigning };
