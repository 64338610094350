import * as React from "react";
import {
	KmReading as PuiKmReading,
	KmReadingOptions
} from "@ploy-ui/km-reading";
import KmReadingAPI from "@ploy-lib/km-reading-api";
import { ExternalViewComponent } from "../types";

const dtResultToOptions = (
	dtResult: Record<string, string>
): KmReadingOptions => ({
	allowedMonths: Number.parseInt(dtResult["kmReadingAllowedMonths"]),
	allowedDeviation: Number.parseFloat(dtResult["kmReadingAllowedDeviation"]),
	percentInterval: Number.parseFloat(dtResult["kmReadingPercentageInterval"]),
	doCalculation: dtResult["kmReadingDoCalculation"] === "false" ? false : true,
	canEditUntilApproved: dtResult["kmReadingCanEditUntilApproved"] === "true",
	hideDateInput: dtResult["kmReadingHideDateInput"] === "true",
	hideCommentSection: dtResult["kmReadingHideCommentSection"] === "true",
	hideDeliveryDate: dtResult["kmReadingHideDeliveryDate"] === "true",
	messages: {
		lessThanPlanned: dtResult["informationMessage1"],
		lessThanAllowed: dtResult["informationMessage2"],
		lessThanInterval: dtResult["informationMessage4"],
		large: dtResult["informationMessage3"],
		kmReadingNotAvailable: dtResult["informationMessage5"]
	}
});

export const KmReading: ExternalViewComponent = ({
	dtResult,
	token = "",
	endpoint,
	internalUser
}) => (
	<KmReadingAPI token={token} endpoint={endpoint}>
		{api => (
			<PuiKmReading
				{...api}
				options={dtResultToOptions(dtResult)}
				isInternal={!!internalUser}
			/>
		)}
	</KmReadingAPI>
);

export default KmReading;
