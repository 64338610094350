import React, { useDebugValue } from "react";
import { useSignerSetup } from ".";
import { defineMessages, useIntl } from "react-intl";
import { TemplateSection } from "@ploy-lib/types";

export function useSectionHeader(section: TemplateSection | null) {
	const messageDef =
		section?.layout && signerSetupHeaderAlterationMessages[section?.layout];

	let header: React.ReactNode = section?.group;

	const setup = useSignerSetup({
		skip: !messageDef || !header
	});

	const intl = useIntl();

	if (header && messageDef && setup && !setup.loading) {
		header = intl.formatMessage(messageDef, {
			header,
			...setup
		});
	}

	useDebugValue(header);

	return header;
}

const signerSetupHeaderAlterationMessages = defineMessages({
	ApplicationSigningSection: {
		id: "core.template-form.section.ApplicationSigningSection.header",
		description:
			"Message to be shown in expandablesection title for applicationSigningSection",
		defaultMessage: `{header}{incompleteSignerSetup, select,
			true { (Velg personer som skal signere)}
			other {}
		}`
	}
});
