import React from "react";
import {
	NumberFieldProps,
	identityRecordOfFieldEditorOptions,
	SliderField,
	EditorSliderFields as EditorBaseSlider
} from "@ploy-ui/form-fields";
import { useVariableData } from "@ploy-lib/calculation";

export const EquitySlider = (props: NumberFieldProps) => {
	const { value: objectPrice } = useVariableData("Calculator", "ObjectPrice");
	return (
		<SliderField {...props} min={0} max={Number(objectPrice)} step={1000} />
	);
};

export const TermsYearsSlider = (props: NumberFieldProps) => {
	const { value: minTerms } = useVariableData("Calculator", "MinTotalMonths");
	const { value: maxTerms } = useVariableData("Calculator", "MaxTotalMonths");
	return (
		<SliderField
			{...props}
			min={Number(minTerms) / 12}
			max={Number(maxTerms) / 12}
		/>
	);
};

export const EditorSliderFields = identityRecordOfFieldEditorOptions({
	EquitySlider: EditorBaseSlider.SliderField,
	TermsYearsSlider: EditorBaseSlider.SliderField
});
