import React, { Suspense, useMemo, useState, useEffect } from "react";
import {
	FormattedRelativeTime,
	FormattedDate,
	FormattedNumber
} from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Cells, ExpandableTable, ExpandedRow } from "@ploy-ui/core";
import {
	LinearProgress,
	Box,
	Typography,
	Paper,
	TablePagination
} from "@material-ui/core";
import { PendingButton } from "@ploy-ui/core";
import ApplicationActions from "./ApplicationActions";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import {
	ApplicationInfoResource,
	ApplicationInfoDetailsResource
} from "@ploy-lib/rest-resources";

import { useResource, useFetcher, useInvalidator } from "@rest-hooks/core";
import { navigate } from "@reach/router";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { useFormikContext } from "formik";
import { useDebounce } from "use-debounce";

const basepath = process.env.PUBLIC_URL;

const defaultColumnData = [
	{
		id: "applicationStatusNameExternal",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.status.label"
				description="result table column label for status"
				defaultMessage="Status"
			/>
		),
		component: Cells.ColoredStatus,
		props: { statusKey: "applicationStatusId" },
		hidden: { xsDown: true },
		hiddenInExpandable: { smUp: true }
	},
	{
		id: "applicationNumber",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.applicationNumber.label"
				description="result table column label for applicationNumber"
				defaultMessage="Saksnr."
			/>
		),
		hidden: { smDown: true },
		hiddenInExpandable: true
	},
	{
		id: "customerName",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.customerName.label"
				description="result table column label for customer name"
				defaultMessage="Kunde"
			/>
		),
		props: {
			statusKey: "applicationStatusId",
			secondLineKey: "applicationNumber"
		},
		component: Cells.DoubleLineSmDown,
		hiddenInExpandable: true
	},
	{
		id: "field5",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.object.label"
				description="result table column label for object"
				defaultMessage="Gjenstand"
			/>
		),
		hidden: true
	},
	{
		id: "productNameExternal",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.productName.label"
				description="result table column label for product name"
				defaultMessage="Produkt"
			/>
		),
		hidden: { smDown: true },
		hiddenInExpandable: { mdUp: true }
	},
	{
		id: "backofficeAccountId",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.accountId.label"
				description="result table column label for backoffice account id"
				defaultMessage="Avtalenummer"
			/>
		),
		hidden: true,
		hiddenInExpandable: (_, { productCodeExternal }) =>
			productCodeExternal !== "LEIE"
	},
	{
		id: "salesPersonName",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.salesPersonName.label"
				description="result table column label for salesPersonName"
				defaultMessage="Selger"
			/>
		),
		hidden: { smDown: true },
		hiddenInExpandable: { mdUp: true }
	},
	{
		id: "documentStatusNameExternal",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.documentStatusName.label"
				description="result table column label for document status name external"
				defaultMessage="Dok. Status"
			/>
		),
		hiddenInExpandable: true
	},
	{
		id: "createdDate",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.createdDate.label"
				description="result table column label for created date"
				defaultMessage="Registreringsdato"
			/>
		),
		hidden: true,
		format: val =>
			val && (
				<FormattedDate value={val} day="numeric" month="long" year="numeric" />
			)
	},
	{
		id: "paidDate",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.paidDate.label"
				description="result table column label for paid date"
				defaultMessage="Utbetalingsdato"
			/>
		),
		hidden: true,
		format: val =>
			val && (
				<FormattedDate value={val} day="numeric" month="long" year="numeric" />
			)
	},
	{
		id: "applicationLastChanged",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.applicationLastChanged.label"
				description="result table column label for application last changed"
				defaultMessage="Siste endring"
			/>
		),
		hidden: true,
		tooltip: val =>
			val && (
				<FormattedDate
					value={val}
					day="numeric"
					month="long"
					year="numeric"
					hour="numeric"
					minute="numeric"
				/>
			),
		format: val =>
			val && (
				<FormattedRelativeTime
					value={(new Date(val).getTime() - new Date().getTime()) / 1000}
					unit="second"
					updateIntervalInSeconds={10}
				/>
			)
	},
	{
		id: "loanAmount",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.loanAmount.label"
				description="result table column label for loan amount"
				defaultMessage="Finansiert beløp"
			/>
		),
		hidden: true,
		format: val => val && <FormattedNumber value={val} format="currency" />
	},
	{
		loadedId: "responsible.mobile",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.responsible.mobile.label"
				description="result table column label for responsible mobile"
				defaultMessage="Telefon saksbehandler"
			/>
		),
		hidden: true,
		hiddenInExpandable: (_, __, { mobile }) => mobile !== "undefined"
	},
	{
		loadedId: "responsible.email",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.responsible.email.label"
				description="result table column label for responsible email"
				defaultMessage="Epost saksbehandler"
			/>
		),
		hidden: true,
		component: Cells.MailtoLink,
		props: {
			subject: row => row.applicationNumber
		},
		hiddenInExpandable: (_, __, { email }) => email !== "undefined"
	},
	{
		id: "actions",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.actions.label"
				description="result table column label for actions"
				defaultMessage="Handlinger"
			/>
		),
		hidden: { mdDown: true },
		hiddenInExpandable: { mdUp: true },
		component: ApplicationActions
	}
];

export const messages = defineMessages({
	lblCancelWarningApplication: {
		id: "dealer.allapplications.searchresults.cancelWarningApplication",
		description: "Label for cancel application alert",
		defaultMessage:
			"Er du sikker på at du vil bortfalle sak: {applicationNumber}"
	}
});

const useStyles = makeStyles(theme => ({
	applicationStatusNameExternal: {
		width: 160,
		textAlign: "center"
	},
	applicationNumber: {
		width: 100
	},
	customerNameInner: {
		display: "flex",
		alignItems: "center",
		"& > div": {
			marginRight: 10
		}
	},
	customerNameText: {
		fontWeight: "bold"
	},
	cancelApplication: {
		float: "right"
	},
	actions: {
		padding: 0
	},
	noResultPaper: {
		width: "100%",
		padding: "18.5px 14px",
		marginBottom: "10%"
	},
	noResultText: {
		textAlign: "center",
		fontWeight: "700"
	},
	cellPadding: {
		padding: `${theme.spacing(2)}px ${theme.spacing(0.5)}px`
	}
}));

const SearchResults = ({ className, columnData = defaultColumnData }) => {
	const classes = useStyles();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(20);

	const { values } = useFormikContext();

	const [searchParams] = useDebounce(values, 200, { leading: true });

	const params = useMemo(
		() => ({
			...searchParams,
			page: page + 1,
			pageSize: rowsPerPage
		}),
		[page, rowsPerPage, searchParams]
	);

	const { records, recordCount, pageSize = rowsPerPage } = useResource(
		ApplicationInfoResource.list(),
		params
	);

	const invalidator = useInvalidator(ApplicationInfoResource.list());

	useEffect(
		() => () => {
			invalidator(params);
		},
		[invalidator, params]
	);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const columns = useMemo(
		() =>
			columnData.map(c => {
				let { hidden, hiddenInExpandable } = c;

				const showAll = searchParams.salepersonId === undefined;

				switch (c.id) {
					case "actions":
						if (showAll) {
							hidden = true;
							hiddenInExpandable = undefined;
						}
						break;
					case "salesPersonName":
						if (!showAll) {
							hidden = true;
							hiddenInExpandable = undefined;
						}
						break;
					default:
						break;
				}

				return {
					...c,
					className: clsx(classes[c.id], classes.cellPadding),
					hidden,
					hiddenInExpandable
				};
			}),
		[columnData, classes, searchParams]
	);

	return recordCount > 0 ? (
		<>
			<ExpandableTable
				tableType="applicationListContainer"
				idColumn="applicationId"
				className={className}
				tableData={records}
				columnData={columns}
				onRowClick={row => {
					navigate(`${basepath}/form/application/${row.applicationNumber}`);
				}}
				renderExpandedSection={row => {
					return (
						<Suspense fallback={<LinearProgress />}>
							<SearchResultExpandedRow
								rowData={row}
								columnData={columns}
								searchParams={params}
							/>
						</Suspense>
					);
				}}
			/>
			<TablePagination
				rowsPerPageOptions={[10, 20, 50]}
				component="div"
				count={recordCount}
				rowsPerPage={pageSize}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
		</>
	) : (
		<Paper className={classes.noResultPaper}>
			<Typography className={classes.noResultText}>
				<FormattedMessage
					id="dealer.allapplications.searchresults.noresult"
					description="Label for no result from searchresult"
					defaultMessage="Ingen resultat"
				/>
			</Typography>
		</Paper>
	);
};

function SearchResultExpandedRow(props) {
	const { rowData, searchParams } = props;

	const loadedData = useResource(ApplicationInfoDetailsResource.detail(), {
		id: rowData.applicationNumber
	});

	const refresh = useFetcher(ApplicationInfoResource.list());
	const refreshDetails = useFetcher(ApplicationInfoDetailsResource.detail());

	return (
		<>
			<ExpandedRow loadedData={loadedData} {...props} />

			{loadedData.canCancelApp && (
				<CancelButton
					applicationId={rowData.applicationId}
					applicationNumber={rowData.applicationNumber}
					onSuccess={() => {
						refresh(searchParams);
						refreshDetails({
							id: rowData.applicationNumber
						});
					}}
				/>
			)}
		</>
	);
}

function CancelButton(props) {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState();

	const { applicationId, applicationNumber, onSuccess } = props;

	const intl = useIntl();

	const cancel = async () => {
		setLoading(true);

		const payload = {
			applicationId
		};

		try {
			if (
				window.confirm(
					intl.formatMessage(messages.lblCancelWarningApplication, {
						applicationNumber
					})
				)
			) {
				await fetch(legacyApiResourceUrl("AllApplications/CancelApplication"), {
					method: "POST",
					body: new Blob([JSON.stringify(payload)], {
						type: "application/json"
					}),
					headers: {
						accept: "application/json"
					}
				});

				setSuccess(true);

				if (onSuccess) onSuccess();
			}
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box display="flex" justifyContent="flex-end">
			{error && <Typography color="error">{error.message}</Typography>}
			<PendingButton
				color="secondary"
				variant="contained"
				onClick={cancel}
				pending={loading}
				success={success}
				error={error}
			>
				<FormattedMessage
					id="dealer.allapplications.searchresults.cancelapplication"
					description="Label for cancel application button"
					defaultMessage="Bortfall sak"
				/>
			</PendingButton>
		</Box>
	);
}

export default SearchResults;
