import { Calculator } from "../calculator";
import { Namespaced } from "@ploy-lib/types";
import { State, ActionTypes, InitialState, ServiceTrigger } from "../types";
import omit from "lodash/omit";

export const initialState: InitialState<any, any> = {
	calculation: {},
	changes: [],
	serviceTriggers: [],
	servicesToTrigger: [],
	init: [],
	isInitialized: false
};

export const createReducer = <
	TN extends string,
	TD extends string | number | boolean
>(
	calculator: Calculator<TN, TD>,
	initialWriteLocked?: Partial<Namespaced<boolean | null, TN>>,
	defaultFieldVisibility?: Partial<Namespaced<boolean, TN>>,
	initialFormValues?: Partial<Namespaced<TD, TN>>
) => (
	state: State<TN, TD> | InitialState<TN, TD>,
	action: ActionTypes<TN, TD>
): State<TN, TD> | InitialState<TN, TD> => {
	try {
		// console.log("\n---", action.type, "---");

		switch (action.type) {
			case "field_patch":
			case "patch": {
				const { patches } = action.payload;

				let [calculation, updatedChanges, updatedInit] = calculator.patch(
					state.calculation,
					patches
				);

				//console.log(namespace, service.name, changes, init);
				return {
					...state,
					calculation,
					changes: state.changes.concat(updatedChanges),
					init: state.init.concat(updatedInit)
				} as State<TN, TD> | InitialState<TN, TD>;
			}

			case "service_success": {
				const {
					meta: {
						service: { name, callTags },
						namespace
					}
				} = action;

				let serviceSuccessChanges = [
					{
						changedFieldId: `${namespace}_Service_${name}_Success`
					},
					{ namespace, changedFieldId: `Service_${name}_Success` },
					...(callTags
						?.split("|")
						.map(changedFieldId => ({ changedFieldId })) ?? [])
				];

				return {
					...state,
					changes: state.changes.concat(serviceSuccessChanges)
				} as State<TN, TD> | InitialState<TN, TD>;
			}
			case "reinit":
				const calculation: typeof state.calculation =
					action.payload.clear && action.payload.clear.length > 0
						? {
								...state.calculation,
								values: omit(
									state.calculation.values,
									action.payload.clear
								) as typeof state.calculation.values,
								isMissing: omit(
									state.calculation.isMissing,
									action.payload.clear
								) as typeof state.calculation.isMissing,
								isEnabled: omit(
									state.calculation.isEnabled,
									action.payload.clear
								) as typeof state.calculation.isEnabled,
								formValues: omit(
									state.calculation.formValues,
									action.payload.clear
								) as typeof state.calculation.formValues
						  }
						: state.calculation;

				return {
					...state,
					calculation,
					isInitialized: false
				};
			case "update_service_triggers":
				return {
					...state,
					servicesToTrigger: [],
					serviceTriggers: state.servicesToTrigger
				};
			case "init":
			case "update":
			case "calculate":
			case "calculate_and_validate":
			case "validate": {
				const { type, payload = {} } = action;
				const { formValues } = payload;

				// console.time("calculator");

				if (formValues != null && (type === "init" || !state.isInitialized)) {
					// console.time("calculator.init");
					const initialized = calculator.init(
						{
							...state.calculation,
							defaultFieldVisibility,
							initialFormValues:
								state.calculation.initialFormValues || initialFormValues
						},
						formValues
					);

					state = {
						...state,
						calculation: initialized,
						isInitialized: true
					};
					// console.timeEnd("calculator.init");
				}

				if (
					formValues != null &&
					state.isInitialized &&
					(type === "update" || state.calculation.formValues !== formValues)
				) {
					// console.time("calculator.update");

					const [calculation, updatedChanges, updatedInit] = calculator.update(
						state.calculation,
						formValues,
						initialWriteLocked || {}
					);

					state = {
						...state,
						calculation,
						changes: state.changes.concat(updatedChanges),
						init: state.init.concat(updatedInit)
					};
					// console.timeEnd("calculator.update");
				}

				if (
					state.isInitialized &&
					(type === "calculate" || type === "calculate_and_validate")
				) {
					// console.time("calculator.calculate");
					let { changes, init, calculation, servicesToTrigger } = state;

					while (changes.length > 0 || init.length > 0) {
						let serviceTriggers: readonly ServiceTrigger<TN>[];
						[calculation, serviceTriggers, changes] = calculator.calculate(
							calculation,
							changes,
							init
						);
						init = [];
						servicesToTrigger = [...servicesToTrigger, ...serviceTriggers];
					}

					state = {
						...state,
						calculation,
						changes: [],
						init: [],
						servicesToTrigger: state.servicesToTrigger.concat(servicesToTrigger)
					};
					// console.timeEnd("calculator.calculate");
				}

				if (
					state.isInitialized &&
					(type === "validate" || type === "calculate_and_validate")
				) {
					// console.time("calculator.validate");
					const calculation = calculator.validate(state.calculation);

					state = {
						...state,
						calculation
					};

					// console.timeEnd("calculator.validate");
				}

				// console.timeEnd("calculator");

				// if (onValidated) onValidated(nextState.errors);
				return state;
			}
			default:
				return state;
		}
	} catch (e: any) {
		console.error(`Failed to handle "${action.type}"\n`, e);

		return state;
	}
};
