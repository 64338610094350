import React, { lazy, Suspense } from "react";

const Noop: React.FC = () => null;

Noop.displayName = "NotEnabledInProduction";

let TenantSelector = Noop;

if (process.env.NODE_ENV === "development") {
	const LazyTenantSelector = lazy(() =>
		import(/* webpackChunkName: "tenant-selector"  */ "./TenantSelector")
	);

	TenantSelector = () => (
		<Suspense fallback={<Noop />}>
			<LazyTenantSelector />
		</Suspense>
	);
}

export default TenantSelector;
