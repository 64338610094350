import React from "react";
import marked from "@ploy-lib/purify-marked";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTenantImage } from "@ploy-ui/tenants";
import { useIntl, defineMessages } from "react-intl";
import { ImageFit } from "@ploy-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
	root: {
		background: theme.palette.grey[800],
		padding: theme.spacing(2)
	},
	container: {
		paddingTop: 50,
		textAlign: "center",
		[theme.breakpoints.up("sm")]: {
			textAlign: "left"
		}
	},
	flag: {
		padding: theme.spacing(2),
		height: 80
	},
	contactSection: {
		color: theme.palette.grey[500],
		"& h3": {
			marginTop: 0,
			marginBottom: 12
		},
		"& p": {
			fontSize: 11,
			marginTop: 8,
			marginBottom: 0
		},
		"& a": {
			color: theme.palette.grey[500],
			textDecoration: "none",
			"&:hover": {
				color: "#00678a"
			}
		}
	},
	logo: {}
}));

const messages = defineMessages({
	footerInfo: {
		id: "dealer.footer.info",
		defaultMessage: `### Knowit Solutions
Nøstegaten 58

5011 Bergen

Sentralbord: [02486](tel:02486)`,
		description: "Footer info. Markdown syntax allowed"
	},
	footerContactInfo: {
		id: "dealer.footer.contact_info",
		defaultMessage: `### Kontaktinfo
E-post: [demofinans@knowit.no](mailto:demofinans@knowit.no)

Web: [www.knowit.no](http://www.knowit.no)`,
		description: "Footer contact info. Markdown syntax allowed"
	}
});

export function Footer(props: { className?: string }) {
	const intl = useIntl();
	const classes = useStyles(props);

	const footerLogo = useTenantImage("footerLogo");

	return (
		<footer className={clsx(classes.root, props.className)}>
			<Grid
				container
				spacing={2}
				justify="center"
				className={classes.container}
			>
				<Grid item xs={12} sm={3} className={classes.logo}>
					<ImageFit src={footerLogo} fit="contain" className={classes.flag} />
				</Grid>
				<Grid item xs={12} sm="auto">
					<Typography
						className={classes.contactSection}
						dangerouslySetInnerHTML={{
							__html: marked(intl.formatMessage(messages.footerInfo))
						}}
					/>
				</Grid>
				<Grid item xs={12} sm="auto">
					<Typography
						className={classes.contactSection}
						dangerouslySetInnerHTML={{
							__html: marked(intl.formatMessage(messages.footerContactInfo))
						}}
					/>
				</Grid>
			</Grid>
		</footer>
	);
}
