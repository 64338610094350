import { BaseCamelCasedResource } from "./BasePlainResource";
import { legacyApiResourceUrl } from "@ploy-lib/core";

export interface BusinessGuarantorSigner {
	readonly customerId: number;
	readonly customerName?: string;
	readonly customerTypeId?: number;
	readonly groupsToSign?: string[];
}

export class BusinessGuarantorSignerResource
	extends BaseCamelCasedResource
	implements BusinessGuarantorSigner {
	readonly customerId: number;
	readonly customerName?: string;
	readonly customerTypeId?: number;
	readonly groupsToSign?: string[];

	pk() {
		return this.customerId.toString();
	}

	static urlRoot = legacyApiResourceUrl("/Signer/GetBusinessGuarantors/");
}
