import { ID } from "./util";

export type GridSize =
	| "auto"
	| 1
	| 2
	| 3
	| 4
	| 5
	| 6
	| 7
	| 8
	| 9
	| 10
	| 11
	| 12;
/**
 * Remove properties `K` from `T`.
 */
export type Omit<T, K extends keyof any> = T extends any
	? Pick<T, Exclude<keyof T, K>>
	: never;

export type PaletteColorKey = "primary" | "secondary" | "error";

export type TextAlignment = "inherit" | "left" | "center" | "right" | "justify";

export type FieldWidth =
	| "1"
	| "2"
	| "3"
	| "4"
	| "5"
	| "6"
	| "7"
	| "8"
	| "9"
	| "10"
	| "11"
	| "12"
	| "auto";

export interface OptionSource {
	selected: string;
	name: string;
	filter?: string;
	labelKey?: string;
	valueKey?: string;
	url?: string;
	service?: FieldService;
	pendingFillOptionValues?: [string, string];
	additionalRequestData?: object;
}

export interface FieldService {
	service: string;
	namespace?: string;
}
export interface FieldSave {
	type: "button" | "select" | "auto";
}

export interface FieldClick extends FieldService {}

export type GridJustification =
	| "flex-start"
	| "center"
	| "flex-end"
	| "space-between"
	| "space-around"
	| "space-evenly";

export enum ErrorDisplay {
	Never = "Never",
	Touched = "Touched",
	Always = "Always",
	AlwaysNoMessage = "AlwaysNoMessage"
}

export enum TemplateDataSource {
	CalcRules = "CalcRules",
	Resource = "Resource"
}

export interface TemplateTableColumn {
	formTemplateTableColumnId: string;
	name: string;
	tableType: string;
	label?: string;

	showSum?: boolean;
	editable?: boolean;
	isMultipleSelect?: boolean;
	optionValues?: { key: string; value: string }[];
	optionSource?: TableColumnOptionSource;
	isGroupingColumn?: boolean;
	hrefLink?: string;
	groupLabel?: string;
	footerGroupLabel?: string;
	multiselect?: boolean;
	hideDeleteButton?: boolean;
}

export interface TableColumnOptionSource {
	namespace?: string;
	fieldName?: string;
	key?: string;
	value?: string;
}
export interface TableType {
	displayName: string;
	tableTypeName: string;
	dataSources: DataSource[];
}
export interface DataSource {
	displayName: string;
	sourceName: string;
	isResource: boolean;
	entityId: string;
	namespace: string;
}

export interface TemplateField {
	formTemplateFieldId: string;
	namespace?: string;
	name: string;

	/** @deprecated Use dedicated properties */
	modifier?: string;

	label?: string;
	secondaryLabel?: string;

	/** @deprecated Use helperText */
	tooltip?: string;

	placeholder?: string;
	prefix?: string;

	/** @deprecated Use suffix */
	valueSuffix?: string;

	textAreaRows?: number;
	modalText?: string;

	disabled?: boolean;
	literal?: boolean;
	textAlign?: TextAlignment;
	alignWithPrevious?: boolean;
	alwaysVisible?: boolean;
	alwaysEnabled?: boolean;
	hiddenLabel?: boolean;
	width?: FieldWidth;
	role?: string;

	renderAs?: string;
	variant?: string;

	renderAsLiteral?: string;
	literalVariant?: string;

	horizontal?: boolean;
	color?: string;

	errorDisplay?: ErrorDisplay;
	errorDisplayLiteral?: ErrorDisplay;

	/** @deprecated Use literalErrorDisplay = ErrorDisplay.Never */
	hideErrorWhenLiteral?: boolean;

	hideForMobile?: boolean;
	disableValidation?: boolean;
	fullWidth?: boolean;
	mailto?: boolean;

	boldText?: boolean;
	italicText?: boolean;

	// TODO: Update DTO and interface-designer
	helperText?: string; // TODO: rename from 'tooltip'?
	suffix?: string; // TODO: rename from 'valueSuffix'?
	margin?: string;
	multiple?: boolean;
	manual?: boolean;
	justify?: GridJustification;
	icon?: string;
	uppercaseLabel?: boolean;
	allowEmpty?: boolean;
	hiddenIfNoChoice?: boolean;
	searchable?: boolean;
	emptyEqualsZero?: boolean;
	href?: string;
	target?: string;
	to?: string;
	commentType?: string;

	optionSource?: OptionSource;
	search?: FieldService;
	save?: FieldSave;
	click?: FieldClick;
	open?: FieldService;
	globalEvent?: string;

	formatString?: string;

	canResetWriteLocked?: boolean;

	action?: string;
}

export interface TemplateSection {
	formTemplateSectionId: string;
	sectionTemplateId?: number;
	sectionTitle?: string;
	internalDescription?: string;
	style?: string;
	layout?: string;
	fields: TemplateField[];
	literal?: boolean;
	tabGroup?: string;
	tableColumns?: TemplateTableColumn[];

	// Mostly relevant with smart groups. If true, only fields part will matter
	mergeSectionWithPrevious?: boolean;

	// Expansion group settings
	group?: string;
	displaySectionTitle?: boolean;
	showWhenCollapsed?: boolean;
	// Shared expansion group settings
	enableExpansion?: boolean;
	expandInitially?: boolean;

	// Shared group settings
	groupIcon?: string;
	outerWidthSM?: GridSize;
	groupBackgroundColor?: string;
	groupBorder?: boolean;
	boldExpansionTitle?: boolean;

	innerWidthSM?: GridSize;
	useSeparator?: boolean;
	elevation?: number;
	marginBottom?: number;
	square?: boolean;
	outline?: PaletteColorKey;
	hideForMobile?: boolean;
	nextLabel?: string;
	prevLabel?: string;

	//	Editable layout options:
	mainText?: string;
	showStatus?: boolean;
	showUpdateSupplier?: boolean;
	hideSectionIfNoVisibleFields?: boolean;
	standardMargin?: boolean;
	centerText?: boolean;
	helperText?: string;
	showDialogButtons?: boolean;
	hideSignerList?: boolean;
	hideSignerEditing?: boolean;
	showAlternativeMessage?: boolean;
	fullWidthOpenButton?: boolean;
	showUpdateCoSigner?: boolean;
	useStandardLayoutForDirectUsers?: boolean;
	useResourceBinding?: boolean;
	tableSchema?: string;
	tableType?: string;
	tableIsEditable?: boolean;
	mergeColumnValues?: boolean;
	submitOnRowChange?: boolean;
	borderlessTable?: boolean;
	tableFilter?: string[];
	multiselect?: boolean;
	canDeleteRow?: boolean;
	canAddNewRows?: boolean;
	masterCheckmarkChecked?: boolean;
	dataSource?: DataSource;
	decisionTableType?: string;
	allowRuntimeColumnAdditions?: boolean;
	emptyTableMessage?: string;
	fullWidth?: boolean;
}

export interface TemplatePanel {
	formPanelId: string;
	panelTitle?: string;
	sections: TemplateSection[];
	initialTab?: string;
	elevation?: number;
	square?: boolean;
	hideForMobile?: boolean;
	disableValidation?: boolean;
	literal?: boolean;
}

export interface TemplatePageBase {
	formPageId: string;
	pageTitle?: string;
	layout?: string;
	prevLabel?: string;
	nextLabel?: string;
	submitLabel?: string;
}

export interface TemplatePage extends TemplatePageBase {
	panels: Record<string, TemplatePanel>;
	description?: string;
	displayInStepper?: boolean;
	displayInXofY?: boolean;
	hideFunctionButtons?: boolean;
	reduceFormHeightForLaptops?: boolean;
	maxWidth?: number;
	backgroundColor?: string;
}

export interface TemplatePageDeprecated extends TemplatePageBase {
	sections: TemplateSection[];
	summary?: TemplateField[];
	summaryLayout?: string;
}

export interface FormTemplate<P extends TemplatePageBase = TemplatePageBase> {
	formTemplateId?: ID;
	name: string;
	description?: string;
	category?: string;
	pages: P[];
	dataSource?: TemplateDataSource;
	resource?: string;
	changeDate?: Date;
}

export interface SectionTemplate {
	sectionTemplateId?: ID;
	name: string;
	description?: string;
	fields: TemplateField[];
	tableColumns?: TemplateTableColumn[];
	tableDataType?: string;
}

export const isDeprecatedPage = (
	page: TemplatePageBase
): page is TemplatePageDeprecated =>
	(page as TemplatePage).panels == null ||
	Object.keys((page as TemplatePage).panels).length === 0;

export const isTemplatePage = (page: TemplatePageBase): page is TemplatePage =>
	!isDeprecatedPage(page);
