import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "../resources/BaseResource";

export interface ProofOfDeliveryReleasedInformation {
	readonly applicationNumber: string;
	readonly isReleased: boolean;
}

export class ProofOfDeliveryReleasedInformationResource
	extends BaseCamelCasedResource
	implements ProofOfDeliveryReleasedInformation {
	readonly applicationNumber: string;
	readonly isReleased: boolean;

	pk() {
		return this.applicationNumber;
	}

	static urlRoot = legacyApiResourceUrl(
		"ProofOfDelivery/IsProofOfDeliveryReleased"
	);
}
