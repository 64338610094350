import React, { useState } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { useCalculationFields, Field } from "@ploy-lib/calculation";
import { TemplateField } from "@ploy-lib/types";
import marked from "@ploy-lib/purify-marked";
import clsx from "clsx";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { SectionProps } from "../Section";
import { FormHelperTextContainerWrapper, HelperTextIconButton } from "../..";

addRegisteredSectionLayout({
	name: "MarkdownSection",
	displayName: "MarkdownSection",
	settings: {
		editableOptions: {
			mainText: true,
			standardMargin: true,
			centerText: true,
			helperText: true
		}
	}
});

const useStyles = makeStyles(
	(theme: Theme) => ({
		root: {
			margin: "0 auto"
		},
		standardMargin: {
			maxWidth: "550px"
		},
		centerText: {
			textAlign: "center",
			justifyContent: "center"
		},
		body: {
			"& > p": {
				marginBlockStart: `${theme.spacing(0.5)}px`,
				marginBlockEnd: `${theme.spacing(0.5)}px`,
				lineHeight: "24px",
				fontSize: theme.typography.body1.fontSize
			},
			"& > h4": {
				fontWeight: theme.typography.h4.fontWeight,
				fontSize: theme.typography.h4.fontSize,
				marginBlockStart: `${theme.spacing(1)}px`,
				marginBlockEnd: `${theme.spacing(1)}px`
			},
			"& > h6": {
				fontWeight: theme.typography.h6.fontWeight,
				fontSize: theme.typography.h6.fontSize,
				marginBlockStart: `${theme.spacing(0.75)}px`,
				marginBlockEnd: `${theme.spacing(0.75)}px`
			}
		}
	}),
	{ name: "DployMarkdownSection" }
);

interface MarkdownSectionProps extends SectionProps {
	mainText?: string;
	standardMargin?: boolean;
	centerText?: boolean;
	helperText?: string;
}

const emptyArray = [];

export const MarkdownSection = injectIntl(
	(props: MarkdownSectionProps & WrappedComponentProps) => {
		const {
			intl,
			mainText = "",
			standardMargin = false,
			centerText = false,
			allFields = emptyArray,
			className,
			onClick,
			helperText = ""
		} = props;

		const calculationFields = useCalculationFields(
			...allFields.map(f => ({ name: f.name, namespace: f.namespace } as Field))
		);

		const mainTextValues = calculationFields.reduce((acc, field, index) => {
			acc[allFields[index].name] = field?.fieldValue?.toString() ?? "";
			return acc;
		}, {});

		const message = intl.formatMessage(
			{
				id: `markdown.section.${props.formTemplateSectionId}`,
				description: "Markdown section",
				defaultMessage: mainText
			},
			mainTextValues
		);

		const classes = useStyles(props);
		const [showHelperText, setShowHelperText] = useState(false);

		return (
			<Grid
				className={clsx(className, classes.root, {
					[classes.standardMargin]: standardMargin,
					[classes.centerText]: centerText
				})}
				onClick={onClick}
				xs={10}
				container
			>
				<Grid item xs={11}>
					<div
						className={classes.body}
						dangerouslySetInnerHTML={{
							__html: marked(message || "")
						}}
					/>
				</Grid>
				{helperText && (
					<HelperTextIconButton
						toggleHelperText={() => setShowHelperText(display => !display)}
						showHelperText={showHelperText}
					/>
				)}
				<Grid item container xs={12}>
					{showHelperText && helperText ? (
						<FormHelperTextContainerWrapper helperText={helperText} />
					) : null}
				</Grid>
			</Grid>
		);
	}
);
