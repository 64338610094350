import { defineMessages } from "react-intl";

export default defineMessages({
	selectAllChecbox: {
		id: "table.selectAllCheckbox",
		defaultMessage: "Velg Alle",
		description: "Label for global table checkbox"
	},
	"limitDetails.customerId": {
		id: "table.LimitDetails.Customer",
		defaultMessage: "Kunde",
		description: "Label for customer"
	},
	"limitDetails.objectTypes": {
		id: "table.LimitDetails.ObjectTypes",
		defaultMessage: "Gjenstandsgruppe",
		description: "Label for objecttypes"
	},
	"limitDetails.limitAmount": {
		id: "table.LimitDetails.LimitAmount",
		defaultMessage: "Finansiert",
		description: "Label for limitamount"
	},
	"limitDetails.limitApplicationNumber": {
		id: "table.LimitDetails.limitApplicationNumber",
		defaultMessage: "Rammesøknad",
		description: "Label for limitApplicationNumber"
	},
	"limitDetails.loanAmount": {
		id: "table.LimitDetails.LoanAmount",
		defaultMessage: "Fin.beløp",
		description: "Label for loanamount"
	},
	"limitDetails.usedAmount": {
		id: "table.LimitDetails.UsedAmount",
		defaultMessage: "Opptrekk",
		description: "Label for usedamount"
	},
	"limitDetails.remainingAmount": {
		id: "table.LimitDetails.remainingAmount",
		defaultMessage: "Restbeløp",
		description: "Label for restamount"
	},
	"limitDetails.products": {
		id: "table.LimitDetails.Products",
		defaultMessage: "Produkt",
		description: "Label for products"
	},
	"limitDetails.minEquity": {
		id: "table.LimitDetails.MinEquity",
		defaultMessage: "Startleie %",
		description: "Label for min equity"
	},
	"limitDetails.objectCondition": {
		id: "table.LimitDetails.ObjectCondition",
		defaultMessage: "Tilstand",
		description: "Label for object condition"
	},
	"limitDetails.terms": {
		id: "table.LimitDetails.Terms",
		defaultMessage: "Løpetid",
		description: "Label for terms"
	},
	"limitDetails.vatRule": {
		id: "table.LimitDetails.VatRule",
		defaultMessage: "MVA på lån",
		description: "Label for vatrule"
	},
	"limitDetails.pd": {
		id: "table.LimitDetails.PD",
		defaultMessage: "PD Kunde",
		description: "Label for PD Customer"
	},
	"limitDetails.lgd": {
		id: "table.LimitDetails.LGD",
		defaultMessage: "LGD Ramme",
		description: "Label for LGD Limit"
	},
	"limitDetails.lgdAmount": {
		id: "table.LimitDetails.LGDAmount",
		defaultMessage: "LGD Beløp",
		description: "Label for LGD Amount"
	},
	"limitDetails.toDate": {
		id: "table.LimitDetails.toDate",
		defaultMessage: "Sluttdato",
		description: "Label for to date"
	},
	"limitDetails.usedDepotValue": {
		id: "table.LimitDetails.usedDepotValue",
		defaultMessage: "DV Opptrekk",
		description: "Label for used depot value"
	},
	"limitDetails.name": {
		id: "table.LimitDetails.name",
		defaultMessage: "Gjenstandsgruppe",
		description: "Label for name"
	},
	"limitDetails.normalizedLoss": {
		id: "table.LimitDetails.normalizedLoss",
		defaultMessage: "NT",
		description: "Label for normalizedLoss"
	},
	"limitDetails.depotValue": {
		id: "table.LimitDetails.depotValue",
		defaultMessage: "DV Ramme",
		description: "Label for depotValue"
	},
	"Guarantor.customerName": {
		id: "table.Guarantor.CustomerName",
		defaultMessage: "Navn",
		description: "Label for Customer Name"
	},
	"Guarantor.customerNumberCombined": {
		id: "table.Guarantor.CustomerNumberCombined",
		defaultMessage: "Org.nr/Pers.nr.",
		description: "Label for Customer Number"
	},
	"Guarantor.guarantorAmount": {
		id: "table.Guarantor.GuarantorAmount",
		defaultMessage: "Garantibeløp",
		description: "Label for Guarantor Amount"
	},
	"Guarantor.guarantorType": {
		id: "table.Guarantor.GuarantorType",
		defaultMessage: "Garantitype",
		description: "Label for Guarantor Type"
	},
	"applications.applicationNumber": {
		id: "table.ApplicationLimitResource.ApplicationNumber",
		defaultMessage: "Søknad/Kontrakt",
		description: "Label for applicationnumber"
	},
	"applications.applicationStatus": {
		id: "table.ApplicationLimitResource.ApplicationStatus",
		defaultMessage: "Status",
		description: "Label for applicationstatus"
	},
	"applications.objectGroup": {
		id: "table.ApplicationLimitResource.objectGroup",
		defaultMessage: "Gj.gruppe",
		description: "Label for objectGroup"
	},
	"applications.objectCondition": {
		id: "table.ApplicationLimitResource.objectCondition",
		defaultMessage: "Tilstand",
		description: "Label for objectCondition"
	},
	"applications.customerAgreementId": {
		id: "table.ApplicationLimitResource.customerAgreementId",
		defaultMessage: "Ramme",
		description: "Label for customerAgreementId"
	}
});
