import { RouteComponentProps } from "@reach/router";
import { isNotNull } from "@ploy-lib/core";

export function getBasepath<TParams>({
	path,
	uri = "",
	navigate,
	default: isDefault,
	location,
	...params
}: RouteComponentProps<TParams>) {
	if (isDefault || !path) return uri;

	let pathUri = Object.entries(params as TParams).reduce(
		(p, [key, value]) =>
			key === "*"
				? p.replace(key, "")
				: p.replace(`:${key}`, value != null ? value.toString() : ""),
		path
	);
	const basepath = removeTrailingSlash(
		removeTrailingSlash(decodeURI(uri)).replace(
			new RegExp(`/${removeTrailingSlash(pathUri)}/?$`),
			""
		)
	);
	return basepath;
}

export const removeTrailingSlash = (str: string) =>
	str.endsWith("/") ? str.substring(0, str.length - 1) : str;

export function interpolateParams<TParams>(
	routeProps: RouteComponentProps<TParams>
) {
	const { path = "", uri, navigate, location, ...params } = routeProps;

	const newPath = path
		.split("/")
		.map(section => {
			if (!section) return null;
			if (section === "*") return (params as any)[section];
			if (!section.startsWith(":")) return section;
			const paramName = section.substring(1);
			return (params as any)[paramName];
		})
		.filter(isNotNull)
		.join("/");

	return `${newPath}`;
}

export function validApplicationNumber(appNo: string) {
	return appNo && Number(appNo.replace("-", "")) > 0;
}
