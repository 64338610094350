import { BaseCamelCasedResource } from "./BasePlainResource";
import { ID } from "../types";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { AbstractInstanceType, Resource, SchemaList } from "@rest-hooks/rest";

export enum SignerType {
	BusinessSigner = 0,
	PrivateSigner = 1,
	PrivateCosigner = 2,
	VendorSigner = 3,
	GuarantorSigner = 4
}

export interface ApplicationSignerDocumentGroup {
	readonly applicationSignerDocumentGroupId?: ID;
	readonly documentCode?: string | null;
	readonly documentName?: string | null;
}

export interface ApplicationSigner {
	readonly applicationSignerId?: ID;
	readonly applicationId?: ID;
	readonly documents: ApplicationSignerDocumentGroup[];
	readonly mustSign: boolean;
	readonly name: string;
	readonly firstName: string;
	readonly lastName: string;
	readonly birthDate: string;
	readonly ssn: string;
	readonly role: string;
	readonly phone: string;
	readonly email: string;
	readonly ssnAddedByInternal: boolean;
	readonly isVendorSigner: boolean;
	readonly reference: string;
	readonly copiedFromCustomer: boolean;
	readonly signerType: SignerType;
	readonly ssnIsHidden: boolean;
	readonly validToDate: string;
	readonly lockPersonalInfo: boolean;
	readonly lockContactInfo: boolean;
	readonly canSetMustSign: boolean;
	readonly guarantorId?: number;
}

export class ApplicationSignerResource
	extends BaseCamelCasedResource
	implements ApplicationSigner {
	readonly applicationSignerId?: ID;
	readonly applicationId?: ID;
	readonly documents: ApplicationSignerDocumentGroup[] = [];
	readonly mustSign: boolean = false;
	readonly name: string = "";
	readonly firstName: string = "";
	readonly lastName: string = "";
	readonly birthDate: string = "";
	readonly ssn: string = "";
	readonly role: string = "";
	readonly phone: string = "";
	readonly email: string = "";
	readonly ssnAddedByInternal: boolean = false;
	readonly isVendorSigner: boolean = false;
	readonly reference: string = "";
	readonly copiedFromCustomer: boolean = false;
	readonly signerType: SignerType = SignerType.BusinessSigner;
	readonly ssnIsHidden: boolean = false;
	readonly validToDate: string = "";
	readonly lockPersonalInfo: boolean = false;
	readonly lockContactInfo: boolean = false;
	readonly canSetMustSign: boolean = true;
	readonly guarantorId?: number;

	pk() {
		return this.applicationSignerId?.toString();
	}

	static async fetch(input: RequestInfo, init: RequestInit) {
		const jsonResponse = await super.fetch(input, init);

		let { signer, errorMessage, success } = jsonResponse || ({} as any);

		const actualResponse = signer ? signer : jsonResponse;

		if (errorMessage || success === false) throw new Error(errorMessage);

		return actualResponse;
	}

	static list<T extends typeof Resource>(this: T) {
		return super.list().extend({
			schema: [this] as SchemaList<AbstractInstanceType<T>>,
			url: (params: Readonly<Record<string, string | number>>) => {
				const applicationSignersUrl = legacyApiResourceUrl(
					"/Signer/GetSignersForApplication/"
				);
				const applicationVendorSignersUrl = legacyApiResourceUrl(
					"/Signer/GetApplicationVendorSigners/"
				);
				const businessGuarantorSigners = legacyApiResourceUrl(
					"/Signer/GetBusinessGuarantorSigners/"
				);

				const url = this.listUrl(params);

				const actualUrlRoot =
					params && params.vendor
						? applicationVendorSignersUrl
						: params && params.guarantorId
						? businessGuarantorSigners
						: applicationSignersUrl;

				return url.replace(this.urlRoot, actualUrlRoot);
			}
		});
	}

	static ssnCreate<T extends typeof Resource>(this: T) {
		return this.create().extend({
			url: (params?: Readonly<Record<string, string | number | boolean>>) =>
				this.listUrl(params).replace(
					this.urlRoot,
					legacyApiResourceUrl("Signer/GetCustomerSsn")
				)
		});
	}

	static ssnUpdate<T extends typeof Resource>(this: T) {
		return this.update().extend({
			url: (params?: Readonly<Record<string, string | number | boolean>>) =>
				this.listUrl(params).replace(
					this.urlRoot,
					legacyApiResourceUrl("Signer/GetCustomerSsn")
				)
		});
	}

	static urlRoot = legacyApiResourceUrl("Signer/SaveApplicationSigner");
}
