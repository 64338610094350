import React from "react";
import { AutocompleteField } from "@ploy-ui/form-fields";
import { useIntl, defineMessages } from "react-intl";
import { LoginResource } from "@ploy-lib/rest-resources";
import { useFetcher } from "@rest-hooks/core";
import { FormBase } from "@ploy-ui/login";

const messages = defineMessages({
	submitError: {
		id: "dealer.login.vendor.error",
		description: "An error occurred",
		defaultMessage: "En feil har oppstått"
	},
	selectVendorLabel: {
		id: "dealer.login.field.vendor.label",
		description: "Label for vendor select field",
		defaultMessage: "Velg forhandler"
	},
	submitButtonText: {
		id: "dealer.login.button.continue.label",
		description: "Label for continue button",
		defaultMessage: "Fortsett"
	}
});

const VendorForm = ({ vendors }) => {
	const setVendor = useFetcher(LoginResource.setVendor());

	const intl = useIntl();

	const items = vendors.slice().sort((a, b) => a.name.localeCompare(b.name));

	const initValues = { customerId: null };

	const fieldConfig = [
		{
			name: "customerId",
			component: AutocompleteField,
			helperText: " ",
			label: intl.formatMessage(messages.selectVendorLabel),
			items: items,
			getItemLabel: item => item.name,
			getItemValue: item => item.id,
			fullWidth: true,
			margin: "normal",
			autoFocus: true
		}
	];
	return (
		<FormBase
			submit={values => setVendor(undefined, values)}
			initValues={initValues}
			fieldConfig={fieldConfig}
			submitButtonText={intl.formatMessage(messages.submitButtonText)}
			icon="flag-o"
			autoSubmit={true}
		/>
	);
};

export default VendorForm;
