import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	defineMessages,
	FormattedMessage,
	FormattedTime,
	useIntl
} from "react-intl";
import { SectionProps } from "../Section";
import { StatusIcon } from "@ploy-ui/core";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { useCalculationFields } from "@ploy-lib/calculation";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { ApplicationStatus, DocumentStatus } from "@ploy-lib/types";
import { useAppLoad } from "../../appLoadContext";
import clsx from "clsx";
import { useFetcher } from "@rest-hooks/core";
import { AppLoadResource } from "@ploy-lib/rest-resources";
import { legacyApiResourceUrl } from "@ploy-lib/core";

const appLoadShape = AppLoadResource.detail();

const generalMessages = defineMessages({
	[ApplicationStatus.NotSet]: {
		id: `form.application-status-header.NotSet.message`,
		description: `Application status message if status is NotSet`,
		defaultMessage: "Søknad {applicationNumber} behandles"
	},
	[ApplicationStatus.Saved]: {
		id: `form.application-status-header.Saved.message`,
		description: `Application status message if status is Saved`,
		defaultMessage: "Søknad {applicationNumber} behandles"
	},
	[ApplicationStatus.Submitted]: {
		id: `form.application-status-header.Submitted.message`,
		description: `Application status message if status is Submitted`,
		defaultMessage: "Søknad {applicationNumber} behandles"
	},
	[ApplicationStatus.Scoring]: {
		id: `form.application-status-header.Scoring.message`,
		description: `Application status message if status is Scoring`,
		defaultMessage: "Søknad {applicationNumber} behandles"
	},
	[ApplicationStatus.ManualUW]: {
		id: `form.application-status-header.ManualUW.message`,
		description: `Application status message if status is ManualUW`,
		defaultMessage: "Søknad {applicationNumber} er sendt til manuell behandling"
	},
	[ApplicationStatus.ManuallyRejected]: {
		id: `form.application-status-header.ManuallyRejected.message`,
		description: `Application status message if status is ManuallyRejected`,
		defaultMessage: "Søknad {applicationNumber} ble dessverre avslått"
	},
	[ApplicationStatus.AutoRejected]: {
		id: `form.application-status-header.AutoRejected.message`,
		description: `Application status message if status is AutoRejected`,
		defaultMessage: "Søknad {applicationNumber} ble dessverre avslått"
	},
	[ApplicationStatus.Cancelled]: {
		id: `form.application-status-header.Cancelled.message`,
		description: `Application status message if status is Cancelled`,
		defaultMessage: "Søknad {applicationNumber} er bortfalt"
	},
	[ApplicationStatus.Paid]: {
		id: `form.application-status-header.Paid.message`,
		description: `Application status message if status is Paid`,
		defaultMessage: "Søknad {applicationNumber} er utbetalt"
	},
	[ApplicationStatus.Transferred]: {
		id: `form.application-status-header.Transferred.message`,
		description: `Application status message if status is Transferred`,
		defaultMessage: "Søknad {applicationNumber} er utbetalt"
	},
	[ApplicationStatus.Replaced]: {
		id: `form.application-status-header.Replaced.message`,
		description: `Application status message if status is Replaced`,
		defaultMessage: "Søknad {applicationNumber} er erstattet"
	},
	[ApplicationStatus.Expired]: {
		id: `form.application-status-header.Expired.message`,
		description: `Application status message if status is Expired`,
		defaultMessage: "Søknad {applicationNumber} er bortfalt"
	},
	[ApplicationStatus.ApprovedWithConditions]: {
		id: `form.application-status-header.ApprovedWithConditions.message`,
		description: `Application status message if status is ApprovedWithConditions`,
		defaultMessage: "Søknad {applicationNumber} er innvilget med forutsetninger"
	},
	[ApplicationStatus.Prescore]: {
		id: `form.application-status-header.Prescore.message`,
		description: `Application status message if status is Prescore`,
		defaultMessage: "Søknad {applicationNumber} behandles"
	},
	[ApplicationStatus.Processing]: {
		id: `form.application-status-header.Processing.message`,
		description: `Application status message if status is Processing`,
		defaultMessage: "Søknad {applicationNumber} behandles"
	},
	[ApplicationStatus.Scoring]: {
		id: `form.application-status-header.Scoring.message`,
		description: `Application status message if status is Scoring`,
		defaultMessage: "Søknad {applicationNumber} behandles"
	},
	[ApplicationStatus.Invoiced]: {
		id: `form.application-status-header.Invoiced.message`,
		description: `Application status message if status is Invoiced`,
		defaultMessage: "Søknad {applicationNumber} behandles"
	},
	[ApplicationStatus.PartlyPaid]: {
		id: `form.application-status-header.PartlyPaid.message`,
		description: `Application status message if status is PartlyPaid`,
		defaultMessage: "Søknad {applicationNumber} er utbetalt"
	},
	[ApplicationStatus.Offer]: {
		id: `form.application-status-header.Offer.message`,
		description: `Application status message if status is Offer`,
		defaultMessage: "Søknad {applicationNumber}  avventer kundeaksept"
	},
	[ApplicationStatus.RejectedMissingDocumentation]: {
		id: `form.application-status-header.RejectedMissingDocumentation.message`,
		description: `Application status message if status is RejectedMissingDocumentation`,
		defaultMessage: "Søknad {applicationNumber} ble dessverre avslått"
	},
	[ApplicationStatus.RedeemProofOfFinancing]: {
		id: `form.application-status-header.RedeemProofOfFinancing.message`,
		description: `Application status mesage if status is RedeemProofOfFinancing`,
		defaultMessage: "Lånebevis {applicationNumber} er innvilget"
	},
	[ApplicationStatus.Fulfilled]: {
		id: `form.application-status-header.Fulfilled.message`,
		description: `Application status message if status is Fulfilled`,
		defaultMessage: "Søknad {applicationNumber} er utbetalt"
	}
});

const withBackoffice = defineMessages({
	[ApplicationStatus.AutoApproved]: {
		id: `form.application-status-header.AutoApproved-backoffice.message`,
		description: `Application status message if status is AutoApproved`,
		defaultMessage:
			"Søknad {applicationNumber} med avtalenummer {backofficeAccount} er innvilget"
	},
	[ApplicationStatus.ManuallyApproved]: {
		id: `form.application-status-header.ManuallyApproved-backoffice.message`,
		description: `Application status message if status is ManuallyApproved`,
		defaultMessage:
			"Søknad {applicationNumber} med avtalenummer {backofficeAccount} er innvilget"
	}
});

const withoutBackoffice = defineMessages({
	[ApplicationStatus.AutoApproved]: {
		id: `form.application-status-header.AutoApproved-no-backoffice.message`,
		description: `Application status message if status is AutoApproved`,
		defaultMessage: "Søknad {applicationNumber} er innvilget"
	},
	[ApplicationStatus.ManuallyApproved]: {
		id: `form.application-status-header.ManuallyApproved-no-backoffice.message`,
		description: `Application status message if status is ManuallyApproved`,
		defaultMessage: "Søknad {applicationNumber} er innvilget"
	}
});

const subMessages = defineMessages({
	[ApplicationStatus.NotSet]: {
		id: `form.application-status-header.NotSet.sub-message`,
		description: `Application status sub message if status is NotSet`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Saved]: {
		id: `form.application-status-header.Saved.sub-message`,
		description: `Application status sub message if status is Saved`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Submitted]: {
		id: `form.application-status-header.Submitted.sub-message`,
		description: `Application status sub message if status is Submitted`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Scoring]: {
		id: `form.application-status-header.Scoring.sub-message`,
		description: `Application status sub message if status is Scoring`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.ManualUW]: {
		id: `form.application-status-header.ManualUW.sub-message`,
		description: `Application status sub message if status is ManualUW`,
		defaultMessage:
			"Søknaden blir behandlet, og en av våre saksbehandlere vil ta kontakt med deg innen kort tid."
	},
	[ApplicationStatus.AutoApproved]: {
		id: `form.application-status-header.AutoApproved.sub-message`,
		description: `Application status sub message if status is AutoApproved`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.ManuallyApproved]: {
		id: `form.application-status-header.ManuallyApproved.sub-message`,
		description: `Application status sub message if status is ManuallyApproved`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.ManuallyRejected]: {
		id: `form.application-status-header.ManuallyRejected.sub-message`,
		description: `Application status sub message if status is ManuallyRejected`,
		defaultMessage:
			"Vennligst ta kontakt med din lokale kontaktperson for ytterligere informasjon"
	},
	[ApplicationStatus.AutoRejected]: {
		id: `form.application-status-header.AutoRejected.sub-message`,
		description: `Application status sub message if status is AutoRejected`,
		defaultMessage:
			"Vennligst ta kontakt med din lokale kontaktperson for ytterligere informasjon"
	},
	[ApplicationStatus.Cancelled]: {
		id: `form.application-status-header.Cancelled.sub-message`,
		description: `Application status sub message if status is Cancelled`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Paid]: {
		id: `form.application-status-header.Paid.sub-message`,
		description: `Application status sub message if status is Paid`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Transferred]: {
		id: `form.application-status-header.Transferred.sub-message`,
		description: `Application status sub message if status is Transferred`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Replaced]: {
		id: `form.application-status-header.Replaced.sub-message`,
		description: `Application status sub message if status is Replaced`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Expired]: {
		id: `form.application-status-header.Expired.sub-message`,
		description: `Application status sub message if status is Expired`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.ApprovedWithConditions]: {
		id: `form.application-status-header.ApprovedWithConditions.sub-message`,
		description: `Application status sub message if status is ApprovedWithConditions`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Prescore]: {
		id: `form.application-status-header.Prescore.sub-message`,
		description: `Application status sub message if status is Prescore`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Processing]: {
		id: `form.application-status-header.Processing.sub-message`,
		description: `Application status sub message if status is Processing`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Scoring]: {
		id: `form.application-status-header.Scoring.sub-message`,
		description: `Application status sub message if status is Scoring`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Invoiced]: {
		id: `form.application-status-header.Invoiced.sub-message`,
		description: `Application status sub message if status is Invoiced`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.PartlyPaid]: {
		id: `form.application-status-header.PartlyPaid.sub-message`,
		description: `Application status sub message if status is PartlyPaid`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Offer]: {
		id: `form.application-status-header.Offer.sub-message`,
		description: `Application status sub message if status is Offer`,
		defaultMessage:
			"Kunde må søke selv. Vi har sendt SMS og e-post med informasjon om dette"
	},
	[ApplicationStatus.RejectedMissingDocumentation]: {
		id: `form.application-status-header.RejectedMissingDocumentation.sub-message`,
		description: `Application status sub message if status is RejectedMissingDocumentation`,
		defaultMessage:
			"Vennligst ta kontakt med din lokale kontaktperson for ytterligere informasjon"
	},
	[ApplicationStatus.RedeemProofOfFinancing]: {
		id: `form.application-status-header.RedeemProofOfFinancing.sub-message`,
		description: `Application status mesage if status is RedeemProofOfFinancing`,
		defaultMessage: "Lånebevis er sendt til kunde"
	},
	[ApplicationStatus.Fulfilled]: {
		id: `form.application-status-header.Fulfilled.sub-message`,
		description: `Application status sub message if status is Fulfilled`,
		defaultMessage: "\u200B"
	}
});

const subMessagesDirect = defineMessages({
	[ApplicationStatus.NotSet]: {
		id: `form.formweb.application-status-header.NotSet.sub-message`,
		description: `Application status sub message if status is NotSet`,
		defaultMessage: "AppStatus = NotSet"
	},
	[ApplicationStatus.Saved]: {
		id: `form.formweb.application-status-header.Saved.sub-message`,
		description: `Application status sub message if status is Saved`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Submitted]: {
		id: `form.formweb.application-status-header.Submitted.sub-message`,
		description: `Application status sub message if status is Submitted`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Scoring]: {
		id: `form.formweb.application-status-header.Scoring.sub-message`,
		description: `Application status sub message if status is Scoring`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.ManualUW]: {
		id: `form.formweb.application-status-header.ManualUW.sub-message`,
		description: `Application status sub message if status is ManualUW`,
		defaultMessage: `Vi vil kontakte deg så snart vi har behandlet din {productType, select,
				leasing {leasingsøknad}
				loan {lånesøknad}
			}.`
	},
	[ApplicationStatus.AutoApproved]: {
		id: `form.formweb.application-status-header.AutoApproved.sub-message`,
		description: `Application status sub message if status is AutoApproved`,
		defaultMessage: `Gratulerer, {productType, select,
				leasing {leasingsøknaden}
				loan {lånesøknaden}
			} er innvilget, og {isEsigned, select,
				true {alle dokumenter er ferdig signert}
				false {du kan signere lånedokumenter <distributionsigningportalurl>her</distributionsigningportalurl>}
			}.`
	},
	[ApplicationStatus.ManuallyApproved]: {
		id: `form.formweb.application-status-header.ManuallyApproved.sub-message`,
		description: `Application status sub message if status is ManuallyApproved`,
		defaultMessage: `Gratulerer, {productType, select,
			leasing {leasingsøknaden}
			loan {lånesøknaden}
		} er innvilget, og {isEsigned, select,
			true {alle dokumenter er ferdig signert}
			false {du kan signere lånedokumenter <distributionsigningportalurl>her</distributionsigningportalurl>}
		}.`
	},
	[ApplicationStatus.ManuallyRejected]: {
		id: `form.formweb.application-status-header.ManuallyRejected.sub-message`,
		description: `Application status sub message if status is ManuallyRejected`,
		defaultMessage: `Vi beklager at din {productType, select,
			leasing {leasingsøknad}
			loan {lånesøknad}
		} ikke kan innvilges.`
	},
	[ApplicationStatus.AutoRejected]: {
		id: `form.formweb.application-status-header.AutoRejected.sub-message`,
		description: `Application status sub message if status is AutoRejected`,
		defaultMessage: `Vi beklager at din {productType, select,
			leasing {leasingsøknad}
			loan {lånesøknad}
		} ikke kan innvilges.`
	},
	[ApplicationStatus.Cancelled]: {
		id: `form.formweb.application-status-header.Cancelled.sub-message`,
		description: `Application status sub message if status is Cancelled`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Paid]: {
		id: `form.formweb.application-status-header.Paid.sub-message`,
		description: `Application status sub message if status is Paid`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Transferred]: {
		id: `form.formweb.application-status-header.Transferred.sub-message`,
		description: `Application status sub message if status is Transferred`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Replaced]: {
		id: `form.formweb.application-status-header.Replaced.sub-message`,
		description: `Application status sub message if status is Replaced`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Expired]: {
		id: `form.formweb.application-status-header.Expired.sub-message`,
		description: `Application status sub message if status is Expired`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.ApprovedWithConditions]: {
		id: `form.formweb.application-status-header.ApprovedWithConditions.sub-message`,
		description: `Application status sub message if status is ApprovedWithConditions`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Prescore]: {
		id: `form.formweb.application-status-header.Prescore.sub-message`,
		description: `Application status sub message if status is Prescore`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Processing]: {
		id: `form.formweb.application-status-header.Processing.sub-message`,
		description: `Application status sub message if status is Processing`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Scoring]: {
		id: `form.formweb.application-status-header.Scoring.sub-message`,
		description: `Application status sub message if status is Scoring`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Invoiced]: {
		id: `form.formweb.application-status-header.Invoiced.sub-message`,
		description: `Application status sub message if status is Invoiced`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.PartlyPaid]: {
		id: `form.formweb.application-status-header.PartlyPaid.sub-message`,
		description: `Application status sub message if status is PartlyPaid`,
		defaultMessage: "\u200B"
	},
	[ApplicationStatus.Offer]: {
		id: `form.formweb.application-status-header.Offer.sub-message`,
		description: `Application status sub message if status is Offer`,
		defaultMessage: "AppStatus.Offer"
	},
	[ApplicationStatus.RejectedMissingDocumentation]: {
		id: `form.formweb.application-status-header.RejectedMissingDocumentation.sub-message`,
		description: `Application status sub message if status is RejectedMissingDocumentation`,
		defaultMessage: "AppStatus.RejectedMisssingDocumentation"
	},
	[ApplicationStatus.RedeemProofOfFinancing]: {
		id: `form.formweb.application-status-header.RedeemProofOfFinancing.sub-message`,
		description: `Application status message if status is RedeemProofOfFinancing`,
		defaultMessage: `Gratulerer, {productType, select,
			leasing {leasingsøknaden}
			loan {lånesøknaden}
		} er innvilget og du vil straks motta en e-post med ditt lånebevis. Når du har funnet {objectGroup, select,
				car {bilen}
				ship {båten}
				motorcycle {kjøretøyet}
				caravan {kjøretøyet}
				other {kjøretøyet}
			} du ønsker å kjøpe, går du til <distributionmypageurl>Min Side</distributionmypageurl> for å fullføre søknaden.`
	},
	[ApplicationStatus.Fulfilled]: {
		id: `form.formweb.application-status-header.Fulfilled.sub-message`,
		description: `Application status message if status is Fulfilled`,
		defaultMessage: "\u200B"
	}
});

const subMessagesDirectDocumentStatus = defineMessages({
	[DocumentStatus.SblRequestSent]: {
		id: `form.application-status-header.DocumentStatus-SblRequestSent.message`,
		description: `Application documentstatus submessage if documentstatus is SblRequestSent`,
		defaultMessage: `{hasCosigner, select,
			true {Vi avventer nå samtykke fra medsøker før vi kan behandle søknaden}
			false {Vennligst vent mens vi henter data fra Skatteetaten}
		}`
	},
	[DocumentStatus.SblRequestReceived]: {
		id: `form.application-status-header.DocumentStatus-SblRequestReceived.message`,
		description: `Application documentstatus submessage if documentstatus is SblRequestReceived`,
		defaultMessage: `Vennligst vent mens vi behandler søknaden`
	},
	[DocumentStatus.CustomerRejectedInformationAccess]: {
		id: `form.application-status-header.DocumentStatus-CustomerRejectedInformationAccess.message`,
		description: `Application documentstatus submessage if documentstatus is CustomerRejectedInformationAccess`,
		defaultMessage:
			"Søknad {applicationNumber} Vi trenger ditt samtykke til å innhente kredittinformasjon for å behandle søknaden din. Vennligst ta kontakt med {contactInfo_fullname} på epost {contactInfo_email}, eller ring {contactInfo_phone}"
	}
});

// TODO: get data from controller instead of displayRules?
const calculationFields = [
	{
		name: "BackOfficeAccountId",
		namespace: "Main"
	},
	{
		name: "MainHeader",
		namespace: "Main"
	},
	{
		name: "MainInformation",
		namespace: "Main"
	},
	{
		name: "AdditionalInformationRelatedToHeader",
		namespace: "Main"
	},
	{
		name: "ObjectIcon",
		namespace: "Object"
	}
];

const useStyles = makeStyles(theme => ({
	subHeaderDirText: {
		marginBottom: 20,
		textAlign: "center",
		[theme.breakpoints.down("sm")]: {
			marginLeft: 8,
			marginRight: 8,
			marginBottom: 12,
			textAlign: "left"
		}
	},
	subHeaderText: {
		whiteSpace: "pre-wrap"
	}
}));

addRegisteredSectionLayout({
	name: "ApplicationStatusSection",
	displayName: "ApplicationStatusSection",
	settings: {
		editableOptions: {
			useStandardLayoutForDirectUsers: true
		}
	}
});

const ApplicationStatusSection = (props: SectionProps) => {
	const {
		applicationNumber,
		applicationStatus,
		applicationStatusId,
		documentStatusId,
		documentStatus,
		applicationStatusDescription,
		documentStatusDescription,
		isDirectUser,
		settings,
		productTypeId,
		isEsigned,
		contactInfo,
		coSignerId,
		refetchApp
	} = useAppLoad();
	const { className, onClick, useStandardLayoutForDirectUsers } = props;
	const distributionValues = settings && settings.distributionValues;
	const styles = useStyles(props);
	var interval = useRef<NodeJS.Timeout>();

	const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());
	const checkAndRefetch = useCallback(async () => {
		const response = await fetch(
			legacyApiResourceUrl("AppLoad/GetAppStatusDesc/" + applicationNumber)
		);
		const newStatusDesc = await response.json();

		if (
			newStatusDesc.AppStatusDesc !== applicationStatusDescription ||
			newStatusDesc.DocStatusDesc !== documentStatusDescription
		) {
			refetchApp();
		}
	}, [
		applicationNumber,
		applicationStatusDescription,
		documentStatusDescription,
		refetchApp
	]);

	useEffect(() => {
		if (interval.current) clearInterval(interval.current);
		interval.current = setInterval(async () => {
			await checkAndRefetch();
			setLastUpdateTime(Date.now());
		}, 3000);
		return () => interval.current && clearInterval(interval.current);
	}, [checkAndRefetch, setLastUpdateTime]);

	const [
		backofficeAccountField,
		mainHeaderField,
		subHeader1Field,
		subHeader2Field,
		objectIconField
	] = useCalculationFields(...calculationFields);

	const objectGroup = (objectIconField && objectIconField.value) || "other";
	const productType = productTypeId === 2 ? "leasing" : "loan";
	const intl = useIntl();

	var values = {
		applicationNumber,
		backofficeAccount:
			backofficeAccountField && backofficeAccountField.visible
				? (backofficeAccountField.fieldValue as string)
				: "",
		mainHeader:
			mainHeaderField && mainHeaderField.visible
				? (mainHeaderField.fieldValue as string)
				: "",
		subHeader1:
			subHeader1Field && subHeader1Field.visible
				? (subHeader1Field.fieldValue as string)
				: "",
		subHeader2:
			subHeader2Field && subHeader2Field.visible
				? (subHeader2Field.fieldValue as string)
				: "",
		objectGroup: objectGroup,
		productType: productType,
		isEsigned: isEsigned,
		hasCosigner: !!coSignerId
	};

	if (distributionValues !== undefined)
		Object.keys(distributionValues).forEach(key => {
			values[key.toLowerCase()] = (children: string) => (
				<a
					href={distributionValues[key]}
					rel="noopener noreferrer"
					target="_blank"
				>
					{children}
				</a>
			);
		});
	if (contactInfo) {
		Object.keys(contactInfo).forEach(key => {
			values[`contactInfo_${key.toLowerCase()}`] = contactInfo[key];
		});
	}

	const getDirectMessage = intl.formatMessage(
		subMessagesDirect[applicationStatus],
		values
	);
	const getDirectMessageDocumentStatus = subMessagesDirectDocumentStatus[
		documentStatus
	]
		? intl.formatMessage(
				subMessagesDirectDocumentStatus[documentStatus],
				values
		  )
		: undefined;

	const messages = values.backofficeAccount
		? { ...generalMessages, ...withBackoffice }
		: { ...generalMessages, ...withoutBackoffice };

	const main =
		isDirectUser && !useStandardLayoutForDirectUsers
			? ""
			: values.mainHeader
			? intl.formatMessage(
					{
						id: `text.mainHeader.${applicationNumber}`,
						defaultMessage: values.mainHeader
					},
					values
			  )
			: intl.formatMessage(messages[applicationStatus], values);

	const appNumberText =
		!applicationNumber || main.includes(applicationNumber) ? null : (
			<FormattedMessage
				id="form.application-status-header.fallback"
				description="Application status fallback message if main message is overridden"
				defaultMessage="Søknad {applicationNumber}"
				values={values}
			/>
		);

	const sub =
		isDirectUser && !useStandardLayoutForDirectUsers ? (
			getDirectMessageDocumentStatus ?? getDirectMessage
		) : values.subHeader1 ? (
			intl.formatMessage(
				{
					id: `text.subHeader1.${applicationNumber}`,
					defaultMessage: values.subHeader1
				},
				values
			)
		) : values.subHeader2 ? (
			values.subHeader2
		) : (
			<FormattedMessage {...subMessages[applicationStatus]} values={values} />
		);

	return (
		<Box textAlign="center" className={className} onClick={onClick}>
			{(!isDirectUser || useStandardLayoutForDirectUsers) && (
				<div>
					<StatusIcon status={applicationStatusId} fontSize={64} />
					<Typography variant="h4" data-message-type="applicationHeader">
						{main}
					</Typography>
				</div>
			)}
			<Box
				data-message-type="applicationSubHeader"
				mt={2}
				className={clsx(styles.subHeaderText, {
					[styles.subHeaderDirText]: isDirectUser
				})}
				fontFamily="fontFamily"
				fontStyle="italic"
				fontSize={isDirectUser ? 18 : 16}
				fontWeight={700}
			>
				{sub}
			</Box>
			{appNumberText && (!isDirectUser || useStandardLayoutForDirectUsers) && (
				<Box
					data-message-type="applicationNumberText"
					mt={2}
					fontFamily="fontFamily"
					fontStyle="italic"
					fontSize={16}
					fontWeight={700}
				>
					{appNumberText}
				</Box>
			)}
			{settings && settings.showLastUpdateTime ? (
				<Box
					data-message-type="lastUpdatedText"
					mt={2}
					fontFamily="fontFamily"
					fontStyle="italic"
					fontSize={10}
				>
					<FormattedMessage
						id="dealer.appstatus.lastUpdated"
						description="Label last updated time"
						defaultMessage="Last update time "
					/>
					<FormattedTime value={lastUpdateTime} timeStyle="medium" />
				</Box>
			) : null}
		</Box>
	);
};

ApplicationStatusSection.displayName = "DployApplicationStatusSection";

export { ApplicationStatusSection };
