import React, { memo } from "react";

import { ExternalViewSettingsResource } from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";

import * as views from "./views";
import { makeStyles } from "@material-ui/core/styles";
import { RouteComponentProps } from "@reach/router";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		paddingTop: theme.spacing(8),
		padding: theme.spacing(4)
	}
}));

type View = keyof typeof views;

function isView(str: string): str is View {
	return (views as any)[str] != null;
}

export const ExternalViewLoader = memo(
	({ view }: RouteComponentProps<{ view: View }>) => {
		const classes = useStyles();

		if (!view) throw new Error("Ingen ekstern visning angitt");

		const { component, endpoint, token, dtResult, internalUser } = useResource(
			ExternalViewSettingsResource.detail(),
			{
				view
			}
		);

		const Component = views[isView(component) ? component : view];

		return (
			<div className={classes.root}>
				<Component
					endpoint={endpoint}
					token={token}
					dtResult={dtResult}
					internalUser={internalUser}
				/>
			</div>
		);
	}
);
