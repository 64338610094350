import { apiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BaseResource";

export interface LimitCustomerType {
	limitCustomerId: number;
	applicationId: number;
	customerId: number;
	customerName: string;
	canDrawFromMainLimit: boolean;
}

export class LimitCustomerResource
	extends BaseCamelCasedResource
	implements LimitCustomerType {
	readonly limitCustomerId: number;
	readonly applicationId: number;
	readonly customerId: number;
	readonly customerName: string;
	readonly canDrawFromMainLimit: boolean;

	pk() {
		return this.limitCustomerId?.toString() || "";
	}

	/**
	 * Get the url for many Resources
	 */
	static listUrl(
		allParams: Readonly<Record<string, string | number>> = {}
	): string {
		const { applicationId } = allParams;
		if (applicationId !== undefined) {
			return `${this.urlRoot}/get-limit-customers-for-app/${applicationId}`;
		}
		throw new Error("LimitCustomerResource require applicationId");
	}

	static urlRoot = apiResourceUrl("limit-customer");
}
