import React, { useCallback, memo } from "react";
import MuiRadio from "@material-ui/core/Radio";
import MuiCheckbox from "@material-ui/core/Checkbox";
import MuiSwitch from "@material-ui/core/Switch";
import MuiRadioGroup from "@material-ui/core/RadioGroup";
import MuiFormGroup from "@material-ui/core/FormGroup";
import MuiToggleButton from "@material-ui/lab/ToggleButton";
import MuiToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import MuiFormControl, {
	FormControlProps as MuiFormControlProps
} from "@material-ui/core/FormControl";
import MuiFormLabel from "@material-ui/core/FormLabel";
import MuiFormControlLabel, {
	FormControlLabelProps as MuiFormControlLabelProps
} from "@material-ui/core/FormControlLabel";
import MuiFormHelperText from "@material-ui/core/FormHelperText";
import { RadioGroupProps as FormikRadioGroupProps } from "formik-material-ui";
import { Overwrite, Omit, HasItems, BaseFieldProps } from "./types";
import {
	identity,
	identityRecordOfFieldEditorOptions,
	getFieldErrorProps
} from "./utils";
import { makeStyles, Theme, MuiThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import merge from "lodash/merge";

export interface RadioGroupOptions<T> extends HasItems<T> {}

const variants = [
	"standard",
	"switch",
	"button",
	"toggleButton",
	"toggleButtonNoIcons"
] as const;
const colors = ["primary", "secondary", "default"] as const;

export interface RadioGroupProps<T>
	extends Overwrite<
			FormikRadioGroupProps,
			Omit<MuiFormControlProps, "variant" | "color">
		>,
		Overwrite<BaseFieldProps, HasItems<T>> {
	variant?: typeof variants[number];
	color?: typeof colors[number];
}

const useStyles = makeStyles((theme: Theme) => ({
	buttonGroup: {
		display: "flex",
		flexWrap: "wrap",
		marginTop: 8,
		marginBottom: 8
	},
	toggleButtonGroup: {
		flexGrow: 1,
		flexShrink: 0
	},
	toggleButtonSwitch: {
		color: theme.palette.primary.main,
		backgroundColor: "#fff",
		marginTop: 12,
		fontSize: 15
	}
}));

const toggleButtonSwitchTheme = (theme: Theme) => {
	const overrides = {
		...theme,
		overrides: {
			...theme.overrides,
			MuiToggleButton: {
				root: {
					"&:not(:first-child)": {
						border: `1px solid ${theme.palette.grey[700]}`,
						borderLeft: `1px solid ${theme.palette.grey[700]}`
					}
				}
			}
		}
	};
	return merge({}, theme, overrides) as Theme;
};

export function SelectionField<T extends any>(props: RadioGroupProps<T>) {
	const {
		field,
		form,
		helperText,
		disabled,
		options,
		errorDisplay,
		...rest
	} = props;
	const { name, value } = field;
	const { isSubmitting, setFieldValue } = form;

	const onChange = useCallback(
		(selected: string | number | boolean | string[] | number[]) =>
			setFieldValue(name, selected),
		[setFieldValue, name]
	);

	return (
		<DploySelectionField
			{...rest}
			{...getFieldErrorProps(props, errorDisplay)}
			name={name}
			value={value}
			disabled={isSubmitting || disabled}
			onChange={onChange}
		/>
	);
}

export interface DploySelectionFieldProps<T>
	extends Omit<MuiFormControlProps, "variant" | "onChange" | "color">,
		Overwrite<BaseFieldProps, HasItems<T>> {
	variant?: typeof variants[number];
	color?: typeof colors[number];
	onChange: (value?: any) => void;
	name?: string;
	value: any;
}

function DploySelectionFieldInternal<T extends any>({
	onChange,
	value,
	label,
	helperText,
	disabled,
	error,
	items = [],
	getItemLabel = identity,
	getItemValue = identity,
	getItemId = identity,
	valueKey,
	labelKey,
	getItemSuggestions,
	onSelectItem,
	fullWidth,
	classes,
	variant,
	multiple,
	searchable,
	clearable,
	manual,
	pending,
	emptyEqualsZero,
	formatString,
	horizontal: row,
	color,
	secondaryLabel,
	italicText,
	boldText,
	modalText,
	textAlign,
	...props
}: DploySelectionFieldProps<T>) {
	const onButtonChange = useCallback(
		(_, selected: string | number | boolean) =>
			onChange?.(selected == null && !clearable ? value : selected),
		[onChange, value, clearable]
	);

	const onMultipleChange = useCallback(
		(selected: string | number | boolean) => {
			const list = value?.includes(selected)
				? value?.filter(v => v !== selected)
				: value
				? [...value, selected]
				: [selected];
			if (onChange) onChange(list);
		},
		[onChange, value]
	);

	const buttonClasses = useStyles(props);

	let formGroup: React.ReactNode;

	switch (variant) {
		case "button": {
			formGroup = (
				<MuiToggleButtonGroup
					className={buttonClasses.buttonGroup}
					value={value}
					exclusive={!multiple}
					onChange={onButtonChange}
				>
					{items.map(item => {
						const itemValue = getItemValue(item);
						const itemLabel = getItemLabel(item);

						return (
							<MuiToggleButton
								disabled={disabled}
								data-option-label={itemLabel}
								data-option-value={itemValue}
								key={String(itemLabel)}
								className={buttonClasses.toggleButtonGroup}
								value={itemValue}
							>
								{itemLabel}
							</MuiToggleButton>
						);
					})}
				</MuiToggleButtonGroup>
			);
			break;
		}
		case "toggleButtonNoIcons":
		case "toggleButton": {
			const notSelected = items.find(
				i => String(getItemValue(i)) !== String(value)
			);
			formGroup = (
				<MuiThemeProvider theme={toggleButtonSwitchTheme}>
					<MuiToggleButton
						disabled={disabled}
						selected={Boolean(value)}
						onChange={() => {
							onChange(getItemValue(notSelected as T));
						}}
						value={value}
						className={buttonClasses.toggleButtonSwitch}
					>
						{variant === "toggleButtonNoIcons" ? null : value ? (
							<ClearIcon />
						) : (
							<AddIcon />
						)}
						{notSelected && getItemLabel(notSelected)}
					</MuiToggleButton>
				</MuiThemeProvider>
			);
			break;
		}
		default: {
			const Control =
				variant === "switch" ? MuiSwitch : multiple ? MuiCheckbox : MuiRadio;
			const Group = multiple ? MuiFormGroup : MuiRadioGroup;

			formGroup = (
				<Group row={row} {...props}>
					{items.map(item => {
						const itemValue = getItemValue(item);
						const itemLabel = getItemLabel(item);

						return (
							<FormControlLabel
								data-option-label={itemLabel}
								data-option-value={itemValue}
								key={String(
									typeof itemLabel === "object" ? itemValue : itemLabel
								)}
								onChange={multiple ? onMultipleChange : onChange}
								value={itemValue}
								label={itemLabel}
								disabled={disabled}
								control={
									<Control
										checked={
											multiple
												? value?.includes(itemValue) ?? false
												: value === itemValue
										}
										color={color}
									/>
								}
							/>
						);
					})}
				</Group>
			);
		}
	}

	return (
		<MuiFormControl
			component={"fieldset" as "div"}
			error={error}
			disabled={disabled}
			fullWidth={fullWidth}
			classes={classes}
		>
			<MuiFormLabel component={"legend" as "label"}>{label}</MuiFormLabel>
			{formGroup}
			{helperText && <MuiFormHelperText>{helperText}</MuiFormHelperText>}
		</MuiFormControl>
	);
}

DploySelectionFieldInternal.displayName = "DploySelectionField";

export const DploySelectionField = memo(DploySelectionFieldInternal);

type FormControlLabelProps<TValue> = Overwrite<
	MuiFormControlLabelProps,
	{
		value: TValue;
		onChange: (value: TValue) => void;
	}
>;

const FormControlLabel = <TValue extends any>(
	props: FormControlLabelProps<TValue>
) => {
	const { onChange: propsOnChange, value } = props;

	const onChange = useCallback(() => propsOnChange(value), [
		value,
		propsOnChange
	]);

	return (
		<MuiFormControlLabel
			{...props}
			value={String(props.value)}
			onChange={onChange}
		/>
	);
};

export const EditorSelectionFields = identityRecordOfFieldEditorOptions({
	SelectionField: {
		editableOptions: {
			variant: variants,
			color: colors,
			horizontal: true
		}
	}
});
