import { Theme, makeStyles } from "@material-ui/core";
import merge from "lodash/merge";

export const useStyles = makeStyles(
	(theme: Theme) => ({
		mainSections: {
			padding: theme.spacing(2)
		},
		mainSectionsReducedPadding: {
			padding: 0
		},
		mainSectionsHeader: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			textTransform: "uppercase" as "uppercase",
			lineHeight: 3.3,
			fontSize: 12,
			fontWeight: 700,
			borderBottom: "1px solid #ddd"
		},
		summaryHeader: {
			justifyContent: "center",
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
			borderWidth: 1,
			borderStyle: "solid",
			borderColor: theme.palette.grey[400]
		},
		buttonItem: {
			display: "flex",
			flexGrow: 1,
			"& > *": {
				fontSize: 16,
				flexGrow: 1
			}
		},
		actionsPanel: {
			justifyContent: "center",
			background: "inherit",
			border: "none"
		}
	}),
	{ name: "DployColumnsSummaryHeader" }
);

export const columnsSummaryHeaderTheme = (theme: Theme): Theme => {
	const dployFormLiteral = (theme.overrides as any).DployFormLiteral || {};
	return ({
		...theme,
		overrides: {
			...theme.overrides,
			DployFormSectionGroup: {
				sectionGroupWrapper: {
					paddingBottom: theme.spacing(1)
				}
			},
			DployFormLiteral: {
				root: {
					borderBottom: "none",
					fontSize: 24,
					alignItems: "center",
					...dployFormLiteral.root
				},
				marginNormal: {
					marginBottom: 0,
					...dployFormLiteral.marginNormal
				},
				value: {
					flexGrow: 1,
					textAlign: "center",
					fontWeight: 700,
					...dployFormLiteral.value
				},
				label: {
					flexGrow: 1,
					fontSize: 16,
					...dployFormLiteral.label
				},
				suffix: {
					flexGrow: 1,
					fontSize: 14,
					width: "auto",
					textTransform: "uppercase",
					textAlign: "right",
					...dployFormLiteral.suffix
				}
			}
		}
	} as unknown) as Theme;
};

export const reducedSummaryTheme = (theme: Theme): Theme => {
	const overrides = {
		overrides: {
			DployFormSectionGroup: {
				sectionGroupWrapper: {
					padding: 0,
					paddingTop: 0,
					paddingBottom: 0
				},
				expansionLabel: {
					[theme.breakpoints.down("sm")]: {
						marginLeft: theme.spacing(1)
					},
					[theme.breakpoints.up("md")]: {
						marginLeft: 28
					}
				}
			},
			DployFormSection: {
				root: {
					"&:not(:last-child)": {
						[theme.breakpoints.down("sm")]: {
							marginBottom: theme.spacing(1)
						}
					}
				},
				header: {
					[theme.breakpoints.up("md")]: {
						marginLeft: 20
					}
				}
			},
			DployFormFieldContainer: {
				styleHelperText: {
					marginTop: 0
				}
			},
			DployFormLiteral: {
				marginNormal: {
					[theme.breakpoints.down("sm")]: {
						paddingLeft: 4
					},
					[theme.breakpoints.up("md")]: {
						paddingLeft: 24
					}
				}
			}
		}
	};
	return merge({}, theme, overrides) as Theme;
};

export const actionsTheme = (theme: Theme): Theme => ({
	...theme,
	overrides: {
		...theme.overrides,
		DployFormSectionGroup: {
			sectionGroupWrapper: {
				padding: 0,
				paddingTop: 0,
				paddingBottom: 0
			},
			sectionGroup: {
				maxWidth: "none"
			}
		},
		MuiButton: {
			...(theme.overrides && theme.overrides.MuiButton),
			root: {
				...(theme.overrides &&
					theme.overrides.MuiButton &&
					theme.overrides.MuiButton.root),
				whiteSpace: "nowrap"
			}
		}
	} as any
});
