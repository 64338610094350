import React from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	useMediaQuery,
	useTheme
} from "@material-ui/core";
import { defineMessages, useIntl } from "react-intl";
import { DashboardGrid } from "@ploy-ui/dashboard";
import { isNotNull } from "@ploy-ui/form-fields/es/utils";
import { MarkdownElement, PendingButton } from "@ploy-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

export type ConfirmAction =
	| "save"
	| "save_all"
	| "delete"
	| "cancel"
	| "export_unsaved";

export interface DashboardActionConfirmDialogProps {
	onConfirm?: () => Promise<any> | void;
	onClose?: () => void;
	action?: ConfirmAction;
	changed?: {
		dashboard: DashboardGrid;
		dirty?: Partial<Record<Breakpoint, boolean>>;
	}[];
}

const titleMessages = defineMessages<ConfirmAction>({
	save: {
		id: "dealer.dasbhoard.action-confirm-save-dialog.title",
		defaultMessage: "Lagre"
	},
	save_all: {
		id: "dealer.dasbhoard.action-confirm-save_all-dialog.title",
		defaultMessage: "Lagre alle"
	},
	delete: {
		id: "dealer.dasbhoard.action-confirm-delete-dialog.title",
		defaultMessage: "Slett"
	},
	cancel: {
		id: "dealer.dasbhoard.action-confirm-cancel-dialog.title",
		defaultMessage: "Avbryt"
	},
	export_unsaved: {
		id: "dealer.dasbhoard.action-confirm-export_unsaved-dialog.title",
		defaultMessage: "Endringer er ikke lagret"
	}
});

const contentMessages = defineMessages<ConfirmAction>({
	save: {
		id: "dealer.dasbhoard.action-confirm-save-dialog.content",
		defaultMessage: `Er du sikker på at du vil lagre {names}?
{dirty, select,
	none	{}
	other {

---

Endringer i {dirty} påvirker andre skjermstørrelser, vennligst verifiser at visningen er korrekt.
	}
}`
	},
	save_all: {
		id: "dealer.dasbhoard.action-confirm-save_all-dialog.content",
		defaultMessage: `Er du sikker på at du vil lagre {names}?
{dirty, select,
	none	{}
	other {

---

Endringer i {dirty} påvirker andre skjermstørrelser, vennligst verifiser at visningen er korrekt.
	}
}`
	},
	delete: {
		id: "dealer.dasbhoard.action-confirm-delete-dialog.content",
		defaultMessage: "Er du sikker på at du vil slette {names}?"
	},
	cancel: {
		id: "dealer.dasbhoard.action-confirm-cancel-dialog.content",
		defaultMessage:
			"Er du sikker på at du vil avbryte redigering. Det er ulagrede endringer i {names}."
	},
	export_unsaved: {
		id: "dealer.dasbhoard.action-confirm-export_unsaved-dialog.content",
		defaultMessage: `Du har ulagrede endringer. Disse må lagres før de kan eksporteres.

Er du sikker på at du vil lagre {names}?
{dirty, select,
	none	{}
	other {

---

Endringer i {dirty} påvirker andre skjermstørrelser, vennligst verifiser at visningen er korrekt.
	}
}`
	}
});

const confirmMessages = defineMessages<ConfirmAction>({
	save: {
		id: "dealer.dasbhoard.action-confirm-save-dialog.confirm.label",
		defaultMessage:
			"{dirty, select, none {Lagre} other {Lagre uten å verifisere} }"
	},
	save_all: {
		id: "dealer.dasbhoard.action-confirm-save_all-dialog.confirm.label",
		defaultMessage:
			"{dirty, select, none {Lagre alle} other {Lagre alle uten å verifisere} }"
	},
	delete: {
		id: "dealer.dasbhoard.action-confirm-delete-dialog.confirm.label",
		defaultMessage: "Slett"
	},
	cancel: {
		id: "dealer.dasbhoard.action-confirm-cancel-dialog.confirm.label",
		defaultMessage: "Forkast endringer"
	},
	export_unsaved: {
		id: "dealer.dasbhoard.action-confirm-export_unsaved-dialog.confirm.label",
		defaultMessage:
			"{dirty, select, none {Lagre alle} other {Lagre alle uten å verifisere} }"
	}
});

const cancelMessages = defineMessages<ConfirmAction>({
	save: {
		id: "dealer.dasbhoard.action-confirm-save-dialog.cancel.label",
		defaultMessage:
			"{dirty, select, none {Avbryt} other {Fortsett redigering} }"
	},
	save_all: {
		id: "dealer.dasbhoard.action-confirm-save_all-dialog.cancel.label",
		defaultMessage:
			"{dirty, select, none {Avbryt} other {Fortsett redigering} }"
	},
	delete: {
		id: "dealer.dasbhoard.action-confirm-delete-dialog.cancel.label",
		defaultMessage: "Avbryt"
	},
	cancel: {
		id: "dealer.dasbhoard.action-confirm-cancel-dialog.cancel.label",
		defaultMessage: "Fortsett redigering"
	},
	export_unsaved: {
		id: "dealer.dasbhoard.action-confirm-export_unsaved-dialog.cancel.label",
		defaultMessage:
			"{dirty, select, none {Avbryt} other {Fortsett redigering} }"
	}
});

export function DashboardActionConfirmDialog(
	props: DashboardActionConfirmDialogProps
) {
	const { onConfirm, onClose, action, changed } = props;

	const intl = useIntl();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const names = changed
		?.map(({ dashboard }) => `"${dashboard.name}"`)
		.filter(isNotNull);

	const dirty = changed
		?.filter(change => change.dirty && Object.values(change.dirty).some(d => d))
		.map(({ dashboard }) => `"${dashboard.name}"`)
		.filter(isNotNull);

	const values = {
		names: (names && intl.formatList(names, {})) ?? "",
		dirty: (dirty && intl.formatList(dirty, {})) || "none"
	};

	const primaryConfirm = action !== "cancel" && !dirty?.some(x => x);

	return (
		<Dialog
			open={!!action}
			fullScreen={isMobile}
			aria-labelledby="dashboard-action-confirm-dialog-title"
			onClose={onClose}
		>
			<DialogTitle id="dashboard-action-confirm-dialog-title">
				{action && intl.formatMessage(titleMessages[action], values)}
			</DialogTitle>
			<DialogContent>
				<MarkdownElement>
					{action && intl.formatMessage(contentMessages[action], values)}
				</MarkdownElement>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={onClose}
					color={!primaryConfirm ? "primary" : "default"}
					variant={!primaryConfirm ? "contained" : "text"}
				>
					{action && intl.formatMessage(cancelMessages[action], values)}
				</Button>
				<PendingButton
					onClick={onConfirm}
					color={primaryConfirm ? "primary" : "default"}
					variant={primaryConfirm ? "contained" : "text"}
				>
					{action && intl.formatMessage(confirmMessages[action], values)}
				</PendingButton>
			</DialogActions>
		</Dialog>
	);
}
