import React from "react";
import { addRegisteredLayout, PanelSettings } from "../layoutDescriptions";
import { Grid } from "@material-ui/core";
import { WizardStepper } from "./WizardStepper";
import { FormErrors } from "../../../components/FormErrors";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { TemplatePageProps, LayoutPage } from "../../../types";
import { useStyles, actionsTheme } from "./styles";
import { Panel } from "../../../panel";
import FormStatus from "../../../components/FormStatus";
import { TemplatePanel } from "@ploy-lib/types";

export type WizardSummaryPanels = {
	main: TemplatePanel;
	summary: TemplatePanel;
	actions: TemplatePanel;
	appActions: TemplatePanel;
};
export type WizardSummaryPanelKeys = keyof WizardSummaryPanels;
export type WizardSummaryPage = LayoutPage<
	WizardSummaryPanelKeys,
	WizardSummaryPanels
>;

const panelSettings: Record<WizardSummaryPanelKeys, PanelSettings> = {
	main: {},
	summary: {
		defaultOptions: {
			literal: true
		}
	},
	actions: {},
	appActions: {}
};

addRegisteredLayout({
	name: "WizardSummary",
	displayName: "WizardSummary",
	panels: panelSettings
});

export interface WizardSummaryProps
	extends TemplatePageProps<WizardSummaryPage> {}

export function WizardSummary(props: WizardSummaryProps) {
	const classes = useStyles(props);
	const { header, panels } = props;

	const { main, summary, actions, appActions } = panels;

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} container>
				<Grid container spacing={3}>
					<Grid item xs={8} md={4}>
						{header}
					</Grid>
					<Grid item xs={4} container alignItems="stretch" justify="flex-end">
						<MuiThemeProvider theme={actionsTheme}>
							<Panel
								{...appActions}
								justify="flex-end"
								disableExpansion
								transparent
								disableTabs
							/>
						</MuiThemeProvider>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} md={8}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<WizardStepper />
						<FormStatus className={classes.errorSection} />
						<FormErrors className={classes.errorSection} />
					</Grid>
					<Grid item xs={12}>
						{summary && <Panel {...summary} />}
					</Grid>
					<Grid item xs={12}>
						{main && <Panel {...main} />}
					</Grid>
					<Grid item xs={12}>
						{actions && (
							<MuiThemeProvider theme={actionsTheme}>
								<Panel
									justify="flex-end"
									disableExpansion
									transparent
									disableTabs
									{...actions}
								/>
							</MuiThemeProvider>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
