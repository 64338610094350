import React, { useCallback, useState, useEffect } from "react";
import { useFetcher, useInvalidator } from "@rest-hooks/core";
import {
	Grid,
	Paper,
	Button,
	List,
	ListItem,
	ListItemSecondaryAction,
	Checkbox,
	ListItemText,
	ListItemIcon
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {
	ApplicationSignerResource,
	SigningDocumentResource,
	ApplicationSigner,
	useResourceWithInvalidate
} from "@ploy-lib/rest-resources";
import { AppSignerForm } from "./AppSignerForm";
import {
	FormattedMessage,
	injectIntl,
	WrappedComponentProps,
	defineMessages
} from "react-intl";

export interface AppSignerListProps {
	applicationNumber: string;
	documents: SigningDocumentResource[];
	vendor?: boolean;
	ssnServiceEnabled: boolean;
	esignType: number;
	selfEmployedCustomer?: boolean;
	onEdit?: (selected: boolean) => void;
	canAddAndDelete: boolean;
	canEditIndividualDocuments: boolean;
	requirePhoneOrEmail: boolean;
	showValidPeriod?: boolean;
	disabled: boolean;
	mustSignDefaultChecked: boolean;
	guarantorId?: number;
}

const messages = defineMessages({
	addSigner: {
		id: "core.application-signing.signer-list.add-new",
		defaultMessage: "Legg til ny signeringspliktig"
	}
});

const AppSignerList = injectIntl(
	(props: AppSignerListProps & WrappedComponentProps) => {
		const {
			applicationNumber,
			documents,
			intl,
			vendor = false,
			onEdit,
			ssnServiceEnabled,
			esignType,
			selfEmployedCustomer = false,
			requirePhoneOrEmail,
			showValidPeriod,
			canAddAndDelete,
			canEditIndividualDocuments,
			disabled,
			mustSignDefaultChecked,
			guarantorId
		} = props;

		const signers = useResourceWithInvalidate(
			ApplicationSignerResource.list(),
			{
				vendor,
				applicationNumber,
				guarantorId
			}
		);

		const updateSigner = useFetcher(ApplicationSignerResource.update());

		const [selected, setSelected] = useState<
			ApplicationSigner | null | undefined
		>(null);

		const goBack = useCallback(() => {
			setSelected(null);
		}, []);

		const invalidateSigners = useInvalidator(ApplicationSignerResource.list());

		const onSuccess = useCallback(() => {
			invalidateSigners({
				vendor,
				applicationNumber,
				guarantorId
			});
			goBack();
		}, [applicationNumber, goBack, invalidateSigners, vendor, guarantorId]);

		useEffect(
			() => onEdit && onEdit(selected != null || selected === undefined),
			[selected, onEdit]
		);

		const allDocsAreSigned = documents && documents.every(d => d.signed);
		const canAddSigner =
			!allDocsAreSigned &&
			!vendor &&
			!selfEmployedCustomer &&
			esignType !== 1 &&
			canAddAndDelete &&
			!disabled;
		const canEditDocuments = !selfEmployedCustomer;

		if (selected !== null) {
			return (
				<Paper elevation={0}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Button onClick={goBack}>
								<ChevronLeftIcon />
								<FormattedMessage
									id="core.application-signing.signer-list.go-back"
									defaultMessage="Tilbake"
								/>
							</Button>
						</Grid>
						<Grid item xs={12}>
							<AppSignerForm
								applicationNumber={applicationNumber}
								signer={selected}
								documents={documents}
								disabled={allDocsAreSigned || disabled}
								vendor={vendor}
								onSuccess={onSuccess}
								canEditDocuments={canEditDocuments}
								canEditIndividualDocuments={canEditIndividualDocuments}
								esignType={esignType}
								ssnServiceEnabled={ssnServiceEnabled}
								requirePhoneOrEmail={requirePhoneOrEmail}
								showValidPeriod={showValidPeriod}
								mustSignDefaultChecked={mustSignDefaultChecked}
								guarantorId={guarantorId}
							/>
						</Grid>
					</Grid>
				</Paper>
			);
		}
		return (
			<Paper elevation={0}>
				<List dense={true}>
					{signers &&
						signers.map((signer, i) => (
							<ListItem
								key={
									signer.applicationSignerId
										? signer.applicationSignerId.toString()
										: i.toString()
								}
								button
								onClick={() => setSelected(signer)}
							>
								<ListItemIcon>
									<ChevronRightIcon />
								</ListItemIcon>
								<ListItemText
									primary={`${signer.lastName} ${signer.firstName}`}
									secondary={signer.role || " "}
								/>
								<ListItemSecondaryAction>
									{signer.mustSign && !selfEmployedCustomer ? (
										<Checkbox
											color="primary"
											onChange={() => {
												if (signer.mustSign) {
													updateSigner(
														{ applicationSignerId: signer.applicationSignerId },
														{
															...signer,
															mustSign: false
														}
													);
												}
											}}
											checked={signer.mustSign}
											disabled={
												allDocsAreSigned ||
												Boolean(
													signer.applicationSignerId &&
														documents &&
														documents.some(
															d =>
																d.signed &&
																d.signers.includes(signer.applicationSignerId!)
														)
												) ||
												!signer.canSetMustSign
											}
										/>
									) : null}
								</ListItemSecondaryAction>
							</ListItem>
						))}
					{!canAddSigner ? null : (
						<ListItem button onClick={() => setSelected(undefined)}>
							<ListItemIcon>
								<AddIcon />
							</ListItemIcon>
							<ListItemText primary={intl.formatMessage(messages.addSigner)} />
						</ListItem>
					)}
				</List>
			</Paper>
		);
	}
);

AppSignerList.displayName = "DployAppSignerList";

export { AppSignerList };
