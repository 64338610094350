import {
	ApplicationsTableSchema,
	DTDetailTableSchema,
	LimitDetailTableSchema
} from "./index";

export const tableSchemas: Record<string, any> = {
	LimitDetailSchema: LimitDetailTableSchema,
	DTTableSchema: DTDetailTableSchema,
	ApplicationsTableSchema: ApplicationsTableSchema
};
