import * as React from "react";
import { Commission as PuiCommission } from "@ploy-ui/commission";
import CommissionAPI from "@ploy-lib/commission-api";
import { ExternalViewComponent } from "../types";

export const Commission: ExternalViewComponent = ({
	dtResult,
	token = "",
	endpoint,
	internalUser
}) => (
	<CommissionAPI token={token} endpoint={endpoint}>
		{api => <PuiCommission {...api} internalUser={internalUser} />}
	</CommissionAPI>
);

export default Commission;
