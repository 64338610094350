import { WithGetSet } from "./types";

export const testFunction = {
	requires: ["ObjectPrice", "Equity"],

	returns: ["LoanAmount"],

	apply: function(data: WithGetSet<number>, missing) {
		return data.get("ObjectPrice")! - data.get("Equity")!;
	}
};
