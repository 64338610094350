import { ConfigElement } from "./ExpandableList";
import { ExpandableElement, CloseRequest } from "./ExpandableElement";

import React, { useEffect, useState } from "react";

import {
	List,
	ListItem,
	Typography,
	Grid,
	IconButton
} from "@material-ui/core";

import { Formik } from "formik";

import { Add } from "@material-ui/icons";

import { v4 as uuidv4 } from "uuid";

import * as Yup from "yup";

export type ExpandableListEditSingleProps<RowData extends Object> = {
	elementConfig: ConfigElement<RowData>[];
	elementIdKey: string;
	onSave?: (value: RowData) => any;
	onCreate?: (value: RowData) => any;
	onUpdate?: (value: RowData) => any;
	onDelete?: (value: RowData) => any;
	initialElementsList?: RowData[];
	header?: string;
	collapsedElement?: (values: RowData) => JSX.Element;
	elementCreator?: (config: ConfigElement<RowData>[], idKey: string) => RowData;
	margin?: string;
	canAddElement?: boolean;
	useThemedButtons?: boolean;
	requestClose?: CloseRequest;
	canDelete?: boolean;
	autofillFunction?: (value: RowData) => Promise<RowData>;
	columns?: number;
	canSave?: Boolean;
	maxRows?: number;
};
/**
 * Dynamic expandable list component. Creates a formik fieldArray form with support for adding and removing elements
 * @param elementConfig - Element that configures values that will be rendered and specifies the datamodel in which the values be submitted
 * @param elementIdKey - String that is keyof RowData
 * @param onSave - onSave for individual items
 * @param onCreate - onCreate for a new element
 * @param onUpdate - onUpdate for an allready existing element
 * @param onDelete - onDelete for individual items
 * @param initialElementList - list of initial elements. Each element should contain values for the properties defined in the elementConfig and the elementIdKey with a unique identifier value
 * @param header - Header above the list
 * @param collapsedElement - Custom element that is rendered when the element is collapsed. RowData values are availible
 * @param elementCreator - Custom function to create an empty rowdata element
 * @param margin
 * @param canAddElement - set to false to dissallow adding new elements to the form
 * @param useThemedButtons - if true, buttons in form elements uses primary/secondary color from tenant
 * @param requestClose - request closing the form: contains function for cancel and close
 * @param canDelete -
 * @param autofillFunction - function for autofilling a row when any field with attribute autofillKey: true are filled. Function will recieve a row of RowData and return a autofilled row of RowData.
 * @param columns - number of columns in list.
 * @param canSave - used to hide save button.
 * @param maxRows - Upper limit on number of rows, unlimited if undefined
 */
function ExpandableListEditSingle<RowData extends Object>(
	props: ExpandableListEditSingleProps<RowData>
) {
	const {
		elementIdKey,
		elementConfig,
		initialElementsList = [],
		header,
		collapsedElement,
		onSave,
		onCreate,
		onUpdate,
		onDelete,
		elementCreator = (config: ConfigElement<RowData>[], idKey: string) => {
			return config.reduce(
				(acc, c) => {
					acc[c.name as string] = null;
					return acc;
				},
				({ [idKey]: uuidv4() } as unknown) as RowData
			);
		},
		margin = "normal",
		canAddElement = true,
		useThemedButtons,
		requestClose,
		canDelete,
		autofillFunction,
		columns = 3,
		canSave = true,
		maxRows
	} = props;

	const [selectedElement, setSelectedElement] = useState<string | undefined>(
		undefined
	);

	const validationSchema = Yup.object().shape(
		elementConfig
			.filter(c => c.validation)
			.reduce((acc, c) => ({ ...acc, [c.name]: c.validation }), {})
	);

	const [elementList, setElementList] = useState<RowData[]>(
		initialElementsList
	);
	useEffect(() => setElementList(initialElementsList), [initialElementsList, setElementList]);

	const onDeleteWrapper = (value: RowData, index: number) => {
		!isNewElement(value) && onDelete && onDelete(value);
		removeFromForm(value, index);
	};

	const removeFromForm = (value: RowData, index: number) => {
		setElementList(
			elementList.filter(v => v[elementIdKey] !== value[elementIdKey])
		);
		setSelectedElement(undefined);
	};

	const isNewElement = (value: RowData) => {
		const emptyElement = elementCreator(elementConfig, elementIdKey);
		const res = elementConfig
			.filter(fieldConfig => fieldConfig.required)
			.every(
				fieldConfig =>
					value[fieldConfig.name] === emptyElement[fieldConfig.name]
			);
		return res;
	};

	if (requestClose && !selectedElement) {
		requestClose.closeCallback();
	}

	const autofillFunctionInternal = async (values: RowData, setValues: any) => {
		try {
			const filled = await autofillFunction?.(values);
			if (filled) {
				setValues(filled);
			}
		} catch (err: any) {
			//Leave values as is if error
		}
	};

	return (
		<List dense={true}>
			<ListItem key="header">
				{header && <Typography variant="h6">{header}</Typography>}
			</ListItem>
			{elementList.map((element, index) => {
				return (
					<Formik
						key={`formik.${element[elementIdKey]}`}
						initialValues={elementList[index]}
						onSubmit={async (values, form) => {
							const updatedValue = onSave
								? await onSave(values)
								: isNewElement(elementList[index])
								? onCreate && (await onCreate(values))
								: onUpdate && (await onUpdate(values));

							form.setValues({ ...values, ...updatedValue });
							setElementList(
								elementList.map(v =>
									v[elementIdKey] === values[elementIdKey]
										? { ...values, ...updatedValue }
										: v
								)
							);
							form.setSubmitting(false);
						}}
						validationSchema={validationSchema}
						render={({ values, submitForm, setSubmitting, setValues }) => {
							return (
								(!selectedElement ||
									element[elementIdKey] === selectedElement) && (
									<ExpandableElement<RowData>
										key={element[elementIdKey] + ""}
										index={index}
										elementIdKey={elementIdKey}
										element={element}
										elementConfig={elementConfig}
										isExpandable={true}
										collapsedElement={collapsedElement}
										initialOpen={elementList.every(
											e => e[elementIdKey] !== element[elementIdKey]
										)}
										setSelected={setSelectedElement}
										isSelected={element[elementIdKey] === selectedElement}
										editSingle={true}
										onSave={submitForm}
										onDelete={onDeleteWrapper}
										margin={margin}
										formValue={values}
										setFormValues={setValues}
										useThemedButtons={useThemedButtons}
										closeRequest={selectedElement ? requestClose : undefined}
										removeElementFromForm={removeFromForm}
										isNewElement={isNewElement}
										canDelete={canDelete}
										divider={canAddElement || elementList.length !== index + 1}
										autofillFunction={() =>
											autofillFunctionInternal(values, setValues)
										}
										columns={columns}
										canSave={canSave}
									/>
								)
							);
						}}
					/>
				);
			})}
			{canAddElement &&
				(maxRows === undefined || elementList.length < maxRows) && (
					<ListItem key="actionButtons">
						<Grid container justify="space-between">
							{!selectedElement && (
								<Grid item>
									<IconButton
										onClick={() => {
											const newElement = elementCreator(
												elementConfig,
												elementIdKey
											);
											setElementList(elementList.concat([newElement]));
											setSelectedElement(newElement[elementIdKey]);
										}}
									>
										<Add />
									</IconButton>
								</Grid>
							)}
						</Grid>
					</ListItem>
				)}
		</List>
	);
}

ExpandableListEditSingle.displayName = "ExpandableListEditSingle";

export { ExpandableListEditSingle };
