import React from "react";
import {
	API,
	GetFulfillmentRequest,
	GetFulfillmentResponse
} from "@ploy-lib/fulfillment-api";
import {
	Box,
	Paper,
	Grid,
	CircularProgress,
	Checkbox,
	Typography,
	TextField,
	FormControlLabel,
	Button
} from "@material-ui/core";
import {
	defineMessages,
	useIntl,
	FormattedNumber,
	FormattedMessage
} from "react-intl";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from "@material-ui/pickers";
import DateUtils from "@date-io/luxon";
import { DateType } from "@date-io/type";
import WarningRounded from "@material-ui/icons/WarningRounded";
import { BaseResource } from "@ploy-lib/rest-resources";
import { apiResourceUrl } from "@ploy-lib/core";
import { useResource } from "@rest-hooks/core";

const messages = defineMessages({
	fulfillmentHeader: {
		id: "fulfillment-header",
		defaultMessage: "Beregne innfrielse lån",
		description: "Header text for fulfillment view"
	},
	regNumberLabel: {
		id: "fulfillment-reg-number-label",
		defaultMessage: "Registreringsnummer",
		description: "Label for registration number field"
	},
	phoneNumberLabel: {
		id: "fulfillment-phone-number",
		defaultMessage: "Kundens mobilnummer",
		description: "Label for phone number field"
	},
	checkboxLabel: {
		id: "fulfillment-checkbox-label",
		defaultMessage:
			"Kunde har godkjent at jeg innhenter innfrielsessum og lagrer dette i 14 dager, og jeg er klar over at kunden får melding på SMS om dette",
		description: "Label for customer consent chekcbox"
	},
	dateLabel: {
		id: "fulfillment-date-label",
		defaultMessage: "Dato"
	},
	buttonText: {
		id: "fulfillment-button-text",
		defaultMessage: "Beregn",
		description: "Button text for getting fulfillment"
	},
	amountLabel: {
		id: "fulfillment-amount-label",
		defaultMessage: "Beløp:"
	},
	accountInfoHeader: {
		id: "fulfillment-account-info-header",
		defaultMessage: "Betalingsinformasjon:"
	},
	accountLabel: {
		id: "fulfillment-account-label",
		defaultMessage: "Kontonr:"
	},
	customerIdentificationLabel: {
		id: "fulfillment-customer-identifiction-label",
		defaultMessage: "KID-nr:"
	},
	markWithMessage: {
		id: "fulfillment-mark-with-message",
		defaultMessage: "Merkes med: Innfrielse "
	},
	warningMessage: {
		id: "fulfillment-warning-message",
		defaultMessage:
			"Det forutsettes at beløpet blir betalt innen denne dato for at lånet skal bli innfridd"
	},
	noResultsMessage: {
		id: "fulfillment-no-results-message",
		defaultMessage:
			"Kan ikke oppgi innfrielsessum, kontakt oss på telefon: 55 61 00 50"
	},
	invalidContractTypeMessage: {
		id: "fulfillment-invalid-contract-type-message",
		defaultMessage: "Kontrakten er ikke tilgjengelig for innfrielse"
	},
	resultHeader: {
		id: "fulfillment-results-header",
		defaultMessage: "Innfrielsessum per {searchDate, date, long} på {regNumber}"
	}
});

class FulfillmentSettingsResource extends BaseResource {
	showPhoneNumber = false;
	showDate = false;
	showCarMake = false;

	pk() {
		return "settings";
	}

	static urlRoot = apiResourceUrl("fulfillment");
}

export const Fulfillment = ({ internalUser, getFulfillment }: API) => {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [failed, setFailed] = React.useState<boolean>();
	const [disabled, setDisabled] = React.useState<boolean>(false);
	const [regNumber, setRegNumber] = React.useState<string>("");
	const [phoneNumber, setPhoneNumber] = React.useState<string>("");
	const [checked, setChecked] = React.useState<boolean>(false);
	const [result, setResult] = React.useState<GetFulfillmentResponse>();
	const [fulfillmentDate, setFulfillmentDate] = React.useState<DateType>();
	const dateToday = new Date();

	const intl = useIntl();

	const handleChangeRegNumber = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setRegNumber(event.target.value);
	};

	const handleChangePhoneNumber = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setPhoneNumber(event.target.value);
	};

	const handleChangeFulfillmentDate = (fulfillmentDate: DateType | null) => {
		setFulfillmentDate(fulfillmentDate ?? undefined);
	};

	const utils = new DateUtils();

	const handleSearch = async () => {
		setLoading(true);
		setDisabled(true);
		const obj: GetFulfillmentRequest = {
			registrationNumber: regNumber,
			phoneNumber,
			fulfillmentDate: fulfillmentDate?.toJSDate()
		};
		try {
			const response = await getFulfillment(obj);
			response.registrationNumber = regNumber;
			setResult(response);
			setFailed(false);
		} catch (err: any) {
			setDisabled(false);
			setFailed(true);
		}
		setLoading(false);
	};

	const settings = useResource(FulfillmentSettingsResource.detail(), {});

	return (
		<Grid container spacing={2} justify="space-around">
			<Grid item xs={12}>
				<Paper elevation={0}>
					<Box p={2}>
						<Box p={2}>
							<Typography variant="h5">
								{intl.formatMessage(messages.fulfillmentHeader)}
							</Typography>
						</Box>
						<Box p={1} style={{ display: "inline-block" }}>
							<TextField
								id="fulfillment-reg-number"
								label={intl.formatMessage(messages.regNumberLabel)}
								value={regNumber}
								onChange={e => handleChangeRegNumber(e)}
								variant="outlined"
								placeholder=""
								InputLabelProps={{ shrink: true }}
								disabled={disabled}
							/>
						</Box>
						{settings.showPhoneNumber ? (
							<Box p={1} style={{ display: "inline-block" }}>
								<TextField
									id="fulfillment-phone-number"
									label={intl.formatMessage(messages.phoneNumberLabel)}
									value={phoneNumber}
									onChange={e => handleChangePhoneNumber(e)}
									variant="outlined"
									placeholder=""
									InputLabelProps={{ shrink: true }}
									disabled={disabled}
								/>
							</Box>
						) : null}
						{settings.showDate ? (
							<Box p={1} style={{ display: "inline-block" }}>
								<MuiPickersUtilsProvider utils={DateUtils}>
									<KeyboardDatePicker
										id="fulfillment-date"
										label={intl.formatMessage(messages.dateLabel)}
										value={fulfillmentDate}
										onChange={handleChangeFulfillmentDate}
										variant="dialog"
										format="dd/MM/yyyy"
										margin="none"
										InputLabelProps={{ shrink: true }}
										disabled={disabled}
										inputVariant="outlined"
									/>
								</MuiPickersUtilsProvider>
							</Box>
						) : null}
						<Box p={1}>
							<FormControlLabel
								control={
									<Checkbox
										checked={checked}
										onChange={() => setChecked(!checked)}
										disabled={disabled}
									/>
								}
								label={intl.formatMessage(messages.checkboxLabel)}
							/>
						</Box>
						<Box p={1}>
							<Button
								color="primary"
								variant="outlined"
								onClick={() => handleSearch()}
								disabled={
									disabled ||
									!checked ||
									regNumber.length < 6 ||
									(settings.showPhoneNumber && phoneNumber.length < 8) ||
									(settings.showDate &&
										(fulfillmentDate === undefined ||
											utils.isBefore(fulfillmentDate, utils.date(dateToday))))
								}
							>
								{intl.formatMessage(messages.buttonText)}
							</Button>
							{loading && (
								<Box p={1} component="span">
									<CircularProgress size={30} />
								</Box>
							)}
						</Box>
					</Box>
				</Paper>
			</Grid>
			{!!!failed ? (
				result &&
				result.fulfillmentInformation &&
				result.fulfillmentInformation.length > 0 ? (
					result.fulfillmentInformation.map((x, i) => {
						return (
							<Grid item xs={12} key={i}>
								<Paper elevation={0}>
									<Box p={2} id={"fulfillmentInfo"}>
										{settings.showCarMake ? (
											<Typography variant="h5" id={"fulfillmentObject"}>
												{result.carMake + " " + result.carModel}
											</Typography>
										) : null}
										<Box p={1} component="span" fontWeight="fontWeightBold">
											<FormattedMessage
												id="fulfillment-results-header"
												defaultMessage="Innfrielsessum per {searchDate, date, long} på {regNumber}"
												values={{
													regNumber: result.registrationNumber,
													searchDate: x
														? new Date(x.fulfillmentDate)
														: new Date()
												}}
											/>
										</Box>
										<Box p={1}>
											<Box width={60} display="inline-block">
												{intl.formatMessage(messages.amountLabel)}
											</Box>
											<Box
												component="span"
												fontWeight="fontWeightBold"
												id={"fulfillmentAmount"}
											>
												<FormattedNumber value={x.amount} format="currency" />
											</Box>
										</Box>
										<Box p={1}>
											<Box>
												{intl.formatMessage(messages.accountInfoHeader)}
											</Box>
											<Box>
												<Box width={60} display="inline-block">
													{intl.formatMessage(messages.accountLabel)}
												</Box>
												<Box
													component="span"
													fontWeight="fontWeightBold"
													id={"fulfillmentAccount"}
												>
													{result.accountNumber}
												</Box>
											</Box>
											<Box>
												{result.customerIdentification ? (
													<>
														<Box width={60} display="inline-block">
															{intl.formatMessage(
																messages.customerIdentificationLabel
															)}
														</Box>
														<Box
															component="span"
															fontWeight="fontWeightBold"
															id={"fulfillmentCustomerId"}
														>
															{result.customerIdentification}
														</Box>
													</>
												) : (
													<FormattedMessage
														id="fulfillment-customer-identification-missing"
														defaultMessage="{label} {regNumber}"
														values={{
															label: messages.markWithMessage,
															regNumber: result.registrationNumber
														}}
													/>
												)}
											</Box>
											<Box p={1} display="flex" alignItems="center">
												<FormattedMessage
													id="fulfillment-warning"
													defaultMessage="{icon} {message}"
													values={{
														icon: <WarningRounded color="secondary" />,
														message: intl.formatMessage(messages.warningMessage)
													}}
												/>
											</Box>
										</Box>
									</Box>
								</Paper>
							</Grid>
						);
					})
				) : (
					result &&
					result.invalidContractType && (
						<Grid item xs={12}>
							<Paper elevation={0}>
								<Box p={2}>
									<Typography variant="h6">
										{intl.formatMessage(messages.invalidContractTypeMessage)}
									</Typography>
								</Box>
							</Paper>
						</Grid>
					)
				)
			) : (
				<Grid item xs={12}>
					<Paper elevation={0}>
						<Box p={2}>
							<Typography variant="h6">
								{intl.formatMessage(messages.noResultsMessage)}
							</Typography>
						</Box>
					</Paper>
				</Grid>
			)}
		</Grid>
	);
};
