import * as React from "react";
import { useTenant } from "@ploy-ui/tenants";
import { useSettingsDispatch, EnvVariable } from "@ploy-lib/core";
import { Divider, Grid, Button, makeStyles } from "@material-ui/core";

const localeLabel = (locale: string) => {
	if (!locale) return "";

	const languageName = new (Intl as any).DisplayNames(locale, {
		style: "short",
		type: "language"
	}).of(locale);

	return `${languageName}`;
};

const useStyles = makeStyles(theme => ({
	divider: {
		margin: theme.spacing(0.5),
		height: "auto"
	},
	button: {
		"&.Mui-disabled": {
			color: "inherit",
			fontWeight: 700
		},
		fontWeight: 300
	}
}));

export function LanguageSelector() {
	const dispatch = useSettingsDispatch();
	const { tenant, locale } = useTenant();

	const classes = useStyles();

	if ((tenant?.locales.length ?? 0) < 2) return null;

	return (
		<Grid container alignItems="stretch" justify="center">
			{tenant?.locales.flatMap((l, i) => (
				<React.Fragment key={l}>
					{i > 0 && (
						<Divider orientation="vertical" className={classes.divider} />
					)}
					<Button
						className={classes.button}
						color="inherit"
						size="small"
						disabled={l === locale}
						variant="text"
						onClick={() => dispatch({ type: EnvVariable.Locale, payload: l })}
					>
						{localeLabel(l)}
					</Button>
				</React.Fragment>
			))}
		</Grid>
	);
}
