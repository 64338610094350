import React from "react";
import { useResource } from "@rest-hooks/core";
import { LoginResource } from "@ploy-lib/rest-resources";

export default function LoginRedirectLink() {
	const { redirectLink: { href, label } = {} } = useResource(
		LoginResource.status(),
		{}
	);

	return href && label ? <a href={href}>{label}</a> : null;
}

LoginRedirectLink.displayName = "LoginRedirectLink";
