import React from "react";
import Portal from "./Portal";
import { VulcanDataResource } from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";
import { LandingPage } from "@ploy-lib/rest-resources";

const LayoutSwitcher = props => {
	const { landingPage } = useResource(VulcanDataResource.detail(), {});

	if (process.env.NODE_ENV === "development") return <Portal {...props} />;

	switch (landingPage) {
		case LandingPage.Classic: {
			var applicationNumber = props.uri.match("application/([0-9]+[0-9]+)");
			if (applicationNumber != null) {
				window.location.replace(
					"/Pages/Application/ApplicationRedirect.aspx?appNo=" +
						applicationNumber[1]
				);
			} else {
				window.location.replace("/Pages/StartPage/StartPage.aspx");
			}
			return null;
		}
		case LandingPage.VulcanPortal:
		case LandingPage.Vulcan: {
			window.location.replace(
				window.location.pathname.replace(process.env.PUBLIC_URL, "/Main")
			);
			return null;
		}
		case LandingPage.Phoenix:
		default:
			return <Portal {...props} />;
	}
};

export default LayoutSwitcher;
