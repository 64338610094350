import { memo, useMemo } from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DashboardGrid, DashboardGridResource } from "@ploy-ui/dashboard";

import { useStatefulResource } from "@rest-hooks/legacy";

import { makeStyles, Theme } from "@material-ui/core/styles";

export interface DashboardSelectorProps {
	selected: string | undefined;
	onSelect: (id: string | undefined) => void;
	editedDashboards?: Record<string, DashboardGrid>;
	variant: TextFieldProps["variant"];
}

const useStyles = makeStyles((theme: Theme) => ({
	inputRoot: {
		backgroundColor: "rgba(255, 255, 255, 0.8)",
		"&:hover": {
			backgroundColor: "rgba(255, 255, 255, 1)"
		},
		"&.Mui-focused": {
			backgroundColor: "rgba(255, 255, 255, 1)"
		}
	}
}));

DashboardSelectorImpl.displayName = "DashboardSelector";
function DashboardSelectorImpl(props: DashboardSelectorProps) {
	const classes = useStyles(props);
	const { selected, onSelect, variant, editedDashboards } = props;

	const { data: dashboards = [], loading, error } = useStatefulResource(
		DashboardGridResource.list(),
		{}
	);

	if (error && !error.response) throw error;

	const allDashboards = useMemo(
		() => [
			...dashboards.map(d => ({
				value: d.pk()!,
				label: `${d.name}${
					editedDashboards?.[d.pk()!] || !dashboards.includes(d) ? " *" : ""
				}`
			})),
			...Object.entries(editedDashboards ?? {})
				.filter(([pk]) => !dashboards.some(d => d.pk() === pk))
				.map(([pk, d]) => ({
					value: pk,
					label: `${d.name} *`
				}))
		],
		[dashboards, editedDashboards]
	);

	return (
		<Autocomplete
			openOnFocus
			loading={loading}
			id="dashboard-selector"
			multiple={false}
			options={allDashboards}
			value={allDashboards.find(d => d.value === selected) ?? null}
			onChange={(e, value) => onSelect(value?.value)}
			size="small"
			getOptionSelected={(option, value) => option.value === value.value}
			getOptionLabel={o => o.label}
			renderInput={params => (
				<TextField
					{...params}
					label="Velg dashboard"
					variant={variant}
					error={!!error}
					helperText={error?.message}
				/>
			)}
			classes={{
				inputRoot: classes.inputRoot
			}}
			fullWidth
		/>
	);
}

export const DashboardSelector = memo(DashboardSelectorImpl);
