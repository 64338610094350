import React, { useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import {
	NavigationContextValue,
	NavigationProvider
} from "@ploy-ui/template-form";
import { getBasepath } from "../utils";

export interface RouteNavigationProviderProps {
	children: React.ReactNode;
}

export function RouteNavigationProvider(
	props: RouteNavigationProviderProps &
		RouteComponentProps<{
			"*": string;
		}>
) {
	const { children, navigate, ...routeProps } = props;

	const basepath = getBasepath(routeProps);

	const value: NavigationContextValue = useMemo(
		() => ({
			home: () => navigate?.("/"),
			application: (applicationNumber, fromSubmit: boolean = false) =>
				navigate?.(
					`${basepath}/application/${applicationNumber}?fromSubmit=${fromSubmit}`
				),
			applicationFromCalculator: (category, externalCode) =>
				navigate?.(`${basepath}/${category}/${externalCode}`)
		}),
		[navigate, basepath]
	);

	return <NavigationProvider value={value}>{children}</NavigationProvider>;
}
