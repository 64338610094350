import React from "react";
import Todos from "./Todos";
import { applyPropertyControls, ControlType } from "../../property-controls";
import {
	ApplicationStatus,
	DocumentStatus,
	DocumentStatusId,
	ApplicationStatusId,
	ID
} from "@ploy-lib/types";
import { DocumentType, ApplicationInfoFilter } from "@ploy-lib/rest-resources";
import { TaskFilter } from "./TaskListItem";

interface RawTaskFilter extends ApplicationInfoFilter {
	id: string;
	salepersonId: ID;
	filterName: string;
	allSignedButSecurity: boolean;
	appfilter: "All" | "Mine";
	primaryMessage: string;
	secondaryMessage: string;
	icon: string;
}

applyPropertyControls(Todos, {
	showEmptyResult: {
		type: ControlType.Boolean,
		title: "Vis gjøremål med tomt resultat"
	},
	tasks: {
		type: ControlType.Array,
		title: "Gjøremålsliste",
		description: (
			<>
				<p>
					Tittel og undertekst støtter{" "}
					<a
						href="http://userguide.icu-project.org/formatparse/messages"
						target="_blank"
						rel="noopener noreferrer"
					>
						ICU formattering
					</a>{" "}
					av variabler.
				</p>
				<p>Tilgjengelige variabler:</p>
				<ul>
					<li>
						<code>amount</code>: Antall treff i filteret.
					</li>
				</ul>
			</>
		),
		coerce: (value, values) => {
			let rawTaskFilters: RawTaskFilter[] = [];

			try {
				if (values.taskFilterList)
					rawTaskFilters = JSON.parse(values.taskFilterList);
			} catch (e: any) {
				console.warn(e);
			}

			return rawTaskFilters.map(
				({
					id,
					filterName,
					primaryMessage,
					secondaryMessage,
					icon,
					appfilter,
					allSignedButSecurity,
					...filter
				}): TaskFilter => {
					const appStatus = Array.isArray(filter.appStatus)
						? filter.appStatus as number[]
						: filter.appStatus as number;

					const docStatus = Array.isArray(filter.docStatus)
						? filter.docStatus as number[]
						: filter.docStatus as number;

					return {
						id,
						name: filterName,
						filter: {
							...filter,
							salepersonId:
								filter.salepersonId ??
								(appfilter === "Mine" ? "current" : undefined),
							allTasksSignedExceptType:
								filter.allTasksSignedExceptType ??
								(allSignedButSecurity ? [DocumentType.Security] : undefined),
							appStatus,
							docStatus
						},
						primaryMessage,
						secondaryMessage,
						iconClass: icon
					};
				}
			);
		},
		propertyControl: {
			type: ControlType.Object,
			title: "Gjøremål",
			propertyControls: {
				id: {
					type: ControlType.Hidden,
					title: "Id"
				},
				name: {
					type: ControlType.String,
					title: "Navn",
					defaultValue: "Nytt gjøremål"
				},
				iconClass: {
					type: ControlType.String,
					title: "Ikon",
					description: "Fyll inn navnet til et ikon fra Font Awesome"
				},
				primaryMessage: {
					type: ControlType.String,
					title: "Tittel",
					multiline: true
				},
				secondaryMessage: {
					type: ControlType.String,
					title: "Undertekst",
					multiline: true
				},
				filter: {
					type: ControlType.Object,
					title: "Filter",
					propertyControls: {
						vendorId: {
							type: ControlType.Enum,
							title: "Forhandler",
							options: ["current"],
							optionTitles: ["Innlogget forhandler"],
							defaultValue: "current"
						},
						salepersonId: {
							type: ControlType.Enum,
							title: "Selger",
							options: ["", "current"],
							optionTitles: ["Alle selgere", "Innlogget selger"],
							defaultValue: ""
						},
						fullText: {
							type: ControlType.String,
							title: "Fritekst",
							placeholder: "Hva/hvem leter du etter"
						},
						carRegNo: {
							type: ControlType.String,
							title: "Reg.nr.",
							placeholder: "AB12345"
						},
						hasCarRegNo: {
							type: ControlType.Boolean,
							title: "Mangler reg.nr."
						},
						customerName: {
							type: ControlType.String,
							title: "Kundenavn"
						},
						customerNameContains: {
							type: ControlType.Enum,
							title: "",
							options: [false, true],
							optionTitles: ["Begynner med", "Innholder"]
						},
						productList: {
							type: ControlType.Array,
							title: "Produkt",
							propertyControl: {
								type: ControlType.Enum,
								title: "",
								options: []
							}
						},
						fromDate: {
							type: ControlType.Date,
							title: "Fra reg. dato"
						},
						toDate: {
							type: ControlType.Date,
							title: "Til reg. dato"
						},
						appStatus: {
							type: ControlType.Array,
							title: "Status",
							propertyControl: {
								type: ControlType.Enum,
								title: "",
								options: Object.values(ApplicationStatus)
							}
						},
						docStatus: {
							type: ControlType.Array,
							title: "Dokumentstatus",
							propertyControl: {
								type: ControlType.Enum,
								title: "",
								options: Object.values(DocumentStatus)
							}
						},
						allTasksSignedExceptType: {
							type: ControlType.Array,
							title: "Alt signert untatt",
							propertyControl: {
								type: ControlType.Enum,
								title: "",
								options: Object.values(DocumentType)
							}
						},
						allTasksCompletedExceptType: {
							type: ControlType.Array,
							title: "Alt fullført untatt",
							propertyControl: {
								type: ControlType.Enum,
								title: "",
								options: Object.values(DocumentType)
							}
						},
						searchInVendorHierarchy: {
							type: ControlType.Boolean,
							title: "Søk i forhandlerhierarki"
						},
						useInternalDocStatus: {
							type: ControlType.Boolean,
							title: "Bruk intern dokumentstatus"
						}
					}
				}
			}
		}
	},
	boxProps: {
		type: ControlType.Object,
		title: "Standard instillinger",
		propertyControls: {
			header: {
				type: ControlType.String,
				title: "Tittel"
			},
			iconClass: {
				type: ControlType.String,
				title: "Ikon",
				description: "Fyll inn navnet til et ikon fra Font Awesome"
			},
			backgroundColor: {
				type: ControlType.Color,
				title: "Bakgrunnsfarge",
				description: "Bakgrunnsfarge for boksen",
				coerce: (_, boxProps) => {
					if (boxProps.grey) return "grey.200";
				}
			},
			headerColor: {
				type: ControlType.Color,
				title: "Tittelfarge",
				description: "Tekstfarge som blir brukt på tittel og ikon",
				coerce: (_, boxProps) => {
					if (boxProps.grey) return "grey.500";
				}
			}
		}
	}
});

export default Todos;
