import { apiResourceUrl } from "@ploy-lib/core";
import { DataSource, TableType } from "@ploy-lib/types";
import { BaseCamelCasedResource } from "./BaseResource";

export class TableTypeResource
	extends BaseCamelCasedResource
	implements TableType {
	readonly displayName: string;
	readonly tableTypeName: string;
	readonly dataSources: DataSource[] = [];

	pk() {
		return this.displayName || "";
	}

	static urlRoot = apiResourceUrl("tableType");
}
