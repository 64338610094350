import * as React from "react";
import { Fulfillment as PuiFulfillment } from "@ploy-ui/fulfillment";
import FulfillmentAPI from "@ploy-lib/fulfillment-api";
import { ExternalViewComponent } from "../types";

export const Fulfillment: ExternalViewComponent = ({
	dtResult,
	token = "",
	endpoint,
	internalUser
}) => (
	<FulfillmentAPI token={token} endpoint={endpoint}>
		{api => <PuiFulfillment {...api} internalUser={internalUser} />}
	</FulfillmentAPI>
);

export default Fulfillment;
