import {
	Checkbox,
	CircularProgress,
	FormHelperText,
	Grid,
	InputLabel,
	List,
	ListItem,
	ListItemIcon,
	ListItemText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useState } from "react";
import { MarkdownElement } from "..";

export interface BaseField {
	fieldId: number;
	label: string;
	component: (
		props: Partial<BaseField> & {
			item: Item<BaseField>;
			onChange: (newItem: Item<BaseField>) => any;
		}
	) => JSX.Element;
}

export interface Item<Field> {
	id: string;
	checked: boolean;
	primary?: string;
	secondary?: string;
	description?: string;
	fields?: Field[];
	value?: number | string;
}

export interface CheckBoxListProps<Field> {
	items: Item<Field>[];
	loading: boolean;
	onChange: (newItem: Item<Field>) => any;
	summaryComponent: (items: Item<Field>[]) => JSX.Element;
	markdownPostProcess: (html: string) => string;
}

/**
 * Dynamic list of elements that can be checked. Can optionally render fields for every item.
 * @param items List of items to list.
 * @param loading true if component is loading
 * @param onChange Callback function that is called every time a change happens.
 * @param summaryComponent Component to render in the bottom of the list.
 * @param markdownPostProcess Function to process the result from the markdown parsing
 */
function CheckBoxList<
	Field extends { fieldId: number; label: string; component: any } = BaseField
>(props: CheckBoxListProps<Field>) {
	const {
		items,
		loading,
		onChange,
		summaryComponent,
		markdownPostProcess
	} = props;

	const classes = useStyles();

	const handleCheckingItem = item => {
		setOpen(item.checked ? null : item.id);
		const newItem = { ...item, checked: !item.checked };
		onChange(newItem);
	};

	const [open, setOpen] = useState<null | string>(null);

	return (
		<List style={{ width: "100%" }} dense>
			{items.map((item, idx) => (
				<ListItem key={idx} divider>
					<Grid container>
						<Grid item container xs={12} justify="center" alignContent="center">
							<ListItemIcon onClick={() => handleCheckingItem(item)}>
								<Checkbox
									edge="start"
									checked={item?.checked}
									tabIndex={-1}
									disableRipple
								/>
							</ListItemIcon>
							<ListItemText
								primary={item.primary}
								secondary={item.secondary}
								onClick={() =>
									setOpen(!open || open !== item.id ? item.id : null)
								}
							/>
							<ListItemIcon className={classes.loading}>
								{loading && open === item.id && (
									<CircularProgress size={30} thickness={3} />
								)}
							</ListItemIcon>
						</Grid>
						{open === item.id && (
							<Grid item container xs={12} spacing={2}>
								{item.description && (
									<Grid item xs={12}>
										<MarkdownElement
											text={item.description}
											component={FormHelperText}
											postProcess={markdownPostProcess}
										/>
									</Grid>
								)}
								{item.fields &&
									item.fields.map(
										field =>
											field?.component && (
												<Grid
													item
													xs={12}
													key={"field-" + field.label + "-" + field.fieldId}
												>
													<InputLabel>{field.label}</InputLabel>
													{field.component({
														...field,
														item: item,
														onChange: onChange
													})}
												</Grid>
											)
									)}
							</Grid>
						)}
					</Grid>
				</ListItem>
			))}
			{summaryComponent && (
				<ListItem key={"summaryComponent"}>{summaryComponent(items)}</ListItem>
			)}
		</List>
	);
}

const useStyles = makeStyles({
	loading: { alignItems: "center", justifyContent: "flex-end" }
});

export { CheckBoxList };
