import { useMemo } from "react";
import mapValues from "lodash/mapValues";
import { GeneratedCalcRule } from "../../calculator";
import calcrules from "@ploy-lib/calc-rules";

const evalGeneratedCalcRules = function<TData>(
	script: string,
	sourceUrl?: string
) {
	const sourceUrlComment = sourceUrl && `//# sourceURL=${sourceUrl}`;

	const globalFuncs = { ReadableNumber: calcrules.formatNumber };

	const mapedFuncs = Object.keys(globalFuncs)
		.map(x => `var ${x} = globalFuncs.${x};`)
		.join("\n");

	// eslint-disable-next-line no-eval
	return eval(`(function(globalFuncs) {
		  ${mapedFuncs}
		  ${script}

		  return {
			  calculate: calculate,
			  validate: validate
		  }
		})
		${sourceUrlComment || ""}
		`)(globalFuncs) as GeneratedCalcRule<TData>;
};

export const useGeneratedCalcRules = <TData, TN extends string>(
	generatedCalcRules: Partial<Record<TN, string>>
) => {
	return useMemo(
		() =>
			mapValues(generatedCalcRules, (cr: string, ns: string) =>
				evalGeneratedCalcRules<TData>(cr, `calcrules:///${ns}.js`)
			) as Partial<Record<TN, GeneratedCalcRule<TData>>>,
		[generatedCalcRules]
	);
};
