import React from "react";
import { Hidden, Typography, Tooltip } from "@material-ui/core";

import { getIn } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { ColumnDefinition } from "./ExpandableTable";

const useStyles = makeStyles(
	theme => ({
		column: {
			display: "inline-block",
			marginRight: 20
		},
		label: {},
		value: {},
		cancelButton: {
			position: "absolute" as "absolute",
			bottom: "10px",
			right: "10px"
		}
	}),
	{
		name: "DployExpandedRow"
	}
);

export interface ExpandedRowProps<
	TData extends object,
	TLoaded extends object
> {
	columnData: ColumnDefinition<TData, TLoaded>[];
	rowData: TData;
	loadedData?: TLoaded;
}

export const ExpandedRow = <
	TData extends object,
	TLoaded extends object,
	TOtherProps extends object = {}
>(
	props: ExpandedRowProps<TData, TLoaded> & TOtherProps
) => {
	const { columnData, rowData, loadedData, ...rest } = props;
	const classes = useStyles(props);
	return (
		<table>
			<tbody>
				{columnData
					.filter(col => col.hiddenInExpandable !== true)
					.map((col, key) => {
						const cellData = col.id
							? getIn(rowData, col.id)
							: col.loadedId
							? getIn(loadedData, col.loadedId)
							: undefined;

						const value = col.format
							? col.format(cellData, rowData, loadedData)
							: cellData;
						const tooltip = col.tooltip
							? col.tooltip(cellData, rowData, loadedData)
							: null;

						var content = (
							<Typography className={classes.value}>{value}</Typography>
						);

						const Component = col.component;

						if (
							typeof col.hiddenInExpandable === "function" &&
							col.hiddenInExpandable(cellData, rowData, loadedData)
						)
							return null;

						if (Component)
							content = (
								<Component
									rowData={rowData}
									loadedData={loadedData}
									{...rest}
									{...col.props}
								>
									{value}
								</Component>
							);

						return (
							<Hidden {...col.hiddenInExpandable} key={key}>
								<tr>
									{col.label ? (
										<td className={classes.column}>
											<Typography className={classes.label}>
												{col.label}
											</Typography>
										</td>
									) : null}
									<td colSpan={col.label ? 1 : 2}>
										{tooltip ? (
											<Tooltip title={tooltip} placement="top-start">
												{content}
											</Tooltip>
										) : (
											content
										)}
									</td>
								</tr>
							</Hidden>
						);
					})}
			</tbody>
		</table>
	);
};
