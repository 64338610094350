import { NetworkError, Manager, Middleware } from "@rest-hooks/core";
import { LoginResource } from "../plain/LoginResource";

const authMiddleware: Middleware = ({ dispatch }) => next => async action => {
	switch (action.type) {
		case "rest-hooks/purge":
		case "rest-hooks/rpc":
		case "rest-hooks/receive":
			if (
				action.error &&
				(action.payload as NetworkError).status === 401 &&
				action.meta.schema.urlRoot !== LoginResource.urlRoot
			) {
				// TODO: dispatch auth system
				// dispatch({ type: "rest-hooks/reset" }); // requires rest-hooks>= 2.2.0
				window.location.reload();
			}
			next(action);
			break;
		default:
			next(action);
			return;
	}
};

export class AuthManager implements Manager {
	cleanup() {}
	getMiddleware = () => authMiddleware;
}
