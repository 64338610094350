import { memo } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Drawer from "./Drawer";
import MobileAppBar from "./MobileAppBar";
import Content from "./Content";
import * as Pages from "../pages";
import {
	DrawerContextProvider,
	AppSearchParamContextProvider,
	EditingContextProvider
} from "../../contexts";
import { Router } from "@reach/router";
import { NoMatch, ErrorPage, GlobalSuspense } from "@ploy-ui/core";
import { useThemeProps } from "@ploy-ui/tenants";
import { ErrorHandler } from "@ploy-lib/core";
import Box from "@material-ui/core/Box";
import FixedBottomMenu from "./Drawer/FixedBottomMenu";
import { Hidden } from "@material-ui/core";
import { VulcanDataResource } from "@ploy-lib/rest-resources";
import InformUser from "./InformUser";
import { useResource } from "@rest-hooks/core";

import { v1routes } from "./routeMigration";

const basepath = process.env.PUBLIC_URL || "";

const Page = memo(({ className, redirectDefault = "dashboard" }) => (
	<GlobalSuspense>
		<ErrorHandler
			fallback={error => (
				<Box
					p={3}
					className={className}
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<ErrorPage error={error} />
				</Box>
			)}
		>
			<Router className={className}>
				{v1routes}

				{/* Route config */}
				<Pages.Dashboard path="/dashboard/*" />
				<Pages.AllApplications path="/applications/*" />
				<Pages.PendingOrders path="/pending-orders/*" />
				<Pages.ExternalViews path="/external/*" />
				<Pages.Reports path="/reports/*" />
				<Pages.FormViews path="/form/*" />
				<Pages.Profile path="/profile/*" />
				<Pages.PendingProofOfDelivery path="/proof-of-delivery/*" />
				<Pages.VendorSigner path="/vendor-signer/*" />
				<Pages.WholesaleLimitPage path="/wholesale-limit/*" />
				<Pages.GeneralInformationPage path="/general-info/*" />
				{/* Redirect to default */}
				<NoMatch default noThrow to={`${basepath}/${redirectDefault}`} />
			</Router>
		</ErrorHandler>
	</GlobalSuspense>
));

function Portal(props) {
	const { drawerColor = "primary.main" } = useThemeProps(props, "Portal");

	const classes = useStyles(props);

	const { initialView } = useResource(VulcanDataResource.detail(), {});

	return (
		<DrawerContextProvider>
			<AppSearchParamContextProvider>
				<EditingContextProvider>
					<Box
						className={classes.root}
						flexGrow={1}
						zIndex={1}
						position="relative"
						display="flex"
						width="100%"
						flexDirection={["column", "row"]}
					>
						<InformUser />

						<Hidden xsDown>
							<Drawer
								color={drawerColor}
								position={"absolute"}
								bgcolor={drawerColor}
							/>
						</Hidden>

						<Hidden smUp>
							<MobileAppBar />
						</Hidden>

						<Content>
							<Hidden smUp>
								<div className={classes.toolbarSpacer} />
							</Hidden>

							<Page className={classes.page} redirectDefault={initialView} />
						</Content>

						<Hidden smUp>
							<FixedBottomMenu />
						</Hidden>
					</Box>
				</EditingContextProvider>
			</AppSearchParamContextProvider>
		</DrawerContextProvider>
	);
}

Portal.displayName = "Portal";

const useStyles = makeStyles(
	theme => ({
		root: {
			[theme.breakpoints.down("xs")]: {
				overflowX: "hidden"
			}
		},
		toolbarSpacer: {
			width: "100%",
			...theme.mixins.toolbar
		},
		page: {
			maxWidth: 1440,
			width: "100%",
			flexGrow: 1,
			display: "flex",
			flexDirection: "column"
		}
	}),
	{ name: "Portal" }
);

export default Portal;
