import { ValidationHelpers } from "@ploy-lib/validation-helpers";

/**
 * Make all properties in T optional except K
 */
export type PartialExcept<T, K extends keyof T> = Partial<T> &
	{ [P in K]-?: T[P] };

/**
 * Make only K optional in T
 */
export type PartialOnly<T, K extends keyof T> = {
	[P in Exclude<keyof T, K>]: T[P];
} &
	{ [P in K]?: T[P] };

export type MapToRequired<T> = { [K in keyof T]: Required<T[K]> };

export type ObjectMap<TData> = Partial<{ [key: string]: TData }>;

export type Namespaced<T, S extends string = string> = Record<
	S,
	Partial<Record<string, T>>
>;

export interface CtrlField {
	ctrl: string;
	resolved: string;
	overrideIndex?: number;
}

export interface VariableField extends CtrlField {
	variable: string;
}

export interface ResolvedCtrl<TNamespaces> extends CtrlField {
	namespace: TNamespaces;
	ref: string;
}

export interface ResolvedVariable<TNamespaces> extends VariableField {
	namespace: TNamespaces;
	ref: string;
}

export type ResolvedField<TNamespaces> = (
	| ResolvedCtrl<TNamespaces>
	| VariableField
	| CtrlField
) & {
	fieldName: string;
};

export type OverrideMap<TData> = Partial<
	Record<string, Partial<Record<number, ObjectMap<TData>>>>
>;

export interface CalcUtils<TData> {
	mergeFunctions(
		from: number,
		to: number,
		variables: ObjectMap<TData>,
		variableOverrides: OverrideMap<TData>
	): ObjectMap<TData>;
	Financial: any;
	FinancialUtils: any;
	DebouncedStartLoanAmountCalculation(namespace: string, name: string): void;
	formatNumber(num: number, locale: string, format: string): string;
	round(num: number, decimals: number): number;
}

export interface ValidateOptions {
	DoNotRender?: boolean;
	IsFieldValidation?: boolean;
	ActiveFields?: string[];
	IsScenarioNext?: boolean;
	OnlyRemoveValidation?: boolean;
	validateAll?: boolean;
}

export interface Calculation<TData> {
	data: ObjectMap<TData>;
	checked: ObjectMap<boolean>;
	missing: ObjectMap<boolean>;
	triggerChanges: Set<string>;
}

export interface Validation {
	valid: boolean;
	messages: ObjectMap<string>;
	visible: ObjectMap<boolean>;
	enabled: ObjectMap<boolean>;
	validatedFields: {
		Messages: ObjectMap<string>;
		Highlights: ObjectMap<boolean>;
		Manual: ObjectMap<string[]>;
	};
}

export interface FunctionBase {
	output: string;
	inputs: string[];
	call: (...args: any[]) => any;
}

export interface CalculateFunction extends FunctionBase {
	type: "value";
}
export function isCalculateFunction(f: FunctionTypes): f is CalculateFunction {
	return f.type === "value";
}

export interface FieldErrorFunction extends FunctionBase {
	type: "error";
	highlight: string[];
	call: (...args: any[]) => string | undefined;
}
export function isFieldErrorFunction(
	f: FunctionTypes
): f is FieldErrorFunction {
	return f.type === "error";
}

export interface VariableStateFunction extends FunctionBase {
	type: "missing" | "writeLocked";
	call: (...args: any[]) => boolean | undefined;
}
export function isVariableStateFunction(
	f: FunctionTypes
): f is VariableStateFunction {
	return f.type === "missing" || f.type === "writeLocked";
}

export interface FieldStateFunction extends FunctionBase {
	type: "visible" | "enabled";
	call: (...args: any[]) => boolean | undefined;
}
export function isFieldStateFunction(
	f: FunctionTypes
): f is FieldStateFunction {
	return f.type === "visible" || f.type === "enabled";
}

export type FunctionTypes =
	| CalculateFunction
	| VariableStateFunction
	| FieldErrorFunction
	| FieldStateFunction;

export interface CalcRule {
	isFieldVisible(name: string): boolean;
}

export type WithGetSet<V> = Pick<Map<string, V>, "get" | "set">;

export interface GeneratedCalcRule<TData> {
	calculate(
		changedfieldID: string,
		data: WithGetSet<number | string | TData>,
		checked: WithGetSet<boolean>,
		missing: WithGetSet<boolean>,
		initial: WithGetSet<number | string | TData>,
		variableOverrides: OverrideMap<TData>,
		CalcRulesLib: CalcUtils<TData>,
		Validation: ValidationHelpers & {
			default: ValidationHelpers;
			validation: ValidationHelpers;
		},
		calcrule: CalcRule
	): Calculation<TData>;
	validate(
		isManual: boolean,
		options: ValidateOptions,
		data: WithGetSet<number | string | TData>,
		checked: WithGetSet<boolean>,
		missing: WithGetSet<boolean>,
		initial: WithGetSet<number | string | TData>,
		variableOverrides: OverrideMap<TData>,
		CalcRulesLib: CalcUtils<TData>,
		Validation: ValidationHelpers & {
			default: ValidationHelpers;
			validation: ValidationHelpers;
		},
		calcrule: CalcRule
	): Validation;
}
