import { GeneratedCalcRule, CalcUtils } from "./types";
import CalcRulesLib from "@ploy-lib/calc-rules";
import {
	getInNamespaced,
	setInMutableNamespacedCopy,
	isResolvedCtrl,
	filterNameToFieldName,
	withGetFromField,
	withGet,
	stripControlIdPrefixAndSuffix
} from "./utils";
import { IsFieldVisibleIn } from "./createIsFieldVisibleIn";
import { CalculatorState } from "./createCalculator";
import { ValidationHelpers } from "@ploy-lib/validation-helpers";

const CalcRulesLibCore: CalcUtils<any> = {
	mergeFunctions: CalcRulesLib.mergeFunctions,
	formatNumber: CalcRulesLib.formatNumber,
	round: CalcRulesLib.round,
	Financial: CalcRulesLib.Financial,
	FinancialUtils: CalcRulesLib.FinancialUtils,
	DebouncedStartLoanAmountCalculation: () => {}
};

export interface Validate<TNamespaces extends string, TData> {
	(
		input: [
			CalculatorState<TNamespaces, TData>,
			Record<"visible" | "enabled", Record<string, boolean>>
		]
	): [
		CalculatorState<TNamespaces, TData>,
		Record<"visible" | "enabled", Record<string, boolean>>
	];
}

export function createValidate<TNamespaces extends string, TData>(
	namespace: TNamespaces,
	calcrule: GeneratedCalcRule<TData>,
	validationHelpers: ValidationHelpers,
	isFieldVisibleIn: IsFieldVisibleIn<TNamespaces>
): Validate<TNamespaces, TData> {
	return function validate([input, results]) {
		const { values, controlFieldMaps, fieldControlMaps } = input;

		let { isFieldVisible, defaultFieldVisibility, isEnabled, errors } = input;

		const variableOverrides = {};
		const data = withGet(input.values, namespace);
		const missing = withGet(input.isMissing, namespace);
		const checked = withGet(input.isWriteLocked, namespace);
		const initial = withGetFromField(input, input.initialFormValues, namespace);

		try {
			const validation = calcrule.validate(
				true,
				{ validateAll: true },
				data,
				checked,
				missing,
				initial,
				variableOverrides,
				CalcRulesLibCore,
				{
					default: validationHelpers,
					validation: validationHelpers,
					...validationHelpers
				},
				{
					isFieldVisible: isFieldVisibleIn(
						isFieldVisible,
						defaultFieldVisibility
					)
				}
			);

			// const manuallySet = new Map<string, string[]>();
			const manuallySet = new Set<string>();

			// Ignore validation.messages for now.

			const highlights = validation.validatedFields.Highlights || {};
			const messages = validation.validatedFields.Messages || {};
			const manual = validation.validatedFields.Manual || {};

			const visited = new Set();
			const wasVisited = (key: string) => {
				if (visited.has(key)) return true;

				visited.add(key);
			};

			for (let fieldOrCtrl of Object.keys(highlights).reverse()) {
				let error = messages[fieldOrCtrl] || highlights[fieldOrCtrl];
				let setManually = manual[fieldOrCtrl];

				let ctrl = getInNamespaced(fieldControlMaps, namespace, fieldOrCtrl);
				let field =
					getInNamespaced(controlFieldMaps, namespace, ctrl) ||
					getInNamespaced(controlFieldMaps, namespace, fieldOrCtrl);

				let fieldName = fieldOrCtrl;
				let fieldNamespace = namespace;
				if (field) {
					fieldName = field.fieldName;
					fieldNamespace = isResolvedCtrl<TNamespaces>(field)
						? field.namespace
						: namespace;
				}
				// Do not overwrite manual validations with automatic ones
				if (!manuallySet.has(fieldName) || setManually) {
					if (errors[fieldNamespace] === undefined) {
						errors[fieldNamespace] = {};
					}

					if (wasVisited(`${fieldNamespace}.${fieldName}`)) continue;

					errors =
						error == null
							? errors
							: setInMutableNamespacedCopy(
									input.errors,
									errors,
									fieldNamespace,
									fieldName,
									error
							  );
				}

				if (setManually) {
					// manuallySet.set(field.fieldName, setManually);
					manuallySet.add(fieldName);
				}
			}

			const viewFilterResult = getInNamespaced(
				values,
				"Calculator" as TNamespaces,
				"ObjectOptions"
			);
			for (let filterName of Object.keys(viewFilterResult || {})) {
				let isVisible = Boolean(
					viewFilterResult[filterName].numericValue ||
						viewFilterResult[filterName].NumericValue
				);
				let fieldName = filterNameToFieldName(filterName.replace("Show", ""));
				validation.visible[fieldName] = isVisible;
				validation.visible[fieldName + "ExVat"] = isVisible;
				validation.visible[fieldName + "IncVat"] = isVisible;
			}

			for (let [ref, visible] of Object.entries(validation.visible)) {
				for (let fieldOrCtrl of ref.split(",")) {
					let fieldName = stripControlIdPrefixAndSuffix(fieldOrCtrl);

					let fieldControl = fieldControlMaps[namespace]?.[fieldName];
					if (fieldControl === undefined) continue;

					let visible_field = getInNamespaced(
						controlFieldMaps,
						namespace,
						fieldControl
					);
					if (visible_field) {
						let fieldNamespace = isResolvedCtrl<TNamespaces>(visible_field)
							? visible_field.namespace
							: namespace;

						if (visible != null) {
							const path = `${fieldNamespace}.${visible_field.fieldName}`;

							const current =
								results.visible[path] != null ? results.visible[path] : true;

							results.visible[path] = visible && current;
						}
					}
				}
			}

			for (const [ref, enabled] of Object.entries(validation.enabled)) {
				for (const ctrl of ref.split(",")) {
					const field = getInNamespaced(controlFieldMaps, namespace, ctrl);

					if (field) {
						const fieldNamespace = isResolvedCtrl<TNamespaces>(field)
							? field.namespace
							: namespace;

						if (enabled != null) {
							const path = `${fieldNamespace}.${field.fieldName}`;

							const current =
								results.enabled[path] != null ? results.enabled[path] : true;

							results.enabled[path] = enabled && current;
						}
					}
				}
			}
		} catch (e: any) {
			console.warn(`${namespace}.validate() failed: ${e.message}`);
		}

		if (isFieldVisible !== input.isFieldVisible) {
			input = { ...input, isFieldVisible };
		}

		if (isEnabled !== input.isEnabled) {
			input = { ...input, isEnabled };
		}

		if (errors !== input.errors) {
			input = { ...input, errors };
		}

		return [input, results];
	};
}
