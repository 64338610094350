import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BasePlainResource";
import { ID } from "../types";

export interface Profile {
	email: string;
	firstName: string;
	lastName: string;
	mobileNo: string;
	roleName: string;
}

export class ProfileResource extends BaseCamelCasedResource implements Profile {
	readonly salespersonId: ID;
	readonly email: string;
	readonly firstName: string;
	readonly lastName: string;
	readonly mobileNo: string;
	readonly roleName: string;

	get fullName() {
		return `${this.firstName} ${this.lastName}`;
	}

	get initials() {
		return this.fullName
			.split(" ")
			.map(n => n[0])
			.join("");
	}

	pk() {
		return "singleton";
	}

	static url(urlParams: Readonly<Record<string, any>>): string {
		return this.listUrl(urlParams);
	}

	static urlRoot = legacyApiResourceUrl("VulcanData/CurrentUserProfile");
}
