import React, { useState } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grid,
	useTheme,
	useMediaQuery,
	Box,
	Breadcrumbs,
	Link,
	Typography,
	IconButton
} from "@material-ui/core";
import { ControlFields } from "./property-controls/ControlFields";
import {
	Formik,
	Form,
	useFormikContext,
	FormikConfig,
	Field,
	FieldProps
} from "formik";
import { isGridComponent } from "./content/types";
// import { useDebounce } from "use-debounce";

import * as ContentComponentsWithControls from "./content/with-property-controls";
import {
	ControlType,
	isControlComponent,
	PropertyControls
} from "./property-controls";
import { isNotNull } from "@ploy-lib/core";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { makeStyles } from "@material-ui/core/styles";
import { FormikSubmitButton } from "./property-controls/fields/FormikSubmitButton";

export type GridComponentWithControl = keyof typeof ContentComponentsWithControls;

interface GridItemEditorProps<P> {
	component: GridComponentWithControl;
	initialValues: Partial<P>;
	onSubmit: FormikConfig<Partial<P>>["onSubmit"];
	open: boolean;
	onClose?: () => void;
}

const useStyles = makeStyles(theme => ({
	dialogPaper: {
		overflow: "hidden"
	},
	dialogContent: {
		[theme.breakpoints.up("md")]: {
			overflow: "hidden"
		}
	}
}));

export function GridItemEditor<P = {}>(props: GridItemEditorProps<P>) {
	const classes = useStyles(props);
	const { component, initialValues, onSubmit, open, onClose } = props;

	const Component = ContentComponentsWithControls[component];

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

	const [selected, setSelected] = useState<string>();

	if (!isControlComponent(Component)) return null;

	let controls: PropertyControls<any> | null = Component.propertyControls;
	let arrayControl: typeof controls[any] | null = null;
	const selectedControls = selected
		?.split(".")
		.map((p, i, arr) => {
			let title: React.ReactNode = arrayControl ? `[${p}]` : undefined;
			const control = arrayControl ?? controls?.[p];

			controls = null;
			arrayControl = null;

			const fieldName = arr.slice(0, i + 1).join(".");

			if (control?.type === ControlType.Object) {
				controls = control.propertyControls;
				title = title ?? control.title;
			} else if (
				control?.type === ControlType.Array &&
				control.propertyControl.type !== ControlType.Enum
			) {
				arrayControl = control.propertyControl;
				title = title ?? control.title;
			}
			if (!control) return null;

			return { title, fieldName };
		})
		.filter(isNotNull);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="form-dialog-title"
			maxWidth="md"
			fullWidth
			fullScreen={fullScreen}
			classes={{
				paper: classes.dialogPaper
			}}
		>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validateOnBlur={false}
				validateOnChange={false}
				validateOnMount={false}
			>
				<>
					<Box clone display="flex" alignItems="center">
						<DialogTitle disableTypography>
							<IconButton
								onClick={() =>
									setSelected(
										selectedControls?.[selectedControls.length - 2]?.fieldName
									)
								}
								disabled={!selectedControls}
								title="Tilbake"
							>
								<ChevronLeftIcon />
							</IconButton>
							<Breadcrumbs>
								{!selected ? (
									<Typography variant="h6" id="form-dialog-title">
										<Field name="boxProps.header">
											{(props: FieldProps) => (
												<>{props.field.value ?? component}</>
											)}
										</Field>
									</Typography>
								) : (
									<Link
										id="form-dialog-title"
										component="button"
										type="button"
										variant="h6"
										color="inherit"
										onClick={() => setSelected(undefined)}
										disabled={!selected}
									>
										<Field name="boxProps.header">
											{(props: FieldProps) => (
												<>{props.field.value || component}</>
											)}
										</Field>
									</Link>
								)}

								{selectedControls?.map(({ title, fieldName }) =>
									selected === fieldName ? (
										<Typography key={fieldName} variant="h6">
											{title}
										</Typography>
									) : (
										<Link
											component="button"
											type="button"
											variant="h6"
											color="inherit"
											key={fieldName}
											onClick={() => setSelected(fieldName)}
										>
											{title}
										</Link>
									)
								)}
							</Breadcrumbs>
						</DialogTitle>
					</Box>
					<DialogContent className={classes.dialogContent}>
						<Form>
							<Grid container spacing={2}>
								<Box
									clone
									overflow={[undefined, undefined, "auto"]}
									maxHeight={[undefined, undefined, "calc(100vh - 200px)"]}
								>
									<Grid item xs={12} md={6}>
										<ControlFields
											propertyControls={Component.propertyControls}
											margin="dense"
											onSelect={setSelected}
											selected={selected}
										/>
									</Grid>
								</Box>
								<Box
									clone
									overflow="auto"
									maxHeight={[undefined, undefined, "calc(100vh - 200px)"]}
								>
									<Grid item xs={12} md={6}>
										<Preview component={Component} />
									</Grid>
								</Box>
							</Grid>
						</Form>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								setSelected(undefined);
								onClose?.();
							}}
						>
							Avbryt
						</Button>
						<FormikSubmitButton variant="outlined" color="primary">
							Lagre
						</FormikSubmitButton>
					</DialogActions>
				</>
			</Formik>
		</Dialog>
	);
}

function Preview(props: { component: React.ComponentType<any> }) {
	const { component: Component } = props;

	const form = useFormikContext();

	// const [previewProps] = useDebounce(form.values, 1000);
	const previewProps = form.values;

	let minHeight = 250;

	if (isGridComponent(Component)) minHeight = Component.minHeight ?? minHeight;

	return (
		<Box minHeight={minHeight} clone>
			<Component {...previewProps} />
		</Box>
	);
}
