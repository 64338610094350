import React from "react";
import Link, { LinkProps } from "@material-ui/core/Link";
import { RowProps } from "../ExpandableTable";

export type MailtoLinkProps<
	TData extends object,
	TLoaded extends object
> = RowProps<TData, TLoaded> & {
	subject?: string | ((row: TData, loaded: TLoaded | undefined) => string);
};

const MailtoLink = <TData extends object, TLoaded extends object>({
	rowData,
	loadedData,
	children,
	subject,
	...props
}: MailtoLinkProps<TData, TLoaded> & LinkProps) => {
	const subjectContent =
		typeof subject === "function" ? subject(rowData, loadedData) : subject;

	return children ? (
		<Link
			{...props}
			href={`mailto: ${children}${subjectContent &&
				"&subject="}${subjectContent}`}
		>
			{children}
		</Link>
	) : null;
};

export default MailtoLink;
