import React, { useMemo, useCallback } from "react";
import {
	ContactInfo,
	ContactInfoResource,
	CustomerType
} from "@ploy-lib/rest-resources";
import {
	Grid,
	FormHelperText,
	FormControl,
	Typography
} from "@material-ui/core";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { object, string } from "yup";
import { Formik, Form, Field, FormikHelpers } from "formik";
import { TextField } from "@ploy-ui/form-fields";
import { PendingButton } from "./Button";
import { useFetcher } from "@rest-hooks/core";
import { createValidationHelpers } from "@ploy-lib/validation-helpers";

export interface ContactInfoProps {
	contactInfo: ContactInfo;
	applicationNumber: string;
	onUpdateContactInfo: (info: ContactInfo) => void;
}
export interface ContactInfoOptions {
	showCustomerInfo?: boolean;
	showContactPersonInfo?: boolean;
	disabled?: boolean;
}
const messages = defineMessages({
	invalidEmail: {
		id: "form_fields.email.invalid",
		defaultMessage: "ugyldig email",
		description: "Ugyldig email"
	},
	wrongPhoneNumber: {
		id: "form_fields.phoneNumber.wrong",
		defaultMessage: "Må være på formatet XXXXXXXX(8 siffer)",
		description: "text for wrong phone number"
	},
	wrongPhoneHomeNumber: {
		id: "form_fields.phoneNumberHome.wrong",
		defaultMessage: "Må være på formatet XXXXXXXX(8 siffer)",
		description: "Text for wrong phone home number"
	},
	invalidContactPerson: {
		id: "form_fields.invalid-contacperson",
		defaultMessage: "Må angi kontaktperson",
		description: "invalid contactperson"
	},
	updateError: {
		id: "form_fields.update.error",
		defaultMessage: "Kunne ikke oppdatere informasjon",
		description: "Error message when update customer information fails"
	},
	updateSuccess: {
		id: "form_fields.update.success",
		defaultMessage: "Informasjon oppdatert!",
		description: "Success message when customer information updated"
	}
});

const ContactInformation = (props: ContactInfoProps & ContactInfoOptions) => {
	const {
		contactInfo,
		applicationNumber,
		onUpdateContactInfo,
		showCustomerInfo = true,
		showContactPersonInfo = true,
		disabled = false
	} = props;

	const intl = useIntl();

	const validation = createValidationHelpers(intl.locale);

	const ContactInfoSchema = useMemo(
		() =>
			object<Partial<ContactInfo>>().shape({
				contactPerson: string().test(
					"ContactPersonValidation",
					intl.formatMessage(messages.invalidContactPerson),
					(contactPerson: string) =>
						contactPerson === null ||
						contactPerson === undefined ||
						contactPerson.match(/(\w+){2,}/) !== null
				),
				contactPersonEmail: string().test(
					"ContactPersonEmailValidation",
					intl.formatMessage(messages.invalidEmail),
					(mail: string) =>
						mail === null ||
						mail === undefined ||
						mail.match(/\S+@\S+\.\S+/) !== null
				),
				phoneMobile: string().test(
					"PhoneValidation",
					intl.formatMessage(messages.wrongPhoneNumber),
					(phone: string) =>
						phone === null ||
						phone === undefined ||
						validation.phoneValidation(phone)
				),
				// .matches(
				// 	/(^[0-9]{8}$)|(^[0-9]{3}[0-9]{2}[0-9]{3}$)/,
				// 	intl.formatMessage(messages.wrongPhoneNumber)
				// )
				email: string().test(
					"EmailValidation",
					intl.formatMessage(messages.invalidEmail),
					(mail: string) =>
						mail === null ||
						mail === undefined ||
						mail.match(/\S+@\S+\.\S+/) !== null
				),
				phoneHome: string().test(
					"PhoneHomeValidation",
					intl.formatMessage(messages.wrongPhoneHomeNumber),
					(phone: string) =>
						phone === null ||
						phone === undefined ||
						validation.phoneHomeValidation(phone)
				)
				//.matches(
				//	/(^[0-9]{8}$)|(^[0-9]{3} [0-9]{2} [0-9]{3}$)/,
				//	intl.formatMessage(messages.wrongPhoneNumber)
				//)
			}),
		[intl, validation]
	);

	const updateInfo = useFetcher(ContactInfoResource.update());

	const submit = useCallback(
		async (values: ContactInfo, formik: FormikHelpers<ContactInfo>) => {
			try {
				const result = await updateInfo(
					{ applicationNumber, role: contactInfo.role },
					values
				);

				formik.setStatus({
					success: true,
					message: intl.formatMessage(messages.updateSuccess)
				});

				onUpdateContactInfo?.(result);
			} catch (e: any) {
				formik.setStatus({
					success: false,
					message: intl.formatMessage(messages.updateError)
				});
			}
		},
		[updateInfo, applicationNumber, contactInfo.role, intl, onUpdateContactInfo]
	);

	return (
		<Formik<Partial<ContactInfo>>
			validationSchema={ContactInfoSchema}
			initialValues={useMemo(() => ({ ...contactInfo }), [contactInfo])}
			onSubmit={submit}
			enableReinitialize
			validateOnMount
		>
			{formik => (
				<Form>
					<Grid container spacing={1}>
						{showCustomerInfo && (
							<>
								{contactInfo.type !== CustomerType.Private && (
									<Grid item xs={12}>
										<Typography variant="body1">
											<FormattedMessage
												id="core.change.contact.information.header"
												description="Contact information header"
												defaultMessage="Foretak"
											/>
										</Typography>
									</Grid>
								)}
								<Grid container spacing={1}>
									<Grid item xs={12} md={6}>
										<Field
											name="email"
											label={
												<FormattedMessage
													id="core.contact-info.contact-information-form.field-email"
													defaultMessage="Email"
												/>
											}
											variant="outlined"
											margin="dense"
											component={TextField}
											fullWidth
											disabled={disabled}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<Field
											name="phoneHome"
											label={
												<FormattedMessage
													id="core.contact-info.contact-information-form.field-phone-home"
													defaultMessage="Fasttelefon"
												/>
											}
											variant="outlined"
											margin="dense"
											component={TextField}
											fullWidth
											disabled={disabled}
										/>
									</Grid>
									{contactInfo.type === CustomerType.Private && (
										<Grid item xs={12} md={6}>
											<Field
												name="phoneMobile"
												label={
													<FormattedMessage
														id="core.contact-info.contact-information-form.field-phone-mobile"
														defaultMessage="Mobilnummer"
													/>
												}
												variant="outlined"
												margin="dense"
												component={TextField}
												fullWidth
												disabled={disabled}
											/>
										</Grid>
									)}
								</Grid>
							</>
						)}
						{showContactPersonInfo &&
							contactInfo.type !== CustomerType.Private && (
								<>
									<Grid item xs={12}>
										<Typography variant="body1">
											<FormattedMessage
												id="core.change.contact.information.header.contact.person"
												description="Contact information header"
												defaultMessage="Kontaktperson"
											/>
										</Typography>
									</Grid>
									<Grid container spacing={1}>
										<Grid item xs={12} md={6}>
											<Field
												name="contactPerson"
												label={
													<FormattedMessage
														id="core.contact-info.contact-information-form.field-contactperson"
														defaultMessage="Kontaktperson"
													/>
												}
												variant="outlined"
												margin="dense"
												component={TextField}
												fullWidth
												disabled={disabled}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<Field
												name="contactPersonEmail"
												label={
													<FormattedMessage
														id="core.contact-info.contact-information-form.field-contactperson-email"
														defaultMessage="Email"
													/>
												}
												variant="outlined"
												margin="dense"
												component={TextField}
												fullWidth
												disabled={disabled}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<Field
												name="phoneMobile"
												label={
													<FormattedMessage
														id="core.contact-info.contact-information-form.field-contactperson-phone-mobile"
														defaultMessage="Mobilnummer"
													/>
												}
												variant="outlined"
												margin="dense"
												component={TextField}
												fullWidth
												disabled={disabled}
											/>
										</Grid>
									</Grid>
								</>
							)}
						<Grid item>
							<FormControl
								margin="normal"
								error={formik.status && formik.status.success === false}
							>
								<PendingButton
									pending={formik.isSubmitting}
									disabled={!formik.isValid || !formik.dirty || disabled}
									onClick={() => {
										formik.submitForm();
									}}
									variant="contained"
									color="secondary"
								>
									<FormattedMessage
										id="form_field.updateCustomerInformation.button.label"
										defaultMessage="Oppdater"
									/>
								</PendingButton>
								<FormHelperText>
									{formik.status && formik.status.message
										? formik.status.message
										: null}
								</FormHelperText>
							</FormControl>
						</Grid>
					</Grid>
				</Form>
			)}
		</Formik>
	);
};

ContactInformation.displayName = "DployContactInformation";

export { ContactInformation };
