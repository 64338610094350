import { EditorDatePickerFields } from "./DatePickerFields";
import { EditorTextFields } from "./TextFields";
import { EditorNumberFields } from "./NumberFields";
import { EditorSelectionFields } from "./SelectionField";
import { EditorAutocompleteFields } from "./MuiSelectFields";
import { EditorCheckboxFields } from "./CheckboxField";
import { EditorDropzoneFields } from "./DropzoneFields";
import { EditorSliderFields } from "./SliderFields";

export * from "./formatters/number";
export * from "./formatters/date";
export * from "./formatters/extractNumberFormat";

export * from "./helpers/IntlDateTimePicker";
export * from "./helpers/IntlNumberField";
export * from "./DployTextField";
export * from "./DployAutocomplete";
export * from "./Dropzone";

export * from "./DatePickerFields";
export * from "./NumberFields";
export * from "./SliderFields";
export * from "./TextFields";
export * from "./MuiSelectFields";
export * from "./SelectionField";
export * from "./CheckboxField";
export * from "./DropzoneFields";

export { FieldRoleData } from "./types";
export { FieldActionData } from "./types";

export {
	MuiAutocompleteField as AutocompleteField,
	MuiSelectField as SelectField
} from "./MuiSelectFields";

export {
	formatToParts,
	identityRecordOfFieldEditorOptions,
	identity,
	getFieldErrorProps,
	getFieldError
} from "./utils";
export {
	BaseFieldProps,
	HasItems,
	FieldEditorOptions,
	SectionEditorOptions
} from "./types";
export { useFormikOnError } from "./hooks";

export const EditorFields = {
	...EditorDatePickerFields,
	...EditorNumberFields,
	...EditorTextFields,
	...EditorSelectionFields,
	...EditorCheckboxFields,
	...EditorDropzoneFields,
	...EditorAutocompleteFields,
	...EditorSliderFields,
	AutocompleteField: EditorAutocompleteFields.MuiAutocompleteField,
	SelectField: EditorAutocompleteFields.MuiSelectField
};
