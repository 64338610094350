import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import SearchBar from "material-ui-search-bar";
import clsx from "clsx";
import { PendingIconButton } from "@ploy-ui/core";

import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { lighten, makeStyles } from "@material-ui/core/styles";

import DeleteIcon from "@material-ui/icons/Delete";

const messages = defineMessages({
	search: {
		id: "dploy.template-form.table-section.search.placeholder",
		descrption: "Search",
		defaultMessage: "Søk"
	}
});

const useToolbarStyles = makeStyles(theme => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1)
	},
	highlight:
		theme.palette.type === "light"
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85)
			  }
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark
			  },
	title: {
		flex: "1 1 100%"
	}
}));

export interface TableToolbarProps {
	title?: React.ReactNode;
	numSelected?: number;
	searchValue?: string;
	onSearch?: (query: string) => void;
	onDelete?: () => void | Promise<void>;
}

export function TableToolbar(props: TableToolbarProps) {
	const classes = useToolbarStyles();
	const {
		numSelected = 0,
		title,
		searchValue = "",
		onSearch,
		onDelete
	} = props;

	const intl = useIntl();

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0
			})}
		>
			{numSelected > 0 ? (
				<Typography
					className={classes.title}
					color="inherit"
					variant="subtitle1"
					component="div"
				>
					<FormattedMessage
						id="dploy.template-form.table-section.selected.title"
						description="{numSelected} selected"
						defaultMessage="{numSelected} valgt"
						values={{ numSelected }}
					/>
				</Typography>
			) : (
				<Typography
					className={classes.title}
					variant="h6"
					id="tableTitle"
					component="div"
				>
					{title}
				</Typography>
			)}

			{numSelected > 0 ? (
				<Tooltip title="Delete">
					<PendingIconButton
						aria-label="delete"
						onClick={onDelete}
						success={false}
						error={false}
					>
						<DeleteIcon />
					</PendingIconButton>
				</Tooltip>
			) : onSearch ? (
				<SearchBar
					placeholder={intl.formatMessage(messages.search)}
					value={searchValue}
					onChange={onSearch}
					onCancelSearch={() => onSearch("")}
					cancelOnEscape
				/>
			) : null}
		</Toolbar>
	);
}
