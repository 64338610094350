import React, { useEffect, useState } from "react";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { useIntl } from "react-intl";
import Section, { SectionProps } from "../Section";
import { PendingButton } from "@ploy-ui/core";
import {
	Grid,
	DialogContent,
	Dialog,
	DialogActions,
	DialogTitle,
	Button
} from "@material-ui/core";
import { useCalculation, useTemplateDebugManager } from "@ploy-lib/calculation";
import { useFormikContext, getIn } from "formik";
import { getFieldName } from "@ploy-lib/core";

addRegisteredSectionLayout({
	name: "DialogSection",
	displayName: "DialogSection",
	settings: {
		editableOptions: { showDialogButtons: true, fullWidthOpenButton: true }
	}
});

const messages = {
	closeDialogButtonLabel: {
		id: "dialogSection.close.button",
		defaultMessage: "Lukk",
		description: "label of close modal button"
	}
};

const DialogSection = (props: SectionProps) => {
	const {
		sectionTitle,
		showDialogButtons,
		fullWidthOpenButton,
		...rest
	} = props;
	const intl = useIntl();
	const { enabled: debugEnabled } = useTemplateDebugManager();
	const { errors } = useCalculation("errors");
	const { errors: formikErrors, touched: formikTouched } = useFormikContext();

	const [sectionError, setSectionError] = useState(false);
	const [isOpen, setOpen] = useState(!debugEnabled && !showDialogButtons);

	useEffect(() => {
		if (
			props.fields.some(field => {
				const name = getFieldName(field);
				const touched = getIn(formikTouched, name);
				const error = getIn(formikErrors, name);
				return touched && error;
			})
		) {
			setSectionError(true);
			setOpen(true);
		} else {
			setSectionError(false);
		}
	}, [
		formikErrors,
		formikTouched,
		errors,
		props.fields,
		setSectionError,
		setOpen
	]);

	const onClickHandler = () => setOpen(true);
	const onCloseHandler = () => setOpen(false);
	return (
		<>
			{showDialogButtons && (
				<Grid container justify="center" spacing={2}>
					<Grid item xs={fullWidthOpenButton ? 12 : undefined}>
						<PendingButton
							fullWidth={fullWidthOpenButton}
							pending={false}
							size="large"
							variant={"contained"}
							color={"secondary"}
							onClick={onClickHandler}
						>
							{sectionTitle}
						</PendingButton>
					</Grid>
				</Grid>
			)}
			<Dialog
				open={isOpen}
				onClose={onCloseHandler}
				disableBackdropClick={!showDialogButtons || sectionError}
				disableEscapeKeyDown={!showDialogButtons || sectionError}
			>
				<DialogTitle>{sectionTitle}</DialogTitle>
				<DialogContent>
					<Section {...rest} />
				</DialogContent>
				{showDialogButtons && !sectionError && (
					<DialogActions>
						<Button onClick={onCloseHandler} color="primary">
							{intl.formatMessage(messages.closeDialogButtonLabel)}
						</Button>
					</DialogActions>
				)}
			</Dialog>
		</>
	);
};

DialogSection.displayName = "DployDialogSection";

export { DialogSection };
