import React, { useState } from "react";
import { useStyles } from "./styles";
import { Grid, useTheme, useMediaQuery, styled } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { FormattedNumber, FormattedMessage } from "react-intl";
import clsx from "clsx";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { OverlayCircularProgress } from "@ploy-ui/core";
import { useNavigationState } from "../../NavigationContext";
import { AppLoadResource } from "@ploy-lib/rest-resources";
import { useInvalidator } from "@rest-hooks/core";
import { InputFieldProps } from "../../types";
import { ImageOrIcon } from "./ImageOrIcon";
import { useAppLoad } from "../../appLoadContext";

const DarkOverlayCircularProgress = styled(OverlayCircularProgress)({
	backgroundColor: "rgba(0, 0, 0, 0.3)"
});

interface InfoBlockProps {
	category: React.ReactNode;
	content: React.ReactNode;
}

export interface CarInfoSummaryProps extends InputFieldProps {
	small: boolean;
}

export enum ObjectGroups {
	Car = "car",
	Boat = "ship",
	Mc = "motorcycle",
	Caravan = "caravan"
}

const InfoBlock = (props: InfoBlockProps) => {
	const { category, content } = props;
	const classes = useStyles(props);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<Grid
			item
			container
			direction="row"
			justify={isMobile ? "space-between" : "flex-start"}
		>
			<Grid item md={6}>
				<Typography className={classes.cardText}>{category}</Typography>
			</Grid>
			<Grid item md={6}>
				<Typography className={classes.cardText} color="textSecondary">
					{content}
				</Typography>
			</Grid>
		</Grid>
	);
};

export function CarInfoSummary(props: CarInfoSummaryProps) {
	const {
		field: { value: infoString },
		small = false
	} = props;
	const classes = useStyles(props);
	const invalidateApp = useInvalidator(AppLoadResource.detail());
	const { settings } = useAppLoad();
	const canChangeObject = settings && settings.canChangeObject;

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const [pending, setPending] = useState(false);

	const navigation = useNavigationState();

	const changeObject = async () => {
		setPending(true);
		try {
			var result = await fetch(
				legacyApiResourceUrl(`/AppChange/ChangeObject`),
				{
					method: "POST"
				}
			);
			var data = await result.json();
			invalidateApp({
				applicationNumber: data.ApplicationNumber
			});
			setPending(false);

			navigation.application(data.ApplicationNumber);
		} catch (err: any) {
			setPending(false);
			throw Error("Error when changing object. " + err.message);
		}
	};

	var infoSplit = infoString.split(";");

	const info = {
		make: infoSplit[0] || "",
		model: infoSplit[1] || "",
		yearModel: infoSplit[2] || "",
		engine: infoSplit[3] || "",
		regNo: (infoSplit[4] && infoSplit[4].toUpperCase()) || "",
		kmTotal: Number.parseInt(infoSplit[5]) || 0,
		listPrice: Number.parseInt(infoSplit[6]) || 0,
		size: Number.parseInt(infoSplit[7]) || 0,
		horsepower: Number.parseInt(infoSplit[8]) || 0,
		imageUrl: infoSplit[9] || "",
		objectGroup: infoSplit[10] || "other" //From DT: Object_Options_Global
	};

	return (
		<div className={classes.root}>
			{pending && <DarkOverlayCircularProgress pending />}
			<Grid
				className={small ? classes.contentWrapperSmall : classes.contentWrapper}
			>
				{canChangeObject && (
					<Grid className={classes.changeCarLink} xs={12}>
						<p onClick={changeObject} className={classes.noMargin}>
							<FormattedMessage
								id="ploy-ui.core.carInfoSummary.changeCarObject_link.label"
								defaultMessage={`Ønsker du å endre på søknaden eller har funnet {objectGroup, select,
														car {en annen bil}
														ship {en annen båt}
														motorcycle {en annen motorsykkel}
														caravan {en annen caravan}
														other {et annet kjøretøy}
													}`}
								values={info}
							/>
						</p>
					</Grid>
				)}
				<Grid
					container
					spacing={isMobile ? 1 : 2}
					className={isMobile ? "" : classes.noWrap}
				>
					{(info.objectGroup !== "other" || info.imageUrl !== "") && (
						<Grid item className={isMobile ? classes.imageStretch : ""}>
							<ImageOrIcon
								src={info.imageUrl}
								alt={`${info.make} ${info.model}`}
								size={small ? "small" : "large"}
								icon={info.objectGroup}
								bgcolor="primary.main"
								iconColor="secondary"
							/>
						</Grid>
					)}
					{(!small || info.make) && (
						<Grid item container direction="column">
							{/* Make, Model, Year */}
							<Grid item container spacing={0}>
								{small ? (
									info.make && (
										<Grid>
											<Typography className={classes.carMakeModelHeaderSmall}>
												<FormattedMessage
													id="ploy-ui.core.carInfoSummary.small.carModel.label"
													description="Headertext for carmodel"
													defaultMessage="Modell"
													values={info}
												/>
											</Typography>
											<Typography className={classes.carMakeModelContentSmall}>
												<FormattedMessage
													id="ploy-ui.core.carInfoSummary.small.carModel.value"
													description="Value text for carModel"
													defaultMessage="{make} {model}"
													values={info}
												/>
											</Typography>
										</Grid>
									)
								) : (
									<Grid
										item
										container
										direction={isMobile ? "row" : "column"}
										style={isMobile ? { justifyContent: "space-between" } : {}}
									>
										<Typography className={classes.carMakeModelHeader}>
											<FormattedMessage
												id="ploy-ui.core.carInfoSummary.header"
												description="Car indo header"
												defaultMessage="{make} {model}"
												values={info}
											/>
										</Typography>
										<Typography
											className={clsx(classes.cardText, classes.carYearText)}
											color="textSecondary"
										>
											{info.yearModel && (
												<FormattedMessage
													id="ploy-ui.core.carInfoSummary.sub-header"
													description="Car indo sub header"
													defaultMessage="{yearModel}"
													values={info}
												/>
											)}
										</Typography>
									</Grid>
								)}
							</Grid>
							{/* RegNo. KmTotal, Price, ChangeCarObject */}
							{!small && (
								<Grid item>
									{isMobile && info.listPrice !== 0 && (
										<Typography
											className={clsx(classes.carPriceText, classes.cardText)}
										>
											<FormattedNumber
												value={info.listPrice}
												format="currency"
											/>
										</Typography>
									)}
									{info.regNo !== "" && (
										<InfoBlock
											category={
												<FormattedMessage
													id="ploy-ui.core.carInfoSummary.regnr.label"
													description="Label for reg number"
													defaultMessage="{objectGroup, select, boat {Kjennemerke} other {Reg.nr.}}"
													values={info}
												/>
											}
											content={
												<FormattedMessage
													id="ploy-ui.core.carInfoSummary.regnr.value"
													description="Label for reg number"
													defaultMessage="{regNo}"
													values={info}
												/>
											}
										/>
									)}
									{info.kmTotal !== 0 && (
										<InfoBlock
											category={
												<FormattedMessage
													id="ploy-ui.core.carInfoSummary.mileage.label"
													description="Label for mileage"
													defaultMessage="Km. stand"
													values={info}
												/>
											}
											content={
												<FormattedMessage
													id="ploy-ui.core.carInfoSummary.mileage.value"
													description="Value for mileage"
													defaultMessage="{kmTotal} km"
													values={info}
												/>
											}
										/>
									)}
									{info.size !== 0 && (
										<InfoBlock
											category={
												<FormattedMessage
													id="ploy-ui.core.carInfoSummary.size.label"
													description="Label for size in meters"
													defaultMessage="Lengde i meter"
													values={info}
												/>
											}
											content={
												<FormattedMessage
													id="ploy-ui.core.carInfoSummary.size.value"
													description="Value for size in meters"
													defaultMessage="{size} m"
													values={info}
												/>
											}
										/>
									)}
									{info.horsepower !== 0 && (
										<InfoBlock
											category={
												<FormattedMessage
													id="ploy-ui.core.carInfoSummary.horsepower.label"
													description="Label for boat horsepower"
													defaultMessage="Hestekrefter"
													values={info}
												/>
											}
											content={
												<FormattedMessage
													id="ploy-ui.core.carInfoSummary.horsepower.value"
													description="Value for horsepower"
													defaultMessage="{horsepower} hp"
													values={info}
												/>
											}
										/>
									)}
									{!isMobile && info.listPrice !== 0 && (
										<Grid item xs container direction="row">
											<Grid
												item
												className={clsx(classes.carPriceText, classes.cardText)}
											>
												<FormattedNumber
													value={info.listPrice}
													format="currency"
												/>
											</Grid>
										</Grid>
									)}
								</Grid>
							)}
						</Grid>
					)}
				</Grid>
			</Grid>
		</div>
	);
}
