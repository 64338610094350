import { BaseResource } from "./BaseResource";
import { ID } from "../types";
import { legacyApiResourceUrl } from "@ploy-lib/core";

export interface Person {
	readonly fullName: string;
}

export interface Driver extends Person {
	readonly salepersonId: number;
}

export interface Car {
	readonly carId: ID;
	readonly ordNr: string;
	readonly interior: string;
	readonly color: string;
	readonly description: string;
	readonly deliveryLocation: string;
}

export interface CarOrderDetails {
	readonly applicationId?: ID;
	readonly deliveryDate?: Date;
	readonly selectedDriver: Driver | Partial<Driver>;
	readonly avaiableDrivers: Driver[];
	readonly customer: Person;
	readonly car: Car;
	readonly disableSendOrderSection: boolean;
	readonly disableEditRegNumberButton: boolean;
	readonly confirmDate?: Date;
	readonly confirmUser: string;
	readonly carOrderConfirmed: boolean;
}

export class CarOrderDetailsResource extends BaseResource
	implements CarOrderDetails {
	readonly applicationId?: ID;
	readonly deliveryDate?: Date;
	readonly selectedDriver: Driver | Partial<Driver> = {};
	readonly avaiableDrivers: Driver[] = [];
	readonly customer: Person;
	readonly car: Car;
	readonly disableSendOrderSection: boolean;
	readonly disableEditRegNumberButton: boolean;
	readonly confirmDate?: Date;
	readonly confirmUser: string;
	readonly carOrderConfirmed: boolean;

	pk() {
		return this.applicationId?.toString();
	}

	static urlRoot = legacyApiResourceUrl("CarOrder/Details");
}
