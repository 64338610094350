import React, { memo, useCallback, useState, useMemo } from "react";
import { PendingButton, PendingButtonProps } from "@ploy-ui/core";
import { download } from "@ploy-lib/core";

import { usePageState } from "../../PageContext";
import { useNavigationState } from "../../NavigationContext";

import Send from "@material-ui/icons/Send";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";
import MoreVert from "@material-ui/icons/MoreVert";
import { Modal, Paper, makeStyles, Theme } from "@material-ui/core";
import { useFormHasErrors } from "../../components/FormStatus";
import {
	identityRecordOfFieldEditorOptions,
	DployFormControl,
	getFieldErrorProps,
	FieldRoleData
} from "@ploy-ui/form-fields";
import { Icon, Box, Button, FormHelperText } from "@material-ui/core";
import { styled } from "@material-ui/core";

import { AppActions } from "../../components/AppActions";
import { touchAllVisibleFields } from "../../components/TouchVisibleFieldsOnPreviousPages";
import { usePages } from "../../pagesContext";
import {
	isButtonColor,
	isButtonVariant,
	ButtonFieldProps,
	commonButtonColors,
	commonButtonVariants
} from "./ButtonCommon";
import { OverlayCircularProgress } from "@ploy-ui/core";
import { defineMessages, FormattedMessage } from "react-intl";
import marked from "@ploy-lib/purify-marked";
import { getIn } from "formik";
import {
	useTemplateFieldIsVisible,
	useTemplateSectionIsVisible
} from "../../hooks";
import clsx from "clsx";

export type ActionRole =
	| "navigate_before"
	| "navigate_next"
	| "navigate_home"
	| "submit"
	| "submit_no_validate"
	| "appActions"
	| "validateForm";

type Extends<T, U extends T> = U;

type SelectableActionRoles = Extends<
	ActionRole,
	| "navigate_before"
	| "navigate_next"
	| "navigate_home"
	| "submit_no_validate"
	| "submit"
	| "validateForm"
>;

const buttonRoleLocalization = defineMessages<SelectableActionRoles>({
	navigate_before: {
		id: "buttonRoleScope.navigate_before",
		defaultMessage: "Navigate to previous"
	},
	navigate_next: {
		id: "buttonRoleScope.navigate_next",
		defaultMessage: "Navigate to next"
	},
	submit: {
		id: "buttonRoleScope.submit",
		defaultMessage: "Submit"
	},
	submit_no_validate: {
		id: "buttonRoleScope.submit_no_validate",
		defaultMessage: "Submit, no validation"
	},
	navigate_home: {
		id: "buttonRoleScope.navigate_home",
		defaultMessage: "Navigate to home"
	},
	validateForm: {
		id: "buttonRoleScope.validateForm",
		defaultMessage: "Validate form"
	}
});

const DarkOverlayCircularProgress = styled(OverlayCircularProgress)({
	background: "rgba(0, 0, 0, 0.3)"
});

const useStyles = makeStyles((theme: Theme) => ({
	modal: {
		position: "absolute",
		width: "100%",
		backgroundColor: "rgba(0,0,0,0.5)",
		padding: theme.spacing(3),
		display: "flex",
		overflowY: "auto"
	},
	modalContainer: {
		margin: "auto"
	},
	modalContent: { padding: 30, width: 350 },
	modalConfirmButton: {
		marginTop: 10,
		width: "100%",
		backgroundColor: "lightgray"
	},
	buttonAsText: {
		margin: 0
	}
}));
function ButtonFieldImpl({
	className,
	label,
	color,
	variant,
	role,
	icon,
	form,
	field,
	onClick,
	fullWidth,
	disabled,
	pending,
	success,
	margin,
	startIcon,
	endIcon,
	showOverlay,
	errorDisplay
}: ButtonFieldProps) {
	const buttonColor = isButtonColor(color) ? color : undefined;
	const buttonVariant = isButtonVariant(variant) ? variant : undefined;

	const pages = usePages();
	const { next, prev, step, isLastStep } = usePageState();
	const formHasErrors = useFormHasErrors();

	const hasFormErrors = useMemo(() => formHasErrors(form, undefined, true), [
		formHasErrors,
		form
	]);

	const errorProps = useMemo(
		() => getFieldErrorProps({ field, form, errorDisplay }),
		[errorDisplay, field, form]
	);

	const hasPageErrors = useMemo(
		() => formHasErrors(form, pages.slice(0, step + 1), false),
		[formHasErrors, form, pages, step]
	);

	const [appActionsAnchor, setAppActionsAnchor] = useState<
		Element | ((element: Element) => Element) | null | undefined
	>();

	const fieldIsVisible = useTemplateFieldIsVisible();
	const sectionIsVisible = useTemplateSectionIsVisible();
	const {
		touched,
		setTouched,
		errors,
		setErrors,
		setFieldValue,
		submitForm,
		setSubmitting
	} = form;

	const navigateNext = useCallback(() => {
		const relevantTouched = touchAllVisibleFields(
			touched,
			pages.slice(0, step + 1),
			fieldIsVisible,
			sectionIsVisible
		);

		setTouched(relevantTouched);
		if (
			!formHasErrors(
				{ ...form, touched: relevantTouched },
				pages.slice(0, step + 1),
				false
			)
		)
			next();
	}, [
		fieldIsVisible,
		form,
		formHasErrors,
		next,
		pages,
		sectionIsVisible,
		setTouched,
		step,
		touched
	]);

	const validateForm = useCallback(
		async e => {
			const allTouched = touchAllVisibleFields(
				touched,
				pages,
				fieldIsVisible,
				sectionIsVisible
			);

			setTouched(allTouched);

			if (formHasErrors({ errors, touched: allTouched }, undefined, true)) {
				throw new Error("Failed to validate");
			}

			if (onClick) return onClick(e) as any;
		},
		[
			errors,
			fieldIsVisible,
			formHasErrors,
			onClick,
			pages,
			sectionIsVisible,
			setTouched,
			touched
		]
	);

	const submit = useCallback(
		async (shouldValidate: Boolean, e) => {
			setSubmitting(true);

			const allTouched = touchAllVisibleFields(
				touched,
				pages,
				fieldIsVisible,
				sectionIsVisible
			);

			if (shouldValidate) {
				setTouched(allTouched);

				if (formHasErrors({ errors, touched: allTouched }, undefined, true)) {
					setSubmitting(false);
					throw new Error("Failed to validate");
				}
			}

			if (onClick) {
				const result = (await onClick(e)) as any;

				if (result.ok && result.data) {
					setFieldValue("__calculation.submitResult", result.data);
					submitForm();
				} else {
					setSubmitting(false);
					throw new Error("Failed to validate");
				}
			} else {
				submitForm();
			}

			if (!shouldValidate) {
				setTouched(touched);
				setErrors(errors);
			}
		},
		[
			errors,
			fieldIsVisible,
			formHasErrors,
			onClick,
			pages,
			sectionIsVisible,
			setErrors,
			setFieldValue,
			setSubmitting,
			setTouched,
			submitForm,
			touched
		]
	);

	const navigation = useNavigationState();

	const roleClickMap: Record<ActionRole, typeof onClick> = {
		navigate_before: prev,
		navigate_next: () => {
			navigateNext();
		},
		navigate_home: () => navigation.home(),
		submit: useCallback(async e => submit(true, e), [submit]),
		submit_no_validate: useCallback(async e => submit(false, e), [submit]),
		appActions: ({ currentTarget }) => setAppActionsAnchor(currentTarget),
		validateForm: validateForm
	};

	const labelIcon =
		icon && typeof icon === "string" ? <Icon className={icon} /> : icon;

	const roleIconMap: Record<
		ActionRole,
		Pick<PendingButtonProps, "endIcon" | "startIcon">
	> = {
		navigate_before: {
			startIcon: labelIcon ?? <NavigateBefore />
		},
		navigate_next: {
			endIcon: labelIcon ?? <NavigateNext />
		},
		navigate_home: {},
		submit: {
			endIcon: labelIcon ?? <Send />
		},
		submit_no_validate: {
			endIcon: labelIcon ?? <Send />
		},
		appActions: {
			endIcon: labelIcon ?? <MoreVert />
		},
		validateForm: { endIcon: labelIcon ?? <Send /> }
	};

	const roleDisabledMap: Record<ActionRole, boolean | undefined> = {
		navigate_before: step <= 0,
		navigate_next: isLastStep || hasPageErrors,
		navigate_home: !isLastStep,
		submit: disabled || hasFormErrors,
		submit_no_validate: disabled,
		appActions: !isLastStep,
		validateForm: hasFormErrors
	};

	const iconProps: Pick<PendingButtonProps, "endIcon" | "startIcon"> =
		startIcon || endIcon
			? {
					startIcon,
					endIcon
			  }
			: (role && roleIconMap[role]) ?? {
					endIcon: labelIcon
			  };
	return (
		<DployFormControl
			margin={margin as any}
			fullWidth={fullWidth}
			className={className}
		>
			{(role === "submit" || showOverlay) && pending && (
				<DarkOverlayCircularProgress pending />
			)}

			<PendingButton
				onClick={(role && roleClickMap[role]) || onClick}
				pending={pending && role !== "submit"}
				success={role === "submit" ? false : success}
				error={role === "submit" ? false : errorProps?.error}
				helperText={errorProps?.helperText}
				fullWidth={fullWidth}
				color={buttonColor}
				variant={buttonVariant}
				disabled={(role && roleDisabledMap[role]) || disabled}
				size="large"
				{...iconProps}
			>
				{label}
			</PendingButton>
			{role === "appActions" && isLastStep && (
				<AppActions
					form={form}
					anchor={appActionsAnchor}
					setAnchor={setAppActionsAnchor}
				/>
			)}
		</DployFormControl>
	);
}

ButtonFieldImpl.displayName = "ButtonField";
export const ButtonField = memo(ButtonFieldImpl);

function ButtonLinkImpl(props: ButtonFieldProps) {
	const {
		label,
		color,
		variant,
		icon,
		field,
		form,
		onClick,
		fullWidth,
		disabled,
		options: { target },
		errorDisplay,
		margin,
		modalText,
		startIcon,
		endIcon,
		mailto,
		textAlign
	} = props;
	const { value, name } = field;

	const errorProps = getFieldErrorProps(props, errorDisplay);
	const error = getIn(form.errors, name);

	const buttonColor = isButtonColor(color) ? color : undefined;
	const buttonVariant = isButtonVariant(variant) ? variant : undefined;

	const [success, setSuccess] = useState(false);
	const [responseError, setResponseError] = useState<string>();
	const [modalOpen, setModalOpen] = useState(false);

	const classes = useStyles();

	const labelIcon =
		icon && typeof icon === "string" ? <Icon className={icon} /> : icon;

	const onClickHandler: typeof onClick = async e => {
		form.setFieldTouched(name, true);
		setResponseError(undefined);
		setSuccess(false);

		if (!onClick || error) return;

		try {
			const response: any = await onClick(e);

			if (response && response.data instanceof Blob) {
				download(response.data);
			}
			if (response && response.data && response.data.Error)
				setResponseError(response.data.Error);
			else setSuccess(true);
		} catch (e: any) {
			console.error(e);
		}
	};

	const button = mailto ? (
		value ? (
			<a style={{ textAlign: textAlign }} href={"mailto: " + value}>
				{value}
			</a>
		) : (
			<></>
		)
	) : (
		<PendingButton
			color={buttonColor}
			variant={buttonVariant}
			fullWidth={fullWidth}
			disabled={disabled || errorProps?.error}
			size="large"
			onClick={e => {
				if (modalText) setModalOpen(true);
				else return onClickHandler(e);
			}}
			success={false}
			startIcon={startIcon}
			endIcon={endIcon ?? labelIcon}
		>
			{label}
		</PendingButton>
	);

	const helperText =
		(success && value) ||
		(errorProps?.error && errorProps.helperText) ||
		responseError;

	return (
		<DployFormControl
			className={clsx({ [classes.buttonAsText]: buttonVariant === "text" })}
			error={errorProps?.error || Boolean(responseError)}
			margin={margin as any}
			fullWidth={fullWidth}
		>
			<Modal
				className={classes.modal}
				open={modalOpen}
				onClose={() => setModalOpen(false)}
			>
				<Paper className={classes.modalContainer}>
					<div className={classes.modalContent}>
						<div
							dangerouslySetInnerHTML={{
								__html: marked(modalText || "")
							}}
						/>
						<Button
							className={classes.modalConfirmButton}
							onClick={e => {
								setModalOpen(false);
								onClickHandler(e);
							}}
						>
							<FormattedMessage
								id="ploy-ui.template-form.buttons.buttonlink.confirm"
								defaultMessage="Aksepter"
								description="Confirm button for download modal"
							/>
						</Button>
					</div>
				</Paper>
			</Modal>
			{button}
			{helperText && (
				<Box
					component={FormHelperText}
					textAlign="center"
					color={
						errorProps?.error || Boolean(responseError)
							? "error.main"
							: success
							? "success.main"
							: undefined
					}
				>
					{helperText}
				</Box>
			)}
		</DployFormControl>
	);
}

ButtonLinkImpl.displayName = "ButtonLink";
export const ButtonLink = memo(ButtonLinkImpl);

const preparedButtonRoles: FieldRoleData[] = Object.entries(
	buttonRoleLocalization
).map(([k, v]) => ({
	name: k,
	localization: v
}));

export const EditorButtonFields = identityRecordOfFieldEditorOptions({
	ButtonField: {
		fieldRoles: preparedButtonRoles,
		editableOptions: {
			variant: commonButtonVariants,
			color: commonButtonColors
		}
	},
	ButtonLink: {
		fieldRoles: preparedButtonRoles,
		editableOptions: {
			variant: commonButtonVariants,
			color: commonButtonColors,
			mailto: true,
			modalText: true
		}
	}
});

export default ButtonField;
