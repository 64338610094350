import React, {
	forwardRef,
	useState,
	useCallback,
	useEffect,
	useRef
} from "react";
import { TextFieldProps as FormikTextFieldProps } from "formik-material-ui";
import {
	IntlDateTimePicker,
	IntlOptions,
	PickerProps,
	IntlDatePicker,
	IntlTimePicker,
	IntlKeyboardDatePicker,
	IntlKeyboardTimePicker,
	IntlKeyboardDateTimePicker
} from "./helpers/IntlDateTimePicker";
import { BaseFieldProps, Omit } from "./types";
import "@date-io/luxon";
import { DateType } from "@date-io/type";
import {
	identityRecordOfFieldEditorOptions,
	getFieldErrorProps
} from "./utils";

export type PickerFieldProps<TPicker extends PickerProps> = Omit<
	IntlOptions & TPicker,
	"value" | "onChange" | "variant" | "inputVariant"
> &
	FormikTextFieldProps &
	BaseFieldProps & {
		pickerVariant: TPicker["variant"];
	};

function isDateType(value: any): value is DateType {
	return value != null && typeof (value as DateType).toISOTime === "function";
}

const parseDate = (value: any) =>
	isDateType(value) ? value.toISODate() : value;

const parseTime = (value: any) =>
	isDateType(value) ? value.toISOTime() : value;

const parseDateTime = (value: any) =>
	isDateType(value) ? value.toISO() : value;

const useFieldToIntlPickerField = <TPicker extends PickerProps>(
	props: PickerFieldProps<TPicker>,
	parser: (value: any) => any
): TPicker => {
	const {
		options,
		items,
		getItemValue,
		getItemLabel,
		getItemId,
		valueKey,
		labelKey,
		getItemSuggestions,
		onSelectItem,
		manual,
		horizontal: row,
		multiple,
		pending,
		form,
		field,
		disabled,
		pickerVariant,
		variant,
		onAccept: propsOnAccept,
		emptyEqualsZero,
		allowNull,
		formatString,
		secondaryLabel,
		italicText,
		boldText,
		modalText,
		onBlur: propsBlur,
		errorDisplay,
		...rest
	} = props;
	const { name, value, onBlur: formikBlur } = field;

	const { isSubmitting, setFieldError, setFieldValue, setFieldTouched } = form;

	const [inputValue, setInputValue] = useState("");

	const onChange = useCallback(
		(date: DateType | null, value?: string) => {
			if (typeof value !== "undefined") setInputValue(value);

			if (!(allowNull && date == null) && (date == null || !date.isValid)) {
				setFieldValue(name, "");
			} else {
				setFieldValue(name, parser(date));
			}
		},
		[allowNull, name, parser, setFieldValue]
	);

	const errorProps = getFieldErrorProps(props, errorDisplay);

	const fieldErrorRef = useRef(
		errorProps?.error ? errorProps.helperText : undefined
	);
	useEffect(() => {
		fieldErrorRef.current = errorProps?.error
			? errorProps.helperText
			: undefined;
	});

	const onError = useCallback(
		(error, value) => {
			const newError = error || undefined;
			if (newError !== fieldErrorRef.current) setFieldError(name, newError);
		},
		[setFieldError, name]
	);

	const onAccept = useCallback(
		async date => {
			if (propsOnAccept) propsOnAccept(date);

			await new Promise(r => setTimeout(r, 50)); // https://github.com/jaredpalmer/formik/issues/2457
			setFieldTouched(name, true);
		},
		[name, propsOnAccept, setFieldTouched]
	);

	const onBlur = useCallback(
		(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			formikBlur && formikBlur(e);
			propsBlur && propsBlur(e);
		},
		[formikBlur, propsBlur]
	);

	return {
		...rest,
		...field,
		...errorProps,
		onBlur,
		disabled: isSubmitting || disabled,
		value,
		inputValue,
		onChange,
		onAccept,
		onError,
		variant: pickerVariant,
		inputVariant: variant
	} as TPicker;
};

const makePickerField = <TPicker extends PickerProps>(
	Picker: React.ComponentType<TPicker>,
	formatType: string,
	parser: (value: any) => any
) =>
	forwardRef<any, PickerFieldProps<TPicker>>((props, ref) => (
		<Picker
			formatType={formatType}
			{...useFieldToIntlPickerField(props, parser)}
			ref={ref}
		/>
	));

export const DatePickerField = makePickerField(
	IntlDatePicker,
	"date",
	parseDate
);
export const TimePickerField = makePickerField(
	IntlTimePicker,
	"time",
	parseTime
);
export const DateTimePickerField = makePickerField(
	IntlDateTimePicker,
	"date",
	parseDateTime
);
export const KeyboardDatePickerField = makePickerField(
	IntlKeyboardDatePicker,
	"date",
	parseDate
);
export const KeyboardTimePickerField = makePickerField(
	IntlKeyboardTimePicker,
	"time",
	parseTime
);
export const KeyboardDateTimePickerField = makePickerField(
	IntlKeyboardDateTimePicker,
	"date",
	parseDateTime
);

export const EditorDatePickerFields = identityRecordOfFieldEditorOptions({
	DatePickerField: {},
	TimePickerField: {},
	DateTimePickerField: {}
});

export { useUtils as useDateUtils } from "@material-ui/pickers";

DatePickerField.displayName = "DployDatePickerField";
TimePickerField.displayName = "DployTimePickerField";
DateTimePickerField.displayName = "DployDateTimePickerField";
KeyboardDatePickerField.displayName = "DployKeyboardDatePickerField";
KeyboardTimePickerField.displayName = "DployKeyboardTimePickerField";
KeyboardDateTimePickerField.displayName = "DployKeyboardDateTimePickerField";
