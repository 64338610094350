import React, {
	Suspense,
	useCallback,
	useEffect,
	useMemo,
	useState
} from "react";
import { CalculationForm } from "@ploy-ui/calculation-form";
import {
	ProductResource,
	ProductFilter,
	ProductCategory,
	MinimalInitialData
} from "@ploy-lib/rest-resources";
import { NetworkErrorBoundary } from "rest-hooks";
import { useResource, useInvalidator } from "@rest-hooks/core";
import { ProductSelector } from "./ProductSelector";
import { renderTemplateWithRouteState } from "./renderTemplate";
import { parsedQueryProps, RouteComponentProps } from "@ploy-lib/routing";
import { NoMatch } from "@ploy-ui/core";
import {
	MissingPage,
	useNavigationState,
	useAppActionState,
	AppActionProvider
} from "@ploy-ui/template-form";
import {
	getBasepath,
	interpolateParams,
	validApplicationNumber
} from "../../utils";
import { RouteNavigationProvider } from "../../components/RouteNavigationProvider";
import isEqual from "lodash/isEqual";

export interface ProductViewProps {
	externalCode?: string;
	category?: ProductCategory;
	enableSelect?: boolean;
	formContext?: string;
	context?: string;
	initialize?: boolean;
	wholesale?: boolean;
	onSubmit?: (values: any, form: any) => void;
	disallowedFieldRoles?: readonly string[];
	onSubmitResult?: (submitResult: any) => void;
	newapplication?: boolean;
	externalPostback?: (result: any) => void;
}

export const ProductView = (props: RouteComponentProps<ProductViewProps>) => {
	const { externalPostback, ...rest } = props;

	return (
		<RouteNavigationProvider {...props}>
			<AppActionProvider externalPostback={externalPostback}>
				<ProductViewInternal {...rest} />
			</AppActionProvider>
		</RouteNavigationProvider>
	);
};

export const ProductViewInternal = (
	props: RouteComponentProps<ProductViewProps>
) => {
	const {
		navigate,
		location,
		onSubmit,
		path = "",
		disallowedFieldRoles
	} = props;
	const {
		externalCode,
		category,
		enableSelect,
		formContext,
		context,
		wholesale,
		initialize = true,
		ext: initialDataFromQuerystring,
		newapplication
	} = parsedQueryProps(
		props as RouteComponentProps<
			ProductViewProps & {
				ext?: MinimalInitialData;
			}
		>
	);
	const [initialData, setInitialData] = useState(initialDataFromQuerystring);
	useEffect(() => {
		if (!isEqual(initialData, initialDataFromQuerystring))
			setInitialData(initialDataFromQuerystring);
	}, [initialData, initialDataFromQuerystring]);

	const hasRouteState = navigate && location;

	const navigation = useNavigationState();
	const appAction = useAppActionState();

	const productFilter: ProductFilter = useMemo(() => {
		const filter: ProductFilter = {
			active: true,
			creatable: true,
			category,
			wholesale,
			newapplication
		};

		if (formContext?.toLowerCase() === "calculator") {
			filter.calculator = true;
		}

		return filter;
	}, [category, wholesale, formContext, newapplication]);

	const products = useResource(ProductResource.list(), productFilter);
	const invalidateProducts = useInvalidator(ProductResource.list());

	useEffect(
		() => () => {
			//when externalCode is undefined, menuItem has been clicked and we need to invalidate
			if (!externalCode) invalidateProducts(productFilter);
		},
		[invalidateProducts, productFilter, externalCode]
	);

	const product =
		externalCode &&
		products.find(
			p => p.externalCode.toLowerCase() === externalCode.toLowerCase()
		);

	const basepath = getBasepath(props);

	const handleSubmit = useCallback(
		(values, form) => {
			const { __calculation: { submitResult } = {} as any } = values;

			if (submitResult?.DoPostBack && appAction.externalPostback) {
				appAction.externalPostback(submitResult);
			} else {
				if (
					submitResult &&
					validApplicationNumber(submitResult.ApplicationNumber)
				) {
					navigation.application(submitResult.ApplicationNumber, true);
				} else if (product && productFilter.calculator) {
					//navigate from calculator to application
					navigation.applicationFromCalculator(
						product.category.toLocaleLowerCase(),
						product.externalCode.toLocaleLowerCase()
					);
				}
			}
			form.setSubmitting(false);
		},
		[appAction, navigation, product, productFilter.calculator]
	);

	if (!product || !product.id) {
		if (!enableSelect || products.length === 0)
			throw new Error("404: Not Found");

		const externalCode = products[0].externalCode.toLowerCase();

		const newPath = path.includes("externalCode")
			? interpolateParams({ ...props, externalCode })
			: `${interpolateParams({ ...props })}/${externalCode}`;

		return <NoMatch {...props} noThrow to={`${basepath}/${newPath}`} />;
	}

	const header = (
		<ProductSelector
			products={products}
			selected={product}
			disabled={!enableSelect}
			{...props}
		/>
	);

	const productExternalCode = (
		externalCode || product.externalCode
	).toLocaleUpperCase();

	return (
		<Suspense fallback={<MissingPage loading header={header} />}>
			<NetworkErrorBoundary
				key={productExternalCode}
				fallbackComponent={({ error }) => (
					<MissingPage error={error} header={header} />
				)}
			>
				<CalculationForm
					productExternalCode={productExternalCode}
					onSubmit={onSubmit || handleSubmit}
					header={header}
					formContext={formContext}
					context={context}
					skipInitialize={!initialize}
					initialData={initialData}
					disallowedFieldRoles={disallowedFieldRoles}
				>
					{hasRouteState && renderTemplateWithRouteState}
				</CalculationForm>
			</NetworkErrorBoundary>
		</Suspense>
	);
};
