import React, { useState } from "react";
import {
	Button,
	MuiThemeProvider,
	AppBar,
	Toolbar,
	Box,
	SwipeableDrawer
} from "@material-ui/core";
import { Panel } from "../";
import { FormattedMessage } from "react-intl";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useStyles, expandedFooterTheme, minimizedFooterTheme } from "./styles";
import clsx from "clsx";
import { PanelProps } from "../Panel";
import { useCalculation } from "@ploy-lib/calculation";
import { getIn, useFormikContext } from "formik";
import { getFieldName } from "@ploy-lib/core";

export const StickyFooter = (props: {
	innerRef: React.Ref<HTMLInputElement>;
	stickyFooter?: PanelProps;
	stickyFooterExpanded?: PanelProps;
}) => {
	const { innerRef, stickyFooter, stickyFooterExpanded } = props;
	const [expanded, setExpanded] = useState(false);
	const classes = useStyles(props);
	const formik = useFormikContext();

	const { errors } = useCalculation("errors");

	const canExpand =
		stickyFooterExpanded &&
		stickyFooterExpanded.sections &&
		stickyFooterExpanded.sections.length > 0;

	if (
		(stickyFooter &&
			stickyFooter.sections &&
			stickyFooter.sections.length === 0 &&
			!canExpand) ||
		(!canExpand && !stickyFooter)
	) {
		return null;
	}

	const onSave = () => {
		for (let section of stickyFooterExpanded!.sections!) {
			for (let field of section.fields!) {
				const name = getFieldName(field);
				formik.setFieldTouched(name, true);

				if (getIn(errors, name)) {
					return;
				}
			}
		}
		setExpanded(false);
	};

	return (
		<>
			{/* Sticky footer */}
			<AppBar
				className={clsx(classes.root, classes.appBarBottom)}
				elevation={stickyFooter && stickyFooter.elevation}
				square={stickyFooter && stickyFooter.square}
				position="fixed"
				color="inherit"
				id="stickyFooterContainerMinimized"
				ref={innerRef}
			>
				<Toolbar>
					{canExpand && (
						<Box>
							<Button
								classes={{
									label: classes.openButtonLabel,
									endIcon: classes.openButtonEndIcon,
									text: classes.openButtonText
								}}
								color="primary"
								onClick={() => setExpanded(true)}
								endIcon={<ExpandLessIcon />}
							>
								<FormattedMessage
									id="sticky-footer.show-details-button"
									defaultMessage="Gjør endringer"
								/>
							</Button>
						</Box>
					)}
					<Box flexGrow={[1, 1, 0]} />
					<Box p={1} flexGrow={1}>
						{stickyFooter && (
							<MuiThemeProvider theme={minimizedFooterTheme}>
								<Panel {...stickyFooter} transparent />
							</MuiThemeProvider>
						)}
					</Box>
				</Toolbar>
			</AppBar>

			{/* Expanded sticky footer */}
			{canExpand && (
				<SwipeableDrawer
					PaperProps={{
						elevation: stickyFooterExpanded && stickyFooterExpanded.elevation,
						square: stickyFooterExpanded && stickyFooterExpanded.square
					}}
					classes={{
						paper: clsx(classes.root)
					}}
					anchor="bottom"
					open={expanded}
					onClose={onSave}
					onOpen={() => setExpanded(true)}
					disableSwipeToOpen
				>
					<AppBar
						className={classes.appBarHeader}
						position="static"
						color="inherit"
					>
						<Toolbar className={classes.saveButton}>
							<Button onClick={onSave}>
								<FormattedMessage
									id="sticky-footer-expanded.summary-header.close"
									description="Label close button"
									defaultMessage="Lagre"
								/>
							</Button>
						</Toolbar>
					</AppBar>
					{stickyFooterExpanded && (
						<MuiThemeProvider theme={expandedFooterTheme}>
							<Panel {...stickyFooterExpanded} transparent />
						</MuiThemeProvider>
					)}
				</SwipeableDrawer>
			)}
		</>
	);
};
