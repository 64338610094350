import {
	getContrastRatio,
	darken,
	lighten,
	Theme
} from "@material-ui/core/styles";
import merge from "lodash/merge";

export function colorOverride(theme: Theme): Theme {
	const contrastThreshold = 2.5;

	const primaryTooLight =
		getContrastRatio(theme.palette.primary.main, theme.palette.common.white) <
		contrastThreshold;

	const secondaryTooLight =
		getContrastRatio(theme.palette.secondary.main, theme.palette.common.white) <
		contrastThreshold;

	if (primaryTooLight || secondaryTooLight) {
		// Same as in MuiLinearProgress implementation
		const getColor = (color: string) =>
			theme.palette.type === "light"
				? lighten(color, 0.62)
				: darken(color, 0.5);

		const primary = primaryTooLight
			? theme.palette.text.primary
			: theme.palette.primary.main;
		const backgroundPrimary = getColor(primary);

		const secondary = secondaryTooLight
			? theme.palette.text.secondary
			: theme.palette.secondary.main;
		const backgroundSecondary = getColor(secondary);

		const overrideColors: Partial<Theme> = {
			overrides: {
				MuiCheckbox: {
					colorPrimary: {
						"&$checked": {
							color: primary
						}
					},
					colorSecondary: {
						"&$checked": {
							color: secondary
						}
					}
				},
				MuiFormLabel: {
					root: {
						"&$focused": {
							color: primary
						}
					},
					colorSecondary: {
						"&$focused": {
							color: secondary
						}
					}
				},
				MuiOutlinedInput: {
					root: {
						"&$focused $notchedOutline": {
							borderColor: primary
						}
					},
					colorSecondary: {
						"&$focused $notchedOutline": {
							borderColor: secondary
						}
					}
				},
				MuiInput: {
					underline: {
						"&::after": {
							borderBottomColor: primary
						}
					},
					colorSecondary: {
						"&$underline::after": {
							borderBottomColor: secondary
						}
					}
				},
				MuiFilledInput: {
					underline: {
						"&::after": {
							borderBottomColor: primary
						}
					},
					colorSecondary: {
						"&$underline::after": {
							borderBottomColor: secondary
						}
					}
				},
				MuiBottomNavigationAction: {
					root: {
						"&$selected": {
							color: primary
						}
					}
				},
				MuiButton: {
					textPrimary: {
						color: primary
					}
				},
				MuiCircularProgress: {
					colorPrimary: {
						color: primary
					},
					colorSecondary: {
						color: secondary
					}
				},
				MuiLinearProgress: {
					colorPrimary: {
						backgroundColor: backgroundPrimary
					},
					colorSecondary: {
						backgroundColor: backgroundSecondary
					},
					dashedColorPrimary: {
						backgroundImage: `radial-gradient(${backgroundPrimary} 0%, ${backgroundPrimary} 16%, transparent 42%)`
					},
					dashedColorSecondary: {
						backgroundImage: `radial-gradient(${backgroundSecondary} 0%, ${backgroundSecondary} 16%, transparent 42%)`
					},
					barColorPrimary: {
						backgroundColor: primary
					},
					barColorSecondary: {
						backgroundColor: secondary
					}
				},
				DployOverlayCircularProgress: {
					content: {
						color: primary
					}
				},
				MuiLink: {
					root: {
						color: primary
					}
				}
			}
		};

		theme = merge({}, theme, overrideColors);
	}

	return theme;
}
