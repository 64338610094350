import React from "react";
import { identityRecordOfFieldEditorOptions } from "@ploy-ui/form-fields";
import marked from "@ploy-lib/purify-marked";

interface MarkdownFieldProps {
	field: { value: any };
}

export const MarkdownField = (props: MarkdownFieldProps) => {
	const {
		field: { value }
	} = props;

	return (
		<div
			dangerouslySetInnerHTML={{
				__html: marked(value) || ""
			}}
		/>
	);
};

export const EditorMarkdownFields = identityRecordOfFieldEditorOptions({
	MarkdownField: {
		editableOptions: {
			mailto: true,
			placeholder: true
		}
	}
});
