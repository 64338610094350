import React from "react";
import clsx from "clsx";

import Icon from "@material-ui/core/Icon";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useMatch } from "@reach/router";
import { autocolor } from "@ploy-ui/core";
import { useDrawer } from "../../../contexts";
import { ListItemSecondaryAction, MenuItem } from "@material-ui/core";

const useStyles = makeStyles(
	theme => ({
		root: {
			"&:not($selected)": {
				color: props =>
					autocolor({
						bgcolor: props.color,
						emphasizeText: 0.29,
						autoContrastText: true,
						theme
					}).color
			}
		},
		collapsed: {},
		icon: {
			color: "inherit",
			display: "flex",
			justifyContent: "center"
		},
		faIcon: {
			overflow: "visible",
			fontSize: "20px"
		},
		primary: {
			color: "inherit",
			fontSize: "inherit",
			fontWeight: "inherit",
			[theme.breakpoints.between("xs", "md")]: {
				textAlign: "center",
				whiteSpace: "pre-wrap"
			}
		},
		primaryOpen: {
			textAlign: "left"
		},
		secondary: {
			color: "inherit",
			fontSize: "inherit",
			fontWeight: "inherit"
		},
		text: {},
		selected: () => {}
	}),
	{ name: "MenuItemLink" }
);

export const MenuItemNavLink = props => {
	const {
		primary,
		secondary,
		className,
		iconClass,
		icon,
		children,
		secondaryAction,
		...rest
	} = props;

	const classes = useStyles(props);

	const { isOpen } = useDrawer();

	const match = useMatch(`${rest.to}/*`);

	const selected = Boolean(match);

	return (
		<MenuItem
			selected={selected}
			{...rest}
			className={clsx(classes.root, className, {
				[classes.collapsed]: !isOpen
			})}
			classes={{ selected: classes.selected }}
			component={rest.to ? Link : rest.href && "a"}
		>
			{iconClass || icon ? (
				<ListItemIcon className={clsx(classes.icon)}>
					{icon ?? (
						<Icon className={clsx(`fa fa-${iconClass}`, classes.faIcon)} />
					)}
				</ListItemIcon>
			) : undefined}
			<ListItemText
				inset={!icon && !iconClass}
				classes={{
					primary: clsx({ [classes.primaryOpen]: isOpen }, classes.primary),
					secondary: clsx(classes.secondary)
				}}
				className={classes.text}
				primary={primary}
				secondary={secondary}
			/>
			{children}
			{selected && secondaryAction && isOpen ? (
				<ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>
			) : (
				selected && isOpen && <ChevronRightIcon />
			)}
		</MenuItem>
	);
};
