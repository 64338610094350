import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import { RowProps } from "@ploy-ui/core";
import { ApplicationInfo, CarOrderDetails } from "@ploy-lib/rest-resources";

const useStyles = makeStyles(theme => ({
	root: {
		whiteSpace: "pre-line" as "pre-line",
		padding: theme.spacing(4),
		margin: theme.spacing(2)
	}
}));

export const CarOrderDescription = (
	props: RowProps<ApplicationInfo, CarOrderDetails>
) => {
	const classes = useStyles();
	return (
		<Paper className={classes.root}>
			<Typography variant="h4" paragraph>
				<FormattedMessage
					id="dealer.pendingorders.description.header"
					description="Car order information header"
					defaultMessage="Informasjon"
				/>
			</Typography>
			<Typography>
				<FormattedMessage
					id="dealer.pendingorders.description.text"
					description="car order information "
					defaultMessage={`Bilbestillingen må fylles ut innen {return_days, plural, one {# dag} other {# dager}}.

					Bilen vil bli førstegangsregistrert på {legal_name}. Vennligst fyll inn estimert leveringsdato og fører på bilen (dersom dette er kjent).

					{legal_name} forbeholder seg retten til å kansellere bestillingen dersom ønsket leverigndato ikke kan overholdes.

					NB: Kontakt kunde for gjennomgang av alle detaljer i bilbestillingen slik at alt blir som kunde ønsker. Eventuelle endringer skal godkjennes av {name}`}
					values={{
						legal_name: (
							<FormattedMessage
								id="tenant.legal_name"
								defaultMessage="Kreditor AS"
								description="The full legal name of the current tenant (financial institution)"
							/>
						),
						name: (
							<FormattedMessage
								id="tenant.name"
								defaultMessage="kreditor"
								description="The name of the current tenant (financial institution)"
							/>
						),
						return_days: 3
					}}
				/>
			</Typography>
		</Paper>
	);
};
