import React, { memo, useEffect } from "react";
import { CalculationManager } from "@ploy-lib/calculation";
import { FormTemplate } from "@ploy-lib/types";
import { FormikConfig, Formik, connect } from "formik";
import { Omit } from "@ploy-ui/core";
import { useTenant } from "@ploy-ui/tenants";
import {
	TemplateForm,
	TemplateFormProps,
	AppLoadProvider
} from "@ploy-ui/template-form";
import { useCalculationResources } from "./useCalculationResources";
import { useMergeUpdateValues } from "./useMergeUpdateValues";
import { useDateUtils } from "@ploy-ui/form-fields";
import { MinimalInitialData } from "@ploy-lib/rest-resources";
import { CgfTemplateFieldDefaultsProvider } from "./CgfTemplateFieldDefaultsContext";

export interface CalculationFormProps<TValues>
	extends Omit<
		FormikConfig<TValues>,
		"initialValues" | "children" | "component" | "render"
	> {
	productExternalCode?: string;
	applicationNumber?: string;
	appLoadPayload?: object;
	formContext?: string;
	context?: string;
	skipInitialize?: boolean;
	template?: FormTemplate;
	header?: React.ReactNode;
	initialData?: MinimalInitialData;
	children?: (props: TemplateFormProps) => React.ReactNode;
	disallowedFieldRoles?: readonly string[];
}

export type ProductFormProps<TValues> = {
	productExternalCode: string;
} & CalculationFormProps<TValues>;

export type ApplicationFormProps<TValues> = {
	applicationNumber: string;
	appLoadPayload?: object;
} & CalculationFormProps<TValues>;

CalculationFormImpl.displayName = "CalculationForm";
function CalculationFormImpl<
	TNamespaces extends string = string,
	TData extends string | number | boolean = string | number | boolean,
	TValues extends Record<TNamespaces, Record<string, TData>> = Record<
		TNamespaces,
		Record<string, TData>
	>
>(props: ProductFormProps<TValues> | ApplicationFormProps<TValues>) {
	const {
		productExternalCode,
		applicationNumber,
		appLoadPayload = undefined,
		formContext = "Standard",
		context,
		template: propTemplate,
		children,
		header,
		skipInitialize,
		initialData,
		disallowedFieldRoles,
		...formikProps
	} = props;

	const [
		appData,
		refetchApp,
		calc,
		template,
		cgf,
		fetchDataModel
	] = useCalculationResources(
		applicationNumber,
		productExternalCode,
		formContext,
		context,
		props.template,
		skipInitialize,
		initialData,
		disallowedFieldRoles,
		appLoadPayload
	);

	const dateUtils = useDateUtils();
	const { locale } = useTenant();

	// TODO: Use material-ui Dialog instead
	const undefinedVariables =
		calc &&
		Object.entries(calc.undefinedVariables).flatMap(([namespace, variables]) =>
			variables.map(v => `${namespace}.${v.name}`)
		);

	const undefVarsJoined = undefinedVariables?.join("\n");

	useEffect(() => {
		if (undefVarsJoined)
			alert(`Undefined variables used in the form:\n${undefVarsJoined}`);
	}, [undefVarsJoined]);

	if (!calc || !template) {
		return <TemplateForm error header={header} />;
	}

	const canSubmit = appData
		? appData.vulcanContext === "SubmitApplication"
		: false;

	return (
		<Formik
			key={`${applicationNumber || productExternalCode}.${
				appData!.vulcanContext
			}`}
			initialValues={cgf.initialValues as TValues}
			{...formikProps}
			validateOnBlur={false}
			validateOnChange={false}
		>
			<FormikCalculation
				cgfMap={cgf.cgfMap}
				initialValues={cgf.initialValues as TValues}
				initialWriteLocked={cgf.initialChecked}
				initialVisible={cgf.initialVisible}
				calcRules={calc.calcRules}
				variables={calc.variables}
				services={calc.services}
				resolves={calc.model.resolves}
				validators={calc.validators}
				macros={calc.macros}
				functions={cgf.additionalFunctions}
				serviceBodyFields={cgf.serviceBodyFields}
				additionalServiceBody={calc.customSubmitFields}
				namespaces={calc.namespaces}
				clear={calc.clear}
				locale={locale}
				dateUtils={dateUtils}
				onUpdateDataModel={fetchDataModel}
				appData={appData}
			>
				<CgfTemplateFieldDefaultsProvider value={cgf.mapTemplateFieldDefaults}>
					<AppLoadProvider value={{ ...appData!, refetchApp: refetchApp }}>
						{children ? (
							children({
								template,
								header,
								canSubmit
							})
						) : (
							<TemplateForm
								template={template}
								header={header}
								canSubmit={canSubmit}
							/>
						)}
					</AppLoadProvider>
				</CgfTemplateFieldDefaultsProvider>
			</FormikCalculation>
		</Formik>
	);
}

export const CalculationForm = memo(CalculationFormImpl);

const FormikCalculation = connect(({ formik, initialValues, ...props }) => {
	useMergeUpdateValues(initialValues, formik, props.clear);

	return (
		<CalculationManager
			{...props}
			initialValues={initialValues}
			formValues={formik.values}
		/>
	);
}) as typeof CalculationManager;
