import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BasePlainResource } from "./BasePlainResource";
import omit from "lodash/omit";
import paramsToString from "../resources/BaseResource";
import {
	CalculationResponse,
	ID,
	Resolve,
	Namespaced,
	CGFSection,
	Variable,
	Service,
	Validator
} from "@ploy-lib/types";
import {
	Resource,
	AbstractInstanceType,
	SimpleRecord,
	EndpointExtraOptions
} from "@rest-hooks/rest";

const createClearAndMerge = (clear: string[]) => <T extends object>(
	a: T,
	b: T
): T => ({
	...omit(a, clear),
	...b
});

export class CalculationResource<TN extends string = string>
	extends BasePlainResource
	implements CalculationResponse<TN> {
	readonly id?: ID;
	readonly context?: string;
	readonly formContext?: string;
	readonly namespaces: string[] = [];
	readonly clear: string[] = [];

	readonly model: {
		resolves: Record<string, Resolve<TN>>;
		namespacedCGFSections: Namespaced<CGFSection, TN>;
	};
	readonly calcRules: Record<TN, string>;
	readonly customSubmitFields: Namespaced<string, TN>;
	readonly variables: Record<TN, Variable[]>;
	readonly services: Record<TN, Service[]>;
	readonly validators: Record<TN, Validator[]>;
	readonly macros: Namespaced<number, TN>;
	readonly formTemplate?: string;
	readonly undefinedVariables: Record<TN, Variable[]> = {} as Record<
		TN,
		Variable[]
	>;

	pk() {
		return `${this.id}_${this.formContext}_${this.context}`;
	}

	static url<T extends typeof Resource>(
		this: T,
		urlParams: Readonly<Record<string, any>>
	) {
		const { id, ...searchParams } = urlParams;

		const url = super.listUrl();

		if (url.endsWith("/")) {
			return `${url}${id}${paramsToString(searchParams)}`;
		}
		return `${url}/${id}${paramsToString(searchParams)}`;
	}

	static merge<T extends typeof SimpleRecord>(
		this: T,
		first: AbstractInstanceType<T>,
		second: AbstractInstanceType<T>
	) {
		const existing = first as CalculationResource;
		const partial = second as CalculationResource;

		const isPartial =
			(partial.namespaces && partial.namespaces.length > 0) ||
			(partial.clear && partial.clear.length > 0);

		if (!isPartial) return this.fromJS(second);

		const clearMerge = createClearAndMerge(partial.clear || []);

		const props: CalculationResponse = {
			id: partial.id || existing.id,
			context: partial.context || existing.context,
			formContext: partial.formContext || existing.formContext,
			formTemplate: partial.formTemplate || existing.formTemplate,
			namespaces: [
				...new Set([
					...existing.namespaces.filter(x => partial.clear.includes(x)),
					...partial.namespaces
				])
			],
			clear: partial.clear,
			calcRules: clearMerge(existing.calcRules, partial.calcRules),
			model: {
				...existing.model,
				resolves: {
					...existing.model.resolves,
					...partial.model.resolves
				},
				namespacedCGFSections: clearMerge(
					existing.model.namespacedCGFSections,
					partial.model.namespacedCGFSections
				)
			},
			customSubmitFields: clearMerge(
				existing.customSubmitFields,
				partial.customSubmitFields
			),
			variables: clearMerge(existing.variables, partial.variables),
			services: clearMerge(existing.services, partial.services),
			validators: clearMerge(existing.validators, partial.validators),
			macros: clearMerge(existing.macros, partial.macros),
			undefinedVariables: clearMerge(
				existing.undefinedVariables,
				partial.undefinedVariables
			)
		};

		return this.fromJS(props as AbstractInstanceType<T>);
	}

	static getEndpointExtra(): EndpointExtraOptions {
		return {
			...super.getEndpointExtra(),
			invalidIfStale: true,
			dataExpiryLength: Infinity
		};
	}

	static urlRoot = legacyApiResourceUrl("AppLoanLeasing/VulcanRawScripts");
}
