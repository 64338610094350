import { createContext, useContext } from "react";

export interface NavigationContextValue {
	home: () => void;
	application: (applicationNumber: string, fromSubmit?: boolean) => void;
	applicationFromCalculator: (category: string, externalCode: string) => void;
	// product: (productExternalCode: string) => void;
	// category: (category: string) => void;
	// calculator: (productExternalCode: string) => void;
}

function missingContextWarning() {
	console.warn(`${Context.displayName}: missing provider`);
}

const defaultContext: NavigationContextValue = {
	home: missingContextWarning,
	application: missingContextWarning,
	applicationFromCalculator: missingContextWarning
};

const Context = createContext<NavigationContextValue>(defaultContext);
Context.displayName = "NavigationState";

export const NavigationContext = Context;
export const NavigationProvider = Context.Provider;
export const NavigationConsumer = Context.Consumer;

export function useNavigationState() {
	return useContext(Context);
}
