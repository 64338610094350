import React from "react";
import {
	ListItem,
	ListItemIcon,
	ListItemText,
	MenuList,
	Icon,
	makeStyles,
	Divider,
	Button,
	MenuItem
} from "@material-ui/core";
import { navMigration } from "../routeMigration";
import clsx from "clsx";
import {
	ChevronRight as ChevronRightIcon,
	Close as CloseIcon
} from "@material-ui/icons";
import { RouterMenuItem } from "@ploy-ui/core";
import { ReleaseInfo } from "./ReleaseInfo";
import { LanguageSelector } from "../../LanguageSelector";
import { useIntl } from "react-intl";
import { messages } from "./MenuItemList";

import PersonIcon from "@material-ui/icons/Person";

const BASEPATH = process.env.PUBLIC_URL;

export const FixedBottomMenuItemList = props => {
	const { menuItems } = props;

	const intl = useIntl();

	return (
		<div>
			<MenuList>
				{menuItems.map(
					({
						menuItemName,
						icon,
						menuItemLabel,
						navigateUrl,
						navigateUrlexternal
					}) => (
						<LinkMenuItem
							key={menuItemName}
							href={navigateUrlexternal}
							target={navigateUrlexternal && "_blank"}
							to={
								navigateUrlexternal
									? undefined
									: `${BASEPATH}/${navMigration[navigateUrl] || navigateUrl}`
							}
							primary={menuItemLabel}
							iconClass={icon}
						/>
					)
				)}
			</MenuList>

			<Divider />

			<MenuList>
				<LinkMenuItem
					to={`${BASEPATH}/profile`}
					primary={intl.formatMessage(messages.Profile)}
					icon={<PersonIcon />}
				/>

				<LinkMenuItem
					to={`${BASEPATH}/logout`}
					primary={intl.formatMessage(messages.Logout)}
					iconClass="sign-out"
				/>

				<LanguageSelector />

				<ListItem>
					<ListItemText>
						<ReleaseInfo />
					</ListItemText>
					<Button onClick={() => props.closeDrawer()}>
						<CloseIcon style={{ fontSize: "2rem" }} />
					</Button>
				</ListItem>
			</MenuList>
		</div>
	);
};

const LinkMenuItem = ({ iconClass, primary, ...props }) => {
	const classes = useStyles();

	if (props.to)
		return (
			<RouterMenuItem {...props}>
				<ListItemIcon className={classes.icon}>
					<Icon className={clsx(`fa fa-${iconClass}`, classes.icon)} />
				</ListItemIcon>
				<ListItemText primary={primary} />
				<ChevronRightIcon />
			</RouterMenuItem>
		);

	if (props.href)
		return (
			<MenuItem {...props} component={"a"}>
				<ListItemIcon className={classes.icon}>
					<Icon className={clsx(`fa fa-${iconClass}`, classes.icon)} />
				</ListItemIcon>
				<ListItemText primary={primary} />
				<ChevronRightIcon />
			</MenuItem>
		);

	return null;
};

const useStyles = makeStyles({
	root: {},
	selected: {},
	icon: {
		overflow: "initial",
		textAlign: "center"
	},
	blackText: {
		color: "black"
	}
});
