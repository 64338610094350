import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography
} from "@material-ui/core";
import { ModalContainer } from "./ModalContainer";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		},
		wrapper: {
			width: 400
		},
		headerText: {
			marginTop: 12
		},
		formControl: {
			marginTop: 12,
			marginBottom: 12
		},
		submitButton: {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
			marginBottom: 12
		}
	})
);

export interface ReasonForCancelModalProps {
	open: boolean;
	setOpen: (e: boolean) => void;
	confirmCancel: (e: any) => Promise<void>;
	reasonsForCancel: string[];
	appId: number;
}

export const ReasonForCancelModal = (props: ReasonForCancelModalProps) => {
	const { open, setOpen, confirmCancel, reasonsForCancel, appId } = props;

	const onCloseHandler = () => setOpen(false);

	const classes = useStyles();

	const [reasonForCancel, setReasonForCancel] = useState("");
	const handleRadioBtnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setReasonForCancel((event.target as HTMLInputElement).value);
	};

	return (
		<ModalContainer open={open} handleClose={onCloseHandler}>
			<Grid xs={12} container justify="space-around">
				<Grid item>
					<Typography variant="h5" className={classes.headerText}>
						<FormattedMessage
							id="reason-for-cancel-modal.header-text"
							defaultMessage="Årsak til bortfalt"
							description="Header text of reason for cancel modal"
						/>
					</Typography>
				</Grid>
				<Grid item xs={10}>
					<FormControl className={classes.formControl}>
						<RadioGroup value={reasonForCancel} onChange={handleRadioBtnChange}>
							{reasonsForCancel.map((reason: string) => (
								<FormControlLabel
									value={reason}
									control={<Radio />}
									label={reason}
								/>
							))}
						</RadioGroup>
					</FormControl>
				</Grid>
				<Grid>
					<Button
						className={classes.submitButton}
						onClick={event => {
							setOpen(false);
							confirmCancel(event).then(async () => {
								//	Update UnderWritingDTO with 'ReasonForUpdate':
								await fetch(
									legacyApiResourceUrl(
										"UnderwritingData/UpdateReasonForDecision"
									),
									{
										method: "POST",
										headers: {
											Accept: "application/json",
											"Content-Type": "application/json"
										},
										body: JSON.stringify({
											reason: reasonForCancel,
											applicationId: appId
										})
									}
								);
							});
						}}
					>
						<FormattedMessage
							id="reason-for-cancel-modal.confirm-cancel-button"
							defaultMessage="Bortfall søknad"
							description="Confirm cancel application text"
						/>
					</Button>
				</Grid>
			</Grid>
		</ModalContainer>
	);
};
