import React, { Suspense } from "react";
import { SectionProps } from "../Section";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { DataTable } from "@ploy-ui/core";
import { WrappedComponentProps, FormattedMessage } from "react-intl";
import {
	RefinancingResource,
	FulfillmentResource,
	useResourceWithInvalidate
} from "@ploy-lib/rest-resources";
import { useAppLoad } from "../../appLoadContext";

addRegisteredSectionLayout({
	name: "FulfillmentSection",
	displayName: "FulfillmentSection",
	settings: {}
});

addRegisteredSectionLayout({
	name: "RefinancingSection",
	displayName: "RefinancingSection",
	settings: {}
});

const columns = [
	{
		field: "contractId",
		title: (
			<FormattedMessage
				id="dploy.fulfillmentSection.contractId.header"
				defaultMessage="Kontraktnr."
			/>
		)
	},
	{
		field: "text",
		title: (
			<FormattedMessage
				id="dploy.fulfillmentSection.tekst.header"
				defaultMessage="Tekst."
			/>
		)
	},
	{
		field: "matureDate",
		title: (
			<FormattedMessage
				id="dploy.fulfillmentSection.matureDate.header"
				defaultMessage="Forfallsdato."
			/>
		)
	},
	{
		field: "refinancedAmount",
		title: (
			<FormattedMessage
				id="dploy.fulfillmentSection.refinancedAmount.header"
				defaultMessage="Restgjeld."
			/>
		)
	}
];

const emptyArray = [];

const FinancingSection = (props: SectionProps & { financingCode: string }) => {
	const { className, onClick, financingCode } = props;

	const { applicationNumber, id: appSessionId } = useAppLoad();

	const resourceBase =
		financingCode === "REFINANCING"
			? RefinancingResource.list()
			: FulfillmentResource.list();

	const result =
		useResourceWithInvalidate(
			resourceBase,
			appSessionId
				? {
						applicationNumber
				  }
				: null
		) ?? emptyArray;

	return (
		<div className={className} onClick={onClick}>
			<Suspense fallback={null}>
				<DataTable
					columns={columns}
					data={result}
					listCellsBreakpoint={"xs"}
					options={{
						search: false,
						grouping: false,
						showEmptyDataSourceMessage: false,
						emptyRowsWhenPaging: false,
						paging: false,
						header: true,
						toolbar: false
					}}
				/>
			</Suspense>
		</div>
	);
};

const FulfillmentSection = (props: SectionProps & WrappedComponentProps) => {
	return <FinancingSection {...props} financingCode={"FULFILLMENT"} />;
};

const RefinancingSection = (props: SectionProps & WrappedComponentProps) => {
	return <FinancingSection {...props} financingCode={"REFINANCING"} />;
};

FulfillmentSection.displayName = "DployFulfillmentSection";
RefinancingSection.displayName = "DployRefinancingSection";

export { FulfillmentSection, RefinancingSection };
