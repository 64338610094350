import { useFetcher, useResource } from "@rest-hooks/core";
import {
	FormTemplate,
	TemplateDataSource,
	TemplatePage
} from "@ploy-lib/types";
import {
	FormTemplateResource,
	dataSourceResources,
	BaseResource
} from "@ploy-lib/rest-resources";
import { TemplateForm } from "@ploy-ui/template-form";
import { Formik, FormikConfig } from "formik";
import { RoutePageProvider } from "../../components/RoutePageProvider";
import { RouteComponentProps } from "@reach/router";
import { parsedQueryProps } from "@ploy-lib/routing";
import { useCallback } from "react";

export interface TemplateViewProps {
	formTemplateId?: string;
	modalClick?: number;
}

export const TemplateView = (props: RouteComponentProps<TemplateViewProps>) => {
	const {
		formTemplateId,
		modalClick,
		path,
		default: isDefault,
		location,
		navigate,
		uri,
		"*": _,
		...restParams
	} = parsedQueryProps(
		props as RouteComponentProps<TemplateViewProps & { "*"?: string }>
	);

	const formTemplate = useResource(
		FormTemplateResource.detail(),
		formTemplateId ? { formTemplateId } : restParams
	);

	const form = (
		<RoutePageProvider labels={formTemplate.pages.map(p => p.pageTitle)}>
			<TemplateForm
				template={formTemplate as FormTemplate<TemplatePage>}
				modalClick={modalClick}
			/>
		</RoutePageProvider>
	);

	switch (formTemplate.dataSource) {
		case TemplateDataSource.Resource:
			const resource =
				formTemplate.resource && dataSourceResources[formTemplate.resource];

			if (resource)
				return (
					<ResourceForm
						resource={resource}
						params={Object.keys(restParams).length > 0 ? restParams : null}
					>
						{form}
					</ResourceForm>
				);
			break;
		case TemplateDataSource.CalcRules:
			break;
	}

	return (
		<Formik initialValues={{}} onSubmit={values => console.log(values)}>
			{form}
		</Formik>
	);
};

interface ResourceFormProps<TResource extends typeof BaseResource> {
	resource: TResource;
	params: Record<string, any> | null;
	children: React.ReactNode;
}

function ResourceForm<TResource extends typeof BaseResource>(
	props: ResourceFormProps<TResource>
) {
	const { resource, params, children } = props;

	const existing = useResource(resource.detail(), params);

	const data = existing ?? resource.fromJS();

	const create = useFetcher(resource.create());
	const update = useFetcher(resource.update());

	const onSubmit = useCallback<FormikConfig<typeof data>["onSubmit"]>(
		async (values, helpers) => {
			helpers.setStatus({});

			try {
				const response =
					params && existing
						? await update(params, values)
						: await create({}, values);

				helpers.resetForm({ values: response });
				helpers.setStatus({ success: true });
			} catch (error: any) {
				helpers.setStatus({
					error
				});
			}
		},
		[create, existing, params, update]
	);

	// const onReset = useCallback<
	// 	NonNullable<FormikConfig<typeof data>["onReset"]>
	// >(
	// 	(values, helpers) => {
	// 		helpers.resetForm({ values: data });
	// 	},
	// 	[data]
	// );

	return (
		<Formik initialValues={data} onSubmit={onSubmit}>
			{children}
		</Formik>
	);
}
