import React, { useState, useEffect } from "react";
import { Typography, Container } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "@reach/router";

export interface ErrorPageContentProps {
	header: React.ReactNode;
	body?: React.ReactNode;
	className?: string;
	onClick?: () => void;
}

export const ErrorPageContent = (props: ErrorPageContentProps) => {
	const { header, body, ...rest } = props;

	return (
		<Container {...rest}>
			<Typography variant="h5" gutterBottom>
				{header}
			</Typography>
			{body}
		</Container>
	);
};

export const ErrorPage = ({
	location,
	error,
	getLoginHref
}: {
	error: any;
	getLoginHref?: (returnUrl?: string) => string;
} & RouteComponentProps) => {
	const status = error?.response?.status ?? error.statusCode;

	if (status === 401 && getLoginHref) {
		const returnUrl =
			location &&
			(new URL(getLoginHref(), location.origin).origin === location.origin
				? location.pathname
				: location.href);

		if (window) window.location.href = getLoginHref(returnUrl);
		return null;
	}

	let header = (
		<FormattedMessage
			id="dploy.error.default"
			defaultMessage="En feil har oppstått"
		/>
	);

	let body: React.ReactNode | undefined = undefined;

	if (process.env.NODE_ENV === "development") {
		const isHtml = error.response?.headers
			.get("Content-Type")
			?.includes("html");

		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [html, setHtml] = useState<string>();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (!isHtml) return;

			let active = true;
			(async () => {
				const text = await error.response.text();
				if (active) setHtml(text);
			})();
			return () => {
				active = false;
			};
		}, [error, isHtml]);

		body = (
			<>
				<Typography
					component="pre"
					variant="caption"
					color="error"
					gutterBottom
				>
					{error.stack}
				</Typography>
				{html && (
					<iframe
						frameBorder={0}
						srcDoc={html}
						width="100%"
						height="800px"
						title="Network error response"
					/>
				)}
			</>
		);
	}

	if (status) {
		header = (
			<FormattedMessage
				id="dploy.error.status"
				defaultMessage={`{status}: {status, select,
						401 {Ingen tilgang}
						403 {Ingen tilgang}
						404 {Ikke funnet}
						other {En feil har oppstått}
					}`}
				values={{ status }}
			/>
		);
	}

	return <ErrorPageContent header={header} body={body} />;
};
