import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BasePlainResource";
import { Resource, SchemaList, AbstractInstanceType } from "@rest-hooks/rest";

export interface RefinancingResult {
	contractId: string | number;
	text: string;
	termLength: string;
	customerName: string;
	dateOption: string;
	dateOptions: any[];
	refinancedAmount: number;
	selected: boolean;
	matureDate: string;
}

export class RefinancingResource
	extends BaseCamelCasedResource
	implements RefinancingResult {
	readonly contractId: string | number;
	readonly text: string;
	readonly termLength: string;
	readonly customerName: string;
	readonly dateOption: string;
	readonly dateOptions: any[];
	readonly refinancedAmount: number;
	readonly selected: boolean;
	readonly matureDate: string;

	pk() {
		return this.contractId.toString();
	}

	static list<T extends typeof Resource>(this: T) {
		const endpoint = super.list();
		return endpoint.extend({
			schema: [this] as SchemaList<AbstractInstanceType<T>>,
			fetch: async params => {
				const data = await endpoint.fetch(params);
				return data?.rows ?? [];
			}
		});
	}

	static urlRoot = legacyApiResourceUrl("Refinancing/Load/REFINANCING");
}

export class FulfillmentResource extends RefinancingResource {
	static urlRoot = legacyApiResourceUrl("Refinancing/Load/FULFILLMENT");
}
