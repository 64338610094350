import { BaseCamelCasedResource } from "./BasePlainResource";
import { legacyApiResourceUrl } from "@ploy-lib/core";

export class ThirdPartyScriptSettingsResource extends BaseCamelCasedResource {
	loadZendeskChat = false;
	loadDNBFIZendesk = false;
	loadGoogleTagManager = false;
	loadCookieBot = false;
	zendeskAccountKey?: string;
	zendeskAlternativeAccountKey?: string;

	pk() {
		return "ThirdPartyScriptSettings";
	}

	static urlRoot = legacyApiResourceUrl("Login");
}
