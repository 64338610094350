import React from "react";
import { useResource } from "@rest-hooks/core";
import { Typography, Paper, makeStyles } from "@material-ui/core";
import { CustomerSignatureInfoResource, ID } from "@ploy-lib/rest-resources";

export interface AppSignatureInfoProps {
	applicationNumber: ID;
	hideSignerList?: boolean;
}

const useStyles = makeStyles({
	tableWrapper: {
		"& table": {
			width: "100%"
		}
	}
});

function AppSignatureInfo(props: AppSignatureInfoProps) {
	const { applicationNumber, hideSignerList } = props;
	const classes = useStyles();
	const signatureInfo = useResource(
		CustomerSignatureInfoResource.detail(),
		!applicationNumber ? null : { id: applicationNumber }
	);

	return signatureInfo != null ? (
		<div>
			<Typography>{signatureInfo.signerStatusMessage}</Typography>
			{!hideSignerList && (
				<Paper>
					<div
						className={classes.tableWrapper}
						dangerouslySetInnerHTML={{
							__html: signatureInfo.customerSignersHTML
						}}
					/>
				</Paper>
			)}
		</div>
	) : null;
}

AppSignatureInfo.displayName = "DployAppSignatureInfo";

export { AppSignatureInfo };
