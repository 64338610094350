import Charts from "./Charts";
import { applyPropertyControls, ControlType } from "../../property-controls";
import { sources, sourceMapTitles } from "./common";
import { ChartResource } from "@ploy-lib/rest-resources";

applyPropertyControls(Charts, {
	source: {
		type: ControlType.Enum,
		title: "Datakilde",
		options: sources,
		optionTitles: sources.map(k => sourceMapTitles[k]),
		defaultValue: sources[0],
		coerce: (value, values) => {
			const source = sources.find(name =>
				ChartResource[name]?.()
					?.getFetchKey({})
					.includes(values.loadUrl)
			);
			return source;
		}
	},
	boxProps: {
		type: ControlType.Object,
		title: "Standard instillinger",
		propertyControls: {
			header: {
				type: ControlType.String,
				title: "Tittel"
			},
			iconClass: {
				type: ControlType.String,
				title: "Ikon",
				description: "Fyll inn navnet til et ikon fra Font Awesome"
			},
			backgroundColor: {
				type: ControlType.Color,
				title: "Bakgrunnsfarge",
				description: "Bakgrunnsfarge for boksen",
				coerce: (_, boxProps) => {
					if (boxProps.grey) return "grey.200";
				}
			},
			headerColor: {
				type: ControlType.Color,
				title: "Tittelfarge",
				description: "Tekstfarge som blir brukt på tittel og ikon",
				coerce: (_, boxProps) => {
					if (boxProps.grey) return "grey.500";
				}
			}
		}
	}
});

export default Charts;
