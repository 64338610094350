import React from "react";
import { FormattedMessage } from "react-intl";

import { Typography, Paper } from "@material-ui/core";
import { Search, CancelOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useFormikContext } from "formik";

const useStyles = makeStyles(theme => ({
	searchToggler: {
		display: "flex",
		alignItems: "center",
		marginLeft: "auto",
		marginBottom: "auto",
		padding: 15,
		"&:hover": {
			backgroundColor: theme.palette.grey[200],
			cursor: "pointer"
		}
	},
	searchTogglerText: {
		marginRight: 10,
		fontWeight: "bold"
	}
}));

const AdvancedSearchToggle = ({ onClick, open }) => {
	const classes = useStyles();

	const { resetForm } = useFormikContext();

	return (
		<Paper
			className={classes.searchToggler}
			onClick={() => {
				if (open) resetForm();
				onClick();
			}}
		>
			<Typography className={classes.searchTogglerText}>
				{open ? (
					<FormattedMessage
						id="dealer.allapplications.closesearch.label"
						description="Label for close search button"
						defaultMessage="Avslutt søk"
					/>
				) : (
					<FormattedMessage
						id="dealer.allapplications.opensearch.label"
						description="Label for open search button"
						defaultMessage="Søk i saker"
					/>
				)}
			</Typography>

			{open ? <CancelOutlined /> : <Search />}
		</Paper>
	);
};

AdvancedSearchToggle.displayName = "AdvancedSearchToggle";

export default AdvancedSearchToggle;
