import { CalculatorState } from "../calculator";
import { getInNamespaced, isResolvedCtrl } from "../calculator/utils";
import { setIn, getIn } from "@ploy-lib/core";

export function calculationToParams<TNamespaces extends string, TData>(
	calculation: Partial<CalculatorState<TNamespaces, TData>> = {},
	parameterList: string[] = [],
	serviceNamespace: string
) {
	const { values, variableControlMaps, controlFieldMaps } = calculation;

	if (parameterList.length === 0) return;

	return parameterList.reduce((params, param) => {
		const [source, targetStr, defaultString] = param.trim().split(";");

		const defaultValue =
			defaultString != null
				? // eslint-disable-next-line no-new-func
				  new Function("return " + defaultString + ";")()
				: null;

		let [target, ns = serviceNamespace] = (targetStr || source)
			.split(".")
			.reverse();

		const ctrl = getIn(variableControlMaps, [ns, target]);
		const ref = getIn(controlFieldMaps, [ns, ctrl]);

		if (isResolvedCtrl(ref)) {
			target = ref.resolved;
			ns = ref.namespace;
		}

		const value = getInNamespaced(values, ns, target, defaultValue);

		if (value == null) return params;

		return setIn(params, source, value);
	}, {});
}
