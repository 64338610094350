import {
	Namespaced,
	VariableField,
	ResolvedCtrl,
	CtrlField,
	WithGetSet
} from "./types";
import { CalculatorState } from "./createCalculator";

//'These lists NEEDS to be kept in sync with the ones in CalcRulesServerImpl.vb::GetFieldNameMatch()!
const possibleControlIdPrefixes = ["txt", "span", ".cgf_"];
// When fields are set as Literal, _label are added to the field value in old web
const possiblControlIdSuffixes = ["_label"];

export function getFieldNameMatch(fieldName: string, controlId: string) {
	if (fieldName === controlId) return controlId;

	for (const prefix of possibleControlIdPrefixes) {
		for (const suffix of possiblControlIdSuffixes) {
			if (`${prefix}${fieldName}${suffix}` === controlId) return fieldName;
			if (`${fieldName}${suffix}` === controlId) return fieldName;
			if (`${prefix}${fieldName}` === controlId) return fieldName;
		}
	}
}

export function stripControlIdPrefixAndSuffix(controlId: string) {
	let fieldName = controlId;

	for (const prefix of possibleControlIdPrefixes) {
		if (fieldName.startsWith(prefix))
			fieldName = fieldName.substring(prefix.length);
	}

	for (const suffix of possiblControlIdSuffixes) {
		if (fieldName.endsWith(suffix))
			fieldName = fieldName.substring(0, fieldName.length - suffix.length);
	}

	return fieldName;
}

export function getResolveReference(reference: string) {
	if (reference.toLowerCase().startsWith("#resolve:")) {
		return reference.substring(9);
	}
}

export const compose = <I, O extends I = I>(
	fn1: (a: I) => O = a => (a as unknown) as O,
	...fns: Array<(a: I) => O>
): ((a: I) => O) => {
	return (a: I): O => {
		let o = fn1(a);
		for (const fn of fns) {
			o = fn(o);
		}
		return o;
	};
};

export function composeFirstParam<I, T extends any[], O extends I = I>(
	...funcs: Array<(a: I, ...other: T) => O>
) {
	return (input: I, ...other: T) =>
		compose(...funcs.map(f => (i: I) => f(i, ...other)))(input);
}

export function isNotNull<T>(x: T): x is NonNullable<T> {
	return x != null;
}

export const isEqualOrUndefined = (a: any, b: any) =>
	a === undefined || b === undefined || a === b;

export const shallowClone = <T>(a: T) => ({ ...a } as NonNullable<T>);

export function isResolvedCtrl<TNamespaces = string>(
	ctrlField?: CtrlField
): ctrlField is ResolvedCtrl<TNamespaces> {
	return (
		ctrlField != null && (ctrlField as ResolvedCtrl<TNamespaces>).ref != null
	);
}
export function isVariableField(
	ctrlField?: CtrlField
): ctrlField is VariableField {
	return ctrlField != null && (ctrlField as VariableField).variable != null;
}

export function getInNamespaced<T, TNamespaces extends string>(
	input:
		| Partial<Namespaced<T, TNamespaces>>
		| Namespaced<T, TNamespaces>
		| undefined,
	namespace: TNamespaces | undefined,
	name: string | undefined
): T;

export function getInNamespaced<T, TNamespaces extends string>(
	input:
		| Partial<Namespaced<T, TNamespaces>>
		| Namespaced<T, TNamespaces>
		| undefined,
	namespace: TNamespaces | undefined,
	name: string | undefined,
	defaultValue: NonNullable<T>
): NonNullable<T>;

export function getInNamespaced<T, TNamespaces extends string>(
	input:
		| Partial<Namespaced<T, TNamespaces>>
		| Namespaced<T, TNamespaces>
		| undefined,
	namespace: TNamespaces | undefined,
	name: string | undefined,
	defaultValue?: NonNullable<T>
) {
	if (!(namespace && name)) return defaultValue;

	const value = input?.[namespace]?.[name] as T;

	return value !== undefined ? value : defaultValue;
}

export function existsInNamespaced<T, TNamespaces extends string>(
	input:
		| Partial<Namespaced<T, TNamespaces>>
		| Namespaced<T, TNamespaces>
		| undefined,
	namespace: TNamespaces | undefined,
	name: string | undefined
) {
	if (!(namespace && name)) return false;

	return input?.[namespace]?.[name] !== undefined;
}

export function setInMutableNamespacedCopy<
	T,
	TNamespaces extends string,
	O extends
		| Partial<Namespaced<T, TNamespaces>>
		| Namespaced<T, TNamespaces>
		| undefined
>(
	original: O,
	copy: O,
	namespace: TNamespaces | undefined,
	name: string | undefined,
	value: T
): O {
	if (
		!(namespace && name) ||
		getInNamespaced(copy || original, namespace, name) === value
	)
		return copy || original;

	const mutable =
		!copy || copy === original
			? shallowClone(original)
			: (copy as NonNullable<O>);

	let namespaceObject = mutable[namespace];

	if (!namespaceObject || namespaceObject === original?.[namespace]) {
		namespaceObject = shallowClone(namespaceObject);
	}

	namespaceObject[name] = value;
	mutable[namespace] = namespaceObject;

	return mutable;
}

export function getKeysInNamespace<T, TNamespaces extends string>(
	input:
		| Partial<Namespaced<T, TNamespaces>>
		| Namespaced<T, TNamespaces>
		| undefined,
	namespace: TNamespaces
) {
	const nsRecord = ((input && input[namespace]) || {}) as Record<string, T>;
	return Object.keys(nsRecord);
}

function isKeyOf<T extends {}>(obj: T, key: any): key is keyof T {
	return key && obj[key] !== undefined;
}

export function caseInsensitiveMap<K extends keyof any, T>(
	obj: Partial<Record<K, T>>
) {
	const lowerCaseToCorrectCaseLookup = new Map(
		Object.keys(obj).map(k => [k.toLowerCase(), k] as [string, string])
	);

	return {
		getEntry<C extends keyof any>(key?: C): [K | undefined, T | undefined] {
			if (key) {
				if (isKeyOf(obj, key)) return [key, obj[key]];

				if (typeof key === "string") {
					const correctCaseKey = lowerCaseToCorrectCaseLookup.get(
						key.toLowerCase()
					);
					if (isKeyOf(obj, correctCaseKey))
						return [correctCaseKey, obj[correctCaseKey]];
				}
			}
			return [undefined, undefined];
		}
	};
}

export function mapValueToKey<
	T,
	K extends keyof any,
	TK extends keyof any = T extends keyof any ? T : never
>(
	obj: Record<K, T>,
	getKey: (
		item: NonNullable<T>,
		map: Partial<Record<string, string>>
	) => TK | undefined = x => (x as unknown) as TK
) {
	return Object.entries<T>(obj).reduce((acc, [key, item]) => {
		const newKey = item != null && getKey(item as NonNullable<T>, acc);

		if (newKey) acc[newKey] = key;

		return acc;
	}, {} as Record<TK, string>);
}

/**
 * a === b or both are NaN
 *
 * (NaN === NaN) -> false
 *
 * @param a
 * @param b
 */
export function isEqualOrBothNaN(a: any, b: any): boolean {
	return a === b || (Number.isNaN(a) && Number.isNaN(b));
}

const filterToFieldNameAlias = {
	ChasisNumber: "ChassisNumber",
	Milage: "Mileage",
	PriceKmExceeded: "ContractMileageOverridePrice",
	KmTotal: "ExpectedMileage",
	FirstDueDay: "FirstDueDate",
	CommissionWindowLink: "CommissionsToggle",
	Commission: "CommissionsResults",
	CalcInterestButton: "EnableInterestCalculation",
	FundingInterest: "FundingInterestId"
} as Record<string, string>;

export function filterNameToFieldName(filterName: string): string {
	return filterToFieldNameAlias[filterName] || filterName;
}

export function withGetSet<TN extends string, TD>(
	original: Partial<Namespaced<TD, TN>>,
	copy: Partial<Namespaced<TD, TN>>,
	namespace: TN,
	onSet?: (key: string, value: TD) => void
) {
	const hasGetSet = {
		get(key: string) {
			return getInNamespaced(copy || original, namespace, key);
		},
		set(key: string, value: TD) {
			copy = setInMutableNamespacedCopy(original, copy, namespace, key, value);
			if (onSet) onSet(key, value);
			return this;
		},
		getFinal() {
			return copy;
		}
	};

	return hasGetSet;
}

export function withGet<TN extends string, TD>(
	obj: Partial<Namespaced<TD, TN>> | Namespaced<TD, TN> | undefined,
	namespace: TN
): WithGetSet<TD> {
	return {
		get: (key: string) => getInNamespaced(obj, namespace, key),
		set() {
			return this;
		}
	};
}

export function withGetFromField<TN extends string, TD>(
	input: CalculatorState<TN, TD>,
	obj: Partial<Namespaced<TD, TN>> | Namespaced<TD, TN> | undefined,
	namespace: TN
): WithGetSet<TD> {
	return {
		get: (key: string) => {
			const ctrl = getInNamespaced(input.variableControlMaps, namespace, key);

			const fieldRef = getInNamespaced(input.controlFieldMaps, namespace, ctrl);

			const fieldNamespace = isResolvedCtrl(fieldRef)
				? fieldRef.namespace
				: namespace;

			return getInNamespaced(obj, fieldNamespace, fieldRef.fieldName);
		},
		set() {
			return this;
		}
	};
}
