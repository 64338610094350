import { BaseResource } from "./BaseResource";
import { apiResourceUrl } from "@ploy-lib/core";

export enum ExtrasType {
	NotSet = "NotSet",
	Extras = "Extras",
	Color = "Color"
}

export interface CarObjectExtra {
	readonly code: string;
	readonly label?: string;
	readonly price?: number;
	readonly type?: ExtrasType;
}

export class CarObjectExtraResource extends BaseResource
	implements CarObjectExtra {
	readonly code: string = "";
	readonly label?: string;
	readonly price?: number;
	readonly type?: ExtrasType;

	pk() {
		return `${this.code}`;
	}

	static urlRoot = apiResourceUrl("object-extra");
}
