import {
	PropertyControls,
	ControlType,
	BasePropertyControl,
	ArrayControl,
	ObjectControl,
	EnumControl
} from "./property-controls";

type DeepPartial<T> = {
	[P in keyof T]?: DeepPartial<T[P]>;
};

export function propertyValues<P, BP>(
	propertyControls: PropertyControls<P>,
	initialValues: Partial<P> = {},
	baseInitialValues?: Partial<BP>
): DeepPartial<P> {
	const values: DeepPartial<P> = {};

	const controlKeys = Object.keys(propertyControls) as (keyof P)[];

	for (const name of controlKeys) {
		const control = propertyControls[name] as
			| BasePropertyControl<P[typeof name]>
			| undefined;

		if (!control) continue;

		const value = propertyValue(
			control,
			initialValues[name],
			initialValues,
			baseInitialValues ?? initialValues
		);

		if (value !== undefined) values[name] = value;
	}

	return values;
}

export function propertyValue<T>(
	control: BasePropertyControl<T>,
	initialValue?: any,
	initialValues: Record<string, any> = {},
	baseInitialValues: Record<string, any> = initialValues
): T | DeepPartial<T> | undefined {
	switch (control.type) {
		case ControlType.Hidden:
			return initialValue ?? control.defaultValue;
		case ControlType.Date:
			return getControlValue(
				new Date(initialValue).toJSON() !== null,
				control,
				initialValue,
				initialValues,
				baseInitialValues
			);
		case ControlType.Color: // TODO
		case ControlType.File:
		case ControlType.Image:
		case ControlType.String:
			return getControlValue(
				typeof initialValue === "string",
				control,
				initialValue,
				initialValues,
				baseInitialValues
			);
		case ControlType.Number:
			return getControlValue(
				typeof initialValue === "number",
				control,
				initialValue,
				initialValues,
				baseInitialValues
			);
		case ControlType.Boolean:
			return getControlValue(
				typeof initialValue === "boolean",
				control,
				initialValue,
				initialValues,
				baseInitialValues
			);
		case ControlType.Enum:
			const enumControl = control as EnumControl<any>;
			return getControlValue(
				enumControl.options.includes(initialValue),
				enumControl,
				initialValue,
				initialValues,
				baseInitialValues
			);
		case ControlType.Array:
			const arrayControl = control as ArrayControl<any[]>;
			const arrayValue = getControlValue(
				Array.isArray(initialValue) && initialValue?.length > 0,
				arrayControl,
				initialValue?.length > 0 ? initialValue : undefined,
				initialValues,
				baseInitialValues
			);

			return (arrayValue
				?.slice(0, arrayControl.maxCount)
				.map(v =>
					propertyValue(
						arrayControl.propertyControl,
						v,
						undefined,
						baseInitialValues
					)
				) as unknown) as T;
		case ControlType.Object:
			const objectControl = control as ObjectControl<T>;

			const objectValue = getControlValue(
				typeof initialValues === "object",
				objectControl,
				initialValue,
				initialValues,
				baseInitialValues
			);

			return propertyValues(
				objectControl.propertyControls,
				objectValue,
				baseInitialValues
			);
	}
}

function getControlValue<T>(
	useInitial: boolean,
	control: BasePropertyControl<T>,
	initialValue?: any,
	initialValues: Record<string, any> = {},
	baseInitialValues: Record<string, any> = initialValues
): T | undefined {
	if (initialValue && useInitial) return initialValue;

	// Attempt to coerce value from initial values (if initial value is falsey or incorrect type)
	const coercedInitalValue = control.coerce?.(
		initialValue,
		initialValues,
		baseInitialValues
	);
	if (coercedInitalValue != null) return coercedInitalValue;

	// Fallback to default value (only if both initial and coerced value is nullish)
	return initialValue ?? control.defaultValue;
}
