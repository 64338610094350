import { ID } from "../types";
import { BaseResource } from "./BaseResource";
import { apiResourceUrl } from "@ploy-lib/core";

export interface SalespersonInfo {
	readonly salespersonId: ID;
	readonly firstname?: string;
	readonly lastname?: string;
	readonly fullName?: string;
	readonly email?: string;
	readonly phone?: string;
	readonly mobile?: string;
	readonly username?: string;
	readonly temporarySalesperson?: boolean;
}

export interface SalespersonParams {
	vendorId?: ID;
}

export class SalespersonResource extends BaseResource
	implements SalespersonInfo {
	readonly salespersonId: ID;
	readonly firstname?: string;
	readonly lastname?: string;
	readonly fullName?: string;
	readonly email?: string;
	readonly phone?: string;
	readonly mobile?: string;
	readonly fax?: string;
	readonly username?: string;
	readonly temporarySalesperson?: boolean;

	pk() {
		return this.salespersonId?.toString();
	}

	static urlTemplates = [apiResourceUrl("vendors/{vendorId}/salespersons")];
	static urlRoot = apiResourceUrl("salespersons");
}
