import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import {
	DployTextField,
	identityRecordOfFieldEditorOptions
} from "@ploy-ui/form-fields";
import { CommentType } from "@ploy-lib/rest-resources";
import { useDispatch, useVariableData } from "@ploy-lib/calculation";
import { InputFieldProps } from "../../types";
import { useAppLoad } from "../../appLoadContext";
import { FormattedMessage } from "react-intl";

const commentTypeEnumKeyLabels = Object.keys(CommentType).filter(
	x => typeof CommentType[x as any] === "string"
);

type Comments = Record<string, string>;

interface CommentFieldProps
	extends InputFieldProps<Comments | undefined | null> {
	placeholder?: string;
	label?: string;
	literal?: boolean;
	textAreaRows?: number;
}

const emptyComments: Readonly<Comments> = {};

export const CommentField = (props: CommentFieldProps) => {
	const {
		label,
		textAreaRows,
		options: { commentType },
		literal,
		form: { setFieldValue },
		field,
		alwaysEnabled,
		color,
		margin,
		variant,
		...rest
	} = props;

	const dispatch = useDispatch();
	const commentsVariable = useVariableData<Comments | undefined | null>(
		"Main",
		"Comments"
	);
	const [rowIncrease, setRowIncrease] = useState(0);

	const [showExpandButton, setExpandButton] = useState(false);

	const camelCasedCommentType =
		commentType.charAt(0).toLowerCase() + commentType.slice(1);

	const caseAdjustedCommentType = commentsVariable.value
		? commentType
		: camelCasedCommentType;

	const namedComments = commentsVariable?.value ?? field.value ?? emptyComments;

	const [stringValueOfComment, setStringValueOfComment] = useState(
		namedComments[caseAdjustedCommentType]
	);
	const appLoad = useAppLoad();

	useEffect(() => {
		setExpandButton(
			stringValueOfComment?.length > 0 &&
				canExpandTextArea(label, (textAreaRows || 10) + rowIncrease)
		);
	}, [textAreaRows, rowIncrease, stringValueOfComment, label]);

	const textAreaIsLiteral =
		!alwaysEnabled &&
		(literal ||
			(caseAdjustedCommentType === CommentType.LimitComment &&
				appLoad.isScored));

	const updateComments = (newCommentValue: string) => {
		let updatedComments = {
			...namedComments,
			[caseAdjustedCommentType]: newCommentValue
		};
		setStringValueOfComment(newCommentValue);

		if (field?.name) {
			setFieldValue(field.name, updatedComments);
		}
		dispatch({
			type: "patch",
			payload: {
				patches: [
					
					{
						target: "Comments",
						namespace: "Main",
						value: updatedComments,
						overwrite: true
					}
				]
			}
		});
	};

	return (
		<Grid xs={12}>
			<DployTextField
				{...rest}
				id={label}
				label={label}
				value={stringValueOfComment}
				onChange={e => {
					updateComments(e.target.value);
				}}
				multiline
				rows={(textAreaRows || 10) + rowIncrease}
				disabled={textAreaIsLiteral}
				margin="normal"
				variant={textAreaIsLiteral ? "noBorder" : "input"}	//	"input" is default
			/>
			{showExpandButton && (
				<Button onClick={() => setRowIncrease(rowIncrease + 10)}>
					<FormattedMessage
						id="ploy-ui.template-form.CommentField.show-more-button"
						defaultMessage="Vis mer"
						description="Button to show more text in CommentField"
					/>
				</Button>
			)}
		</Grid>
	);
};

const canExpandTextArea = (name?: string, currentLines?: number) => {
	if (!name) return false;

	const item = document.getElementById(name);
	if (!item) return false;

	if (!currentLines) currentLines = 10;
	var lht = 16;
	var lines = item.scrollHeight / lht;

	return lines > currentLines;
};

export const EditorCommentFields = identityRecordOfFieldEditorOptions({
	CommentField: {
		editableOptions: {
			placeholder: true,
			commentType: commentTypeEnumKeyLabels,
			textAreaRows: true
		}
	}
});
