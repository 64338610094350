import React, {
	useReducer,
	useCallback,
	useMemo,
	createContext,
	useContext
} from "react";

export interface FormContextValue {
	formIsLocked: boolean;
	lockForm: () => void;
	openForm: () => void;
}

const defaultContext: FormContextValue = {
	formIsLocked: false,
	lockForm: () => {},
	openForm: () => {}
};

const Context = createContext<FormContextValue>(defaultContext);
Context.displayName = "FormState";

export const FormProvider = Context.Provider;
export const FormConsumer = Context.Consumer;

export function useFormState() {
	return useContext(Context);
}

export function useFormStateManager(isFormReadOnly = false) {
	const [formIsLocked, dispatch] = useReducer(
		(state: boolean, action: { type: "LOCK_FORM" | "OPEN_FORM" }) => {
			let locked = state;
			switch (action.type) {
				case "LOCK_FORM":
					locked = true;
					break;
				case "OPEN_FORM":
					locked = false;
					break;
				default:
					break;
			}
			return locked;
		},
		isFormReadOnly
	);

	const lockForm = useCallback(() => dispatch({ type: "LOCK_FORM" }), []);
	const openForm = useCallback(() => dispatch({ type: "OPEN_FORM" }), []);

	return useMemo(
		() => ({
			lockForm,
			openForm,
			formIsLocked
		}),
		[lockForm, openForm, formIsLocked]
	);
}

export interface FormStateProviderProps {
	isFormReadOnly?: boolean;
	children: React.ReactNode;
}

export function FormStateProvider({
	isFormReadOnly,
	children
}: FormStateProviderProps) {
	const state = useFormStateManager(isFormReadOnly);

	return <FormProvider value={state}>{children}</FormProvider>;
}
