import { createContext, useContext } from "react";
import {
	TemplateSection,
	TemplateField,
	CalculationResponse,
	TemplateTableColumn
} from "@ploy-lib/types";

// TEMP: TS2742 - https://github.com/microsoft/TypeScript/issues/36675
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Context, Provider } from "react";

export interface IDebugProps {
	className?: string;
	onClick?: React.MouseEventHandler;
}

interface IGetSectionProps {
	(section: TemplateSection): IDebugProps;
	<Props = {}>(
		section: TemplateSection,
		preExistingPropts?: Props & IDebugProps
	): Props & IDebugProps;
}

interface IGetFieldProps {
	(field: TemplateField): IDebugProps;
	<Props = {}>(
		field: TemplateField,
		preExistingPropts?: Props & IDebugProps
	): Props & IDebugProps;
}

interface IGetTableColumnProps {
	(tableColumn: TemplateTableColumn): IDebugProps;
	<Props = {}>(
		tableColumn: TemplateTableColumn,
		preExistingPropts?: Props & IDebugProps
	): Props & IDebugProps;
}

export interface ITemplateDebugContext {
	enabled: boolean;
	isFieldVisible: (field: TemplateField, actualVisibilty: boolean) => boolean;
	getSectionProps: IGetSectionProps;
	getFieldProps: IGetFieldProps;
	getTableColumnProps: IGetTableColumnProps;
}

export const createDefaultTemplateDebugContext: () => ITemplateDebugContext = () => ({
	enabled: false,
	isFieldVisible: (f, actualVisibilty) => actualVisibilty,
	getSectionProps: <Props>(
		section: TemplateSection,
		preExistingProps?: Props & IDebugProps
	) => preExistingProps || {},
	getFieldProps: <Props>(
		field: TemplateField,
		preExistingProps?: Props & IDebugProps
	) => preExistingProps || {},
	getTableColumnProps: <Props>(
		tableColumn: TemplateTableColumn,
		preExistingProps?: Props & IDebugProps
	) => preExistingProps || {}
});

export const templateDebugContext = createContext<ITemplateDebugContext>(
	createDefaultTemplateDebugContext()
);
templateDebugContext.displayName = "TemplateDebugContext";

export const useTemplateDebugManager = () => {
	const state = useContext(templateDebugContext);

	return state;
};

export const useGetDebugSectionProps = () =>
	useTemplateDebugManager().getSectionProps;
export const useGetDebugFieldProps = () =>
	useTemplateDebugManager().getFieldProps;
export const useGetDebugTableColumnProps = () =>
	useTemplateDebugManager().getTableColumnProps;

export const TemplateDebugProvider = templateDebugContext.Provider;

//

export interface ICalcRuleDebugContext {
	enabled: boolean;
	setCalculationResponse: (
		calculationResponse: Readonly<CalculationResponse>
	) => void;
}

export const createDefaultCalcRuleContext: () => ICalcRuleDebugContext = () => ({
	enabled: false,
	setCalculationResponse: () => {}
});

export const calcRuleDebugContext = createContext<ICalcRuleDebugContext>(
	createDefaultCalcRuleContext()
);
calcRuleDebugContext.displayName = "CalcRuleDebugContext";

export const useCalcRuleDebugManager = () => {
	const state = useContext(calcRuleDebugContext);

	return state;
};

export const CalcRuleDebugProvider = calcRuleDebugContext.Provider;
