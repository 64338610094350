import React from "react";
import TextList from "./TextList";
import { applyPropertyControls, ControlType } from "../../property-controls";

import { v4 as uuidv4 } from "uuid";
import { isNotNull } from "@ploy-ui/form-fields/es/utils";
import { ContentVisibility, TextItem } from "./TextListItem";

applyPropertyControls(TextList, {
	items: {
		type: ControlType.Array,
		title: "Liste",
		description: (
			<>
				<p>All tekst støtter Markdown formatering</p>
			</>
		),
		coerce: (_, { content }: { content?: string }) => {
			if (!content) return;

			const lines = content.split("\n");

			const [, tableHeader, , ...rest] = lines;

			if (/^\s*\|.*\|\s*$/.test(tableHeader)) {
				const tableRows = rest.filter(x => x.startsWith("|"));
				const restMarkdown = rest.filter(x => !x.startsWith("|")).join("\n");
				const listItems = tableRows
					.map(x => x.match(/([^|]+)/g))
					.filter(isNotNull)
					.map<TextItem>(match => {
						return {
							id: uuidv4(),
							primary: match?.[0].trim(),
							tertiary: match?.[match.length - 1].trim()
						};
					});

				if (restMarkdown.trim()) {
					listItems[listItems.length - 1].content = restMarkdown;
				}

				return listItems;
			} else {
				const listItems = lines
					.map(x => x.match(/([^||]+)/g))
					.filter(isNotNull)
					.map<TextItem>(match => {
						const [label, value = undefined, field = undefined] = match;

						return {
							id: uuidv4(),
							primary: label.trim(),
							tertiary: value
								?.trim()
								.replace(/{value}/g, `{budgetResponsible_${field?.trim()}}`)
						};
					});

				return listItems;
			}
		},
		propertyControl: {
			type: ControlType.Object,
			title: "",
			propertyControls: {
				primary: {
					type: ControlType.String,
					title: "Hovedtekst"
				},
				secondary: {
					type: ControlType.String,
					title: "Undertekst"
				},
				tertiary: {
					type: ControlType.String,
					title: "Verdi"
				},
				content: {
					type: ControlType.String,
					title: "Innhold",
					multiline: true
				},
				iconClass: {
					type: ControlType.String,
					title: "Ikon",
					description: "Fyll inn navnet til et ikon fra Font Awesome"
				},
				id: {
					type: ControlType.Hidden,
					title: "Id"
				},
				hideDivider: {
					type: ControlType.Boolean,
					title: "Skjul skillelinje"
				},
				contentVisibility: {
					type: ControlType.Enum,
					title: "Innholdsvisning",
					options: Object.values(ContentVisibility),
					defaultValue: ContentVisibility.Always
				}
			}
		}
	},
	boxProps: {
		type: ControlType.Object,
		title: "Standard instillinger",
		propertyControls: {
			header: {
				type: ControlType.String,
				title: "Tittel",
				coerce: (_, boxProps, props: { content?: string }) => {
					if (props.content) {
						const [mdHeader] = props.content.split("\n");

						const [, header] = mdHeader.match(/\s*([^>#]+)$/) ?? [];
						return header;
					}
				}
			},
			iconClass: {
				type: ControlType.String,
				title: "Ikon",
				description: "Fyll inn navnet til et ikon fra Font Awesome",
				coerce: (_, boxProps, props: { content?: string }) => {
					if (props.content) {
						const [mdHeader] = props.content.split("\n");

						const [, iconClass] = mdHeader.match(/fa fa-([-\w]+)/) ?? [];
						return iconClass;
					}
				}
			},
			backgroundColor: {
				type: ControlType.Color,
				title: "Bakgrunnsfarge",
				description: "Bakgrunnsfarge for boksen",
				coerce: (_, boxProps) => {
					if (boxProps.grey) return "grey.200";
				}
			},
			headerColor: {
				type: ControlType.Color,
				title: "Tittelfarge",
				description: "Tekstfarge som blir brukt på tittel og ikon",
				coerce: (_, boxProps) => {
					if (boxProps.grey) return "grey.500";
				}
			}
		}
	}
});

export default TextList;
