import { BaseCamelCasedResource } from "./BasePlainResource";
import { legacyApiResourceUrl } from "@ploy-lib/core";

export enum MenuItemType {
	Custom,
	DB,
	OpenApplication,
	NewApplication,
	Authorization,
	CreditLimitIncrease,
	ExternalPopupWindow
}

export interface MenuItem {
	productType: number;
	enabled: boolean;
	lookId: string;
	type: MenuItemType;
	menuItemLabel: string;
}

export enum LandingPage {
	Classic = "Classic",
	Vulcan = "Vulcan",
	VulcanPortal = "VulcanPortal",
	Phoenix = "Phoenix"
}

export interface VulcanData {
	userFullName?: string;
	userEmail?: string;
	roleName: string | null;
	vendorName?: string;
	vendorId?: number;
	customerCategoryId: number;
	buildVersion: string | null;
	buildDate: string | null;
	credentialType: string;
	homePage: string | null;
	menuItems: MenuItem[];
	landingPage: LandingPage;
}

export class VulcanDataResource extends BaseCamelCasedResource
	implements VulcanData {
	readonly userFullName?: string;
	readonly userEmail?: string;
	readonly roleName: string | null = null;
	readonly vendorName?: string;
	readonly vendorId?: number;
	readonly customerCategoryId: number;

	readonly buildVersion: string | null;
	readonly buildDate: string | null;
	readonly credentialType: string;
	readonly homePage: string | null;
	readonly menuItems: MenuItem[];
	readonly landingPage: LandingPage = LandingPage.Phoenix;

	pk() {
		return "GetData";
	}

	static urlRoot = legacyApiResourceUrl("VulcanData");
}
