import React, { memo } from "react";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { Section, SectionProps } from "..";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { useCalculationField } from "@ploy-lib/calculation";

addRegisteredSectionLayout({
	name: "SpinnerWhenCalculatingSection",
	displayName: "SpinnerWhenCalculatingSection",
	settings: {}
});

const useStyles = makeStyles(theme => ({
	overlay: {
		backgroundColor: "rgba(0,0,0,0.1)",
		margin: -theme.spacing(2),
		height: "100%",
		position: "absolute",
		zIndex: 50,
		justifyContent: "center",
		alignItems: "center"
	}
}));
const SpinnerWhenCalculatingSection = memo((props: SectionProps) => {
	const calculationPending = useCalculationField({
		name: "CalculatePending",
		namespace: "Calculator"
	});

	const isPending = Number(calculationPending?.value) === 1;

	const classes = useStyles();

	return (
		<>
			{isPending && (
				<Grid container xs={12} spacing={1} className={classes.overlay}>
					<CircularProgress />
				</Grid>
			)}
			<Section {...props} />
		</>
	);
});

export { SpinnerWhenCalculatingSection };
