import React from "react";
import { FieldInputProps, useField } from "formik";
import {
	FormControl,
	FormHelperText,
	FormControlProps,
	FormLabel
} from "@material-ui/core";

export type FormikFormControlFieldsetProps = Omit<
	FormControlProps<"fieldset">,
	keyof FieldInputProps<any> | "control"
> & {
	label?: React.ReactNode;
	helperText?: React.ReactNode;
	name: string;
};

export function FormikFormControlFieldset(
	props: FormikFormControlFieldsetProps
) {
	const { name, helperText, label, children, ...rest } = props;

	const [, meta] = useField({ name });

	const error = meta.error;

	const showError = Boolean(error) && meta.touched;

	const helperOrErrorText = showError ? error : helperText;

	return (
		<FormControl component="fieldset" error={showError} {...rest}>
			{label && <FormLabel component="legend">{label}</FormLabel>}

			{children}

			{helperOrErrorText && (
				<FormHelperText>{helperOrErrorText}</FormHelperText>
			)}
		</FormControl>
	);
}
