import { useIntl } from "react-intl";
import { PrimitiveType, Options, FormatXMLElementFn } from "intl-messageformat";
import { useCallback } from "react";

import { formatMessage, MissingTranslationError } from "@formatjs/intl";

export interface MessageFormatter {
	(
		message: string,
		values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
		options?: Options
	): string;
	<T>(
		message: string,
		values?: Record<string, PrimitiveType | T | FormatXMLElementFn<T>>,
		options?: Options
	): Array<T | string> | string | T;
}

export function useFormatMessage(): MessageFormatter {
	const intl = useIntl();

	return useCallback(
		(
			message: string,
			values?: Record<
				string,
				PrimitiveType | FormatXMLElementFn<string, string>
			>,
			options?: Options
		) => {
			return formatMessage(
				{
					locale: intl.locale,
					timeZone: intl.timeZone,
					formats: intl.formats,
					defaultLocale: intl.defaultLocale,
					defaultFormats: intl.defaultFormats,
					messages: intl.messages,
					onError: e => {
						/* Ignore MissingTranslationError */
						if (e.code !== "MISSING_TRANSLATION") intl.onError(e);
					}
				},
				intl.formatters,
				{ id: message, defaultMessage: message },
				values,
				options
			);
		},
		[intl.formatters, intl.locale]
	);
}
