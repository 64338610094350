import React, { useCallback } from "react";
import {
	TextFieldProps as FormikTextFieldProps,
	fieldToTextField
} from "formik-material-ui";
import {
	IntlNumberFieldProps,
	IntlNumberField
} from "./helpers/IntlNumberField";
import { BaseFieldProps, FieldEditorOptions } from "./types";
import { useFormikValueOnChange } from "./hooks";
import { Overwrite, Omit } from "@material-ui/types";
import {
	identityRecordOfFieldEditorOptions,
	getFieldErrorProps
} from "./utils";

export type NumberFieldProps = Overwrite<
	Omit<IntlNumberFieldProps, "onChange" | "value"> &
		FormikTextFieldProps &
		BaseFieldProps,
	{
		style: IntlNumberFieldProps["style"];
		formatString?: string;
	}
>;

const useFieldToIntlNumberField = ({
	items,
	getItemValue,
	getItemLabel,
	getItemId,
	valueKey,
	labelKey,
	getItemSuggestions,
	onSelectItem,
	manual,
	horizontal: row,
	multiple,
	pending,
	options,
	formatString,
	secondaryLabel,
	italicText,
	boldText,
	errorDisplay,
	modalText,
	onBlur: propsBlur,
	...props
}: NumberFieldProps): IntlNumberFieldProps => {
	const onChange = useFormikValueOnChange(props);

	const numberProps = fieldToTextField(
		props as FormikTextFieldProps
	) as IntlNumberFieldProps;

	const { onBlur: formikBlur } = numberProps;

	const onBlur = useCallback(
		(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (formikBlur) formikBlur(e);
			if (propsBlur) propsBlur(e);
		},
		[formikBlur, propsBlur]
	);

	return {
		...numberProps,
		...getFieldErrorProps(props, errorDisplay),
		onChange,
		onBlur,
		formatString,
		value:
			typeof numberProps.value === "number"
				? numberProps.value
				: Number(numberProps.value)
	};
};

export function CurrencyField(props: NumberFieldProps) {
	const numberProps = useFieldToIntlNumberField(props);
	return <IntlNumberField format="currency_input" {...numberProps} />;
}

export function PercentField(props: NumberFieldProps) {
	const numberProps = useFieldToIntlNumberField(props);
	return <IntlNumberField format="percent_input" {...numberProps} />;
}

export function NumberField(props: NumberFieldProps) {
	const numberProps = useFieldToIntlNumberField(props);
	return <IntlNumberField {...numberProps} />;
}

const commonOptions: FieldEditorOptions = {
	editableOptions: {
		placeholder: true,
		suffix: true
	}
};

export const EditorNumberFields = identityRecordOfFieldEditorOptions({
	CurrencyField: commonOptions,
	PercentField: commonOptions,
	NumberField: commonOptions
});
