import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Hidden, { HiddenProps } from "@material-ui/core/Hidden";

import MoreVert from "@material-ui/icons/MoreVert";

import { getIn } from "formik";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
	theme => ({
		root: {},
		expandedColumn: {
			position: "relative" as "relative"
		},
		editColumn: (props: { onRowClick?: any }) => ({
			width: 50,
			minWidth: 50,
			maxWidth: 50,
			"&:last-child": {
				padding: 0
			},
			"&:hover": {
				cursor: "pointer",
				background: props.onRowClick
					? theme.palette.grey[300]
					: theme.palette.grey[200]
			}
		}),
		emptyLastColumn: {
			width: 50,
			minWidth: 50,
			maxWidth: 50,
			"&:last-child": {
				padding: 0
			}
		},
		editIcon: {
			width: "100%"
		},
		clickableRow: {
			"&:hover": {
				cursor: "pointer",
				background: theme.palette.grey[200]
			}
		},
		head: {}
	}),
	{ name: "DployExpandableTable" }
);

export interface RowProps<TData extends object, TLoaded extends object> {
	children?: React.ReactNode;
	rowData: TData;
	loadedData?: TLoaded;
}

export interface CellDataRenderer<
	TData extends object,
	TLoaded extends object
> {
	(cell: any, row: TData, loaded: TLoaded | undefined): React.ReactNode;
}

export interface HiddenMethod<TData extends object, TLoaded extends object> {
	(cell: any, row: TData, loaded: TLoaded | undefined): boolean;
}

export interface ColumnDefinition<
	TData extends object,
	TLoaded extends object
> {
	id?: string;
	loadedId?: string;
	hidden?: HiddenProps | true | HiddenMethod<TData, TLoaded>;
	hiddenInExpandable?: HiddenProps | true | HiddenMethod<TData, TLoaded>;
	label?: string;
	format?: CellDataRenderer<TData, TLoaded>;
	tooltip?: CellDataRenderer<TData, TLoaded>;
	component?: React.ComponentType<RowProps<TData, TLoaded>>;
	className?: string;
	props?: object;
}

export interface ExpandableTableProps<
	TData extends object,
	TLoaded extends object
> {
	tableData: TData[];
	columnData: ColumnDefinition<TData, TLoaded>[];
	idColumn?: string;
	onRowClick?: ((row: TData) => void) | null;
	columnDataOverrides?: any;
	renderExpandedSection?: (data: TData) => React.ReactNode;
	tableType?: string;
	expandable?: boolean;
	classes?: Partial<ReturnType<typeof useStyles>>;
	size?: "medium" | "small" | undefined;
}

export const ExpandableTable = <
	TData extends object,
	TLoaded extends object = {}
>(
	props: ExpandableTableProps<TData, TLoaded>
) => {
	const {
		tableData,
		columnData,
		idColumn = "id",
		onRowClick,
		// columnDataOverrides,
		renderExpandedSection,
		tableType,
		expandable = true,
		size
	} = props;
	const [expanded, setExpanded] = useState<Record<string, boolean>>({});

	const classes = useStyles(props);

	const onMoreInfoClick = (row: TData) => (event: React.MouseEvent) => {
		event.stopPropagation();
		return setExpanded(state => {
			const id = row[idColumn];
			if (state[id]) {
				const { [id]: _, ...expanded } = state;
				return expanded;
			} else {
				return {
					...state,
					[id]: true
				};
			}
		});
	};
	// if (columnDataOverrides) {
	// 	columnData = columnDataOverrides
	// 		.map(cov => {
	// 			for (var index in columnData) {
	// 				if (columnData[index].id === cov.id) {
	// 					return { ...columnData[index], ...cov };
	// 				}
	// 			}
	// 			return cov;
	// 		})
	// 		.filter(col => col !== null);
	// }

	return (
		<Table size={size} className={classes.root} data-tabletype={tableType}>
			<Hidden xsDown>
				<TableHead>
					<TableRow>
						{columnData
							.filter(col => col.hidden !== true && col.id)
							.map((col, i) => (
								<Hidden key={i} {...col.hidden}>
									<TableCell
										classes={{ head: classes.head }}
										// classes={classes.headCell}
									>
										{col.label}
									</TableCell>
								</Hidden>
							))}
						{expandable && <TableCell className={classes.editColumn} />}
					</TableRow>
				</TableHead>
			</Hidden>
			<TableBody>
				{tableData &&
					tableData.map(row => (
						<React.Fragment key={row[idColumn]}>
							<TableRow
								onClick={onRowClick ? () => onRowClick(row) : undefined}
								className={onRowClick ? classes.clickableRow : undefined}
							>
								{columnData
									.filter(col => col.hidden !== true && col.id)
									.map(col => {
										const CellComponent = col.component || DefaultCell;
										const cellData = getIn(row, col.id!);

										return (
											<Hidden key={col.id!.toString()} {...col.hidden}>
												<TableCell className={col.className}>
													{CellComponent ? (
														<CellComponent rowData={row} {...col.props}>
															{cellData}
														</CellComponent>
													) : (
														cellData
													)}
												</TableCell>
											</Hidden>
										);
									})}
								{expandable && (
									<TableCell
										className={classes.editColumn}
										onClick={onMoreInfoClick(row)}
									>
										<MoreVert className={classes.editIcon} />
									</TableCell>
								)}
								{/*
								TODO: ask Åsmund what the heck is going on here? wh ydo we need an empty lastcolumn?
								{!expandable && (
									<TableCell className={classes.emptyLastColumn} />
								)} */}
							</TableRow>
							{expanded[row[idColumn]] ? (
								<TableRow>
									<TableCell
										colSpan={columnData.length + 1}
										className={classes.expandedColumn}
									>
										{renderExpandedSection && renderExpandedSection(row)}
									</TableCell>
								</TableRow>
							) : null}
						</React.Fragment>
					))}
			</TableBody>
		</Table>
	);
};

const DefaultCell = ({ children }) => <span>{children}</span>;
