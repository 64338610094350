import React from "react";
import { useResource } from "@rest-hooks/core";
import { Redirect } from "@reach/router";
import { LoginResource } from "@ploy-lib/rest-resources";

export const authenticated = ({
	authenticate = "/login"
}) => WrappedComponent => props => {
	const { isAuthenticated } = useResource(LoginResource.status(), {});

	if (isAuthenticated) return <WrappedComponent {...props} />;

	return (
		<Redirect noThrow to={authenticate} state={{ returnUri: props.uri }} />
	);
};

export const Authenticated = ({ children, authenticate, uri }) => {
	const { isAuthenticated } = useResource(LoginResource.status(), {});

	if (isAuthenticated) return children;

	return <Redirect noThrow to={authenticate} state={{ returnUri: uri }} />;
};
