import {
	ApplicationLimitDetail,
	ApplicationLimitDetailEntity
} from "@ploy-lib/rest-resources";
import { FieldType, TableColumnMetadata } from "./types";

export class ApplicationsTableSchema extends ApplicationLimitDetailEntity {
	static metaData: Partial<
		Record<keyof ApplicationLimitDetail, TableColumnMetadata>
	> = {
		applicationNumber: {
			fieldType: FieldType.Text
		},
		limitGuid: {
			fieldType: FieldType.Select
		}
	};
}
