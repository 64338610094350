import { apiResourceUrl } from "@ploy-lib/core";
import { Entity } from "@rest-hooks/rest";
import { BaseCamelCasedResource } from "./BaseResource";

export enum CommentType {
	NotSet = -1,
	ApplicationDraft = 0,
	ConditionsForApproval = 1,
	CommentInternal = 2,
	LimitComment = 4
}

export interface CommentResourceType {
	readonly commentId: number;
	readonly applicationId: number;
	readonly commentType: CommentType;
	readonly commentText: string;
	readonly commentCategory: string;
	readonly commentSubCategory: string;
	readonly changeDate?: Date | string;
	readonly isDraft?: boolean;
	readonly userFullName: string;
}

export interface CommentCategoriesType {
	readonly value: string;
	readonly text: string;
	readonly subCategories: CommentCategoriesType[];
}

export interface CommentSettingsResourceType {
	readonly applicationId: number;
	readonly commentType: CommentType;
	readonly standardTexts: string[];
	readonly commentCategories: CommentCategoriesType[];
}

export class CommentCategoriesEntity
	extends Entity
	implements CommentCategoriesType {
	readonly value: string;
	readonly text: string;
	readonly subCategories: CommentCategoriesType[];

	pk() {
		return this.value;
	}
}

export class CommentResource
	extends BaseCamelCasedResource
	implements CommentResourceType {
	readonly commentId: number = 0;
	readonly applicationId: number = 0;
	readonly applicationNumber: string = "";
	readonly commentType: CommentType = CommentType.NotSet;
	readonly commentText: string = "";
	readonly commentCategory: string = "";
	readonly commentSubCategory: string = "";
	readonly changeDate?: Date | string = new Date();
	readonly isDraft?: boolean = false;
	readonly userFullName: string = "";

	pk() {
		return this.commentId.toString() || "";
	}

	static urlRoot = apiResourceUrl("comment");
}

export class CommentSettingsResource
	extends BaseCamelCasedResource
	implements CommentSettingsResourceType {
	readonly applicationId: number = 0;
	readonly commentType: CommentType = CommentType.NotSet;
	readonly standardTexts: string[] = [];
	readonly commentCategories: CommentCategoriesType[] = [];

	static schema = {
		commentCategories: [CommentCategoriesEntity]
	};

	pk() {
		return `${this.applicationId}/${this.commentType}`;
	}

	static urlRoot = apiResourceUrl("comment/settings");
	// static urlTemplates = [
	// 	`${CommentSettingsResource.urlRoot}/{applicationId}/{commentType}`
	// ];
}
