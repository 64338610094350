import React, { useMemo, useCallback } from "react";

import {
	Grid,
	FormControl,
	makeStyles,
	Paper,
	Typography,
	FormHelperText
} from "@material-ui/core";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { TextField } from "@ploy-ui/form-fields";
import { PendingButton, PendingButtonProps } from "@ploy-ui/core";

import { Formik, Form, FastField, connect, FormikHelpers } from "formik";
import { object, string } from "yup";
import { CarOrderDetails } from "@ploy-lib/rest-resources";

const useStyles = makeStyles(theme => ({
	root: {
		whiteSpace: "pre-line" as "pre-line",
		padding: theme.spacing(4),
		margin: theme.spacing(2)
	}
}));

interface AddDriverProps {
	addDriver: () => void;
	loadedData?: CarOrderDetails;
	reloadData?: (params: object, body: object | undefined) => Promise<any>;
}

interface Driver {
	Lastname: string;
	Firstname: string;
	Phone: string | number;
	Email: string;
	EmployeeNumber: number | string;
	ApplicationId: number | string;
}

const messages = defineMessages({
	invalidLastname: {
		id: "form_fields.lastname.missing",
		defaultMessage: "Etternavn mangler",
		description: "text for missing lastname"
	},
	invalidFirstname: {
		id: "form_fields.fistname.missing",
		defaultMessage: "Fornavn mangler",
		description: "text for missing firstname"
	}
});

export function AddDriver(props: AddDriverProps) {
	const { addDriver, loadedData, reloadData } = props;

	const intl = useIntl();

	const classes = useStyles();

	const AddDriverSchema = useMemo(
		() =>
			object<Partial<Driver>>().shape({
				Lastname: string().required(
					intl.formatMessage(messages.invalidLastname)
				),
				Firstname: string().required(
					intl.formatMessage(messages.invalidFirstname)
				)
			}),
		[intl]
	);

	const submit = useCallback(
		async (data, formik) => {
			await onConfirm(data, formik);
			if (reloadData)
				await reloadData(
					{
						applicationId: loadedData?.applicationId
					},
					undefined
				);
			addDriver();
		},
		[addDriver, reloadData, loadedData]
	);

	if (!loadedData) return null;

	return (
		<Formik<Partial<Driver>>
			initialValues={{
				Lastname: "",
				Firstname: "",
				Phone: "",
				Email: "",
				EmployeeNumber: "",
				ApplicationId: loadedData.applicationId
			}}
			onSubmit={submit}
			validationSchema={AddDriverSchema}
			validateOnChange
			validateOnMount
		>
			{({ isSubmitting, status = {} }) => (
				<Form>
					<Paper className={classes.root}>
						<Typography variant={"h6"} paragraph>
							<FormattedMessage
								id="dealer.carorderAction.addDriver.header"
								description="Car order action add driver header"
								defaultMessage="Legg til fører"
							/>
						</Typography>
						<Grid container spacing={2} alignItems="flex-end">
							<Grid item xs={3}>
								<FastField
									name="Lastname"
									label={
										<FormattedMessage
											id="dealer.car.order.add.driver.lastname"
											description="text for driver lastname"
											defaultMessage="Etternavn"
										/>
									}
									component={TextField}
									margin="normal"
									variant="outlined"
									helperText=""
									disabled={isSubmitting}
									fullWidth
								/>
							</Grid>
							<Grid item xs={3}>
								<FastField
									name="Firstname"
									label={
										<FormattedMessage
											id="dealer.car.order.add.driver.firstname"
											description="text for driver firstname"
											defaultMessage="Fornavn "
										/>
									}
									component={TextField}
									margin="normal"
									variant="outlined"
									helperText=""
									disabled={isSubmitting}
									fullWidth
								/>
							</Grid>
							<Grid item xs={3}>
								<FastField
									name="Phone"
									label={
										<FormattedMessage
											id="dealer.car.order.add.driver.phone"
											description="text for driver phone"
											defaultMessage="Mobil "
										/>
									}
									component={TextField}
									margin="normal"
									variant="outlined"
									helperText=""
									disabled={isSubmitting}
									fullWidth
								/>
							</Grid>
							<Grid item xs={3}>
								<FastField
									name="Email"
									label={
										<FormattedMessage
											id="dealer.car.order.add.driver.email"
											description="text for driver email"
											defaultMessage="Email"
										/>
									}
									component={TextField}
									margin="normal"
									variant="outlined"
									helperText=""
									disabled={isSubmitting}
									fullWidth
								/>
							</Grid>
							<Grid item xs={3}>
								<FastField
									name="EmployeeNumber"
									label={
										<FormattedMessage
											id="dealer.car.order.add.driver.EmployeeNumber"
											description="text for driver employee number"
											defaultMessage="Ansattnummer"
										/>
									}
									component={TextField}
									margin="normal"
									variant="outlined"
									helperText=""
									disabled={isSubmitting}
									fullWidth
								/>
							</Grid>
						</Grid>
						<Grid container spacing={4}>
							<Grid item>
								<FormControl margin="normal">
									<SubmitButton
										variant="contained"
										size="large"
										color={"secondary"}
									>
										Legg til
									</SubmitButton>
									<FormHelperText
										color={
											status.success && !status.error ? "inherit" : "error"
										}
									>
										{status.error || status.message || " "}
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item>
								<FormControl margin="normal">
									<PendingButton
										variant="contained"
										onClick={() => addDriver()}
										size="large"
									>
										Avbryt
									</PendingButton>
									<FormHelperText
										color={
											status.success && !status.error ? "inherit" : "error"
										}
									>
										{status.error || status.message || " "}
									</FormHelperText>
								</FormControl>
							</Grid>
						</Grid>
					</Paper>
				</Form>
			)}
		</Formik>
	);
}

const onConfirm = async (data: Driver, formik: FormikHelpers<any>) => {
	const response = await fetch("/Driver/SaveDriver", {
		method: "POST",
		body: new Blob([JSON.stringify(data)], { type: "application/json" }),
		headers: {
			accept: "application/json"
		}
	});
	if (response.ok) {
		formik.setSubmitting(false);
	} else {
		formik.setStatus({ error: "" });
	}
};

const SubmitButton = connect<PendingButtonProps>(({ formik, ...props }) => (
	<PendingButton
		pending={formik.isSubmitting}
		disabled={!formik.isValid}
		onClick={() => {
			formik.submitForm();
		}}
		{...props}
	/>
));
