import qs from "qs";
import { WindowLocation, RouteComponentProps } from "@reach/router";
import mapValues from "lodash/mapValues";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";

export function parseQuery(location: Pick<WindowLocation, "search">) {
	const parsed = qs.parse(location.search, {
		ignoreQueryPrefix: true,
		allowDots: true
	});

	const handleValue = (v: any): any => {
		// Boolean
		if (v.toLowerCase?.() === "false") return false;
		if (v.toLowerCase?.() === "true") return true;

		// Number
		const num = Number.parseFloat(v);
		if (!Number.isNaN(num) && num.toString() === v) return num;

		if (isArray(v)) return v.map(handleValue);
		if (isObject(v)) return mapValues(v, handleValue);

		return v;
	};
	return mapValues(parsed, handleValue);
}

export function parsedQueryProps<TParams, P>(
	props: RouteComponentProps<TParams> & P
): typeof props {
	const query = props.location && parseQuery(props.location);

	return {
		...props,
		...query
	};
}

const paramRe = /^:(.+)/;

export function insertParams(path: string, params: { [key: string]: string }) {
	var segments = segmentize(path);
	return (
		"/" +
		segments
			.map(function (segment) {
				var match = paramRe.exec(segment);
				return match ? params[match[1]] : segment;
			})
			.join("/")
	);
}

export function segmentize(uri: string) {
	return (
		uri
			// strip starting/ending slashes
			.replace(/(^\/+|\/+$)/g, "")
			.split("/")
	);
}
