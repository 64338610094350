import { Box } from "@material-ui/core";
import { useVariableData } from "@ploy-lib/calculation";
import { BeneficialOwnerResource } from "@ploy-lib/rest-resources";
import { PendingButton } from "@ploy-ui/core";
import React, { memo } from "react";
import { useFetcher } from "@rest-hooks/core";
import { useAppLoad } from "../../appLoadContext";
import { useTemplateFieldIsVisible } from "../../hooks";
import { InputFieldProps } from "../../types";

export const GetBeneficialOwnerButtonFromCreditService = memo(
	(props: InputFieldProps) => {
		const { applicationNumber } = useAppLoad();

		const nameSpace = "MONEY_LAUNDERING";

		const { value: epId } = useVariableData(nameSpace, "EpGuid");

		var isFieldVisible = useTemplateFieldIsVisible();

		var includeZip = isFieldVisible({
			name: "IncludeZip",
			namespace: nameSpace
		});
		var fnameAndLnameForWCService = isFieldVisible({
			name: "FnameAndLnameForWCService",
			namespace: nameSpace
		});

		const getBeneficialOwnersFromCreditService = useFetcher(
			BeneficialOwnerResource.fromCreditService()
		);
		const onGetBeneficialOwnersFromCustomerService = () =>
			getBeneficialOwnersFromCreditService(
				{ epId: epId },
				undefined,
				[
					[
						BeneficialOwnerResource.list(),
						{
							epId,
							includeFirstAndLastname: fnameAndLnameForWCService,
							includeZip,
							applicationNumber
						},
						(beneficialownersFromCreditService: string[]) =>
							beneficialownersFromCreditService
					]
				]
			);
		return (
			<Box paddingY={2}>
				<PendingButton
					variant="contained"
					color="secondary"
					size="small"
					onClick={onGetBeneficialOwnersFromCustomerService}
				>
					{props.label}
				</PendingButton>
			</Box>
		);
	}
);
