import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BaseResource";

export interface ProductInfoLink {
	readonly linkText: string;
	readonly linkId: number;
	readonly linkUrl: string;
}

export class ProductInfoLinkResource extends BaseCamelCasedResource
	implements ProductInfoLink {
	readonly linkText: string;
	readonly linkId: number;
	readonly linkUrl: string;

	pk() {
		return `${this.linkId}`;
	}

	static urlRoot = legacyApiResourceUrl(
		"Product/GetAllProductInfoLinksForUser"
	);
}
