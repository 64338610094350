import { useCallback, useMemo, useRef } from "react";
import {
	FormikFormControlFieldset,
	FormikSubmitButton,
	FormikResetButton,
	DashboardGridResource,
	FormikFormControlLabel,
	FormikTextField
} from "@ploy-ui/dashboard";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormGroup,
	InputAdornment,
	useMediaQuery,
	useTheme
} from "@material-ui/core";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Form, Formik } from "formik";
import { useCache, useRetrieve } from "@rest-hooks/core";
import { saveAs } from "file-saver";
import { array, object, string } from "yup";
import { isNotNull } from "@ploy-lib/core";

export interface DashboardsExportDialogProps {
	onClose: () => void;
	open: boolean;
}

const messages = defineMessages({
	dashboardRequired: {
		id: "dealer.dashboard.export.dashboards.validation-required",
		defaultMessage: "Velg ett eller flere dashboard å eksportere"
	},
	filenameRequired: {
		id: "dealer.dashboard.export.filename.validation-required",
		defaultMessage: "Oppgi filnavn som skal brukes ved eksportering"
	}
});

export function DashboardsExportDialog(props: DashboardsExportDialogProps) {
	const { onClose, open } = props;

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const dashboards = useCache(DashboardGridResource.list(), {}) ?? [];

	const allDashboardsPromise = useRetrieve(
		DashboardGridResource.list(),
		open ? { includeContent: true } : null
	);

	const initialValues = {
		dashboards: dashboards.map(d => d.pk()).filter(isNotNull),
		filename: `dashboards_${new Date().toISOString().slice(0, 10)}`
	};

	const dashboardsRef = useRef(dashboards);
	dashboardsRef.current = dashboards;

	const onSubmit = useCallback(
		async (values: typeof initialValues) => {
			// dashboardsRef will be updated with complete data when the "useRetrieve" resolves
			if (allDashboardsPromise) await allDashboardsPromise;

			const exporting = dashboardsRef.current.filter(d => {
				const pk = d.pk();
				return pk && values.dashboards.includes(pk);
			});

			saveAs(
				new Blob([JSON.stringify(exporting, null, 2)], {
					type: "application/json"
				}),
				values.filename
			);

			onClose();
		},
		[allDashboardsPromise, onClose]
	);

	const intl = useIntl();

	const validationSchema = useMemo(() => {
		return object<typeof initialValues>({
			dashboards: array(string())
				.required(intl.formatMessage(messages.dashboardRequired))
				.min(1, intl.formatMessage(messages.dashboardRequired)),
			filename: string().required(intl.formatMessage(messages.filenameRequired))
		});
	}, [intl]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullScreen={isMobile}
			aria-labelledby="dashboard-name-export-dialog-title"
		>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				onReset={onClose}
				validationSchema={validationSchema}
			>
				<>
					<DialogTitle id="dashboard-export-dialog-title">
						<FormattedMessage
							id="dealer.dasbhoard.export-dialog.title"
							defaultMessage="Eksporter dashboard"
						/>
					</DialogTitle>
					<DialogContent>
						<Form>
							<FormikFormControlFieldset
								margin="dense"
								id="dashboards"
								name="dashboards"
								label={
									<FormattedMessage
										id="dealer.dasbhoard.export-dialog.name.label"
										defaultMessage="Velg dashboard å eksportere"
									/>
								}
								fullWidth
							>
								<FormGroup>
									{dashboards.map(d => (
										<FormikFormControlLabel
											key={d.pk() ?? d.name}
											type="checkbox"
											name="dashboards"
											label={d.name}
											value={d.pk()}
										/>
									))}
								</FormGroup>
							</FormikFormControlFieldset>
							<FormikTextField
								autoFocus
								margin="dense"
								id="filename"
								name="filename"
								label={
									<FormattedMessage
										id="dealer.dasbhoard.export-dialog.filename.label"
										defaultMessage="Filnavn"
									/>
								}
								type="text"
								variant="outlined"
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">.json</InputAdornment>
									)
								}}
							/>
						</Form>
					</DialogContent>
					<DialogActions>
						<FormikResetButton color="primary">
							<FormattedMessage
								id="dealer.dasbhoard.export-dialog.reset.label"
								defaultMessage="Avbryt"
							/>
						</FormikResetButton>
						<FormikSubmitButton>
							<FormattedMessage
								id="dealer.dasbhoard.export-dialog.submit.label"
								defaultMessage="Eksporter"
							/>
						</FormikSubmitButton>
					</DialogActions>
				</>
			</Formik>
		</Dialog>
	);
}
