import React, { memo, useRef, useState } from "react";
import { PendingButton } from "@ploy-ui/core";
import {
	Button,
	ButtonGroup,
	Divider,
	ListItem,
	ListItemIcon,
	Menu,
	MenuItem,
	styled,
	Tooltip,
	useMediaQuery,
	useTheme
} from "@material-ui/core";
import { ID } from "@ploy-lib/types";
import { defineMessages, useIntl } from "react-intl";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AddBoxIcon from "@material-ui/icons/AddBox";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import TabletMacIcon from "@material-ui/icons/TabletMac";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";

const PhoneIphoneWideIcon = styled(PhoneIphoneIcon)({
	transform: "rotate(90deg)"
});

const breakpointIcons: Record<Breakpoint, typeof PhoneIphoneWideIcon> = {
	xs: PhoneIphoneIcon,
	sm: PhoneIphoneWideIcon,
	md: TabletMacIcon,
	lg: LaptopMacIcon,
	xl: DesktopMacIcon
};

const breakpointMessages = defineMessages<Breakpoint>({
	xs: {
		id: "dealer.dashboard.breakpoint.phone.tooltip",
		defaultMessage: "Mobil"
	},
	sm: {
		id: "dealer.dashboard.breakpoint.phone-wide.tooltip",
		defaultMessage: "Mobil landskap"
	},
	md: {
		id: "dealer.dashboard.breakpoint.tablet.tooltip",
		defaultMessage: "Tablet"
	},
	lg: {
		id: "dealer.dashboard.breakpoint.laptop.tooltip",
		defaultMessage: "PC skjerm"
	},
	xl: {
		id: "dealer.dashboard.breakpoint.desktop.tooltip",
		defaultMessage: "Stor PC skjerm"
	}
});

const messages = defineMessages({
	save: {
		id: "dealer.dashboard.save.tooltip",
		defaultMessage: "Lagre"
	},
	edit: {
		id: "dealer.dashboard.edit.tooltip",
		defaultMessage: "Rediger navn"
	},
	overflow: {
		id: "dealer.dashboard.overflow.tooltip",
		defaultMessage: "Flere alternativer"
	},
	add: {
		id: "dealer.dashboard.add.tooltip",
		defaultMessage: "Opprett nytt"
	},
	copy: {
		id: "dealer.dashboard.copy.tooltip",
		defaultMessage: "Kopier"
	},
	delete: {
		id: "dealer.dashboard.delete.tooltip",
		defaultMessage: "Slett"
	},
	export: {
		id: "dealer.dashboard.export.tooltip",
		defaultMessage: "Eksporter"
	},
	import: {
		id: "dealer.dashboard.import.tooltip",
		defaultMessage: "Importer"
	}
});

export interface DashboardEditActionsProps {
	selected: ID | undefined;
	isChanged: boolean;
	breakpoint?: Breakpoint;
	maxBreakpoint?: Breakpoint;
	dirtyBreakpoints?: Partial<Record<Breakpoint, boolean>>;
	onAdd?: () => void;
	onSave?: () => Promise<void> | void;
	onCopy?: () => void;
	onEdit?: () => void;
	onDelete?: () => Promise<void> | void;
	onExport?: () => Promise<void> | void;
	onImport?: () => Promise<void> | void;
	onChangeBreakpoint?: (breakpoint: Breakpoint | undefined) => void;
}

DashboardEditActionsImpl.displayName = "DashboardEditActions";
function DashboardEditActionsImpl(props: DashboardEditActionsProps) {
	const {
		selected,
		breakpoint,
		maxBreakpoint,
		isChanged,
		dirtyBreakpoints,
		onAdd,
		onSave,
		onCopy,
		onEdit,
		onDelete,
		onExport,
		onImport,
		onChangeBreakpoint
	} = props;

	const intl = useIntl();
	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.down("sm"));

	const maxBreakpointIndex =
		(maxBreakpoint && theme.breakpoints.keys.indexOf(maxBreakpoint)) ?? -1;

	const breakpoints = theme.breakpoints.keys.slice(0, 3);
	const anchorRef = useRef<any>();
	const [open, setOpen] = useState(false);

	const brkToggleButton = (value: Breakpoint, idx: number) => {
		const Icon = breakpointIcons[value];
		const title = intl.formatMessage(breakpointMessages[value]);

		const selected = breakpoint === value;
		const dirty = dirtyBreakpoints?.[value];

		return (
			<Button
				key={value}
				onClick={() => onChangeBreakpoint?.(selected ? undefined : value)}
				color={selected ? "primary" : dirty ? "secondary" : "inherit"}
				disabled={idx > maxBreakpointIndex}
			>
				<Tooltip title={title}>
					<Icon fontSize="small" titleAccess={title} />
				</Tooltip>
			</Button>
		);
	};

	return (
		<>
			<ButtonGroup size="small" variant="contained" color="primary">
				<PendingButton
					success={false}
					error={false}
					disabled={!selected || !isChanged}
					onClick={onSave}
				>
					<Tooltip title={intl.formatMessage(messages.save)}>
						<SaveIcon
							fontSize="small"
							titleAccess={intl.formatMessage(messages.save)}
						/>
					</Tooltip>
				</PendingButton>
				<Button disabled={!selected} onClick={onEdit}>
					<Tooltip title={intl.formatMessage(messages.edit)}>
						<EditIcon
							fontSize="small"
							titleAccess={intl.formatMessage(messages.edit)}
						/>
					</Tooltip>
				</Button>
				{!sm && (
					<Button onClick={onAdd}>
						<Tooltip title={intl.formatMessage(messages.add)}>
							<AddBoxIcon
								fontSize="small"
								titleAccess={intl.formatMessage(messages.add)}
							/>
						</Tooltip>
					</Button>
				)}
				{!sm && (
					<Button disabled={!selected} onClick={onCopy}>
						<Tooltip title={intl.formatMessage(messages.copy)}>
							<FileCopyIcon
								fontSize="small"
								titleAccess={intl.formatMessage(messages.copy)}
							/>
						</Tooltip>
					</Button>
				)}
				{!sm && (
					<PendingButton
						disabled={!selected}
						success={false}
						error={false}
						onClick={onDelete}
					>
						<Tooltip title={intl.formatMessage(messages.delete)}>
							<DeleteIcon
								fontSize="small"
								titleAccess={intl.formatMessage(messages.delete)}
							/>
						</Tooltip>
					</PendingButton>
				)}
			</ButtonGroup>

			{maxBreakpointIndex > 0 && (
				<ButtonGroup size="small" variant="contained">
					{breakpoints.slice(0, maxBreakpointIndex + 1).map(brkToggleButton)}
				</ButtonGroup>
			)}

			<ButtonGroup size="small" variant="contained">
				<Button
					ref={anchorRef}
					aria-controls="simple-menu"
					aria-haspopup="true"
					onClick={() => setOpen(true)}
				>
					<Tooltip title={intl.formatMessage(messages.overflow)}>
						<MoreVertIcon
							fontSize="small"
							titleAccess={intl.formatMessage(messages.overflow)}
						/>
					</Tooltip>
				</Button>
			</ButtonGroup>

			<Menu
				id="simple-menu"
				anchorEl={anchorRef.current}
				open={open}
				onClose={() => setOpen(false)}
			>
				{sm && [
					<MenuItem key="add" onClick={onAdd}>
						<ListItemIcon>
							<AddBoxIcon
								color="primary"
								fontSize="small"
								titleAccess={intl.formatMessage(messages.add)}
							/>
						</ListItemIcon>
						{intl.formatMessage(messages.add)}
					</MenuItem>,
					<MenuItem key="copy" onClick={onCopy} disabled={!selected}>
						<ListItemIcon>
							<FileCopyIcon
								color="primary"
								fontSize="small"
								titleAccess={intl.formatMessage(messages.copy)}
							/>
						</ListItemIcon>
						{intl.formatMessage(messages.copy)}
					</MenuItem>,
					<MenuItem key="delete" onClick={onDelete} disabled={!selected}>
						<ListItemIcon>
							<DeleteIcon
								color="primary"
								fontSize="small"
								titleAccess={intl.formatMessage(messages.delete)}
							/>
						</ListItemIcon>
						{intl.formatMessage(messages.delete)}
					</MenuItem>,
					<Divider key="divider-actions" />
				]}

				{maxBreakpointIndex < breakpoints.length - 1 && [
					<ListItem key="breakpoints">
						<ButtonGroup size="small" variant="contained">
							{breakpoints.map(brkToggleButton)}
						</ButtonGroup>
					</ListItem>,
					<Divider key="divider-breakpoints" />
				]}

				<MenuItem onClick={onExport} disabled={!selected}>
					<ListItemIcon>
						<CloudDownloadIcon
							color="secondary"
							fontSize="small"
							titleAccess={intl.formatMessage(messages.export)}
						/>
					</ListItemIcon>
					{intl.formatMessage(messages.export)}
				</MenuItem>

				<MenuItem onClick={onImport}>
					<ListItemIcon>
						<CloudUploadIcon
							color="secondary"
							fontSize="small"
							titleAccess={intl.formatMessage(messages.import)}
						/>
					</ListItemIcon>
					{intl.formatMessage(messages.import)}
				</MenuItem>
			</Menu>
		</>
	);
}

export const DashboardEditActions = memo(DashboardEditActionsImpl);
