import React, { Suspense, useEffect, useState } from "react";
import { SectionProps } from "../../Section";
import { addRegisteredSectionLayout } from "../../sectionLayoutDescriptions";
import {
	Grid,
	makeStyles,
	Typography,
	Checkbox,
	Button,
	Tooltip
} from "@material-ui/core";
import { useDebounce } from "use-debounce";
import { DployAutocomplete, DployFormControl } from "@ploy-ui/form-fields";
import {
	CustomerResource,
	CustomerChildrenResource,
	LimitCustomerResource,
	LimitCustomerType,
	CustomerChildrenType
} from "@ploy-lib/rest-resources";
import { FormattedMessage, useIntl } from "react-intl";
import { useFetcher } from "@rest-hooks/core";
import { useStatefulResource } from "@rest-hooks/legacy";
import { useDispatch, useVariableData } from "@ploy-lib/calculation";
import clsx from "clsx";
import { Delete } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import { GroupInformation } from "./GroupInformation";
import { PendingButton } from "@ploy-ui/core";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import HelpIcon from "@material-ui/icons/Help";
import { createValidationHelpers } from "@ploy-lib/validation-helpers";

addRegisteredSectionLayout({
	name: "LimitCustomersSection",
	displayName: "LimitCustomersSection",
	settings: {}
});

const useStyles = makeStyles(theme => ({
	limitCustomersContainer: {
		marginTop: 5
	},
	header: {
		fontSize: 12,
		fontWeight: "bold"
	},
	customerName: {
		paddingLeft: 2
	},
	customerHeader: {
		paddingLeft: 2
	},
	mainCustomerName: {
		fontWeight: "bold"
	},
	deleteIcon: {
		fontSize: 20,
		"&:hover": {
			cursor: "pointer"
		}
	},
	checkboxes: {
		padding: 2
	},
	saveButton: {
		marginTop: 5
	},
	lowContrastText: {
		color: theme.palette.text.disabled
	},
	centerText: {
		textAlign: "center"
	}
}));

export const useModalStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	card: {
		flexBasis: 1000,
		maxHeight: "100vh",
		overflowY: "auto"
	},
	buttonContainer: {
		textAlign: "right"
	},
	button: {
		margin: 5
	}
}));

interface LimitCustomersSectionProps extends SectionProps {
	limitCustomers: LimitCustomerType[];
	addLimitCustomers: (newLimitCustomers: LimitCustomerType[]) => void;
	updateLimitCustomer: (limitCustomer: LimitCustomerType) => void;
	deleteLimitCustomer: (limitCustomer: LimitCustomerType) => void;
}

const LimitCustomersSection = (props: SectionProps) => {
	return <LimitCustomersCalcRulesWrapper {...props} />;
};

const LimitCustomersCalcRulesWrapper = (props: SectionProps) => {
	const dispatch = useDispatch();
	const limitCustomersVariable = useVariableData<LimitCustomerType[]>(
		"Calculator",
		"LIMIT_CUSTOMERS"
	);

	const limitCustomers = limitCustomersVariable.value || [];

	const addLimitCustomers = (newLimitCustomers: LimitCustomerType[]) =>
		dispatch({
			type: "patch",
			payload: {
				patches: [
					{
						target: "LIMIT_CUSTOMERS",
						namespace: "Calculator",
						value: [...limitCustomers, ...newLimitCustomers],
						overwrite: true
					}
				]
			}
		});
	const updateLimitCustomer = (limitCustomer: LimitCustomerType) => {
		var newLimitCustomers = limitCustomers.map(c => {
			if (c.customerId === limitCustomer.customerId) return limitCustomer;
			return c;
		});
		dispatch({
			type: "patch",
			payload: {
				patches: [
					{
						target: "LIMIT_CUSTOMERS",
						namespace: "Calculator",
						value: newLimitCustomers,
						overwrite: true
					}
				]
			}
		});
	};
	const deleteLimitCustomer = (limitCustomer: LimitCustomerType) => {
		dispatch({
			type: "patch",
			payload: {
				patches: [
					{
						target: "LIMIT_CUSTOMERS",
						namespace: "Calculator",
						value: limitCustomers.filter(
							c => c.customerId !== limitCustomer.customerId
						),
						overwrite: true
					}
				]
			}
		});
	};

	return (
		<InnerLimitCustomersSection
			{...props}
			limitCustomers={limitCustomers}
			addLimitCustomers={addLimitCustomers}
			updateLimitCustomer={updateLimitCustomer}
			deleteLimitCustomer={deleteLimitCustomer}
		/>
	);
};

const InnerLimitCustomersSection = (props: LimitCustomersSectionProps) => {
	const {
		limitCustomers,
		addLimitCustomers,
		updateLimitCustomer,
		deleteLimitCustomer,
		literal,
		className,
		onClick,
		collapsedSection
	} = props;
	const classes = useStyles();
	const intl = useIntl();
	const [touched, setTouched] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [searchString] = useDebounce(searchValue, 350);
	const [clickSearchPending, setClickSearchPending] = useState(false);
	const [newChildIds, setNewChildIds] = useState([] as number[]);

	const validation = createValidationHelpers(intl.locale);

	const mainCustomer =
		limitCustomers.length > 0 ? limitCustomers[0] : ({} as LimitCustomerType);

	const { data: searchedCustomers, loading } = useStatefulResource(
		CustomerResource.list(),
		searchString && searchString.length > 2
			? {
					data: searchString
			  }
			: null
	);

	const { data: customerChildren } = useStatefulResource(
		CustomerChildrenResource.detail(),
		mainCustomer.customerId ? { customerId: mainCustomer.customerId } : null
	);
	const updateChildren = useFetcher(CustomerChildrenResource.update());

	return (
		<Grid container className={className} onClick={onClick} spacing={0}>
			{literal ? null : (
				<>
					<Grid item container xs={12}>
						<Grid item xs={7}>
							<DployFormControl fullWidth margin="normal">
								<DployAutocomplete
									label={
										<FormattedMessage
											id={`limit-customers-section.limitCustomer.searchField.label`}
											description="Label for searchfield"
											defaultMessage="Org.nr"
										/>
									}
									placeholder={intl.formatMessage({
										id: `limit-customers-section.limitCustomer.searchField.label`,
										description: "Placeholder for searchfield",
										defaultMessage: "Organisasjonsnr. (9 siffer)"
									})}
									disabled={mainCustomer == null || literal}
									key="competition-selector"
									variant="input"
									pending={loading}
									items={
										searchedCustomers?.filter(
											c =>
												!limitCustomers.find(
													f => f.customerId === parseInt(c.key)
												)
										) || []
									}
									getItemLabel={item => item.value}
									inputValue={searchValue}
									onInputChange={(e, value) => {
										if (e && e.type === "change")
											setSearchValue(value.replace(/\s/g, ""));
									}}
									onChange={(e, customer) => {
										setSearchValue("");
										addLimitCustomers([
											{
												customerId: parseInt(customer?.key!),
												customerName: customer?.value || ""
											} as LimitCustomerType
										]);
									}}
									searchable
									disableClearable
									error={touched && !validation.validOrgNumber(searchValue)}
									helperText={
										touched && !validation.validOrgNumber(searchValue)
											? intl.formatMessage({
													id:
														"limit-customer-section.limitCustomer.searchField.Errormessage",
													description: "Errormessage for searchfield",
													defaultMessage:
														"Organisasjonsnummer må bestå av 9 siffer"
											  })
											: ""
									}
									onBlur={() => setTouched(true)}
								/>
							</DployFormControl>
						</Grid>

						<Grid item xs={5}>
							<DployFormControl margin="normal">
								<PendingButton
									disabled={literal}
									pending={clickSearchPending}
									variant="outlined"
									size="large"
									onClick={() => {
										if (!searchValue) return;
										setClickSearchPending(true);
										fetch(
											legacyApiResourceUrl(
												`/CustomerSearchJson/SearchForCustomer?identifier=${searchValue}`
											),
											{ method: "GET" }
										)
											.then(res => res.json())
											.then(res => {
												if (res[0].CustomerId) {
													setSearchValue("");

													addLimitCustomers([
														{
															customerId: res[0].CustomerId,
															customerName: res[0].CustomerName
														} as LimitCustomerType
													]);
												}
											})
											.finally(() => {
												setClickSearchPending(false);
											});
									}}
									success={false}
								>
									<SearchIcon />
								</PendingButton>
							</DployFormControl>
							<GroupInformation
								customerId={mainCustomer.customerId}
								limitCustomers={limitCustomers}
								addLimitCustomers={addLimitCustomers}
								customerSearchPending={clickSearchPending}
								disabled={literal}
							/>
						</Grid>
					</Grid>
				</>
			)}

			<Grid
				item
				xs={12}
				container
				className={clsx({
					[classes.limitCustomersContainer]: !collapsedSection
				})}
			>
				<Grid item xs={6}>
					<Typography
						variant="h6"
						className={clsx(classes.header, classes.customerHeader, {
							[classes.lowContrastText]: collapsedSection
						})}
					>
						<FormattedMessage
							id={`limit-customers-section.limitCustomer.customerName`}
							description="Choosen company"
							defaultMessage="Valgte selskap"
						/>
					</Typography>
				</Grid>
				<Grid item xs={2}>
					<Typography
						align={"center"}
						variant="h6"
						className={clsx(classes.header, classes.centerText, {
							[classes.lowContrastText]: collapsedSection
						})}
					>
						<FormattedMessage
							id={`limit-customers-section.limitCustomer.canDrawFromMainLimit`}
							description="Can company draw from main limit"
							defaultMessage="Hovedramme"
						/>
					</Typography>
				</Grid>
				{collapsedSection ? null : (
					<>
						<Grid item xs={2}>
							<Typography
								align={"center"}
								variant="h6"
								className={clsx(classes.header, classes.centerText, {
									[classes.lowContrastText]: collapsedSection
								})}
							>
								<FormattedMessage
									id={`limit-customers-section.limitCustomer.childCompany`}
									description="Child company"
									defaultMessage="Underselskap"
								/>
							</Typography>
						</Grid>
						<Grid item xs={2} style={{ textAlign: "center" }}>
							<Tooltip
								title={
									<Typography align={"center"} variant="body2">
										Hovedramme: Selskapet kan trekke på hovedramme
										<br />
										Underselskap: Selskapet er underselskap av hovedkunden
									</Typography>
								}
							>
								<HelpIcon />
							</Tooltip>
						</Grid>
					</>
				)}

				{limitCustomers?.map((row, index) => (
					<Grid
						key={row.customerId}
						item
						xs={12}
						container
						spacing={0}
						alignItems="center"
					>
						<Grid item xs={6}>
							<Typography
								className={clsx(classes.customerName, {
									[classes.mainCustomerName]:
										row.customerId === mainCustomer.customerId,
									[classes.lowContrastText]: collapsedSection
								})}
							>
								{row.customerName}
							</Typography>
						</Grid>
						<Grid item xs={2} className={classes.centerText}>
							<Checkbox
								className={classes.checkboxes}
								id={row.customerId?.toString() + row.customerName}
								checked={
									row.canDrawFromMainLimit ||
									row.customerId === mainCustomer.customerId
								}
								disabled={row.customerId === mainCustomer.customerId || literal}
								onClick={() => {
									updateLimitCustomer({
										...row,
										canDrawFromMainLimit: !row.canDrawFromMainLimit
									} as LimitCustomerType);
								}}
							/>
						</Grid>
						{collapsedSection ? null : (
							<Grid item xs={2} className={classes.centerText}>
								{row.customerId === mainCustomer.customerId ? null : (
									<Checkbox
										className={classes.checkboxes}
										checked={
											customerChildren?.childList?.includes(row.customerId) ||
											customerChildren?.recursiveChildList?.includes(
												row.customerId
											) ||
											newChildIds.includes(row.customerId) ||
											false
										}
										disabled={
											literal ||
											row.customerId === mainCustomer.customerId ||
											customerChildren?.childList?.includes(row.customerId) ||
											customerChildren?.recursiveChildList?.includes(
												row.customerId
											) ||
											false
										}
										//TODO: Different color if recursive child
										onClick={() => {
											if (!newChildIds.includes(row.customerId))
												setNewChildIds([...newChildIds, row.customerId]);
											else
												setNewChildIds([
													...newChildIds.filter(id => id !== row.customerId)
												]);
										}}
									/>
								)}
							</Grid>
						)}
						<Grid item xs={2} className={classes.centerText}>
							{literal || row.customerId === mainCustomer.customerId ? null : (
								<Delete
									className={classes.deleteIcon}
									onClick={() => deleteLimitCustomer(row)}
								/>
							)}
						</Grid>
					</Grid>
				))}
			</Grid>
			{literal ? null : (
				<Grid
					container
					item
					xs={12}
					className={clsx({
						[classes.limitCustomersContainer]: !collapsedSection
					})}
				>
					<Grid xs={7} />
					<Grid xs={5}>
						<DployFormControl>
							<Button
								className={classes.saveButton}
								color="secondary"
								size="large"
								disabled={literal}
								variant="contained"
								onClick={() =>
									updateChildren({}, {
										customerId: customerChildren!.customerId,
										newChildIds: newChildIds
									} as CustomerChildrenType)
								}
							>
								<Typography>
									<FormattedMessage
										id={`limit-customers-section.saveButton`}
										description="Save customer group button text"
										defaultMessage="Oppdater kundekort"
									/>
								</Typography>
							</Button>
						</DployFormControl>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};

LimitCustomersSection.displayName = "LimitCustomersSection";

export { LimitCustomersSection };
