import mapValues from "lodash/mapValues";
import {
	FormTemplate,
	TemplateField,
	TemplateSection,
	TemplatePanel,
	TemplatePage,
	TemplatePageDeprecated
} from "@ploy-lib/types";
import { hasPanels, isNotNull } from "./utils";

export function mapAllTemplateFields(
	template: FormTemplate<TemplatePage>,
	fieldMapper: (field: TemplateField) => TemplateField[]
): FormTemplate<TemplatePage> {
	const sectionWithFieldDefaults = (s: TemplateSection): TemplateSection => ({
		...s,
		fields: (s.fields || []).flatMap(fieldMapper).filter(isNotNull)
	});

	const panelWithFieldDefaults = (panel: TemplatePanel): TemplatePanel => ({
		...panel,
		sections: (panel.sections || []).map(sectionWithFieldDefaults)
	});

	const pageWithDefaults = (p: TemplatePage) => {
		if (!hasPanels(p))
			throw new Error(
				`Page without panels (${
					(p as TemplatePageDeprecated)?.layout
				}) not supported: ${template.name}`
			);

		return {
			...p,
			panels: mapValues(p.panels, panelWithFieldDefaults)
		};
	};

	return {
		...template,
		pages: (template.pages || []).map(pageWithDefaults)
	};
}
