import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CalculationForm } from "@ploy-ui/calculation-form";
import { renderTemplateWithRouteState } from "../Product/renderTemplate";
import { parsedQueryProps, RouteComponentProps } from "@ploy-lib/routing";
import { useInvalidator, useResource } from "@rest-hooks/core";
import { AppLoadResource } from "@ploy-lib/rest-resources";
import { Typography } from "@material-ui/core";
import { RouteNavigationProvider } from "../../components/RouteNavigationProvider";
import {
	AppActionProvider,
	FormStateProvider,
	useAppActionState
} from "@ploy-ui/template-form";

const appLoadShape = AppLoadResource.detail();

export interface ApplicationViewProps {
	applicationNumber?: string;
	formContext?: string;
	context?: string;
	initialize?: boolean;
	disallowedFieldRoles?: readonly string[];
	fromSubmit?: boolean;
	externalPostback?: (result: any) => void;
	formIsReadOnly?: boolean;
	AuthorizationCode?: string;
	FailedAuthorizationCode?: string;
}
export const ApplicationView = (
	props: RouteComponentProps<ApplicationViewProps>
) => {
	const { externalPostback, ...rest } = props;

	return (
		<RouteNavigationProvider {...props}>
			<AppActionProvider externalPostback={externalPostback}>
				<ApplicationViewInternal {...rest} />
			</AppActionProvider>
		</RouteNavigationProvider>
	);
};
export const ApplicationViewInternal = (
	props: RouteComponentProps<ApplicationViewProps>
) => {
	const { navigate, location, disallowedFieldRoles } = props;
	const {
		formContext,
		context,
		applicationNumber,
		initialize = true,
		fromSubmit,
		formIsReadOnly,
		AuthorizationCode,
		FailedAuthorizationCode
	} = parsedQueryProps(props);

	const [
		loadingApplicationAfterSubmission,
		setLoadingApplicationAfterSubmission
	] = useState(fromSubmit);

	const innerAppLoadPayload: object = useMemo(
		() => ({
			applicationNumber,
			fromSession: !initialize ? true : undefined,
			fromSubmit: loadingApplicationAfterSubmission,
			sblAuthCode: FailedAuthorizationCode || AuthorizationCode, // Altinn sends AuthCode when consent is accepted, and FailedAuthCode when consent is rejected,
			sblRejected: FailedAuthorizationCode ? true : undefined
		}),
		[
			applicationNumber,
			initialize,
			loadingApplicationAfterSubmission,
			FailedAuthorizationCode,
			AuthorizationCode
		]
	);
	const appLoad = useResource(appLoadShape, innerAppLoadPayload);
	const hasRouteState = navigate && location;
	const invalidateApp = useInvalidator(appLoadShape);

	const appAction = useAppActionState();
	const header = (
		<Typography style={{ fontWeight: "bold" }}>
			{appLoad.productName}
		</Typography>
	);

	const onSubmit = useCallback(
		(values: any, form: any) => {
			const { __calculation: { submitResult } = {} as any } = values;

			if (submitResult && appAction.externalPostback)
				appAction.externalPostback(submitResult);

			setLoadingApplicationAfterSubmission(true);
			invalidateApp({ applicationNumber });
		},
		[appAction, applicationNumber, invalidateApp]
	);

	useEffect(() => {
		if (appLoad.sblRedirectUrl) {
			window.location.href = appLoad.sblRedirectUrl;
		}
	}, [appLoad.sblRedirectUrl]);

	return applicationNumber ? (
		<FormStateProvider isFormReadOnly={formIsReadOnly}>
			<CalculationForm
				applicationNumber={applicationNumber || ""}
				appLoadPayload={innerAppLoadPayload}
				formContext={formContext}
				context={context}
				onSubmit={onSubmit}
				skipInitialize={!initialize}
				header={appLoad.settings.alwaysShowProductName ? header : null}
				disallowedFieldRoles={disallowedFieldRoles}
			>
				{hasRouteState && renderTemplateWithRouteState}
			</CalculationForm>
		</FormStateProvider>
	) : null;
};
