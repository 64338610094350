import { useState } from "react";
import { AppLoadResource } from "@ploy-lib/rest-resources";
import { useSnackbar } from "notistack";
import { useInvalidator } from "@rest-hooks/core";
import { useNavigationState } from "../NavigationContext";
import { useAppActionState } from "../AppActionContext";
import {
	useServiceHandler,
	useTemplateFieldIsVisible,
	useTemplateSectionIsVisible
} from ".";
import { useCallback } from "react";
import { FunctionButton } from "../components/AppActions";
import { ServiceResult } from "@ploy-lib/calculation";
import { touchAllVisibleFields } from "../components/TouchVisibleFieldsOnPreviousPages";
import { usePages } from "../pagesContext";
import { FormikProps } from "formik/dist/types";
import { useFormHasErrors } from "../components/FormStatus";
import { defineMessages, useIntl } from "react-intl";
import { useAppLoad } from "../appLoadContext";

export const messages = defineMessages({
	validationError: {
		id: "dealer.applications.actions.error.form-errors",
		description: "Error message for validation error",
		defaultMessage: "Skjema har valideringsfeil"
	}
});

interface AppActionClickProps {
	button?: FunctionButton;
	actionEvent?: string;
	onClose?: () => any;
	disableValidation?: boolean;
	form: FormikProps<any>;
}
export const useAppActionClick = (props: AppActionClickProps) => {
	const {
		button,
		actionEvent,
		onClose,
		disableValidation = false,
		form
	} = props;
	const { touched, setTouched, errors } = form;

	const formHasErrors = useFormHasErrors();
	const pages = usePages();

	const intl = useIntl();

	const fieldIsVisible = useTemplateFieldIsVisible();
	const sectionIsVisible = useTemplateSectionIsVisible();

	const [pending, setPending] = useState(false);
	const [success, setSuccess] = useState<boolean>(false);

	const { enqueueSnackbar = () => {} } = useSnackbar() || {};

	const { applicationNumber } = useAppLoad();
	const invalidateApp = useInvalidator(AppLoadResource.detail());

	const navigation = useNavigationState();
	const appAction = useAppActionState();

	const [serviceName] = button?.name.split("_") ?? [];
	const service = serviceName
		? {
				namespace: "Main",
				service: serviceName
		  }
		: undefined;

	const [invokeButtonService] = useServiceHandler(service);

	const onClickButton = useCallback(
		async e => {
			var allTouched;
			if (!disableValidation) {
				allTouched = touchAllVisibleFields(
					touched,
					pages,
					fieldIsVisible,
					sectionIsVisible
				);
				setTouched(allTouched);
			}
			setPending(true);

			if (invokeButtonService && button) {
				try {
					if (formHasErrors({ errors, touched: allTouched }, undefined, true)) {
						enqueueSnackbar(intl.formatMessage(messages.validationError), {
							variant: "error"
						});
					} else {
						const response = (await invokeButtonService(
							e,
							undefined,
							actionEvent
								? {
										actionEvent: actionEvent,
								  }
								: undefined
						)) as ServiceResult;
						if (response.ok) {
							const data = await response.data;

							if (data.Messages && data.Messages.length > 0) {
								for (const m of data.Messages) {
									enqueueSnackbar(m, {
										variant: "error"
									});
								}
							} else if (!data.ApplicationNumber) {
								enqueueSnackbar("En feil oppstod", {
									variant: "error"
								});
							} else {
								if (button.successMessage) {
									enqueueSnackbar(button.successMessage, {
										variant: "success"
									});
									setSuccess(true);
								}

								if (
									appAction.externalPostback &&
									(data.DoPostBack ||
										data.ApplicationNumber !== applicationNumber)
								) {
									appAction.externalPostback(data);
								} else {
									if (data.DoPostBack)
										invalidateApp({
											applicationNumber: data.ApplicationNumber
										});

									navigation.application(data.ApplicationNumber);
								}
							}
						} else {
							enqueueSnackbar(`Nettverksfeil: ${response.error}`, {
								variant: "error"
							});
						}
					}
				} catch (e: any) {
					enqueueSnackbar(e.message, {
						variant: "error"
					});
					console.error(e);
				} finally {
					setPending(false);
					onClose && onClose();
				}
			}
		},
		[
			disableValidation,
			invokeButtonService,
			button,
			touched,
			pages,
			fieldIsVisible,
			sectionIsVisible,
			setTouched,
			formHasErrors,
			errors,
			enqueueSnackbar,
			intl,
			actionEvent,
			appAction,
			applicationNumber,
			invalidateApp,
			navigation,
			onClose
		]
	);

	return {
		pending,
		disabled: button?.disabled ?? undefined,
		success: success,
		onClickButton
	};
};
