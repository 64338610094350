import * as React from "react";

import { Typography, Theme, Divider } from "@material-ui/core";
import Grid, { GridJustification } from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";

import { FieldContainer, fieldToKey } from "../field";
import { makeStyles } from "@material-ui/core/styles";
import { TemplateSection, TemplateField } from "@ploy-lib/types";
// import { useCalculationFields } from "@ploy-lib/calculation";
// import { useEffect } from "react";

const useStyles = makeStyles(
	(theme: Theme) => ({
		root: {
			position: "relative",
			backgroundColor: "inherit"
		},
		paper: {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1)
		},
		header: {
			fontWeight: 700,
			padding: theme.spacing(0.5)
		},
		headerMarginLiteral: {},
		headerSimpleCard: {
			paddingLeft: 12,
			paddingRight: 12,
			paddingTop: 12,
			alignItems: "center",
			display: "flex",
			fontWeight: 400
		},
		outline: ({ outline, square }: SectionProps) => ({
			border: `1px solid ${
				(outline && theme.palette[outline] && theme.palette[outline].main) ||
				theme.palette.secondary.main
			}`
		})
	}),
	{ name: "DployFormSection" }
);

export interface SectionProps extends TemplateSection {
	className?: string;
	classes?: any;
	literal?: boolean;
	justify?: GridJustification;
	collapsedSection?: boolean;
	onClick?: () => void;
	setSectionCompleted?: (
		id: TemplateSection["formTemplateSectionId"],
		completed: boolean
	) => void;
	allFields?: TemplateField[];
}

const Section = (props: SectionProps) => {
	const {
		className,
		classes = {},
		fields = [],
		sectionTitle,
		literal,
		justify,
		onClick,
		useSeparator,
		collapsedSection,
		// showStatus = false,
		// setSectionCompleted,
		// formTemplateSectionId,
		elevation = 0,
		square,
		outline,
		innerWidthSM
	} = props;

	const sectionClasses = useStyles(props);

	const fieldGroups = fields.reduce((acc, f) => {
		if (f.alignWithPrevious && acc.length > 0) {
			acc[acc.length - 1].push(f);
		} else {
			acc.push([f]);
		}
		return acc;
	}, [] as TemplateField[][]);

	// This logic causes performance issues, particularly in IE11
	// useCalculationFields causes rerender for every state change

	// const calculationFields = useCalculationFields(...fields);

	// const allFieldsCompleted = !calculationFields?.some(
	// 	calcField =>
	// 		calcField?.error ||
	// 		calcField?.missing ||
	// 		calcField?.value !== calcField?.initialFieldValue
	// );

	// useEffect(() => {
	// 	if (showStatus)
	// 		setSectionCompleted?.(formTemplateSectionId, allFieldsCompleted);
	// }, [
	// 	formTemplateSectionId,
	// 	allFieldsCompleted,
	// 	setSectionCompleted,
	// 	showStatus
	// ]);

	return (
		<Grid
			component={Paper}
			elevation={elevation}
			square={square}
			container
			className={clsx(sectionClasses.root, className, {
				[sectionClasses.paper]: elevation > 0 || outline,
				[sectionClasses.outline]: outline
			})}
			justify={justify}
			spacing={1}
			onClick={onClick}
		>
			{sectionTitle && (
				<Typography
					className={clsx(sectionClasses.header, {
						[sectionClasses.headerMarginLiteral]:
							literal ||
							(fields.some(x => x) && fields.every(field => field.literal)),
						[sectionClasses.headerSimpleCard]:
							!props.hideSectionIfNoVisibleFields && !fields.some(x => x)
					})}
					variant={
						!props.hideSectionIfNoVisibleFields && !fields.some(x => x)
							? "h5"
							: "body1"
					}
				>
					{sectionTitle}
				</Typography>
			)}

			{fieldGroups.map(fields => {
				if (fields.length === 0) return null;

				return (
					<FieldContainer
						key={fieldToKey(fields[0])}
						fields={fields}
						justify={justify}
						literal={literal}
						lowContrastText={collapsedSection}
						className={classes.fieldContainer}
						linkedHelperText={(innerWidthSM ?? 12) < 12}
					/>
				);
			})}
			{useSeparator && (
				<Grid item xs={12}>
					<Divider orientation="horizontal" />
				</Grid>
			)}
		</Grid>
	);
};

Section.displayName = "DployFormSection";

export default React.memo(Section);
