import { Financial } from "./financial";
import { WithGetSet } from "./types";

function CalculateEndBalanceOnCurrentPaySeriesUsingRemainingTerms(
	data: WithGetSet<number>,
	periodNo = 0
): number {
	return CalculateEndBalanceInternal(
		data,
		periodNo,
		data.get("Interest_" + periodNo)!
	);
}

function CalculateEndBalanceOnCurrentPaySeriesUsingRemainingTermsAndBaseRate(
	data: WithGetSet<number>,
	periodNo = 0
): number {
	return CalculateEndBalanceInternal(data, periodNo, data.get("BaseRate")!);
}

function CalculateEndBalanceOnTermValuePlusVat(
	data: WithGetSet<number>,
	periodNo = 0
): number {
	return (
		CalculateEndBalanceOnCurrentPaySeriesUsingRemainingTerms(data, periodNo) -
		data.get("VATAmount")!
	);
}

function CalculateEndBalanceOnRefinanceAmount(
	data: WithGetSet<number>,
	periodNo = 0
): number {
	const amount = data.get("ExistingLoanSum")!;
	const interest = data.get("Interest_0")!;
	const termLength = data.get("TermLength")!;
	const calcType = data.get("CalculationType") || 0;

	let terms = 0;

	for (let i = 0; i < data.get("PayseriesCount")!; i++) {
		terms += data.get("TermsCount_" + i)!;
	}

	const periodDividedInterest = interest / 100 / (12 / termLength);

	const pmt = Financial.PMT(
		periodDividedInterest,
		terms,
		-amount,
		0,
		calcType
	);

	const firstPaySerieTerms = data.get("TermsCount_0")!;
	const endBalanceOfFirstPaySerie = Financial.FV(
		periodDividedInterest,
		firstPaySerieTerms,
		pmt,
		-amount,
		calcType
	);

	return endBalanceOfFirstPaySerie;
}

function CalculateEndBalanceOnTermValue(
	data: WithGetSet<number>,
	periodNo = 0
): number {
	const interest = data.get("Interest_" + periodNo)!;
	const termLength = data.get("TermLength")!;
	const loanAmount =
		periodNo === 0
			? data.get("LoanAmount")!
			: data.get("EndBalance_" + (periodNo - 1))!;
	const calcType = data.get("CalculationType") || 0;

	const periodDividedInterest = interest / 100 / (12 / termLength);

	const currentPaySerieTerms = data.get("TermsCount_" + periodNo)!;

	const termAmount = data.get("NetTermAmount_" + periodNo)!;

	const endBalanceOfCurrentPaySerie = Financial.FV(
		periodDividedInterest,
		currentPaySerieTerms,
		termAmount,
		-loanAmount,
		calcType
	);

	return endBalanceOfCurrentPaySerie;
}

export function CalculateEndBalanceInternal(
	data: WithGetSet<number>,
	periodNo = 0,
	interest: number
): number {

	const termLength = data.get("TermLength")!;
	const loanAmount =
		periodNo === 0
			? data.get("LoanAmount")!
			: data.get("EndBalance_" + (periodNo - 1))!;
	const calcType = data.get("CalculationType") || 0;

	const periodDividedInterest = interest / 100 / (12 / termLength);

	const currentPaySerieTerms = data.get("TermsCount_" + periodNo)!;

	let remainingNumTerms = 0;

	for (let i = periodNo; i < data.get("PayseriesCount")!; i++) {
		remainingNumTerms += data.get("TermsCount_" + i)!;
	}

	// Compute payment using current interest and the _remaining_ number of terms
	const pmtWithCurrentInterest = Financial.PMT(
		periodDividedInterest,
		remainingNumTerms,
		-loanAmount,
		data.get("EndBalance_" + ((data.get("PayseriesCount") || 0) - 1)) || 0, // Get the last end balance as the residual (calculated first)
		calcType
	);

	// Determine the future (residual) value when paying down currentPaySerieTerms number of terms with pmtWithCurrentInterest as payment each term
	// given loamAmount as starting point
	const endBalanceOfCurrentPaySerie = Financial.FV(
		periodDividedInterest,
		currentPaySerieTerms,
		pmtWithCurrentInterest,
		-loanAmount,
		calcType
	);

	return endBalanceOfCurrentPaySerie;
}

export const EndBalanceBehaviors = {
	CalculateEndBalanceOnCurrentPaySeriesUsingRemainingTerms,
	CalculateEndBalanceOnCurrentPaySeriesUsingRemainingTermsAndBaseRate,
	CalculateEndBalanceOnTermValuePlusVat,
	CalculateEndBalanceOnTermValue,
	CalculateEndBalanceOnRefinanceAmount
};
