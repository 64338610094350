import { TemplatePanel, TemplateSection, TemplateField } from "@ploy-lib/types";

import { defineMessages, IntlShape } from "react-intl";

const translateFieldLable = (translation: {}, panel: TemplatePanel) => {
	const newSections = panel?.sections?.map(section =>
		translateList<TemplateField>(translation, section.fields ?? [], "label")
	);
	return { ...panel, section: newSections };
};
const translateGroup = (translation: {}, panel: TemplatePanel) => {
	const newSections = translateList<TemplateSection>(
		translation,
		panel?.sections ?? [],
		"expansionGroup"
	);
	return { ...panel, sections: newSections };
};
const translateTabGroup = (
	translation: {},
	panel: TemplatePanel
): TemplatePanel => {
	if (panel?.sections === null) return panel;
	const newSections = translateList<TemplateSection>(
		translation,
		panel?.sections ?? [],
		"tabGroup"
	);
	return { ...panel, sections: newSections };
};

/**
 * Translate properties of each element in the list from translation-key to translation-value
 * if the property exist in the element of the list and the value is the same as translation-key
 * @param translation
 * @param list
 * @param property
 */
function translateList<T>(translation: {}, list: T[], property: string): T[] {
	if (list === null) return list;
	const newList = list.reduce((acc: T[], element: T) => {
		if (Object.keys(translation).some(t => element[property] === t))
			element[property] = translation[element?.[property]];
		acc.push(element);
		return acc;
	}, []) as T[];
	return newList;
}

export const translateSideBar = (
	intl: IntlShape,
	sidebar: TemplatePanel
): TemplatePanel => {
	const translation = {
		Summary: intl.formatMessage(messages.summary),
		PaymentPlanSection: intl.formatMessage(messages.paymentplansection)
	};
	sidebar = translateTabGroup(translation, sidebar);
	return sidebar;
};

export const translateSummary = (
	intl: IntlShape,
	summary: TemplatePanel
): TemplatePanel => {
	const translation = {
		Summary: intl.formatMessage(messages.summary),
		PaymentPlanSection: intl.formatMessage(messages.paymentplansection)
	};
	summary = translateTabGroup(translation, summary);
	return summary;
};

export const translateMain = (
	intl: IntlShape,
	main: TemplatePanel
): TemplatePanel => {
	const translation = {
		Summary: intl.formatMessage(messages.summary),
		Commission: intl.formatMessage(messages.commissionsection),
		DocumentUploadSection: intl.formatMessage(messages.documentuploadsection),
		Cosigner: intl.formatMessage(messages.cosignersection),
		Signing: intl.formatMessage(messages.signingsection),
		Fulfillment: intl.formatMessage(messages.fulfillmentsection),
		Refinancing: intl.formatMessage(messages.refinancingsection),
		InputFields: intl.formatMessage(messages.inputfieldssection),
		IncomeExpenses: intl.formatMessage(messages.incomeexpensessection)
	};
	main = translateGroup(translation, main);
	return main;
};

export const translateSidebarActions = (
	intl: IntlShape,
	sidebarAction: TemplatePanel
): TemplatePanel => {
	const translation = {
		CalculatorContinue: intl.formatMessage(messages.calculatorcontinue)
	};
	sidebarAction = translateFieldLable(translation, sidebarAction);
	return sidebarAction;
};
const messages = defineMessages({
	summary: {
		id: "form.sidebar.tab.summary",
		description: "Title of summary tab",
		defaultMessage: "Oppsummering"
	},
	paymentplansection: {
		id: "form.sidebar.tab.paymentplan",
		description: "Title of paymentplansection",
		defaultMessage: "Betalingsplan"
	},
	commissionsection: {
		id: "form.summary.commission",
		description: "Provision summary section expansionGroup",
		defaultMessage: "Provisjoner"
	},
	documentuploadsection: {
		id: "form.summary.documentupload",
		description: "Document upload section expansionGroup",
		defaultMessage: "Dokumentopplasting"
	},
	cosignersection: {
		id: "form.summary.cosigner",
		description: "Cosigner section expansionGroup",
		defaultMessage: "Medsøker"
	},
	signingsection: {
		id: "form.summary.signing",
		description: "Signing section expansionGroup",
		defaultMessage: "Signering"
	},
	fulfillmentsection: {
		id: "form.summary.fulfillment",
		description: "Fulfillment section expansionGroup",
		defaultMessage: "Innfrielser"
	},
	refinancingsection: {
		id: "form.summary.refinancing",
		description: "Refinancing section expansionGroup",
		defaultMessage: "Refinansiering"
	},
	inputfieldssection: {
		id: "form.summary.inputfields",
		description: "Inputfields section expansionGroup",
		defaultMessage: "Tillegsinfo"
	},
	incomeexpensessection: {
		id: "form.summary.incomeexpenses",
		description: "Income expenses section expansionGroup",
		defaultMessage: "Inntekter"
	},
	calculatorcontinue: {
		id: "dealer.applications.actions.new-application.label",
		description: "Label for continue button in calculator",
		defaultMessage: "Ny søknad"
	}
});
