import { useState } from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { useDrawer } from "../../../contexts";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { makeStyles, fade } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { Box } from "@material-ui/core";
import { useNavigate } from "@reach/router";
import { ApplicationInfoResource } from "@ploy-lib/rest-resources";
import { useDebounce } from "use-debounce/lib";
import { useStatefulResource } from "@rest-hooks/legacy";

export const messages = defineMessages({
	searchFailed: {
		id: "core.dploy-search.search-failed",
		defaultMessage: "Søk feilet",
		description: "Search failed"
	},
	minSearchText: {
		id: "core.dploy-search.minSearchText",
		defaultMessage: "Skriv inn minst {minSearch} tegn",
		description:
			"Message when inputValue is shorten then minSearch, Values: minSearch"
	},
	placeholder: {
		id: "dealer.quicksearch.placeholder",
		description: "Placeholder QuickSearch",
		defaultMessage: "Søk i tidligere saker"
	},
	noResultMessage: {
		id: "dealer.quicksearch.noResultMessage",
		description: "Message when no result in quicksearch",
		defaultMessage: "Fant ingen saker"
	}
});

const useStyles = makeStyles(theme => ({
	root: {},
	focused: {},
	search: {
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		"&:hover": {
			backgroundColor: fade(theme.palette.common.white, 0.25)
		},
		width: "100%"
	},
	searchIcon: {
		maxWidth: theme.spacing(7),
		width: "100%",
		height: "100%",
		position: "absolute",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	inputRoot: {
		color: "inherit",
		paddingRight: 0,
		width: "100%"
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("md")]: {
			maxWidth: 200
		}
	}
}));

const Loading = (
	<Box display={"flex"} justifyContent={"center"}>
		<CircularProgress size={24} />
	</Box>
);

export const QuickSearch = (props: { color: string; minSearch: number }) => {
	const { color, minSearch = 2 } = props;
	const { isOpen, open } = useDrawer();

	const classes = useStyles();

	const intl = useIntl();

	const navigate = useNavigate();

	const [inputValue, setInputValue] = useState("");

	const [debouncedValue] = useDebounce(inputValue, 200, { leading: true });

	const {
		data: { records: results = [] } = {},
		loading,
		error
	} = useStatefulResource(
		ApplicationInfoResource.list(),
		debouncedValue.length < minSearch ? null : { fullText: debouncedValue }
	);

	return (
		<Box p={1} onClick={!isOpen ? open : undefined}>
			<Autocomplete
				id="dealer-quick-search"
				color={color}
				autoComplete
				autoHighlight
				disableClearable
				popupIcon={null}
				noOptionsText={
					<Box display={"flex"} justifyContent={"center"}>
						{error
							? intl.formatMessage(messages.searchFailed)
							: inputValue.length < minSearch
							? intl.formatMessage(messages.minSearchText, { minSearch })
							: intl.formatMessage(messages.noResultMessage)}
					</Box>
				}
				onClose={() => {
					setInputValue("");
				}}
				freeSolo={false}
				loadingText={Loading}
				loading={loading}
				options={results}
				onChange={(_, item) => {
					setInputValue("");
					if (item) navigate(`/applications/${item.applicationNumber}`);
				}}
				inputValue={inputValue}
				onInputChange={(_, value) => setInputValue(value)}
				getOptionLabel={option => option.applicationNumber}
				renderOption={renderApplicationInfoOption}
				renderInput={({ InputProps, inputProps }) => (
					<div className={classes.search}>
						<div className={classes.searchIcon}>
							<SearchIcon />
						</div>
						<InputBase
							type="text"
							placeholder={intl.formatMessage(messages.placeholder)}
							classes={{
								root: classes.inputRoot,
								input: classes.inputInput
							}}
							{...InputProps}
							inputProps={{
								"aria-label": intl.formatMessage(messages.placeholder),
								...inputProps
							}}
						/>
					</div>
				)}
			/>
		</Box>
	);
};

function renderApplicationInfoOption(option: ApplicationInfoResource) {
	const values = {
		customerName: option.customerName,
		coSignerName: option.coSignerName,
		applicationNumber: option.applicationNumber,
		productNameExternal: option.productNameExternal,
		loanAmount: option.loanAmount,
		carType: option.carType,
		carBrandAndModel: option.carBrandAndModel,
		applicationStatusNameExternal: option.applicationStatusNameExternal,
		documentStatusNameExternal: option.documentStatusNameExternal,
		applicationLastChanged: option.applicationLastChanged,
		createdDate: option.createdDate
	};

	return (
		<Box whiteSpace="pre-line">
			<Box fontWeight="600">
				<FormattedMessage
					id="dealer.quicksearch.result.header"
					defaultMessage="{customerName}"
					values={values}
				/>
			</Box>
			<Typography variant="caption">
				<FormattedMessage
					id="dealer.quicksearch.result.secondary"
					defaultMessage={`{applicationNumber} , {carType}
						Fin.bel.: {loanAmount, number},-
						{applicationStatusNameExternal}, {documentStatusNameExternal} `}
					values={values}
				/>
			</Typography>
		</Box>
	);
}
