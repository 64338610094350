import React from "react";
import InformationMessage from "../InformationMessage";
import LoginRedirectLink from "../LoginRedirectLink";

import { TextField } from "@ploy-ui/form-fields";

import Typography from "@material-ui/core/Typography";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { makeStyles, getContrastRatio } from "@material-ui/core/styles";
import { Link } from "@reach/router";
import { LoginResource } from "@ploy-lib/rest-resources";
import { useFetcher } from "@rest-hooks/core";
import { FormBase } from "@ploy-ui/login";

const useStyles = makeStyles(theme => ({
	link: {
		color:
			getContrastRatio(theme.palette.primary.main, theme.palette.common.white) <
			2.5
				? theme.palette.text.secondary
				: theme.palette.primary.main,
		textDecoration: "none"
	},
	forgotten: {
		marginTop: 10.5
	},
	shrink: {
		transform: "translate(0, -20px) !important"
	},
	img: {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		maxWidth: "120px",
		width: "120px",
		maxHeight: "120px"
	},
	headerText: { textAlign: "center", marginBottom: "50px" }
}));

export const messages = defineMessages({
	usernamePlaceholder: {
		id: "dealer.login.field.username.placeholder",
		description: "Placeholder for email field",
		defaultMessage: "E-postadresse"
	},
	usernameLabel: {
		id: "dealer.login.field.username.label",
		description: "Label for email field",
		defaultMessage: "E-postadresse"
	},
	passwordPlaceholder: {
		id: "dealer.login.field.password.placeholder",
		description: "Placeholder for password field",
		defaultMessage: "Passord"
	},
	passwordLabel: {
		id: "dealer.login.field.password.label",
		description: "Label for password field",
		defaultMessage: "Passord"
	},
	submitButtonText: {
		id: "dealer.login.button.login.label",
		description: "Label for login button",
		defaultMessage: "Logg inn"
	}
});

const LoginForm = () => {
	const login = useFetcher(LoginResource.login());

	const intl = useIntl();

	const classes = useStyles();

	const fieldConfig = [
		{
			autoFocus: true,
			name: "username",
			component: TextField,
			type: "email",
			autoComplete: "username",
			placeholder: intl.formatMessage(messages.usernamePlaceholder),
			variant: "input",
			helperText: " ",
			margin: "normal",
			label: intl.formatMessage(messages.usernameLabel),
			fullWidth: true
		},
		{
			name: "password",
			component: TextField,
			type: "password",
			autoComplete: "current-password",
			placeholder: intl.formatMessage(messages.passwordPlaceholder),
			variant: "input",
			helperText: " ",
			margin: "normal",
			label: intl.formatMessage(messages.passwordLabel),
			fullWidth: true
		}
	];

	const initValues = { username: "", password: "" };

	return (
		<FormBase
			submit={values => login(undefined, values)}
			initValues={initValues}
			fieldConfig={fieldConfig}
			submitButtonText={intl.formatMessage(messages.submitButtonText)}
		>
			<Typography className={classes.forgotten} align="center">
				<FormattedMessage
					id="dealer.login.text.forgotten_password"
					description="text for forgotten password. Params: link"
					defaultMessage="Glemt passord? <a>Få nytt passord</a>"
					values={{
						a: text => (
							<Link className={classes.link} to="forgotten-password">
								{text}
							</Link>
						)
					}}
				/>
			</Typography>

			<LoginRedirectLink />

			<InformationMessage />
		</FormBase>
	);
};

export default LoginForm;
