import React, { useCallback, useEffect, useMemo, memo } from "react";
import { Overwrite, HasItems, BaseFieldProps } from "../types";
import { identity } from "../utils";
import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useThemeProps } from "@ploy-ui/tenants";
import { DployTextField, DployTextFieldProps } from "../DployTextField";
import { useIntl, defineMessages } from "react-intl";
import { useDebouncedCallback } from "use-debounce";
import { Popper } from "@material-ui/core";

/**
 * The searchable=false variant of this component (basically
 * a regular select, but with consistent styling) is not
 * natively supported by the Autocomplete component.
 *
 * We accomplish it with a "readOnly" prop on the input,
 * and "cursor: pointer" styling.
 *
 * https://github.com/mui-org/material-ui/issues/18136
 */

type AutocompleteSubType<
	T,
	Multiple extends boolean | undefined,
	DisableClearable extends boolean | undefined
> = Pick<
	AutocompleteProps<T, Multiple, DisableClearable, undefined>,
	| "inputValue"
	| "onInputChange"
	| "openOnFocus"
	| "autoComplete"
	| "autoHighlight"
	| "autoSelect"
	| "disableCloseOnSelect"
	| "renderTags"
	| "renderOption"
	| "debug"
	| "value"
	| "defaultValue"
	| "onChange"
	| "multiple"
	| "disableClearable"
>;

export type DployAutocompleteProps<
	T,
	Multiple extends boolean | undefined,
	DisableClearable extends boolean | undefined
> = Omit<DployTextFieldProps, "value" | "onChange" | "autoComplete"> &
	Omit<Overwrite<BaseFieldProps, HasItems<T>>, "multiple" | "clearable"> &
	AutocompleteSubType<T, Multiple, DisableClearable> & {
		removeNoOptionsMessage?: boolean;
		minSearch?: number;
		className?: string;
		debounceTimeout?: number;
		minWidthFocused?: number;
		AutocompleteProps?: Omit<
			Partial<AutocompleteProps<T, any, any, any>>,
			| "value"
			| "defaultValue"
			| "onChange"
			| "multiple"
			| "disableClearable"
			| "freeSolo"
		>;

		/** @deprecated Use disableClearable instead */
		clearable?: boolean;
	};

const messages = defineMessages({
	placeholder: {
		id: "core.dploy-autocomplete.placeholder",
		defaultMessage: "Velg...",
		description: "Default selection message"
	},
	noOptionsMessage: {
		id: "core.dploy-autocomplete.no-options",
		defaultMessage: "Ingen alternativer passer søket",
		description: "No options availible message"
	}
});

const useStyles = makeStyles(
	{
		focused: (props: DployAutocompleteProps<any, any, any>) => ({
			minWidth: props.minWidthFocused ?? undefined
		}),
		root: (props: DployAutocompleteProps<any, any, any>) => ({
			pointerEvents: props.readonly ? ("none" as const) : ("inherit" as const),
			width: props.fullWidth ? "100%" : undefined
		}),
		groupLabel: {
			fontWeight: 700
		},
		inputRoot: (props: DployAutocompleteProps<any, any, any>) => ({
			cursor: props.searchable === false ? "pointer" : "text"
		}),
		input: (props: DployAutocompleteProps<any, any, any>) => ({
			cursor: props.searchable === false ? "pointer !important" : "text"
		})
	},
	{ name: "DployAutocomplete" }
);

const emptyArray = [];

function DployAutocompleteInternal<
	T,
	Multiple extends boolean | undefined = undefined,
	DisableClearable extends boolean | undefined = undefined
>(props: DployAutocompleteProps<T, Multiple, DisableClearable>) {
	const {
		className,
		openOnFocus = true,
		autoComplete,
		autoHighlight = true,
		autoSelect,
		debug,
		disableCloseOnSelect,
		items = emptyArray,
		getItemLabel = identity,
		getItemValue,
		getItemId, // TODO?
		getItemSuggestions,
		debounceTimeout = 200,
		getCreatedItem, // TODO?
		removeNoOptionsMessage,
		multiple,
		searchable = true,
		clearable,
		disableClearable = !clearable,
		manual,
		creatable, // TODO?
		onSelectItem,
		readonly,
		pending,
		minSearch = 1,
		value,
		onChange,
		inputValue: propsInputValue,
		onInputChange: propsOnInputChange,
		variant = "select",
		disabled,
		fullWidth,
		renderTags,
		renderOption,
		minWidthFocused,
		AutocompleteProps,
		...textFieldProps
	} = useThemeProps(props, "DployAutocomplete");

	const classes = useStyles(props);

	const [options, setOptions] = React.useState(items);
	const [inputValue, setInputValue] = React.useState("");
	const [loading, setLoading] = React.useState(false);

	const intl = useIntl();

	const placeholder =
		textFieldProps.placeholder || intl.formatMessage(messages.placeholder);

	const noOptionsMessage = removeNoOptionsMessage
		? null
		: intl.formatMessage(messages.noOptionsMessage);

	const searchValue =
		propsInputValue !== undefined ? propsInputValue : inputValue;

	const [debounce] = useDebouncedCallback(f => f(), debounceTimeout);

	useEffect(() => {
		if (!getItemSuggestions || searchValue.length < minSearch) {
			setOptions(items);
			setLoading(false);
			return;
		}

		let active = true;

		debounce(async () => {
			if (!active) return;

			setLoading(true);
			try {
				const suggestions = await getItemSuggestions(searchValue);
				if (active && suggestions) {
					setOptions(suggestions);
				}
			} finally {
				if (active) setLoading(false);
			}
		});

		return () => {
			active = false;
		};
	}, [searchValue, getItemSuggestions, minSearch, items, debounce]);

	const handleInputChange = useCallback<NonNullable<typeof propsOnInputChange>>(
		(e, value, ...rest) => {
			setInputValue(value);
			if (propsOnInputChange) propsOnInputChange(e, value, ...rest);
		},
		[propsOnInputChange]
	);

	const isGrouped = useMemo(
		() =>
			options.some(x => {
				const label = getItemLabel(x);
				return label && label.toString().includes("|");
			}),
		[options, getItemLabel]
	);

	const groupBy = useCallback(
		(item: T) => {
			const label = getItemLabel(item);

			const [, secondary] = label != null ? label.toString().split("|", 2) : [];

			return secondary || "Annet";
		},
		[getItemLabel]
	);

	const getOptionLabel = useCallback(
		(item: T) => {
			const label = getItemLabel(item);

			const [primary] = label != null ? label.toString().split("|", 1) : [];

			return primary;
		},
		[getItemLabel]
	);

	const selectedValue = useMemo(() => {
		if (value == null || value === undefined) return multiple ? [] : null;
		if (!getItemValue) return value;

		if (multiple) {
			const multipleValue = value as AutocompleteProps<
				T,
				true,
				DisableClearable,
				false
			>["value"];

			return options.filter(x =>
				multipleValue?.some(v => getItemValue(x) === getItemValue(v))
			);
		}
		return (
			options.find(x => getItemValue(x) === getItemValue(value as T)) || null
		);
	}, [getItemValue, multiple, options, value]);

	return (
		<Autocomplete
			openOnFocus={!readonly && openOnFocus}
			forcePopupIcon={readonly ? false : undefined}
			debug={debug}
			autoComplete={autoComplete}
			autoHighlight={autoHighlight}
			autoSelect={autoSelect && !multiple}
			disableCloseOnSelect={disableCloseOnSelect}
			value={selectedValue}
			options={options}
			onChange={onChange}
			getOptionLabel={getOptionLabel}
			getOptionSelected={(option, value) =>
				getItemValue
					? getItemValue(option) === getItemValue(value)
					: option === value
			}
			groupBy={isGrouped ? groupBy : undefined}
			noOptionsText={noOptionsMessage}
			freeSolo={manual}
			multiple={multiple as any}
			loading={loading || pending}
			disableClearable={disableClearable || readonly}
			disabled={disabled}
			onInputChange={handleInputChange}
			inputValue={
				propsInputValue !== undefined
					? propsInputValue
					: !selectedValue &&
					  getItemSuggestions &&
					  items.length === 0 &&
					  disabled &&
					  !multiple &&
					  typeof value === "string"
					? value
					: inputValue
			}
			renderTags={renderTags}
			renderOption={renderOption}
			renderInput={params => (
				<DployTextField
					className={className}
					{...textFieldProps}
					variant={variant as any}
					placeholder={placeholder}
					{...params}
					inputProps={{
						...textFieldProps.inputProps,
						...params.inputProps,
						readOnly: !searchable || readonly,
						tabIndex: readonly ? -1 : undefined
					}}
					InputProps={{
						...textFieldProps.InputProps,
						...params.InputProps,
						startAdornment: (
							<>
								{params.InputProps.startAdornment}
								{textFieldProps.InputProps?.startAdornment}
							</>
						),
						endAdornment: (
							<>
								{pending || loading ? (
									<CircularProgress color="inherit" size={20} />
								) : null}
								{textFieldProps.InputProps?.endAdornment}
								{params.InputProps.endAdornment}
							</>
						)
					}}
					fullWidth
				/>
			)}
			PopperComponent={
				removeNoOptionsMessage &&
				(inputValue.length < minSearch || !loading) &&
				options.length < 1
					? NoOptionsPopper
					: minWidthFocused
					? ({ style, ...rest }) => (
							<Popper
								style={{ width: minWidthFocused, minWidth: "fit-content" }}
								{...rest}
							/>
					  )
					: undefined //use default popper
			}
			{...AutocompleteProps}
			classes={{
				focused: classes.focused,
				root: classes.root,
				groupLabel: classes.groupLabel,
				inputRoot: classes.inputRoot,
				input: classes.input,
				...(AutocompleteProps && AutocompleteProps.classes)
			}}
		/>
	);
}

const NoOptionsPopper = props => {
	return <Popper {...props} style={{ visibility: "hidden" }} />;
};

DployAutocompleteInternal.displayName = "DployAutocomplete";

export const DployAutocomplete: <
	T,
	Multiple extends boolean | undefined = undefined,
	DisableClearable extends boolean | undefined = undefined
>(
	props: DployAutocompleteProps<T, Multiple, DisableClearable>
) => React.ReactElement | null = memo(DployAutocompleteInternal);
