import { BaseCamelCasedResource } from "./BasePlainResource";
import { legacyApiResourceUrl } from "@ploy-lib/core";

interface Addr {
	address1: string;
	addressCity: string;
	addressZip: string;
}

interface Cust {
	firstname: string;
	lastname: string;
	customerName: string;
	customerNumber: string;
	customerCompanyNumber: string;
	customerId: number;
	customerTypeId: number;
	email: string;
	phoneMobile: string;
}
export interface CustomerInfoSearch {
	addr: Addr | undefined;
	cust: Cust | undefined;
}

export class CustomerInfoSearchResource
	extends BaseCamelCasedResource
	implements CustomerInfoSearch {
	readonly addr: Addr | undefined;
	readonly cust: Cust | undefined;

	pk() {
		return this.cust?.customerNumber || this.cust?.customerCompanyNumber;
	}

	static async fetch(input: RequestInfo, init: RequestInit) {
		const response = await super.fetch(input, init);
		if (response?.error !== "OK") {
			const error = new Error(response.message);
			(error as any).status = 400;
			throw error;
		}
		return response as any;
	}

	static urlTemplates = [
		legacyApiResourceUrl(
			"CustomerSearchJson/SearchCustomerNoWithDynCalcModel?custNo={custNo}"
		),
		legacyApiResourceUrl(
			"CustomerSearchJson/SearchCompanyNoWithDynCalcModel?orgno={orgno}"
		)
	];

	static urlRoot = legacyApiResourceUrl("CustomerSearchJson");
}
