import { useMemo } from "react";
import { createMuiTheme } from "@material-ui/core/styles";

import { colorOverride } from "../colorOverrides";
import { createStatusColors } from "../statusColors";
import { Tenant } from "../types";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import merge from "lodash/merge";
import { DployBrandTheme } from "@ploy-lib/types";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { ComponentsProps } from "@material-ui/core/styles/props";

export function createTheme(
	design: string | undefined,
	tenant: Tenant,
	override: ThemeOptions
) {
	const { designs, themeObject = { palette: {} }, designOverride } = tenant;

	let theme = createMuiTheme({
		...themeObject,
		...override,
		palette: {
			...themeObject.palette,
			...override.palette,
			background: {
				...themeObject.palette?.background,
				...override.palette?.background
			}
		},
		props: merge(themeObject.props, override.props)
	});

	theme.palette.status = createStatusColors(
		themeObject.palette?.status,
		theme.palette
	);

	if (design) {
		theme = designs[design]?.muiTheme(theme) ?? theme;
		theme = designOverride?.(theme) ?? theme;
	}
	theme = colorOverride(theme);

	return theme;
}

export function useDesignSettings(
	tenant: Tenant,
	design?: string,
	brandTheme: Partial<DployBrandTheme> = {}
) {
	const {
		palettePrimaryMain,
		paletteSecondaryMain,
		paletteBackgroundDefault,
		paletteBackgroundDrawer
	} = brandTheme;

	const theme = useMemo(() => {
		const overrideTheme = {
			palette: {} as PaletteOptions,
			props: {} as ComponentsProps
		};

		if (palettePrimaryMain)
			overrideTheme.palette.primary = { main: palettePrimaryMain };
		if (paletteSecondaryMain)
			overrideTheme.palette.secondary = { main: paletteSecondaryMain };

		if (paletteBackgroundDefault)
			overrideTheme.palette.background = { default: paletteBackgroundDefault };

		if (paletteBackgroundDrawer)
			overrideTheme.props.Portal = { drawerColor: paletteBackgroundDrawer };

		return createTheme(design, tenant, overrideTheme);
	}, [
		palettePrimaryMain,
		paletteSecondaryMain,
		paletteBackgroundDefault,
		paletteBackgroundDrawer,
		design,
		tenant
	]);

	return { theme };
}
