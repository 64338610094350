import React, { useEffect, useState } from "react";

import { TemplatePageProps, LayoutPage } from "../../../types";
import { addRegisteredLayout, PanelSettings } from "../layoutDescriptions";
import { Grid, IconButton, Modal, Typography } from "@material-ui/core";
import { Panel, PanelProps } from "../../../panel";
import { TemplatePanel } from "@ploy-lib/types";
import { useStyles } from "./styles";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { Formik, useFormikContext } from "formik";
import { PendingButton } from "@ploy-ui/core";

export type ModalPopupPanels = {
	main: TemplatePanel;
	actions: TemplatePanel;
};
export type ModalPopup = keyof ModalPopupPanels;
export type ModalPopupPage = LayoutPage<ModalPopup, ModalPopupPanels>;

const mainPanelSettings: PanelSettings = {
	editableOptions: {
		title: false,
		elevation: true,
		square: true
	}
};

const panelSettings: Record<ModalPopup, PanelSettings> = {
	main: mainPanelSettings,
	actions: {}
};

addRegisteredLayout({
	name: "ModalPopup",
	displayName: "ModalPopup",
	panels: panelSettings,
	canDecideMaxWidth: true
});

export interface ModalPopupProps extends TemplatePageProps<ModalPopupPage> {}

export const ModalPopup = (props: ModalPopupProps) => {
	const { panels, maxWidth, pageTitle, modalClick } = props;

	const { main, actions } = panels;

	const classes = useStyles(props);

	const pageDefinedPanelSpecificProps: Partial<PanelProps> = {
		maxWidth
	};

	const [open, setOpen] = useState(true);

	const {
		submitForm,
		initialValues,
		setValues,
		...formikProps
	} = useFormikContext();

	useEffect(() => {
		setOpen(true);
	}, [modalClick]);

	return (
		<Modal className={classes.modal} open={open} onClose={() => setOpen(!open)}>
			<Formik
				initialValues={initialValues as any}
				onSubmit={async values => {
					setValues(values);
					await submitForm();
					setOpen(false);
				}}
				{...formikProps}
			>
				<Grid
					container
					spacing={3}
					justify="center"
					className={clsx(classes.pageMaxWidth, classes.modalInternalGrid)}
					direction="column"
				>
					<Grid
						item
						container
						direction="row"
						style={{ justifyContent: "space-between" }}
					>
						<Grid item>
							<Typography variant="h5">{pageTitle}</Typography>
						</Grid>
						<Grid item>
							<IconButton onClick={() => setOpen(!open)}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item xs={12} md={12}>
						{main && <Panel {...pageDefinedPanelSpecificProps} {...main} />}
					</Grid>

					{actions && (
						<Grid item xs={12} md={12}>
							<Panel
								transparent
								justify="center"
								{...pageDefinedPanelSpecificProps}
								{...actions}
							/>
						</Grid>
					)}
				</Grid>
			</Formik>
		</Modal>
	);
};

ModalPopup.displayName = "DployFormModalPopup";
