import GeneralInformation from "./GeneralInformation";
import { applyPropertyControls, ControlType } from "../../property-controls";

applyPropertyControls(GeneralInformation, {
	boxProps: {
		type: ControlType.Object,
		title: "Standard instillinger",
		propertyControls: {
			header: {
				type: ControlType.String,
				title: "Tittel"
			},
			iconClass: {
				type: ControlType.String,
				title: "Ikon",
				description: "Fyll inn navnet til et ikon fra Font Awesome"
			},
			backgroundColor: {
				type: ControlType.Color,
				title: "Bakgrunnsfarge",
				description: "Bakgrunnsfarge for boksen",
				coerce: (_, boxProps) => {
					if (boxProps.grey) return "grey.200";
				}
			},
			headerColor: {
				type: ControlType.Color,
				title: "Tittelfarge",
				description: "Tekstfarge som blir brukt på tittel og ikon",
				coerce: (_, boxProps) => {
					if (boxProps.grey) return "grey.500";
				}
			}
		}
	}
});

export default GeneralInformation;
