import { ID } from "../types";
import { apiResourceUrl } from "@ploy-lib/core";
import { BaseResource } from "./BaseResource";
import { DployBrandTheme } from "@ploy-lib/types";

export class ThemeResource extends BaseResource implements DployBrandTheme {
	readonly id?: ID;
	readonly design: string = "";
	readonly palettePrimaryMain: string = "";
	readonly paletteSecondaryMain: string = "";
	readonly paletteBackgroundDefault: string = "";
	readonly paletteBackgroundDrawer: string = "";
	readonly dashboardImageCropMode: string = "";

	pk() {
		return this.id?.toString();
	}

	static readonly automaticValidation = "silent";

	static urlRoot = apiResourceUrl("themes");
}
