import * as React from "react";
import { Grid, Typography } from "@material-ui/core";

export const LabeledProperty = ({
	label,
	value
}: {
	label: React.ReactNode;
	value: React.ReactNode;
}) => (
	<Grid container justify="space-between">
		<Grid item>
			<Typography variant="subtitle2" gutterBottom>
				{label}
			</Typography>
		</Grid>
		<Grid item>
			<Typography variant="subtitle1" gutterBottom>
				{value || " "}
			</Typography>
		</Grid>
	</Grid>
);
