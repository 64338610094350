import React, { Suspense } from "react";
import { UserLimitationResource } from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";
import { Banner } from "@ploy-ui/core";
import { FormattedMessage } from "react-intl";
import BlockIcon from "@material-ui/icons/Block";

function UserLimitationsBannerInternal() {
	const userLimitations = useResource(UserLimitationResource.detail(), {});

	if (!userLimitations || !userLimitations.MissingRequiredCourse) return null;

	return (
		<Banner
			iconComponent={<BlockIcon />}
			description={
				<FormattedMessage
					id="core.user-limitations.description"
					defaultMessage="Mangler påkrevde kurs."
					description="User has not completed required courses"
				/>
			}
		/>
	);
}

export default function UserLimitationsBanner() {
	return (
		<Suspense fallback={null}>
			<UserLimitationsBannerInternal />
		</Suspense>
	);
}
