import RecentApplications from "./RecentApplications";
import { applyPropertyControls, ControlType } from "../../property-controls";

applyPropertyControls(RecentApplications, {
	includeRejected: {
		type: ControlType.Boolean,
		title: "Inkluder bortfalte saker",
		defaultValue: false,
		coerce: value => (String(value) === "1" ? true : false)
	},
	showDocumentStatus: {
		type: ControlType.Boolean,
		title: "Vis dokumentstatus",
		defaultValue: false,
		coerce: value => (String(value) === "1" ? true : false)
	},
	boxProps: {
		type: ControlType.Object,
		title: "Standard instillinger",
		propertyControls: {
			header: {
				type: ControlType.String,
				title: "Tittel",
				defaultValue: "Mine saker"
			},
			iconClass: {
				type: ControlType.String,
				title: "Ikon",
				defaultValue: "file-o",
				description: "Fyll inn navnet til et ikon fra Font Awesome"
			},
			backgroundColor: {
				type: ControlType.Color,
				title: "Bakgrunnsfarge",
				description: "Bakgrunnsfarge for boksen",
				coerce: (_, boxProps) => {
					if (boxProps.grey) return "grey.200";
				}
			},
			headerColor: {
				type: ControlType.Color,
				title: "Tittelfarge",
				description: "Tekstfarge som blir brukt på tittel og ikon",
				coerce: (_, boxProps) => {
					if (boxProps.grey) return "grey.500";
				}
			}
		}
	}
});

export default RecentApplications;
