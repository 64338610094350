import { useRef, useEffect } from "react";
import { FormikContextType } from "formik";
import { isEqual } from "@ploy-lib/calculation";

export function useMergeUpdateValues<TN extends string, TD>(
	initialValues: Partial<Record<TN, Partial<Record<string, TD>>>> | undefined,
	formik: FormikContextType<typeof initialValues>,
	clear: string[] = []
) {
	const { setValues } = formik;

	const originalValuesRef = useRef(initialValues);
	const initialValuesRef = useRef(initialValues);
	const formValuesRef = useRef(formik.values);
	useEffect(() => {
		if (initialValuesRef.current !== initialValues) {
			const originalValues = originalValuesRef.current;
			const lastValues = formValuesRef.current;

			if (initialValues && lastValues) {
				// Merge changes
				const mergeValues: Partial<
					Record<TN, Partial<Record<string, TD>>>
				> = {};

				let namespace: string;
				for (namespace in lastValues) {
					// Don't add back namespaces that have been removed
					if (!initialValues.hasOwnProperty(namespace)) continue;

					const originalNamespace =
						originalValues && originalValues[namespace as string];

					const initialNamespace =
						initialValues && initialValues[namespace as string]!;

					if (originalNamespace === initialNamespace) continue;

					if (clear.includes(namespace)) continue;

					let field: string;
					for (field in lastValues[namespace]) {
						// Don't add back fields that have been removed
						if (!initialNamespace.hasOwnProperty(field)) continue;

						if (isEqual(lastValues[namespace][field], initialNamespace[field]))
							continue;

						// Ignore untouched fields
						if (
							originalNamespace &&
							isEqual(lastValues[namespace][field], originalNamespace[field])
						)
							continue;

						if (!mergeValues.hasOwnProperty(namespace))
							mergeValues[namespace] = { ...initialNamespace };

						// Set the new value to the previous value
						mergeValues[namespace][field] = lastValues[namespace][field];
					}
				}
				mergeValues["AdditionalSubmitValues"] =
					lastValues["AdditionalSubmitValues"];

				setValues({ ...initialValues, ...mergeValues });
			}

			initialValuesRef.current = initialValues;
		}
	}, [setValues, initialValues, clear]);

	useEffect(() => void (formValuesRef.current = formik.values), [
		formik.values
	]);
}
