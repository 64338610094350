import { ID, NetworkError } from "@ploy-lib/types";
import {
	apiResourceUrl,
	isNotNull,
	legacyApiResourceUrl
} from "@ploy-lib/core";
import {
	BaseResource,
	BasePlainCamelCasedResource
} from "@ploy-lib/rest-resources";
import { Layouts, Layout } from "react-grid-layout";
import {
	SchemaDetail,
	SchemaList,
	AbstractInstanceType,
	schema
} from "@rest-hooks/core";
import { SimpleResource } from "@rest-hooks/rest";
import { ContentBoxProps } from "../ContentBox";
import * as ContentComponents from "../content";
import * as ContentComponentsDeprecated from "../content/deprecated";
import { isControlComponent } from "../property-controls";
import { propertyValues } from "../property-controls/propertyValues";
import { v4 as uuidv4 } from "uuid";

export type GridComponent = keyof typeof ContentComponents;

interface GridItemLegacy {
	name: string;
	boxProps?: Partial<ContentBoxProps>;
	properties?: {
		header?: string;
		iconClass?: string;
		boxClass?: string;
		boxHeader?: string;
	};
}

export interface GridItem {
	id: string;
	component?: GridComponent;
	properties: Record<string, any>;
}

export { Layout, Layouts };

export interface GridColumns {
	readonly md: number;
	readonly sm: number;
	readonly xs: number;
}

export const defaultColumns: GridColumns = {
	md: 12,
	sm: 10,
	xs: 6
};

export interface DashboardGrid {
	id?: ID;
	name?: string;
	layouts: Layouts;
	items: Record<string, GridItem>;
	columns: GridColumns;

	_legacyId?: ID;
}

abstract class VulcanResource extends BasePlainCamelCasedResource {
	pk() {
		return "vulcan";
	}

	static vulcan<T extends typeof VulcanResource>(this: T) {
		const endpoint = this.detail();
		return endpoint.extend({
			schema: this as SchemaList<AbstractInstanceType<T>>,
			url: () => this.listUrl(),
			fetch: async (): Promise<AbstractInstanceType<T>> => {
				const jsonResponse = await endpoint.fetch({});

				return jsonResponse?.initialData;
			}
		});
	}

	static readonly page: string | undefined;
	static get urlRoot() {
		return legacyApiResourceUrl(`${this.page}/Vulcan`);
	}
}

class FrontPageVulcanResource extends VulcanResource {
	static readonly page = "FrontPage";
	readonly listOfContent?: GridItemLegacy[][] = [];
}

class DashboardAdminResource extends BasePlainCamelCasedResource {
	readonly dashboardId?: ID;
	readonly name: string = "";
	readonly configuredWidgets: GridItemLegacy[][] = [];

	pk() {
		return this.dashboardId?.toString();
	}

	static list<T extends typeof SimpleResource>(this: T) {
		const endpoint = super.list().extend({
			schema: [this] as SchemaList<AbstractInstanceType<T>>,
			url: () => `${this.urlRoot}/GetDashboardList`
		});

		return endpoint.extend({
			fetch: (params): Promise<AbstractInstanceType<T>[]> =>
				endpoint.fetch(params)
		});
	}

	static detail<T extends typeof SimpleResource>(this: T) {
		const endpoint = super.detail().extend({
			schema: this as SchemaDetail<AbstractInstanceType<T>>,
			url: params =>
				this.listUrl(params).replace(this.urlRoot, `${this.urlRoot}/Setup`)
		});

		return endpoint.extend({
			fetch: async (params): Promise<AbstractInstanceType<T>> => {
				const data = await endpoint.fetch(params);

				return data.dashboard;
			}
		});
	}

	static urlRoot = legacyApiResourceUrl("Admin/DashboardAdmin");
}

export class DashboardGridResource
	extends BaseResource
	implements DashboardGrid {
	readonly id?: ID = undefined;
	readonly name: string = "";
	readonly layouts: Layouts = {};
	readonly items: Record<string, GridItem> = {};
	readonly columns = defaultColumns;

	readonly _legacyId?: ID = undefined;

	pk() {
		return this._legacyId
			? `LEGACY: ${this._legacyId.toString()}`
			: this.id?.toString();
	}

	static list<T extends typeof SimpleResource>(this: T) {
		const legacyEndpoint = DashboardAdminResource.list();

		const endpoint = super.list();

		return endpoint.extend({
			schema: [this] as SchemaList<AbstractInstanceType<T>>,
			fetch: async (params): Promise<Partial<DashboardGrid>[]> => {
				const [data = [], legacyData] = await Promise.all([
					endpoint.fetch(params).catch(() => {}) as Promise<
						Partial<DashboardGrid>[]
					>,
					legacyEndpoint.fetch({})
				]);

				return [
					...data,
					...legacyData
						.filter(d => !data.some(x => x._legacyId === d.dashboardId))
						.map(d => ({
							id: undefined,
							_legacyId: d.dashboardId,
							name: d.name
						}))
				];
			}
		});
	}

	/** Mock implementation */
	static update<T extends typeof SimpleResource>(this: T) {
		return super.update().extend({
			schema: this as SchemaDetail<AbstractInstanceType<T>>,
			fetch: async (params: { id: ID }, body): Promise<DashboardGrid> => {
				await new Promise(resolve => setTimeout(resolve, 1000));

				return { ...body, id: params.id } as DashboardGrid;
			}
		});
	}

	/** Mock implementation */
	static create<T extends typeof SimpleResource>(this: T) {
		return super.create().extend({
			schema: this as SchemaDetail<AbstractInstanceType<T>>,
			fetch: async (params, body): Promise<DashboardGrid> => {
				await new Promise(resolve => setTimeout(resolve, 1000));

				return { ...body, id: uuidv4() } as DashboardGrid;
			}
		});
	}

	/** Mock implementation */
	static delete<T extends typeof SimpleResource>(this: T) {
		return super.delete().extend({
			schema: new schema.Delete(this),
			fetch: async (params: object) => {
				await new Promise(resolve => setTimeout(resolve, 1000));

				if (Math.random() > 0.5)
					throw new NetworkError({
						status: 400,
						statusText: "Bad request"
					} as Response);
			}
		});
	}

	static detail<T extends typeof SimpleResource>(this: T) {
		const fpVulcanEndpoint = FrontPageVulcanResource.vulcan();
		const legacySetupEndpoint = DashboardAdminResource.detail();

		const endpoint = super.detail();

		return endpoint.extend({
			schema: this as SchemaDetail<AbstractInstanceType<T>>,
			fetch: async ({
				_legacyId,
				...params
			}: {
				id?: ID;
				username?: string;
				salespersonUsername?: string;
				_legacyId?: ID;
			}) => {
				const hasParams = Object.values(params).filter(isNotNull).length > 0;

				if (!hasParams && _legacyId) {
					const dashboard = await legacySetupEndpoint.fetch({
						dashboardId: _legacyId
					});

					return convertLegacyGrid(
						dashboard.configuredWidgets,
						dashboard.dashboardId ?? _legacyId
					);
				}

				try {
					return await endpoint.fetch(params);
				} catch (e: any) {
					try {
						if (params.username || params.salespersonUsername) {
							const fpVulcan = await fpVulcanEndpoint.fetch();

							if (fpVulcan) {
								return convertLegacyGrid(
									fpVulcan?.listOfContent ?? [],
									`user: ${params.username}`,
									`user: ${params.username}`
								);
							}
						}
					} catch {}

					throw e;
				}
			}
		});
	}

	static urlTemplates = [
		apiResourceUrl("user/{username}/dashboard-grid"),
		apiResourceUrl("salesperson/{salespersonUsername}/dashboard-grid")
	];

	static urlRoot = apiResourceUrl("dashboard-grids");
}

function convertLegacyGrid(
	legacyGrid: GridItemLegacy[][],
	id: ID,
	name?: string
): DashboardGrid {
	const legacyItems = getItems(legacyGrid);

	const { layouts, columns } = itemsToLayouts(legacyItems);

	const items = Object.fromEntries(
		legacyItems.map((x): [string, GridItem] => {
			const component = fixDeprecatedName(x.name);
			const properties = fixProperties(component, x.properties);
			return [
				x.id,
				{
					id: x.id,
					component,
					properties
				}
			];
		})
	);

	if (name) {
		return {
			id: undefined,
			_legacyId: id,
			name,
			layouts,
			items,
			columns
		};
	}

	return {
		id: undefined,
		_legacyId: id,
		layouts,
		items,
		columns
	};
}

function isDeprecated(
	name: string
): name is keyof typeof ContentComponentsDeprecated {
	return Boolean(
		ContentComponentsDeprecated[
			name as keyof typeof ContentComponentsDeprecated
		]
	);
}

const componentNameMap = new Map(
	(Object.entries(ContentComponents) as [
		GridComponent,
		typeof ContentComponents[GridComponent]
	][]).map(([k, v]) => [v, k] as const)
);

function fixDeprecatedName(name: string): GridComponent {
	if (isDeprecated(name)) {
		const Deprecated = ContentComponentsDeprecated[name];
		const fixedName = componentNameMap.get(Deprecated);

		return fixedName ?? (name as any);
	}

	return name as any;
}

function fixProperties(name: GridComponent, properties: Record<string, any>) {
	const Component = ContentComponents[name];

	if (isControlComponent(Component)) {
		return propertyValues(Component.propertyControls, properties);
	}

	return properties;
}

const boxClassToGridProps = (
	boxClass: string,
	columns: GridColumns
): GridColumns | undefined => {
	const classes = boxClass.split(" ");
	const contentBox = classes.find(x => x.includes("content-box"));

	if (!contentBox) return;

	const [, , size, bp = size] = contentBox.split("-", 4);

	switch (bp) {
		case "small":
			return {
				xs: columns.xs,
				sm: Math.round(columns.sm / 2),
				md: Math.round(columns.md / 3)
			};
		case "medium":
			return {
				xs: columns.xs,
				sm: Math.round(columns.sm / 2),
				md: Math.round(columns.md / 2)
			};
		case "large":
			return {
				xs: columns.xs,
				sm: columns.sm,
				md: columns.md
			};
		default:
			const md = Math.max(1, Math.min(parseInt(size), columns.md));

			return {
				md,
				sm: md < columns.md / 2 ? columns.sm / 2 : columns.sm,
				xs: columns.xs
			};
	}
};

const boxClassToBoxProps = (boxClass: string): Partial<ContentBoxProps> => {
	const classes = boxClass.split(" ");

	return classes.includes("grey")
		? { backgroundColor: "grey.200", headerColor: "grey.500" }
		: {};
};

function getItems(legacyGrid: GridItemLegacy[][]) {
	return (
		legacyGrid
			.flatMap(row =>
				row.map(({ name, boxProps = {}, properties }) => {
					const { boxHeader, header, iconClass, boxClass, ...rest } =
						properties ?? {};

					if (boxClass) {
						boxProps = {
							...boxClassToBoxProps(boxClass),
							...boxProps
						};
					}

					if (header ?? boxHeader)
						boxProps = {
							header: header ?? boxHeader,
							...boxProps
						};

					if (iconClass)
						boxProps = {
							iconClass,
							...boxProps
						};

					return {
						name,
						boxClass,
						properties: {
							boxProps,
							...rest
						}
					};
				})
			)
			.map((item, i) => ({ ...item, id: uuidv4() })) ?? []
	);
}

function gridToLayout(
	partials: (Pick<Layout, "i"> & Partial<Layout>)[] = [],
	cols = 12,
	defaultWidth = 4
): Layout[] {
	let accX = 0;
	let accY = 0;

	const defaultHeight = 7;

	return partials.map(l => {
		const w = Math.min(l.w ?? defaultWidth, l.maxW ?? cols, cols);
		const h = Math.max(l.h ?? defaultHeight, l.minH ?? 1, 1);

		const x = accX + w > cols ? 0 : accX;
		const y = accX + w > cols ? accY + h : accY;

		accX = x + w;
		accY = y;

		return {
			...l,
			h,
			w,
			x: l.x ?? x,
			y: l.y ?? y
		};
	});
}

function itemsToLayouts(
	allItems: {
		id: string;
		boxClass?: string;
	}[]
) {
	const toLayoutItem = (brk: keyof GridColumns) => (item: {
		id: string;
		gridProps?: GridColumns;
	}): Pick<Layout, "i"> & Partial<Layout> => {
		const cols = item.gridProps?.[brk];
		const w = typeof cols === "number" ? cols : undefined;

		return {
			i: item.id,
			w
		};
	};

	const items = allItems.map(item => ({
		...item,
		gridProps: item.boxClass
			? boxClassToGridProps(item.boxClass, defaultColumns)
			: undefined
	}));

	return {
		layouts: {
			md: gridToLayout(
				items.map(toLayoutItem("md")),
				defaultColumns.md,
				defaultColumns.md / 3
			),
			sm: gridToLayout(
				items.map(toLayoutItem("sm")),
				defaultColumns.sm,
				defaultColumns.sm / 2
			),
			xs: gridToLayout(
				items.map(toLayoutItem("xs")),
				defaultColumns.xs,
				defaultColumns.xs
			)
		},
		columns: defaultColumns
	};
}
