import { Variable } from "@ploy-lib/types";
import { Namespaced, ResolvedVariable } from "./types";
import {
	getInNamespaced,
	isNotNull,
	isEqualOrBothNaN,
	isVariableField
} from "./utils";
import { CalculatorState } from "./createCalculator";
import { Patcher } from "./createPatcher";
import { Patch } from "../types";
import { isBodyVariable, isBodyField } from "../service-manager/utils";

interface VariableWithResolve<TN> {
	variable: Variable;
	resolve?: ResolvedVariable<TN>;
}

export function createUpdate<TData, TNamespaces extends string>(
	namespace: TNamespaces,
	variablesWithMaybeResolve: VariableWithResolve<TNamespaces>[]
) {
	return function update(
		input: CalculatorState<TNamespaces, TData>,
		patcher: Patcher<TNamespaces, TData>,
		addChangeTrigger: (
			namespace: TNamespaces,
			changedFieldId: string,
			isResolve: boolean
		) => void = () => {},
		addInitTrigger: (namespace: TNamespaces) => void = () => {},
		formValues?: Partial<Namespaced<TData, TNamespaces>>,
		formWriteLocked?: Partial<Namespaced<boolean | null, TNamespaces>>
	): CalculatorState<TNamespaces, TData> {
		const {
			values,
			controlFieldMaps,
			fieldControlMaps,
			formValues: previousFormValues,
			serviceBodyValuesMap
		} = input;

		let isInitial = false;

		if (!values[namespace]) {
			isInitial = true;
			addInitTrigger(namespace);
		}
		const serviceBodyValues = serviceBodyValuesMap[namespace];

		if (serviceBodyValues) {
			serviceBodyValues.filter(isBodyField).forEach(f => {
				if (isBodyVariable(f)) return;
				// Handle field connected variables
				const newValue = getInNamespaced(formValues, namespace, f.field);
				const previousValue = getInNamespaced(
					previousFormValues,
					namespace,
					f.field
				);

				if (!isInitial && !isEqualOrBothNaN(newValue, previousValue)) {
					addChangeTrigger(namespace, f.field, false);
				}
			});
		}

		const patches = variablesWithMaybeResolve
			.map(({ variable, resolve }) => {
				let variableName = variable.name;
				let value: TData | number | string | undefined;
				let missing: boolean | undefined = undefined;
				let writeLocked: boolean | null | undefined = undefined;
				var changeTrigger = variable.name;

				if (variable.controlID) {
					const field = getInNamespaced(
						controlFieldMaps,
						namespace,
						variable.controlID
					);

					if (field) {
						changeTrigger = field.fieldName;
						const fieldNamespace = resolve ? resolve.namespace : namespace;

						if (formValues) {
							// Update variable with latest value from (resolved?) field

							// Handle field connected variables
							value = getInNamespaced(
								formValues,
								fieldNamespace,
								field.fieldName
							);

							// Ignore fields that are unchanged
							const previousFieldValue = getInNamespaced(
								previousFormValues,
								fieldNamespace,
								field.fieldName
							);

							if (
								previousFormValues &&
								isEqualOrBothNaN(previousFieldValue, value)
							)
								return null;

							const initialWriteLocked = getInNamespaced(
								formWriteLocked,
								fieldNamespace,
								field.fieldName
							);
							if (isInitial) {
								writeLocked = initialWriteLocked != null && initialWriteLocked;
							} else {
								writeLocked = initialWriteLocked != null;
							}
						} else if (resolve) {
							// Update resolve variable with latest value from the resolved fields backing variable

							// Find controlId referring to the resolved field
							const ctrl = getInNamespaced(
								fieldControlMaps,
								resolve.namespace,
								resolve.resolved
							);

							// Get backing variable info for resolved field
							const resolvedFieldControl =
								ctrl &&
								getInNamespaced(controlFieldMaps, resolve.namespace, ctrl);

							// No need to update the value if the resolved field does not have a backing varible,
							// as it cannot have been written to.
							if (
								!resolvedFieldControl ||
								!isVariableField(resolvedFieldControl)
							)
								return null;

							// Get latest value from the resolved fields backing variable
							value = getInNamespaced(
								values,
								resolve.namespace,
								resolvedFieldControl.variable
							);
						} else {
							// Nothing to update

							return null;
						}
					} else {
						// Field not found.
						missing = true;
						writeLocked = false;
						value = getInNamespaced(values, namespace, variableName);
					}
				} else {
					writeLocked = false;
					value = getInNamespaced(values, namespace, variableName);
				}

				return {
					namespace,
					target: variableName,
					overwrite: true,
					isFieldChange: true,
					value,
					missing,
					writeLocked,
					isInitial,
					changeTrigger,
					isResolve: resolve != null
				} as Patch<typeof namespace, typeof value>;
			})
			.filter(isNotNull);

		return patches.length > 0
			? patcher(input, addChangeTrigger, patches)
			: input;
	};
}
