import { apiResourceUrl } from "@ploy-lib/core";
import { v4 as uuidv4 } from "uuid";
import { BaseCamelCasedResource } from "./BaseResource";

export interface Guarantor {
	readonly customerId: number | null;
	readonly __id: string;
	readonly customerTypeId: string | null;
	readonly customerNumber: string | null;
	readonly customerCompanyNumber: string | null;
	readonly customerNumberCombined: string | null;
	readonly customerName: string | null;
	readonly comment: string | null;
	readonly phoneMobile: string | null;
	readonly email: string | null;
	readonly guarantorAmount: string | null;
	readonly guarantorEmployer: string | null;
	readonly guarantorGrossIncome: string | null;
	readonly address: string | null;
	readonly addressCity: string | null;
	readonly addressZip: string | null;
	readonly guarantorType: string | null;
}
export class GuarantorResource
	extends BaseCamelCasedResource
	implements Guarantor {
	readonly customerId: number | null = null;
	readonly customerNumber: string | null = null;
	readonly __id: string = uuidv4();
	readonly customerTypeId: string = "";
	readonly customerCompanyNumber: string = "";
	readonly customerNumberCombined: string = "";
	readonly customerName: string = "";
	readonly comment: string = "";
	readonly phoneMobile: string = "";
	readonly email: string = "";
	readonly guarantorAmount: string = "";
	readonly guarantorEmployer: string = "";
	readonly guarantorGrossIncome: string = "";
	readonly address: string = "";
	readonly addressCity: string = "";
	readonly addressZip: string = "";
	readonly guarantorType: string = "";


	pk() {
		return `${this.customerId || this.__id}`;
	}

	static urlRoot = apiResourceUrl("guarantor");
}
