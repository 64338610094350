import React from "react";

import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { Panel } from "../../../panel";
import { WizardStepper } from "./WizardStepper";
import { FormErrors } from "../../../components/FormErrors";
import {
	useStyles,
	actionsTheme,
	wizardSummaryHeaderTheme,
	wizardSummaryTheme
} from "./styles";
import FormStatus from "../../../components/FormStatus";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { useSticky } from "../../useSticky";
import { usePageState } from "../../../PageContext";
import { PanelSettings } from "../../..";
import { addRegisteredLayout } from "../layoutDescriptions";
import { LayoutPage, TemplatePageProps } from "../../../types";
import { TemplatePanel } from "@ploy-lib/types";
import clsx from "clsx";

export type WizardMainAndSummaryPanels = {
	main: TemplatePanel;
	summary: TemplatePanel;
	summaryHeader: TemplatePanel;
	actions: TemplatePanel;
};

export type WizardMainAndSummaryPanelKeys = keyof WizardMainAndSummaryPanels;
export type WizardMainAndSummaryPage = LayoutPage<
	WizardMainAndSummaryPanelKeys,
	WizardMainAndSummaryPanels
>;

const panelSettings: Record<WizardMainAndSummaryPanelKeys, PanelSettings> = {
	summaryHeader: {
		defaultOptions: {
			literal: true
		}
	},
	main: {},
	summary: {
		editableOptions: {
			hideForMobile: true
		},
		defaultOptions: {
			literal: true
		}
	},
	actions: {
		defaultOptions: {
			literal: true
		}
	}
};

addRegisteredLayout({
	name: "WizardMain",
	displayName: "WizardMain",
	panels: panelSettings
});

export interface WizardMainAndSummaryProps
	extends TemplatePageProps<WizardMainAndSummaryPage> {}

export const WizardMain = (props: WizardMainAndSummaryProps) => {
	return (
		<Grid container spacing={2}>
			<WizardMainAndSummary {...props} />
		</Grid>
	);
};

export const WizardMainAndSummary = (props: WizardMainAndSummaryProps) => {
	const { panels } = props;

	const classes = useStyles(props);
	const theme = useTheme();
	const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
	const { lastStep } = usePageState();

	const { getStickyProps } = useSticky();

	const { main, summary, summaryHeader, actions } = panels;

	return (
		<>
			<Grid item xs={12} md={8}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						{lastStep !== 0 && (
							<WizardStepper
								className={clsx({
									[classes.rounded]: !main.square
								})}
								elevation={main.elevation}
							/>
						)}
						<FormStatus
							className={clsx(classes.errorSection, {
								[classes.rounded]: !main.square
							})}
							elevation={main.elevation}
						/>
						<FormErrors
							className={clsx(classes.errorSection, {
								[classes.rounded]: !main.square
							})}
							elevation={main.elevation}
						/>
						{main && (
							<div className={classes.panelRoot}>
								<Panel
									{...main}
									className={clsx({
										[classes.panelRounded]: !main.square
									})}
								/>
							</div>
						)}
					</Grid>
					<Grid item xs={12}>
						{actions && (
							<MuiThemeProvider theme={actionsTheme}>
								<Panel
									className={classes.actions}
									justify={
										xsDown &&
										(actions.sections || []).flatMap(s => s.fields).length > 1
											? "space-between"
											: "flex-end"
									}
									transparent
									disableExpansion
									disableTabs
									fullWidth
									{...actions}
								/>
							</MuiThemeProvider>
						)}
					</Grid>
				</Grid>
			</Grid>
			{(summaryHeader || summary) && (
				<Grid item xs={12} md={4}>
					<Grid container {...getStickyProps()} spacing={3} direction="column">
						{summaryHeader && (
							<Grid item className={classes.panelGrid}>
								<MuiThemeProvider theme={wizardSummaryHeaderTheme}>
									<Panel
										className={classes.summaryHeader}
										fullWidth
										disableExpansion
										disableTabs
										{...summaryHeader}
									/>
								</MuiThemeProvider>
							</Grid>
						)}
						{summary && (
							<Grid item className={classes.panelGrid}>
								<MuiThemeProvider theme={wizardSummaryTheme}>
									<Panel {...summary} />
								</MuiThemeProvider>
							</Grid>
						)}
					</Grid>
				</Grid>
			)}
		</>
	);
};

WizardMainAndSummary.displayName = "DployFormWizardMain";
