import React from "react";
import { Link } from "@reach/router";
import { useTenantImage } from "@ploy-ui/tenants";

import { makeStyles } from "@material-ui/core/styles";
import { useResource } from "@rest-hooks/core";
import { VulcanDataResource } from "@ploy-lib/rest-resources";
import clsx from "clsx";
import { ImageFit } from "@ploy-ui/core";

const basepath = process.env.PUBLIC_URL;

const AppBarBanner = ({ position = "left", className }) => {
	const { homePage = "/" } = useResource(VulcanDataResource.detail(), {});

	const classes = useStyles();

	const mainlogo = useTenantImage("mainlogo");

	return (
		<Link
			to={homePage || `${basepath}/`}
			className={clsx(className, classes.link)}
		>
			<ImageFit
				src={mainlogo}
				fit="contain"
				position={position}
				className={classes.img}
			/>
		</Link>
	);
};

const useStyles = makeStyles(
	{
		link: {
			flexGrow: 1,
			display: "flex",
			justifyContent: "center",
			alignItems: "stretch"
		},
		img: {
			flexGrow: 1
		}
	},
	{ name: "AppBar" }
);

export default AppBarBanner;
