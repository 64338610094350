import { apiResourceUrl } from "@ploy-lib/core";
import { BaseResource } from "./BaseResource";

export enum EmbProdVisibilityOption {
	Visible = "Visible",
	Hidden = "Hidden",
	HiddenNewFront = "HiddenNewFront",
	VisibleAfterApprove = "VisibleAfterApprove"
}

export interface EmbeddedProductType {
	readonly externalCode?: string;
	readonly name: string;
	readonly externalName: string;
	readonly visibility: EmbProdVisibilityOption;
}

export class EmbeddedProductTypeResource extends BaseResource
	implements EmbeddedProductType {
	readonly externalCode?: string;
	readonly name: string = "";
	readonly externalName: string = "";
	readonly visibility: EmbProdVisibilityOption =
		EmbProdVisibilityOption.Visible;

	pk() {
		return this.externalCode;
	}

	static urlRoot = apiResourceUrl("embedded-product-types");
}
