import { ChartDetailKey, ChartData } from "@ploy-lib/rest-resources";

export interface ChartContentProps {
	source?: ChartDetailKey;
	placeholder?: boolean;
}

export const sources: ChartDetailKey[] = [
	"salesOverview",
	"statusOverview",
	"financialVolumeOverview"
];

export const sourceMapTitles: {
	[key in typeof sources[number]]: string;
} = {
	salesOverview: "Salgsoversikt",
	statusOverview: "Statusoversikt",
	financialVolumeOverview: "Finansertvolumoversikt"
};

export function validChartData(chartData?: ChartData): chartData is ChartData {
	return chartData != null && chartData.series.some(s => s.points.length > 0);
}
