import { Variable } from "@ploy-lib/types";
import { Patch, FieldPatch, isFieldPatch } from "../types";
import {
	getInNamespaced,
	setInMutableNamespacedCopy,
	isEqualOrBothNaN
} from "./utils";
import { Namespaced } from "./types";
import { CalculatorState } from "./createCalculator";

export const isEqualOrUndefined = (a: any, b: any) =>
	a === undefined || b === undefined || a === b;

export const shallowCopy = <T>(a: T) => ({ ...a } as T);

export interface Patcher<TNamespaces extends string, TData> {
	<T extends Partial<CalculatorState<TNamespaces, TData>>>(
		input: T,
		addChangeTrigger: (
			namespace: TNamespaces,
			changedFieldId: string,
			isResolve: boolean
		) => void,
		patches: readonly (
			| Patch<TNamespaces, TData | string | number>
			| FieldPatch<TNamespaces, TData | string | number>
		)[]
	): T;
}

function getNumericValue(value: any, missing?: boolean): [number, boolean] {
	const numValue =
		typeof value !== "number"
			? Number(typeof value === "string" ? value.replace(/\s/g, "") : value)
			: value;
	const isMissing =
		missing || value == null || (value.toString && value.toString() === "");

	if (value === "true") {
		return [1, isMissing];
	}
	if (value === "false") {
		return [0, isMissing];
	}

	// Set numeric values as missing if NaN
	if (Number.isNaN(numValue)) {
		return [0, true];
	} else {
		return [
			numValue,
			missing || value == null || (value.toString && value.toString() === "")
		];
	}
}

function getValue<T>(
	value: number | string | T,
	missing: boolean | undefined,
	isNumeric: boolean | undefined
): [number | string | T, boolean] {
	if (isNumeric) return getNumericValue(value, missing);

	value =
		typeof value === "number"
			? Number.isNaN(value)
				? ""
				: String(value)
			: value;

	// Set text values as missing if null or undefined or empty string
	missing = missing || value == null || (value as any).toString() === "";

	return [value, missing];
}

export function createPatcher<
	TNamespaces extends string,
	TData extends { toString?: () => string }
>(
	variables: Partial<Namespaced<Variable, TNamespaces>>
): Patcher<TNamespaces, TData> {
	return function patch(input, addChangeTrigger, patches) {
		let { values, isMissing, isWriteLocked, initialFormValues } = input;

		for (const patch of patches) {
			if (patch == null) continue;

			if (isFieldPatch(patch)) {
				const { fieldName, initialValue, namespace } = patch;

				initialFormValues =
					initialValue == null
						? initialFormValues
						: setInMutableNamespacedCopy(
								input.initialFormValues,
								initialFormValues,
								namespace,
								fieldName,
								initialValue
						  );

				continue;
			}

			let {
				value,
				missing,
				writeLocked,
				namespace,
				target,
				isInitial,
				overwrite = false,
				isFieldChange,
				changeTrigger,
				isResolve = false,
				clickTrigger
			} = patch;
			let excludeFromChangeTrigger = false;

			const variable = getInNamespaced(variables, namespace, target) || {
				name: target
			};

			isWriteLocked =
				writeLocked == null
					? isWriteLocked
					: setInMutableNamespacedCopy(
							input.isWriteLocked,
							isWriteLocked,
							namespace,
							variable.name,
							writeLocked
					  );

			// Don't modify if variable is write locked
			if (
				getInNamespaced(isWriteLocked, namespace, variable.name) &&
				!isFieldChange
			)
				continue;

			const isInitialized =
				values &&
				values[namespace] &&
				values[namespace]!.hasOwnProperty(variable.name);

			// Set default value if value is undefined and can be reinitialized,
			// or the value has not been declared yet (meaning this is the first update)
			if (value === undefined && (!variable.initializeOnce || !isInitialized)) {
				const defaultValue =
					variable.defaultValue || (variable.isNumeric ? 0 : "");

				excludeFromChangeTrigger = true;
				value = (defaultValue as unknown) as TData;
			}

			const [newValue, newMissing] = getValue(
				value,
				missing,
				variable.isNumeric
			);

			const [currentValue, currentIsMissing] = getValue(
				getInNamespaced(values, namespace, variable.name),
				getInNamespaced(isMissing, namespace, variable.name),
				variable.isNumeric
			);

			if (
				(currentIsMissing || overwrite || !isInitialized) &&
				(getInNamespaced(values, namespace, variable.name) === undefined ||
					!isEqualOrBothNaN(newValue, currentValue))
			) {
				values = setInMutableNamespacedCopy(
					input.values,
					values,
					namespace,
					variable.name,
					newValue
				);
			}

			if (currentIsMissing || overwrite || !isInitialized)
				isMissing =
					newMissing == null
						? isMissing
						: setInMutableNamespacedCopy(
								input.isMissing,
								isMissing,
								namespace,
								variable.name,
								newMissing
						  );

			if (
				!isInitial &&
				((!excludeFromChangeTrigger &&
					getInNamespaced(input.values, namespace, variable.name) !==
						getInNamespaced(values, namespace, variable.name)) ||
					getInNamespaced(input.isWriteLocked, namespace, variable.name) !==
						getInNamespaced(isWriteLocked, namespace, variable.name))
			) {
				addChangeTrigger(namespace, changeTrigger || variable.name, isResolve);
			}

			if (clickTrigger === true) {
				const ctrl = getInNamespaced(
					input.variableControlMaps,
					namespace,
					variable.name
				);

				const resolve = getInNamespaced(
					input.controlFieldMaps,
					namespace,
					ctrl
				);

				clickTrigger = resolve && resolve.fieldName;
			}

			if (clickTrigger) {
				const click = clickTrigger.endsWith("_click")
					? clickTrigger
					: `${clickTrigger}_click`;
				addChangeTrigger(namespace, click, isResolve);
			}
		}

		// return input if unmodified
		if (
			values === input.values &&
			isMissing === input.isMissing &&
			isWriteLocked === input.isWriteLocked &&
			initialFormValues === input.initialFormValues
		)
			return input;

		return { ...input, values, isMissing, isWriteLocked, initialFormValues };
	};
}
