import { useMemo } from "react";
import { useResource } from "@rest-hooks/core";
import {
	loadIntlMessages,
	MessagesLoader
} from "../endpoints/loadIntlMessages";
import { Tenant } from "../types";

export function localeLang(locale: string) {
	const [lang] = locale.split("-");
	return lang;
}

export function useLanguageSettings(
	tenant: Tenant,
	loader: MessagesLoader,
	currentLocale: string,
	defaultLocale?: string
) {
	const locale = useMemo(() => {
		if (tenant.locales.includes(currentLocale)) return currentLocale;

		const userLanguages =
			window.navigator.languages ??
			[
				window.navigator.language,
				(window.navigator as any).userLanguage,
				(window.navigator as any).systemLanguage,
				(window.navigator as any).browserLanguage // IE11
			].filter(Boolean);

		let languageMatch = "";

		for (const userLocale of userLanguages) {
			const exactMatch = tenant.locales.find(
				tenantLocale => tenantLocale.toLowerCase() === userLocale.toLowerCase()
			);
			if (exactMatch) return exactMatch;

			languageMatch =
				languageMatch ??
				tenant.locales.find(
					tenantLocale => localeLang(tenantLocale) === localeLang(userLocale)
				);
		}

		if (languageMatch) return languageMatch;

		if (defaultLocale && tenant.locales.includes(defaultLocale))
			return defaultLocale;

		// Fallback: first locale in tenant-defined list of supported locales
		return tenant.locales[0];
	}, [currentLocale, defaultLocale, tenant.locales]);

	const formats = tenant.getFormats(locale);

	const language = locale.split("-")[0];

	const { tenantName } = tenant;

	const [defaultMessages, tenantMessages] = useResource(
		[loadIntlMessages, { language, loader }],
		[loadIntlMessages, { language, tenantName, tenant, loader }]
	);

	const messages = useMemo(() => {
		if (!tenantMessages || !tenantMessages) return defaultMessages;

		let messagesWithOverride = { ...defaultMessages };

		for (const [id, message] of Object.entries(tenantMessages)) {
			if (message) {
				messagesWithOverride[id] = message;
			}
		}

		return messagesWithOverride;
	}, [defaultMessages, tenantMessages]);

	return {
		locale,
		messages,
		formats
	};
}
