import React from "react";
import * as zopimBootstrapper from "../utils/ZendeskZopimChat";
import * as unifiedBootstrapper from "../utils/ZendeskUnifiedChat";
import Zendesk from "./Zendesk";
import GoogleTagManager from "./GoogleTagManager";
import {
	LoginResource,
	ThirdPartyScriptSettingsResource,
	VulcanDataResource
} from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";
import { CookieBotPostGtm, CookieBotPreGtm } from "./CookieBot";

export const ThirdPartyScripts = () => {
	const { isAuthenticated, cookiebotId, googleTagManagerCode } = useResource(
		LoginResource.status(),
		{}
	);

	const {
		loadZendeskChat,
		loadDNBFIZendesk,
		zendeskAccountKey,
		zendeskAlternativeAccountKey,
		loadGoogleTagManager,
		loadCookieBot
	} = useResource(ThirdPartyScriptSettingsResource.detail(), {});

	const vulcanData = useResource(
		VulcanDataResource.detail(),
		isAuthenticated ? {} : null
	);

	const { userFullName, userEmail, roleName, vendorName, customerCategoryId } =
		vulcanData || {};

	const zendesk = !(loadZendeskChat || loadDNBFIZendesk) ? null : (
		<Zendesk
			accountKey={
				customerCategoryId === 2
					? zendeskAlternativeAccountKey
					: zendeskAccountKey
			}
			username={userFullName}
			email={userEmail}
			tags={[roleName, vendorName, "Vestlandet"]}
			bootstrapper={loadDNBFIZendesk ? unifiedBootstrapper : zopimBootstrapper}
		/>
	);

	const cookieBotPreGtm = !loadCookieBot ? null : <CookieBotPreGtm />;

	const gtm =
		loadGoogleTagManager && googleTagManagerCode ? (
			<GoogleTagManager
				CustomerCategoryId={customerCategoryId}
				GoogleTagManagerCode={googleTagManagerCode}
			/>
		) : null;

	const cookieBotPostGtm =
		loadCookieBot && cookiebotId ? (
			<CookieBotPostGtm cookiebotId={cookiebotId} />
		) : null;

	return (
		<>
			{zendesk}
			{cookieBotPreGtm}
			{gtm}
			{cookieBotPostGtm}
		</>
	);
};
