import React, { useMemo, useCallback, memo } from "react";
import {
	TextFieldProps as FormikTextFieldProps,
	fieldToTextField as fieldToMuiTextField
} from "formik-material-ui";
import { BaseFieldProps, FieldEditorOptions } from "./types";
import { DployTextField, DployTextFieldProps } from "./DployTextField";
import { InputAdornment } from "@material-ui/core";
import {
	identityRecordOfFieldEditorOptions,
	getFieldErrorProps
} from "./utils";

interface TextFieldOptions {
	suffix?: string;
	textAreaRows?: number;
}

export interface TextFieldProps<T = never>
	extends FormikTextFieldProps,
		TextFieldOptions,
		BaseFieldProps<T> {
	options?: TextFieldOptions;
}

const MemoDploytextField = memo(DployTextField);

export const useFieldToTextField = ({
	items,
	getItemLabel,
	getItemId,
	getItemValue,
	valueKey,
	labelKey,
	getItemSuggestions,
	onSelectItem,
	manual,
	horizontal: row,
	multiple,
	pending,
	suffix,
	prefix,
	emptyEqualsZero,
	options,
	formatString,
	searchable,
	minSearch,
	secondaryLabel,
	italicText,
	boldText,
	modalText,
	onBlur: propsBlur,
	errorDisplay,
	clearable,
	...props
}: TextFieldProps<any>): DployTextFieldProps => {
	const InputProps = useMemo(
		() => ({
			startAdornment: prefix ? (
				<InputAdornment position="start">{prefix}</InputAdornment>
			) : (
				props.InputProps && props.InputProps.startAdornment
			),
			endAdornment: suffix ? (
				<InputAdornment position="end">{suffix}</InputAdornment>
			) : (
				props.InputProps && props.InputProps.endAdornment
			),
			...props.InputProps
		}),
		[prefix, props.InputProps, suffix]
	);
	var textFieldProps = fieldToMuiTextField(props);

	const { onBlur: formikBlur } = textFieldProps;

	const onBlur = useCallback(
		(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (formikBlur) formikBlur(e);
			if (propsBlur) propsBlur(e);
		},
		[formikBlur, propsBlur]
	);

	return {
		...textFieldProps,
		...getFieldErrorProps(props, errorDisplay),
		onBlur,
		InputProps
	};
};

export const TextField: React.FC<TextFieldProps> = props => (
	<MemoDploytextField {...useFieldToTextField(props)} />
);

export const UppercaseTextField: React.FC<TextFieldProps> = props => {
	const {
		form: { setFieldValue },
		field: { name }
	} = props;

	const onChange = useCallback(
		event => {
			const { value } = event.target;
			setFieldValue(name, value ? value.toUpperCase() : "");
		},
		[setFieldValue, name]
	);

	return (
		<MemoDploytextField {...useFieldToTextField(props)} onChange={onChange} />
	);
};

export const TextArea: React.FC<TextFieldProps> = props => {
	const rows = props.textAreaRows
		? props.textAreaRows
		: props.options?.textAreaRows
		? props.options.textAreaRows
		: 10;
	return (
		<MemoDploytextField {...useFieldToTextField(props)} multiline rows={rows} />
	);
};

const commonEditorProps: FieldEditorOptions = {
	editableOptions: {
		placeholder: true,
		suffix: true
	}
};

const textAreaEditorProps: FieldEditorOptions = {
	editableOptions: {
		placeholder: true,
		textAreaRows: true
	}
};

export const EditorTextFields = identityRecordOfFieldEditorOptions({
	TextField: commonEditorProps,
	UppercaseTextField: commonEditorProps,
	TextArea: textAreaEditorProps
});
