import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";

import {
	LoginForm,
	VendorForm,
	NewPasswordForm,
	UpdatePasswordForm,
	UpdateMobileForm
} from "./forms";
import { LanguageSelector } from "../../LanguageSelector";
import { Box } from "@material-ui/core";
import { Router, Redirect } from "@reach/router";
import { useResource } from "@rest-hooks/core";
import { LoginResource } from "@ploy-lib/rest-resources";
import { LoginWrapper } from "@ploy-ui/login";
import { useBrowserDeprecationStatus } from "@ploy-lib/core";
import { DeprecationMessage } from "@ploy-ui/core";

const statusFormMap = {
	NotSet: LoginForm,
	ChooseVendor: VendorForm,
	ChangePassword: UpdatePasswordForm,
	SupplyMobile: UpdateMobileForm
};

const styles = theme => ({
	root: {
		display: "flex",
		flexDirection: "column"
	},
	form: {
		width: "100%",
		maxWidth: 320 + theme.spacing(4),
		margin: "31px auto",
		minHeight: 278,
		padding: theme.spacing(2)
	},
	formWrapper: {
		flexGrow: 1
	}
});

function Login(props) {
	const { location, classes, elevation, basepath, footerVisible } = props;
	const { returnUri = `${basepath}/` } = location.state || {};

	const loginData = useResource(LoginResource.status(), {});

	const { deprecated, supported } = useBrowserDeprecationStatus(
		loginData.browserDeprecation
	);

	const [dismissed, setDismissed] = useState(false);

	if (loginData.isAuthenticated && supported)
		return <Redirect noThrow to={returnUri} />;

	const FormComponent = statusFormMap[loginData.status];

	return (
		<LoginWrapper
			classes={classes}
			footerVisible={footerVisible}
			elevation={elevation}
		>
			{!supported || (deprecated && !dismissed) ? (
				<Box p={1}>
					<DeprecationMessage
						onDismiss={() => setDismissed(true)}
						browserDeprecation={loginData.browserDeprecation}
					/>
				</Box>
			) : (
				<Router>
					<NewPasswordForm path="/forgotten-password" {...loginData} />
					<FormComponent default {...loginData} />
				</Router>
			)}
			<Box p={1}>
				<LanguageSelector />
			</Box>
		</LoginWrapper>
	);
}

Login.displayName = "Login";

export default withStyles(styles, { name: "Login" })(Login);
