import React, { Suspense } from "react";
import { Router, RouteComponentProps, RouterProps } from "@reach/router";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorPage } from "@ploy-ui/core";
import { ErrorHandler } from "@ploy-lib/core";

import { ExternalViewLoader } from "./ExternalViewLoader";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(4)
	}
}));

export const ExternalViews = ({
	"*": _,
	...props
}: RouteComponentProps<{ "*": string }> & RouterProps) => {
	const classes = useStyles();

	return (
		<Suspense fallback={<LinearProgress />}>
			<Router {...props} className={classes.root}>
				<ErrorHandler<RouteComponentProps>
					path="/"
					fallback={error => (
						<Router>
							<ErrorPage default error={error} />
						</Router>
					)}
				>
					<ExternalViewLoader path=":view" />
					<ExternalViewLoader default />
				</ErrorHandler>
			</Router>
		</Suspense>
	);
};
