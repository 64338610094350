import React from "react";

import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";

import { Panel } from "../../../panel";
import { TemplatePageProps, LayoutPage } from "../../../types";
import { addRegisteredLayout, PanelSettings } from "../layoutDescriptions";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { TemplatePanel } from "@ploy-lib/types";
import { columnsSummaryHeaderTheme, useStyles, actionsTheme } from "./styles";
import clsx from "clsx";
import FormStatus from "../../../components/FormStatus";
import { useAppLoad } from "../../../appLoadContext";

export type ThreeColumnsPanels = {
	column1: TemplatePanel;
	column2: TemplatePanel;
	column3: TemplatePanel;
	summaryHeader: TemplatePanel;
	actions: TemplatePanel;
	appActions: TemplatePanel;
};

export type ThreeColumnsPanelKeys = keyof ThreeColumnsPanels;
export type ThreeColumnsPage = LayoutPage<
	ThreeColumnsPanelKeys,
	ThreeColumnsPanels
>;

type ThreeColumnPanelSettings = Record<ThreeColumnsPanelKeys, PanelSettings>;

const commonColumnPanelSettings: PanelSettings = {
	editableOptions: {
		title: true,
		elevation: true,
		square: true
	}
};

const summaryHeaderPanelSettings: PanelSettings = {
	editableOptions: {
		title: false,
		elevation: true,
		square: true
	}
};

const panels: ThreeColumnPanelSettings = {
	summaryHeader: summaryHeaderPanelSettings,
	column1: commonColumnPanelSettings,
	column2: commonColumnPanelSettings,
	column3: commonColumnPanelSettings,
	actions: {},
	appActions: {}
};

addRegisteredLayout({
	name: "ThreeColumns",
	displayName: "ThreeColumns",
	panels
});
const useThreeColumnsStyle = makeStyles(theme => ({
	threeColumnPadding: {
		padding: 0
	}
}));

export function ThreeColumns(props: TemplatePageProps<ThreeColumnsPage>) {
	const classes = useStyles(props);
	const threeColumnsStyle = useThreeColumnsStyle(props);
	const { header, panels } = props;

	const {
		summaryHeader,
		column1,
		column2,
		column3,
		actions,
		appActions
	} = panels;

	const { vulcanContext } = useAppLoad();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={3}>
					<Grid item md={4} container alignItems="flex-start">
						{header}
					</Grid>
					<Grid item md={4} container alignItems="stretch">
						{summaryHeader && (
							<MuiThemeProvider theme={columnsSummaryHeaderTheme}>
								<Panel {...summaryHeader} className={classes.summaryHeader} />
							</MuiThemeProvider>
						)}
					</Grid>
					<Grid
						item
						md={4}
						container
						alignItems="stretch"
						wrap="nowrap"
						justify="flex-end"
					>
						{appActions && (
							<MuiThemeProvider theme={actionsTheme}>
								<Panel {...appActions} className={classes.actionsPanel} />
							</MuiThemeProvider>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<FormStatus />
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={3}>
					<Grid item md={4} xs={12}>
						{column1 && (
							<Paper elevation={0}>
								{column1.panelTitle && (
									<Typography
										variant="h6"
										className={classes.mainSectionsHeader}
									>
										{column1.panelTitle}
									</Typography>
								)}
								<Panel
									className={clsx(
										classes.mainSections,
										threeColumnsStyle.threeColumnPadding
									)}
									{...column1}
								/>
							</Paper>
						)}
					</Grid>
					<Grid item md={4} xs={12}>
						{column2 && (
							<Paper elevation={0}>
								{column2.panelTitle && (
									<Typography
										variant="h6"
										className={classes.mainSectionsHeader}
									>
										{column2.panelTitle}
									</Typography>
								)}
								<Panel
									className={clsx(
										classes.mainSections,
										threeColumnsStyle.threeColumnPadding
									)}
									{...column2}
								/>
							</Paper>
						)}
					</Grid>
					<Grid item md={4} xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								{column3 && (
									<Paper elevation={0}>
										{column3.panelTitle && (
											<Typography
												variant="h6"
												className={classes.mainSectionsHeader}
											>
												{column3.panelTitle}
											</Typography>
										)}
										<Panel
											className={clsx(
												classes.mainSections,
												threeColumnsStyle.threeColumnPadding
											)}
											{...column3}
										/>
									</Paper>
								)}
							</Grid>
							<Grid item xs={12}>
								{actions && vulcanContext !== "PostProcess" && (
									<MuiThemeProvider theme={actionsTheme}>
										<Panel
											justify="flex-end"
											transparent
											fullWidth
											{...actions}
										/>
									</MuiThemeProvider>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
