export function applyPropertyControls<P extends {}>(
	Component: React.ComponentType<P>,
	controls: PropertyControls<P>
) {
	if (isControlComponent(Component)) {
		Component.propertyControls = {
			...Component.propertyControls,
			...controls
		};
	} else {
		(Component as ControlComponent<
			React.ComponentType<P>
		>).propertyControls = controls;
	}
}

// type KeyFromVal<T, V> = {
// 	[K in keyof T]: V extends T[K] ? K : never;
// }[keyof T];

// we assume the type to be an object literal with string values
// , should also work with number or symbol
// type Inverse<M extends Record<string, string>> = {
// 	[K in M[keyof M]]: KeyFromVal<M, K>;
// };

export enum ControlType {
	Boolean = "boolean",
	Enum = "enum",
	Number = "number",
	String = "string",
	FusedNumber = "fusednumber",
	Color = "color",
	Image = "image",
	File = "file",
	ComponentInstance = "componentinstance",
	Array = "array",
	Object = "object",
	Date = "date",
	Hidden = "hidden"
}

export interface BasePropertyControl<F> {
	type: ControlType;
	title: React.ReactNode;
	description?: React.ReactNode;
	defaultValue?: F;
	coerce?: <DP extends Record<string, any>, BDP extends Record<string, any>>(
		value: any,
		values: DP,
		basValues: BDP
	) => F | undefined;
}

export interface HiddenControl extends BasePropertyControl<any> {
	type: ControlType.Hidden;
}

export interface BooleanControl extends BasePropertyControl<boolean> {
	type: ControlType.Boolean;
	enabledTitle?: string;
	disabledTitle?: string;
}

export interface EnumControl<T extends string | number | boolean>
	extends BasePropertyControl<T> {
	type: ControlType.Enum;
	options: T[];
	optionTitles?: string[];
}

export interface StringControl extends BasePropertyControl<string> {
	type: ControlType.String;
	placeholder?: string;
	multiline?: boolean;
}

export interface NumberControl extends BasePropertyControl<number> {
	type: ControlType.Number;
	min?: number;
	max?: number;
	unit?: string;
	step?: number;
	displayStepper?: boolean;
}

export interface ColorControl extends BasePropertyControl<string> {
	type: ControlType.Color;
}

export interface DateControl extends BasePropertyControl<string | Date> {
	type: ControlType.Date;
	min?: Date;
	max?: Date;
}

export interface ImageControl extends BasePropertyControl<string> {
	type: ControlType.Image;
}

export interface FileControl extends BasePropertyControl<string> {
	type: ControlType.File;
	allowedFileTypes: string[];
}

export interface ComponentInstanceControl extends BasePropertyControl<string> {
	type: ControlType.ComponentInstance;
}

export interface ArrayControl<F extends any[]> extends BasePropertyControl<F> {
	type: ControlType.Array;
	propertyControl: PropertyControl<F[number]>;
	maxCount?: number;
}

export interface ObjectControl<F extends {}> extends BasePropertyControl<F> {
	type: ControlType.Object;
	propertyControls: PropertyControls<F>;
}

export type PropertyControl<F> =
	| (React.ReactNode extends F
			? ComponentInstanceControl | StringControl
			: never)
	| (boolean extends F ? BooleanControl | EnumControl<boolean> : never)
	| (string extends F
			?
					| StringControl
					| ColorControl
					| EnumControl<string>
					| FileControl
					| ImageControl
					| DateControl
			: never)
	| (Date extends F ? DateControl : never)
	| (number extends F ? NumberControl | EnumControl<number> : never)
	| (F extends any[] ? ArrayControl<F> : never)
	// | ({} extends F ? ObjectControl<F> : never)
	| (F extends object ? ObjectControl<F> : never)
	| (F extends {} ? EnumControl<F[any]> : never)
	| HiddenControl;

export type PropertyControls<P> = {
	[K in keyof P]?: PropertyControl<P[K]>;
};

export type ControlComponent<C extends React.ComponentType<any>> = C & {
	propertyControls: PropertyControls<React.ComponentProps<C>>;
};

// export const is: <K extends keyof Inverse<typeof ControlType>>(
// 	val: K
// ) => Inverse<typeof ControlType>[K];

// export function is<K extends keyof Inverse<typeof ControlType>>(val: K) {
// 	ControlType[val];
// }

export function isControlComponent<C extends React.ComponentType<any>>(
	component: C
): component is ControlComponent<C> {
	return (component as ControlComponent<C>).propertyControls != null;
}
