import { Router, RouteComponentProps, RouterProps } from "@reach/router";
import { ApplicationView, TemplateView, ProductView } from "./views";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorHandler } from "@ploy-lib/core";
import { ErrorPage, NoMatch, GlobalSuspense } from "@ploy-ui/core";
import { Theme } from "@material-ui/core";
import UserLimitationsBanner from "./components/UserLimitationsBanner";

const useStyles = makeStyles(
	(theme: Theme) => ({
		root: {
			[theme.breakpoints.up("sm")]: {
				padding: theme.spacing(2)
			},
			[theme.breakpoints.up("md")]: {
				padding: theme.spacing(4)
			},
			display: "flex",
			justifyContent: "center"
		},
		page: {
			maxWidth: 1440,
			width: "100%"
		}
	}),
	{ name: "DployFormViews" }
);

export const FormView = ({
	"*": _,
	...props
}: RouteComponentProps<{ "*": string }> & RouterProps) => {
	const classes = useStyles(props);

	return (
		<GlobalSuspense>
			<UserLimitationsBanner />
			<div className={classes.root}>
				<Router {...props} className={classes.page}>
					<ErrorHandler<RouteComponentProps>
						path="/"
						fallback={error => (
							<Router>
								<ErrorPage default error={error} />
							</Router>
						)}
					>
						<TemplateView path="template/*" />
						<TemplateView path="template/:formTemplateId/*" />

						<ApplicationView path="application/:applicationNumber/*" />

						{/* Specific product */}
						<ProductView key="product" path="product/:externalCode/*" />
						<ProductView key="product" path="product" />

						{/* All products */}
						<ProductView
							key="product"
							path="all/:externalCode/*"
							enableSelect
						/>
						<ProductView key="product" path="all" enableSelect />

						{/* Calculator products */}
						<ProductView
							key="product"
							path="calculator/:externalCode/*"
							enableSelect
							formContext="Calculator"
						/>
						<ProductView
							key="product"
							path="calculator"
							enableSelect
							formContext="Calculator"
						/>

						{/* Wholesale products */}
						<ProductView
							key="product"
							path="wholesale/:externalCode/*"
							enableSelect
							wholesale
						/>
						<ProductView
							key="product"
							path="wholesale"
							enableSelect
							wholesale
						/>

						{/* Category products, (not wholesale) */}
						<ProductView
							key="product"
							path=":category/:externalCode/*"
							enableSelect
							wholesale={false}
						/>
						<ProductView
							key="product"
							path=":category"
							enableSelect
							wholesale={false}
						/>

						<NoMatch default isRelative noThrow to="all" />
					</ErrorHandler>
				</Router>
			</div>
		</GlobalSuspense>
	);
};

export const FormViews = FormView;
