import React from "react";
import { SectionProps } from "../Section";
import { useCalculationFields } from "@ploy-lib/calculation";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import {
	Theme,
	makeStyles,
	createStyles,
	Grid,
	Typography,
	Divider
} from "@material-ui/core";
import clsx from "clsx";
import { fieldToKey } from "../../field";

addRegisteredSectionLayout({
	name: "CustomerInfoSection",
	displayName: "CustomerInfoSection",
	settings: {}
});

const CustomerInfoSection = (props: SectionProps) => {
	const { fields, onClick, useSeparator, sectionTitle, className } = props;
	const classes = useStyles(props);

	const calculationFields = useCalculationFields(...fields);
	return (
		<Grid container className={clsx(classes.root, className)} onClick={onClick}>
			{sectionTitle && (
				<Grid item xs={12}>
					<Typography variant="caption">{sectionTitle}</Typography>
				</Grid>
			)}
			{calculationFields.map((field, i) => {
				const isLeftSide = i % 2 === 0;
				return (
					<Grid
						item
						xs={6}
						key={fieldToKey(fields[i])}
						className={clsx(
							classes.fieldContainer,
							!isLeftSide && classes.fieldContainerRight,
							calculationFields.length === 1 && classes.fieldContainerSingleItem
						)}
					>
						<Typography
							className={clsx(
								i === 0 && classes.itemFirst,
								isLeftSide && classes.itemLeft
							)}
							variant="body2"
						>
							{field && field.fieldValue}
						</Typography>
					</Grid>
				);
			})}
			{useSeparator && (
				<Grid item xs={12} style={{ paddingTop: 16 }}>
					<Divider orientation="horizontal" />
				</Grid>
			)}
		</Grid>
	);
};

CustomerInfoSection.displayName = "DployCustomerInfoSection";

const useStyles = makeStyles(
	(theme: Theme) =>
		createStyles({
			root: {
				paddingLeft: theme.spacing(1),
				paddingRight: theme.spacing(1)
			},
			fieldContainer: {
				marginTop: theme.spacing(1),
				display: "flex"
			},
			fieldContainerRight: {
				justifyContent: "flex-end",
				textAlign: "right"
			},
			fieldContainerSingleItem: {
				maxWidth: "100%",
				flexBasis: "100%"
			},
			itemLeft: {
				fontWeight: "bold"
			},
			itemFirst: {
				fontSize: "1.1rem"
			}
		}),
	{ name: "DployCustomerInfoSection" }
);

export { CustomerInfoSection };
