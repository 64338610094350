import { useCallback, useMemo, useReducer, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Background from "./Background";
import {
	ContentGrid,
	DashboardGridResource,
	GridItem,
	Layouts,
	Layout,
	DashboardGrid
} from "@ploy-ui/dashboard";
import { ErrorHandler } from "@ploy-lib/core";
import { ErrorPage } from "@ploy-ui/core";
import Box from "@material-ui/core/Box";
import { useAppSearchParams, useEditable } from "../../../contexts";

import "@ploy-ui/dashboard/css/styles.css";
import {
	useResource,
	useFetcher,
	useCache,
	useRetrieve
} from "@rest-hooks/core";
import { useSnackbar } from "notistack";
import { DashboardSelector } from "./DashboardSelector";

import { DashboardEditActions } from "./DashboardEditActions";
import { createReducer, initialState, isChangedSelector } from "./state";
import { LoginResource } from "@ploy-lib/rest-resources";

import { useEventCallback } from "./useEventCallback";
import { DashboardContentCarousel } from "./DashboardContentCarousel";
import { DashboardEditDialog } from "./DashboardEditDialog";
import { DashboardsExportDialog } from "./DashboardsExportDialog";
import { DashboardsImportDialog } from "./DashboardsImportDialog";
import {
	DashboardActionConfirmDialog,
	ConfirmAction
} from "./DashboardActionConfirmDialog";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

export interface DashboardProps {
	className?: string;
}

const Dashboard = (props: DashboardProps) => {
	const { className } = props;
	const classes = useStyles(props);

	const loginInfo = useResource(LoginResource.status(), {});

	const grid = useResource(
		DashboardGridResource.detail(),
		loginInfo.isInternal
			? {
					username: loginInfo.user?.username
			  }
			: {
					salespersonUsername: loginInfo.user?.username
			  }
	);

	const searchParams = useAppSearchParams();

	const dashboards = useCache(DashboardGridResource.list(), {});

	const dashboardsMap = useMemo(
		() =>
			new Map(
				dashboards?.map(d => [
					d.pk(),
					DashboardGridResource.toObjectDefined(d) as DashboardGrid
				])
			),
		[dashboards]
	);

	const [state, dispatch] = useReducer(
		createReducer(dashboardsMap),
		initialState,
		s => ({ ...s, editing: grid.pk() })
	);

	const originalGrid =
		state.editing != null ? dashboardsMap.get(state.editing) : undefined;
	const editingGrid =
		state.editing != null
			? state.editedById[state.editing] ??
			  (originalGrid?.layouts ? originalGrid : undefined)
			: undefined;

	const dirtyBreakpoints = state.editing
		? state.dirtyBreakpointsById[state.editing]
		: undefined;

	const dialogEditingGrid = state.dialogEditing;

	const updateGrid = useFetcher(DashboardGridResource.update());
	const createGrid = useFetcher(DashboardGridResource.create());
	const deleteGrid = useFetcher(DashboardGridResource.delete());

	const { enqueueSnackbar } = useSnackbar();

	const [confirmDialog, setConfirmDialog] = useState<
		| {
				action: ConfirmAction;
				confirm: () => Promise<any> | void;
				cancel?: () => void;
				changed?: {
					dashboard: DashboardGrid;
					dirty?: Partial<Record<Breakpoint, boolean>>;
				}[];
				awaitBeforeClose?: boolean;
		  }
		| undefined
	>();

	const confirmAction = (
		action: ConfirmAction,
		changed: {
			dashboard: DashboardGrid;
			dirty?: Partial<Record<Breakpoint, boolean>>;
		}[],
		confirmedAction?: () => Promise<void>
	) =>
		new Promise<void>((resolve, cancel) =>
			setConfirmDialog({
				confirm: confirmedAction
					? () => confirmedAction().then(resolve)
					: resolve,
				cancel,
				action,
				changed
			})
		);

	const saveEdited = useCallback(
		async (
			editingGrids: [
				editId: string | undefined,
				grid: DashboardGrid
			][] = Object.entries(state.editedById)
		) => {
			await Promise.all(
				editingGrids.map(async ([editId, grid]) => {
					editId =
						editId ??
						Object.entries(state.editedById).find(
							([, editing]) =>
								DashboardGridResource.pk(grid) ===
								DashboardGridResource.pk(editing)
						)?.[0];

					try {
						await (grid.id
							? updateGrid({ id: grid.id }, grid)
							: createGrid({}, grid, [
									[DashboardGridResource.list(), {}, (id, ids) => [...ids, id]]
							  ]));

						if (editId)
							dispatch({
								type: "STOP_EDIT_DASHBOARD",
								payload: { id: editId }
							});

						enqueueSnackbar(`Dashboard "${grid.name}" er lagret.`, {
							variant: "success"
						});
					} catch (e: any) {
						enqueueSnackbar(
							`Lagring av dashboard "${grid.name}" feilet: ${e}`,
							{
								variant: "error"
							}
						);
						throw e;
					}
				})
			);
		},
		[createGrid, enqueueSnackbar, state.editedById, updateGrid]
	);

	const editable = useEditable({
		canEdit: () => loginInfo.isInternal,
		async onSave() {
			const editedGrids = Object.entries(state.editedById);

			if (editedGrids.length > 0) {
				await confirmAction(
					"save_all",
					editedGrids.map(([editId, dashboard]) => ({
						dashboard,
						dirty: state.dirtyBreakpointsById[editId]
					}))
				);

				await saveEdited();
			}
		},
		async onCancel() {
			const editedGrids = Object.entries(state.editedById);

			if (editedGrids.length > 0) {
				await confirmAction(
					"cancel",
					editedGrids.map(([_, dashboard]) => ({ dashboard }))
				);

				for (const [editId] of editedGrids) {
					dispatch({
						type: "STOP_EDIT_DASHBOARD",
						payload: { id: editId }
					});
				}
			}
		}
	});

	useRetrieve(
		DashboardGridResource.detail(),
		editable && (originalGrid?.id || originalGrid?._legacyId)
			? { id: originalGrid.id, _legacyId: originalGrid._legacyId }
			: null
	);

	const handleSaveGrid = useEventCallback(async () => {
		if (!editingGrid) return;

		if (dirtyBreakpoints && Object.values(dirtyBreakpoints).some(x => x)) {
			await confirmAction("save", [
				{ dashboard: editingGrid, dirty: dirtyBreakpoints }
			]);
		}

		try {
			const saved = await (editingGrid.id
				? updateGrid({ id: editingGrid.id }, editingGrid)
				: createGrid({}, editingGrid, [
						[
							DashboardGridResource.list(),
							{},
							(id, ids) => (ids.includes(id) ? ids : [...ids, id])
						]
				  ]));

			dispatch({
				type: "STOP_EDIT_DASHBOARD",
				payload: {
					id: state.editing
				}
			});

			const savedPk = DashboardGridResource.fromJS(saved).pk();

			if (!editingGrid.id && state.editing !== savedPk) {
				dispatch({
					type: "START_EDIT_DASHBOARD",
					payload: { id: savedPk, ifEditing: state.editing }
				});
			}

			enqueueSnackbar(`Dashboard "${editingGrid.name}" er lagret.`, {
				variant: "success"
			});
		} catch (e: any) {
			enqueueSnackbar(
				`Lagring av dashboard "${editingGrid.name}" feilet: ${e}`,
				{
					variant: "error"
				}
			);

			throw e;
		}
	});

	const handleDeleteGrid = useEventCallback(async () => {
		if (editingGrid) {
			await confirmAction("delete", [{ dashboard: editingGrid }]);
			try {
				await deleteGrid({ id: editingGrid.id });
				dispatch({
					type: "START_EDIT_DASHBOARD",
					payload: { id: dashboards?.filter(g => g !== editingGrid)[0]?.pk() }
				});

				enqueueSnackbar(`Dashboard "${editingGrid.name}" er slettet.`, {
					variant: "success"
				});
			} catch (e: any) {
				enqueueSnackbar(
					`Sletting av dashboard "${editingGrid.name}" feilet: ${e}`,
					{
						variant: "error"
					}
				);

				throw e;
			}
		}
	});

	const handleDragItemStart = useCallback(
		(component: GridItem["component"]) =>
			dispatch({
				type: "DRAG_ITEM",
				payload: { component }
			}),
		[]
	);
	const handleDragItemEnd = useCallback(
		(component: GridItem["component"]) =>
			dispatch({
				type: "DROP_ITEM",
				payload: { component }
			}),
		[]
	);

	const handleEditGrid = useCallback(
		() => dispatch({ type: "OPEN_DASHBOARD_DIALOG_FORM" }),
		[]
	);
	const handleCopyGrid = useCallback(
		() => dispatch({ type: "COPY_DASHBOARD" }),
		[]
	);
	const handleAddGrid = useCallback(
		() => dispatch({ type: "ADD_DASHBOARD" }),
		[]
	);

	const handleItemChange = useCallback(
		(item: GridItem) => dispatch({ type: "CHANGE_ITEM", payload: { item } }),
		[]
	);

	const handleItemRemove = useCallback(
		(id: GridItem["id"]) =>
			dispatch({ type: "REMOVE_ITEM", payload: { item: { id } } }),
		[]
	);

	const handleLayoutChange = useCallback(
		(current: Layout[], layouts: Layouts) =>
			dispatch({ type: "CHANGE_LAYOUTS", payload: { layouts, current } }),
		[]
	);

	const handleItemDrop = useCallback(
		(layout: Layout[], layoutItem: Layout, breakpoint: string) =>
			dispatch({
				type: "DROP_LAYOUT_ITEM",
				payload: { layout, layoutItem, breakpoint }
			}),
		[]
	);

	const handleGridSelect = useCallback(
		(id: string | undefined): void =>
			dispatch({ type: "START_EDIT_DASHBOARD", payload: { id } }),
		[]
	);

	const handleGridSave = useCallback(
		(values: DashboardGrid) =>
			dispatch({
				type: "SAVE_DASHBOARD_DIALOG_FORM",
				payload: {
					dashboard: values
				}
			}),
		[]
	);

	const handleDialogClose = useCallback(
		() =>
			dispatch({
				type: "CLOSE_DASHBOARD_DIALOG_FORM"
			}),
		[]
	);

	const handleChangeEditorBreakpoint = useCallback(
		(breakpoint: Breakpoint | undefined) =>
			dispatch({ type: "CHANGE_EDITOR_BREAKPOINT", payload: { breakpoint } }),
		[]
	);

	const handleChangeMaxBreakpoint = useCallback(
		(breakpoint: string) =>
			dispatch({
				type: "CHANGE_MAX_BREAKPOINT",
				payload: { breakpoint: breakpoint as Breakpoint }
			}),
		[]
	);

	const [dialog, setDialog] = useState<"export" | "import">();
	const handleExport = useCallback(async () => {
		const editedGrids = Object.entries(state.editedById);

		if (editedGrids.length > 0) {
			await confirmAction(
				"export_unsaved",
				editedGrids.map(([editId, dashboard]) => ({
					dashboard,
					dirty: state.dirtyBreakpointsById[editId]
				})),
				saveEdited
			);
		}

		setDialog("export");
	}, [saveEdited, state.dirtyBreakpointsById, state.editedById]);

	const handleImport = useCallback(() => setDialog("import"), []);

	const handleImportSubmit = useCallback(
		async (dashboards: DashboardGrid[]) =>
			saveEdited(dashboards.map(d => [undefined, d])),
		[saveEdited]
	);

	const handleCloseDialog = useCallback(() => setDialog(undefined), []);

	const theme = useTheme();

	const maxWidth =
		state.editorBreakpoint &&
		Math.max(theme.breakpoints.width(state.editorBreakpoint), 450);

	return (
		<div className={clsx(classes.root, className)}>
			<Background
				height="400px"
				maxHeight="calc(100vw / 3)"
				filter={editable ? "blur(4px) grayscale(0.7) opacity(0.5)" : ""}
			/>
			<ErrorHandler
				fallback={error => (
					<Box p={3} display="flex" alignItems="center" justifyContent="center">
						<ErrorPage error={error} />
					</Box>
				)}
			>
				{editable && (
					<Box
						position="absolute"
						top={0}
						width="100%"
						height="calc(100vh / 3)"
						maxHeight="calc(100vw / 3)"
					>
						<DashboardContentCarousel
							className={classes.carousel}
							onDragItemStart={handleDragItemStart}
							onDragItemEnd={handleDragItemEnd}
							height="calc(100vh / 3 - 50px)"
							maxHeight="calc(100vw / 3 - 50px)"
						/>
						<Box
							display="flex"
							width="40%"
							position="absolute"
							bottom={0}
							m={1}
						>
							<DashboardSelector
								variant="filled"
								selected={state.editing}
								editedDashboards={state.editedById}
								onSelect={handleGridSelect}
							/>
						</Box>
						<Box
							display="flex"
							width="40%"
							position="absolute"
							bottom={0}
							right={0}
							m={1}
							alignItems="center"
							flexWrap="wrap"
							justifyContent="space-between"
						>
							<DashboardEditActions
								selected={state.editing}
								isChanged={isChangedSelector(state, state.editing)}
								onDelete={handleDeleteGrid}
								onSave={handleSaveGrid}
								onEdit={handleEditGrid}
								onCopy={handleCopyGrid}
								onAdd={handleAddGrid}
								onExport={handleExport}
								onImport={handleImport}
								onChangeBreakpoint={handleChangeEditorBreakpoint}
								breakpoint={state.editorBreakpoint}
								maxBreakpoint={state.maxBreakpoint}
								dirtyBreakpoints={dirtyBreakpoints}
							/>
						</Box>
						<DashboardEditDialog
							grid={dialogEditingGrid}
							editedById={state.editedById}
							onSubmit={handleGridSave}
							onReset={handleDialogClose}
						/>
						<DashboardsExportDialog
							open={dialog === "export"}
							onClose={handleCloseDialog}
						/>
						<DashboardsImportDialog
							open={dialog === "import"}
							onImportSubmit={handleImportSubmit}
							onClose={handleCloseDialog}
						/>
						<DashboardActionConfirmDialog
							action={confirmDialog?.action}
							changed={confirmDialog?.changed}
							onClose={() => {
								confirmDialog?.cancel?.();
								setConfirmDialog(undefined);
							}}
							onConfirm={async () => {
								await confirmDialog?.confirm();
								setConfirmDialog(undefined);
							}}
						/>
					</Box>
				)}
				<ContentGrid
					setApplicationsSearchParams={searchParams.set}
					applicationsSearchPath="../applications"
					applicationOpenPath="../form/application"
					editable={editable}
					grid={editable && editingGrid ? editingGrid : grid}
					onItemChange={editable ? handleItemChange : undefined}
					onItemRemove={editable ? handleItemRemove : undefined}
					onLayoutChange={editable ? handleLayoutChange : undefined}
					onChangeMaxBreakpoint={handleChangeMaxBreakpoint}
					droppingComponent={state.draggingComponent}
					onDrop={editable ? handleItemDrop : undefined}
					maxWidth={editable ? maxWidth : undefined}
				/>
			</ErrorHandler>
		</div>
	);
};

export const useStyles = makeStyles(
	{
		root: {
			position: "relative",
			display: "flex",
			flexDirection: "column",
			flexGrow: 1
		},
		carousel: {
			flex: 1
		}
	},
	{ name: "Dashboard" }
);

export default Dashboard;
