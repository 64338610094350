import React, {
	createContext,
	useReducer,
	useContext,
	useEffect,
	useRef
} from "react";

export enum EnvVariable {
	Tenant = "REACT_APP_TENANT",
	Design = "REACT_APP_DESIGN",
	Locale = "REACT_APP_LOCALE",
	ProxyEnvironment = "REACT_APP_PROXY_ENVIRONMENT"
}

declare global {
	interface Window {
		env?: Partial<Record<EnvVariable, string>>;
	}
}

function getCookieVar(
	key: string,
	defaultValue?: string,
	filter?: (value: string) => boolean
) {
	if (process.env.NODE_ENV !== "development") return defaultValue;

	const [value = ""] = document.cookie
		.split(";")
		.filter(item => item.trim().startsWith(`${key}=`))
		.map(c => c.split("=")[1]);

	const validValue = filter?.(value) ?? true;

	if (value && validValue) return value;

	if (defaultValue) setCookieVar(key, defaultValue);
	else if (!validValue) removeCookieVar(key);

	return defaultValue;
}

function setCookieVar(key: string, value: string) {
	document.cookie = `${key}=${value}; path=/`;
}

function removeCookieVar(key: string) {
	document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
}

function getLocalStorageVar(key: string, defaultValue?: string) {
	return window.localStorage.getItem(`ENV.${key}`) ?? defaultValue ?? "";
}

function setLocalStorageVar(key: string, value: string) {
	return window.localStorage.setItem(`ENV.${key}`, value);
}

function getEnvVar(key: EnvVariable): string | undefined;

function getEnvVar(key: EnvVariable, defaultValue: string): string;

function getEnvVar(
	key: EnvVariable,
	filter?: (value: string) => boolean
): string;

function getEnvVar(
	key: EnvVariable,
	defaultValue: string,
	filter?: (value: string) => boolean
): string;

function getEnvVar(
	key: EnvVariable,
	defaultValueOrFilter?: string | ((value: string) => boolean),
	filter?: (value: string) => boolean
) {
	let defaultValue: string | undefined = undefined;
	if (typeof defaultValueOrFilter === "string") {
		defaultValue = defaultValueOrFilter;
	} else if (defaultValueOrFilter && !filter) {
		filter = defaultValueOrFilter;
	}

	if (process.env.NODE_ENV === "development") {
		const cookieValue = getCookieVar(key, defaultValue, filter);

		if (cookieValue) return cookieValue;
	}

	let value = window.env?.[key];
	if (value && value !== `%${key}%`) return value;

	value = process.env[key];
	if (value && value !== `%${key}%`) return value;

	value = getLocalStorageVar(key, defaultValue);
	if (value) return value;

	return defaultValue;
}

function getEnv({ tenantFilter, envFilter }: Partial<EnvProviderProps> = {}) {
	if (process.env.NODE_ENV === "development") {
		return {
			tenant: getEnvVar(EnvVariable.Tenant, "Demo", tenantFilter),
			locale: getEnvVar(EnvVariable.Locale, window.navigator.language),
			design: getEnvVar(EnvVariable.Design),
			environment: getEnvVar(EnvVariable.ProxyEnvironment, envFilter),
			tenantFilter: tenantFilter ?? (() => true),
			envFilter: envFilter ?? (() => true)
		};
	}

	return {
		tenant: getEnvVar(EnvVariable.Tenant, "Demo", tenantFilter),
		locale: getEnvVar(EnvVariable.Locale, window.navigator.language),
		design: getEnvVar(EnvVariable.Design),
		environment: undefined,
		tenantFilter: () => true,
		envFilter: () => true
	};
}

export type EnvContext = ReturnType<typeof getEnv>;

export interface EnvAction {
	type: EnvVariable;
	payload: string;
}

const envContext = createContext(getEnv());
envContext.displayName = "Env";

const dispatchContext = createContext<React.Dispatch<EnvAction>>(() => {});
dispatchContext.displayName = "EnvDispatch";

export interface EnvProviderProps {
	children: React.ReactChild;
	tenantFilter?: (tenant: string) => boolean;
	envFilter?: (env: string) => boolean;
}

function CookieProvider(props: EnvProviderProps) {
	const [context, dispatch] = useReducer(
		(state: EnvContext, action: EnvAction): EnvContext => {
			if (action.type) setCookieVar(action.type, action.payload);

			return getEnv(props);
		},
		getEnv(props)
	);

	const unMounting = useRef(false);
	useEffect(
		() => () => {
			unMounting.current = true;
		},
		[]
	);

	const proxyEnvironemnt =
		!context.environment || context.environment.includes("local")
			? context.environment
			: context.tenant + context.environment;

	useEffect(
		() => () => {
			if (!unMounting.current) {
				window.location.reload();
			}
		},
		[proxyEnvironemnt]
	);

	return (
		<dispatchContext.Provider value={dispatch}>
			<envContext.Provider value={context} {...props} />
		</dispatchContext.Provider>
	);
}

function LocalStorageProvider(props: EnvProviderProps) {
	const [context, dispatch] = useReducer(
		(state: EnvContext, action: EnvAction): EnvContext => {
			if (action.type) setLocalStorageVar(action.type, action.payload);

			return getEnv(props);
		},
		getEnv(props)
	);

	return (
		<dispatchContext.Provider value={dispatch}>
			<envContext.Provider value={context} {...props} />
		</dispatchContext.Provider>
	);
}

export const EnvProvider =
	process.env.NODE_ENV === "development"
		? CookieProvider
		: LocalStorageProvider;

export function useSettings() {
	return useContext(envContext);
}

export function useSettingsDispatch() {
	return useContext(dispatchContext);
}
