import React, { useState, useCallback, useMemo } from "react";
import Grid, { GridProps, GridSize } from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CachedIcon from "@material-ui/icons/Cached";

import { Field as FormikField } from "formik";

import CalculationField from "./CalculationField";
import { GridJustification, TemplateField } from "@ploy-lib/types";
import { makeStyles } from "@material-ui/core/styles";
import { getFieldName } from "@ploy-lib/core";
import clsx from "clsx";
import marked from "@ploy-lib/purify-marked";
import { DployFormControl } from "@ploy-ui/form-fields";
import {
	useWriteLockedFields,
	useDispatch,
	useGetDebugFieldProps,
	Patch
} from "@ploy-lib/calculation";
import { isNotNull } from "@ploy-ui/form-fields/es/utils";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { convertFieldWidthToGridSize } from "../utils";

const useStyles = makeStyles(
	theme => ({
		root: {},
		rowContainer: {},
		field: {},
		fieldSeparator: {},
		fieldWrapper: {
			position: "relative",
			alignSelf: "center"
		},
		styleTooltipContainer: {
			display: "flex",
			alignItems: "center"
		},
		styleTooltip: {
			alignItems: "center"
		},
		styleHelperText: {
			// Possible slightly brittle this one ^^'
			marginTop: -3,
			"& p, & ul, & ol": {
				fontSize: "0.85rem"
			},
			"& p": {
				marginTop: -4,
				marginBottom: 12,
				whiteSpace: "pre-line"
			}
		},
		collapsedSectionField: {
			color: theme.palette.text.disabled
		}
	}),
	{ name: "DployFormFieldContainer" }
);

export const fieldToKey = (f: TemplateField) =>
	`${f.namespace}.${f.name}.${f.renderAs}.${f.formTemplateFieldId}`;

interface FieldContainerProps {
	fields: TemplateField[];
	className?: string;
	literal?: boolean;
	justify?: GridJustification;
	disabled?: boolean;
	lowContrastText?: boolean;
	linkedHelperText?: boolean;
}

function useResetWriteLocked(fields: TemplateField[]) {
	const dispatch = useDispatch();
	const resetFields = useWriteLockedFields(
		...fields.filter(f => f.canResetWriteLocked)
	);

	const resetWriteLocked = useCallback(() => {
		const patches = resetFields
			.filter(isNotNull)
			.filter(calcField => calcField.writeLocked)
			.map((calcField): Patch<string, any> | null =>
				calcField.namespace && calcField.variableName
					? {
							changeTrigger: "CHECK_" + calcField.variableName,
							namespace: calcField.namespace,
							target: calcField.variableName,
							writeLocked: false
					  }
					: null
			)
			.filter(isNotNull);

		dispatch({
			type: "patch",
			payload: { patches }
		});
	}, [resetFields, dispatch]);

	const canReset =
		resetFields
			.filter(isNotNull)
			.filter(x => x.namespace && x.variableName)
			.filter(calcField => calcField.writeLocked).length > 0;

	return canReset ? resetWriteLocked : undefined;
}

const FieldContainer = (props: FieldContainerProps) => {
	const {
		className,
		literal,
		justify,
		fields,
		disabled,
		lowContrastText,
		linkedHelperText = false
	} = props;

	const [first] = fields;

	const variant =
		first.variant && ["outlined", "filled", "standard"].includes(first.variant)
			? (first.variant as "outlined")
			: "outlined";

	const [HelperTextContainer, showHelperTextInitially] = useMemo(() => {
		if (literal) return [null, false];

		const helperText = fields
			.filter(f => f.helperText)
			.filter(f => !f.literal)
			.filter(f => !f.disabled)
			.map(f => f.helperText)
			.join("\n");

		const trimmedHelperText =
			helperText[0] === "!" ? helperText.substring(1) : helperText;

		const helperTextContainer = !trimmedHelperText
			? null
			: () => (
					<FormHelperTextContainerWrapper
						helperText={trimmedHelperText}
						variant={variant}
					/>
			  );

		return [helperTextContainer, helperText[0] === "!"];
	}, [fields, literal, variant]);

	const [showHelperText, setShowHelperText] = useState(showHelperTextInitially);

	const toggleHelperText = useCallback(
		() => setShowHelperText(show => !show),
		[]
	);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const classes = useStyles(props);
	const getDebugFieldProps = useGetDebugFieldProps();

	const resetWriteLocked = useResetWriteLocked(
		literal ? [] : fields.filter(f => !f.literal)
	);

	const resetButton = resetWriteLocked ? (
		<IconButton onClick={resetWriteLocked}>
			<CachedIcon fontSize="small" />
		</IconButton>
	) : undefined;

	const showHelperTextIconButton = (
		<HelperTextIconButton
			showHelperText={showHelperText}
			toggleHelperText={toggleHelperText}
			firstField={first}
		/>
	);

	return (
		<>
			<Grid
				className={clsx(classes.root, className)}
				item
				xs={literal || fields[0].fullWidth ? 12 : 11}
			>
				<Grid
					className={classes.rowContainer}
					container
					spacing={1}
					justify={justify}
				>
					{fields.map((field, idx, arr) => {
						const {
							label: fieldLabel,
							name: fieldName,
							width,
							margin = "normal",
							uppercaseLabel
						} = field;
						let { hiddenLabel } = field;
						const name = getFieldName(field);

						const defaultGridSize = (12 /
							Math.min(4, fields.length)) as GridProps["sm"];

						const lastField = idx === arr.length - 1;

						return (
							<Grid
								key={fieldToKey(field)}
								item
								xs={
									isMobile
										? 12
										: width
										? convertFieldWidthToGridSize(width)
										: defaultGridSize
								}
								data-cr-field={field.name}
								data-cr-namespace={field.namespace}
								data-cr-component={field.renderAs}
								{...getDebugFieldProps(field, {
									className: clsx(classes.fieldWrapper, classes.field, {
										[classes.fieldSeparator]: !lastField
									})
								})}
							>
								<FormikField
									{...field}
									name={name}
									component={CalculationField}
									fieldName={fieldName}
									margin={margin}
									className={clsx(classes.field, {
										[classes.collapsedSectionField]: lowContrastText
									})}
									label={
										hiddenLabel
											? " "
											: uppercaseLabel && fieldLabel
											? fieldLabel.toLocaleUpperCase()
											: fieldLabel
									}
									literal={literal || field.literal}
									justify={justify || field.justify}
									helperText={undefined}
									buttonText={fieldLabel}
									disabled={disabled || field.disabled}
								/>
								{resetButton && lastField && (
									<Box position="absolute" top={0} p={0.5} right={-32}>
										{resetButton}
									</Box>
								)}
							</Grid>
						);
					})}
					{linkedHelperText &&
						HelperTextContainer &&
						!literal &&
						showHelperTextIconButton}
				</Grid>
			</Grid>
			{!linkedHelperText &&
				HelperTextContainer &&
				!literal &&
				showHelperTextIconButton}
			{showHelperText && HelperTextContainer && (
				<Grid item xs={12}>
					<HelperTextContainer />
				</Grid>
			)}
		</>
	);
};

FieldContainer.displayName = "DployFormFieldContainer";

export default FieldContainer;
export { FieldContainer };

interface HelperTextIconButtonProps {
	firstField?: TemplateField;
	toggleHelperText: () => void;
	showHelperText: boolean;
}

export const HelperTextIconButton = (props: HelperTextIconButtonProps) => {
	const { firstField, toggleHelperText, showHelperText } = props;

	const classes = useStyles();

	return (
		<Grid item xs={1} className={classes.styleTooltipContainer}>
			<DployFormControl
				variant={firstField?.variant as any}
				className={classes.styleTooltip}
			>
				{firstField?.label && <FormLabel> </FormLabel>}
				<IconButton onClick={toggleHelperText}>
					<Icon>{showHelperText ? <HelpIcon /> : <HelpOutlineIcon />}</Icon>
				</IconButton>
			</DployFormControl>
		</Grid>
	);
};

interface HelperTextContainerProps {
	helperText: string;
	variant?: "outlined" | "filled" | "standard";
}

export const FormHelperTextContainerWrapper = (
	props: HelperTextContainerProps
) => {
	const { helperText, variant } = props;

	const classes = useStyles();

	return (
		<FormHelperText variant={variant} className={classes.styleHelperText}>
			<div
				dangerouslySetInnerHTML={{
					__html: marked(helperText)
				}}
			/>
		</FormHelperText>
	);
};
