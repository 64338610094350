import {
	PageStateProviderProps,
	PageProvider,
	PageContextValue
} from "@ploy-ui/template-form";
import React, { useCallback, useMemo, useEffect, memo } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { RouterLink } from "@ploy-ui/core";
import { interpolateParams, getBasepath } from "../utils";

const ensureRange = (num: number, min: number, max: number) =>
	Math.min(Math.max(min, num), max - 1);

const pagePath = ":page/*";

function RoutePageStateProvider({
	pageOptions: { labels, initialStep = 0, children },
	...routeProps
}: { pageOptions: PageStateProviderProps } & RouteComponentProps<{
	page: string;
	"*": string;
}>) {
	const {
		page = (initialStep + 1).toString(),
		uri = "",
		path = pagePath,
		navigate,
		location,
		"*": wildcard
	} = routeProps;

	const lowerCasePage = page && page.toLocaleLowerCase();
	const lowerCaseLabels = labels.map(l => l && l.toLocaleLowerCase());

	const numberOfSteps = lowerCaseLabels.length;

	const search = location ? location.search : "";

	const basepath = getBasepath({
		path,
		uri,
		page: routeProps.page,
		"*": wildcard
	});

	const getStepHref = useCallback(
		idx => {
			idx = ensureRange(idx, 0, numberOfSteps);
			const target = lowerCaseLabels[idx] || (idx + 1).toString();
			const newpath = interpolateParams({
				path,
				page: target
			});
			return `${basepath}/${newpath}${search}`;
		},
		[basepath, lowerCaseLabels, numberOfSteps, path, search]
	);

	const setStepIndex = useCallback(
		(idx: number, replace?: boolean) => {
			const href = getStepHref(idx);
			if (decodeURI(uri) !== href && navigate) navigate(href, { replace });
		},
		[getStepHref, uri, navigate]
	);

	const stepStringIndex = Number.parseInt(lowerCasePage) - 1;

	const stepIndex = Number.isNaN(stepStringIndex)
		? lowerCaseLabels.indexOf(lowerCasePage)
		: stepStringIndex;

	const step = ensureRange(stepIndex, 0, numberOfSteps);

	useEffect(() => {
		if (numberOfSteps > 1 && (lowerCasePage == null || stepIndex !== step)) {
			setStepIndex(step, true);
		}
	}, [
		lowerCasePage,
		setStepIndex,
		stepStringIndex,
		numberOfSteps,
		stepIndex,
		step
	]);

	const next = useCallback<PageContextValue["next"]>(
		() => setStepIndex(step + 1),
		[setStepIndex, step]
	);
	const prev = useCallback<PageContextValue["prev"]>(
		() => setStepIndex(step - 1),
		[setStepIndex, step]
	);
	const goto = useCallback<PageContextValue["goto"]>(
		(idx: number, replace?: boolean) => setStepIndex(idx, replace),
		[setStepIndex]
	);
	const getGotoLink = useCallback<PageContextValue["getGotoLink"]>(
		(idx, props) => <RouterLink {...props} to={getStepHref(idx)} />,
		[getStepHref]
	);
	const reset = useCallback<PageContextValue["reset"]>(() => setStepIndex(0), [
		setStepIndex
	]);

	const state = useMemo<PageContextValue>(
		() => ({
			labels,
			next,
			prev,
			goto,
			reset,
			step,
			getStepHref,
			getGotoLink,
			lastStep: numberOfSteps - 1,
			isLastStep: step === numberOfSteps - 1
		}),

		[
			labels,
			next,
			prev,
			goto,
			reset,
			step,
			getStepHref,
			getGotoLink,
			numberOfSteps
		]
	);

	return <PageProvider value={state}>{children}</PageProvider>;
}

export const RoutePageProvider = memo((props: PageStateProviderProps) => (
	<Router>
		<RoutePageStateProvider key="step" path={pagePath} pageOptions={props} />
		<RoutePageStateProvider key="step" default pageOptions={props} />
	</Router>
));
