import { ID } from "../types";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BasePlainResource";

export interface UploadedDocument {
	readonly documentId?: ID;
	readonly filename: string;
	readonly documentType: string;
	readonly documentTypeDisplayname: string;
	readonly mustSign: boolean;
	readonly archived: boolean;
	readonly documentURL: string;
}

export class UploadedDocumentResource extends BaseCamelCasedResource
	implements UploadedDocument {
	readonly documentId?: ID;
	readonly filename: string = "";
	readonly documentType: string = "";
	readonly documentTypeDisplayname: string = "";
	readonly mustSign: boolean = false;
	readonly archived: boolean = false;
	readonly documentURL: string = "";

	pk() {
		return this.documentId?.toString();
	}

	static urlRoot = legacyApiResourceUrl("DocumentGrid/GetUploadedDocumentList");
}
