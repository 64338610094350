import { CondNull } from "@ploy-lib/rest-resources";
import {
	Denormalize,
	DenormalizeNullable,
	ReadShape,
	useResource
} from "@rest-hooks/core";
import { GridComponent } from "./types";
import { ResourceArgs } from "./types";

declare module "@rest-hooks/core" {
	export function useResource<
		A extends readonly [...ResourceArgs<ReadShape<any, any>, any>[]]
	>(
		...vs: A
	): {
		[K in keyof A]: A[K] extends ResourceArgs<ReadShape<infer S, any>, infer P>
			? CondNull<P, DenormalizeNullable<S>, Denormalize<S>>
			: A[K];
	};
}

export function usePrefetchResources<
	P,
	A extends readonly ResourceArgs<ReadShape<any, any>, any>[],
	V extends readonly ResourceArgs<ReadShape<any, any>, any>[],
	PH extends boolean
>(
	component: GridComponent<P, A, V>,
	props: P,
	placeholder?: PH
): {
	[K in keyof A]: A[K] extends ResourceArgs<ReadShape<infer S, any>, infer P>
		? CondNull<
				PH extends true ? null : P,
				DenormalizeNullable<S>,
				Denormalize<S>
		  >
		: A[K];
};

export function usePrefetchResources<
	P,
	A extends readonly ResourceArgs<ReadShape<any, any>, any>[],
	V extends readonly ResourceArgs<ReadShape<any, any>, any>[]
>(component: GridComponent<P, A, V>, props: P, placeholder?: boolean) {
	const prefetchResources =
		component.getPrefetch?.(props) ?? (([] as unknown) as [...A]);

	const resources = placeholder
		? (prefetchResources.map(r => [r[0], null] as const) as [...A])
		: prefetchResources;

	return useResource(...resources);
}
