import React from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
	AppBar,
	Toolbar,
	Typography,
	Box,
	useMediaQuery
} from "@material-ui/core";

import AppBarBanner from "./AppBarBanner";
import { QuickSearch } from "../Drawer/QuickSearch";
import { ColoredDiv } from "../Drawer/Drawer";
import { EditAction } from "../Drawer/EditAction";

function MobileAppBar(props) {
	const { children, hideBanner, bgcolor, ...rest } = props;

	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up("sm"));
	const classes = useStyles({ bgcolor });

	return (
		<AppBar {...rest} position="absolute" classes={{ root: classes.root }}>
			<ColoredDiv bgcolor={bgcolor} autoContrastText={true}>
				<Toolbar disableGutters>
					<Box width={64}>
						<EditAction />
					</Box>

					<Typography
						variant="h6"
						color="inherit"
						noWrap
						className={classes.title}
					>
						<AppBarBanner
							className={classes.banner}
							position={smUp ? "left" : "center"}
						/>
					</Typography>

					{children}

					<QuickSearch
						classes={{
							root: classes.quickSearch,
							focused: classes.quickSearchFocused
						}}
					/>
				</Toolbar>
			</ColoredDiv>
		</AppBar>
	);
}

const useStyles = makeStyles(theme => ({
	root: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	title: {
		flex: 1,
		display: "flex",
		alignItems: "stretch",
		...theme.mixins.toolbar
	},
	menuButton: {
		margin: "auto"
	},
	banner: {
		...theme.mixins.toolbar
	},
	quickSearch: {
		overflow: "hidden",
		"&:not($quickSearchFocused)": {
			maxWidth: 48
		}
	},
	quickSearchFocused: {}
}));

MobileAppBar.displayName = "MobileAppBar";

export default MobileAppBar;
