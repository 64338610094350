import React from "react"
import {
	ModalContainer,
	ModalContainerProps
} from "./ModalContainer";
import { PaymentPlanSection } from "../section/custom";

const PaymentPlanModal = (props: ModalContainerProps) => (
	<ModalContainer {...props}>
		<PaymentPlanSection
			formTemplateSectionId={""}
			fields={[]}
		/>
	</ModalContainer>
);

const modals = {
	PaymentPlanModal
};

export { modals }
