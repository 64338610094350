﻿/* eslint-disable */

export const bootstrapZendesk = (key: string) => {
	window["$zopim"] ||
		(function(d, s) {
			var z = (window["$zopim"] = function(c) {
					z["_"].push(c);
				}),
				$ = (z["s"] = d.createElement(s)),
				e = d.getElementsByTagName(s)[0];
			//e.className = className;
			z["set"] = function(o) {
				z["set"]._.push(o);
			};
			z["_"] = [];
			z["set"]._ = [];
			$["async"] = !0;
			$.setAttribute("charset", "utf-8");
			$["src"] = "https://v2.zopim.com/?" + key;
			z["t"] = +new Date();
			$["type"] = "text/javascript";
			e.parentNode && e.parentNode.insertBefore($, e);
		})(document, "script");
};

export const setupZendeskIndentity = (
	username: string,
	email: string,
	tags: string[]
) => {
	window["$zopim"](function() {
		window["$zopim"].livechat.setName(username);
		window["$zopim"].livechat.setEmail(email);
		tags.forEach(tag => {
			window["$zopim"].livechat.addTags(tag);
		});
	});
};

export const conditionallyClearZendesk = () => {
	if (window["$zopim"]) {
		window["$zopim"](function() {
			window["$zopim"].livechat.endChat();
			window["$zopim"].livechat.clearAll();
			const zopim = document.querySelectorAll(".zopim");

			for (let node of zopim) {
				node.parentNode && node.parentNode.removeChild(node);
			}

			window["$zopim"] = undefined;
		});
	}
};
