import React, { useState, useCallback } from "react";
import { FormikHelpers } from "formik";

import { Grid, Typography, Box } from "@material-ui/core";
import { DataTable } from "@ploy-ui/core";
import { API, RegistrationObject } from "@ploy-lib/km-reading-api";
import { calculate } from "./utils";
import { SearchForm, SearchFormValues } from "./SearchForm";
import {
	RegisterFormValues,
	ExpandableRegistrationObject
} from "./ExpandableRegistrationObject";
import Check from "@material-ui/icons/Check";
import {
	FormattedDate,
	FormattedNumber,
	FormattedMessage,
	useIntl
} from "react-intl";

import { messages } from "./messages";

export interface KmReadingOptions {
	allowedMonths: number;
	allowedDeviation: number;
	percentInterval: number;
	doCalculation: boolean;
	canEditUntilApproved: boolean;
	hideDateInput: boolean;
	hideCommentSection: boolean;
	hideDeliveryDate: boolean;
	messages: {
		lessThanPlanned: string;
		lessThanAllowed: string;
		lessThanInterval: string;
		large: string;
		kmReadingNotAvailable: string;
	};
}

export interface KmReadingProps extends API {
	className?: string;
	options: KmReadingOptions;
	isInternal: boolean;
}

export type SubmitHandler<T> = (values: T, actions: FormikHelpers<T>) => void;

export const KmReading = ({
	search,
	register,
	getCalculationDetails,
	vendorSearch,
	sendMessage,
	className,
	options,
	isInternal
}: KmReadingProps) => {
	const [rows, setRows] = useState<RegistrationObject[]>([]);

	const intl = useIntl();

	const handleSearch: SubmitHandler<SearchFormValues> = useCallback(
		async ({ carRegistrationNo, fromDate, toDate, vendor }, actions) => {
			try {
				const [orgNo = "", backOfficeId = ""] = vendor ? vendor.split("|") : [];
				const { list, message, success } = await search({
					carRegistrationNo,
					fromDate,
					toDate,
					vendor: orgNo,
					backofficeId: backOfficeId
				});
				const rows = list.sort(
					(x, y) => Number(x.contractEndDate) - Number(y.contractEndDate)
				);
				setRows(rows);
				actions.setStatus({ success, message });
			} catch (err: any) {
				actions.setStatus({
					error: intl.formatMessage(messages.genericError)
				});
			}
			actions.setSubmitting(false);
		},
		[intl, search]
	);

	const handleGetCalculationDetails = useCallback(
		async (carRegistrationNo: string, vendor: string) => {
			try {
				const result = await getCalculationDetails(carRegistrationNo, vendor);
				return result;
			} catch (err: any) {
				console.log(
					`Failed getting data for ${carRegistrationNo} and vendor ${vendor}`,
					err
				);
			}
		},
		[getCalculationDetails]
	);

	const handleRegister: SubmitHandler<RegisterFormValues> = useCallback(
		async (
			{
				reading,
				readingDate,
				contractNo,
				regNo,
				limit,
				price,
				customerName,
				mvaPercentage,
				deliveryDate,
				allowedKm,
				distanceKm,
				emailComment,
				objectType
			},
			actions
		) => {
			try {
				let amount = reading - allowedKm;

				const calculation = calculate({
					price,
					amount,
					mvaPercentage,
					allowedDeviation: options.allowedDeviation
				});

				const invoiceAmount = calculation.net;

				if (reading > limit && options.doCalculation) {
					// Handle frontend input error
					const params = {
						EventCode: "KmRead",
						Source: "kmReading",
						DtInput: {
							ReadKilometer: reading,
							ReadDate: readingDate,
							NetValue: calculation.net,
							VatValue: calculation.vat,
							GrossValue: calculation.gross,
							ContractNumber: contractNo,
							RegistrationNumber: regNo
						}
					};
					const messageSent = await sendMessage(params);
					if (messageSent && messageSent.success) {
						actions.setSubmitting(false);
					} else {
						actions.setStatus({
							error: intl.formatMessage(messages.sendMessageError)
						});
					}
					return;
				}

				const { kmReading, message, success, isApproved } = await register({
					contractNo,
					reading,
					readingDate,
					priceExtraKm: price,
					customerName: customerName,
					registrationNo: regNo,
					invoiceAmount,
					objectType,
					deliveryDate,
					allowedKm,
					distanceKm,
					emailComment
				});
				if (!success) {
					actions.setStatus({
						error: intl.formatMessage(messages.registerError)
					});
					return;
				}

				setRows(list =>
					list.map(x =>
						x.contractNo === contractNo
							? { ...x, registeredKilometer: kmReading, isApproved:isApproved||true  }
							: x
					)
				);
				actions.setStatus({ success, message });
			} catch (err: any) {
				actions.setStatus({
					error: intl.formatMessage(messages.genericError)
				});
			}
			actions.setSubmitting(false);
		},
		[
			options.allowedDeviation,
			options.doCalculation,
			register,
			sendMessage,
			intl
		]
	);

	return (
		<Grid className={className} container spacing={3}>
			<Grid xs={12} item>
				<Grid container spacing={3} justify="space-between">
					<Grid xs={12} md={8} item>
						<Typography variant="h3">
							<FormattedMessage
								id="km_reading.header"
								defaultMessage="Kilometeravlesing"
								values={{
									numContracts: rows.length
								}}
							/>
						</Typography>
					</Grid>
					<Grid xs={12} item>
						<SearchForm
							onSearch={handleSearch}
							isInternal={isInternal}
							vendorSearch={vendorSearch}
							hideDateInput={options.hideDateInput}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid xs={12} item>
				{rows.length > 0 && (
					<>
						<Grid container>
							<Grid item xs={12}>
								<Typography variant="h6" align="right">
									<FormattedMessage
										id="km_reading.contracts.message"
										defaultMessage="Antall kontrakter: {numContracts}"
										values={{
											numContracts: rows.length
										}}
									/>
								</Typography>
							</Grid>
							{options.doCalculation ? (
								<Grid item xs={12}>
									<Typography variant="h6" align="right">
										<FormattedMessage
											id="km_reading.total_residual.message"
											defaultMessage="Sum restverdibeløp: {total}"
											values={{
												total: rows
													.map(x => x.valueAmount)
													.reduce((a, b) => a + b, 0)
											}}
										/>
									</Typography>
								</Grid>
							) : null}
						</Grid>
						<DataTable<RegistrationObject>
							options={{
								search: false,
								showTitle: false,
								pageSize: 10,
								paging: rows.length > 10,
								pageSizeOptions: [10, 15, 20],
								emptyRowsWhenPaging: false,
								toolbar: false
							}}
							style={{ minHeight: 0 }}
							onRowClick={(event, rowData, togglePanel) =>
								togglePanel && togglePanel()
							}
							columns={[
								{
									title: "Reg.nummer",
									field: "registrationNo",
									headerStyle: { paddingRight: 0 }
								},
								{
									title: "Avtalenummer",
									field: "contractNo",
									sorting: false,
									headerStyle: { paddingRight: 0 }
								},
								{
									title: "Kunde",
									field: "customerName",
									headerStyle: { paddingRight: 0 }
								},
								{
									title: "Modell/Type",
									field: "description",
									headerStyle: { paddingRight: 0 }
								},
								{
									title: "Restverdi",
									field: "valueAmount",
									render: row => (
										<FormattedNumber
											value={row.valueAmount}
											format="currency"
										/>
									),
									headerStyle: { paddingRight: 0 }
								},
								{
									title: "Utløpsdato",
									field: "contractEndDate",
									render: row => <FormattedDate value={row.contractEndDate} />,
									headerStyle: { paddingRight: 0 }
								},
								{
									title: "Avlest",
									field: "registeredKilometer",
									render: rowData =>
										!options.canEditUntilApproved ? (
											rowData.registeredKilometer ? (
												<Check />
											) : null
										) : rowData.isApproved ? (
											<Check />
										) : null,
									sorting: false,
									headerStyle: { paddingRight: 0 }
								}
							]}
							data={rows}
							detailPanel={rowData => (
								<Box p={2}>
									<ExpandableRegistrationObject
										key={rowData.contractNo}
										handleRegister={handleRegister}
										onExpand={handleGetCalculationDetails}
										options={options}
										expanded={rows.length === 1 || undefined}
										{...rowData}
									/>
								</Box>
							)}
						/>
					</>
				)}
			</Grid>
		</Grid>
	);
};
