import { useVariableData } from "@ploy-lib/calculation";
import {
	BeneficialOwner,
	BeneficialOwnerResource,
	CustomerInfoSearchResource
} from "@ploy-lib/rest-resources";
import { createValidationHelpers } from "@ploy-lib/validation-helpers";
import { FieldInputProps, FormikProps } from "formik";
import React, { useEffect } from "react";
import { memo, useCallback } from "react";
import { useIntl } from "react-intl";
import { useFetcher, useResource } from "@rest-hooks/core";
import { UpdateBeneficialOwnerFieldView } from ".";
import { useAppLoad } from "../../../appLoadContext";
import { useTemplateFieldIsVisible } from "../../../hooks";
import { InputFieldProps } from "../../../types";
import { createBeneficialOwnerConfig } from "./BeneficialOwnerFieldConfig";

const mapCustomerResourceToBeneficialOwner = (
	customer: Readonly<CustomerInfoSearchResource>
) => {
	const beneficialOwner = {
		firstname: customer?.cust?.firstname,
		lastname: customer?.cust?.lastname,
		name: customer?.cust?.customerName,
		address: customer?.addr?.address1,
		addressCity: customer?.addr?.addressCity,
		addressZip: customer?.addr?.addressZip
	};
	return beneficialOwner as Partial<BeneficialOwner>;
};

/**
 * Wrapper for container and view
 */
const UpdateBeneficialOwnerField = memo((props: InputFieldProps) => {
	const { form, field } = props;
	return <UpdateBeneficialOwnerFieldContainer form={form} field={field} />;
});

interface UpdateBeneficialOwnerFieldContainerProps {
	field: FieldInputProps<any>;
	form: FormikProps<any>;
}
/**
 * Container for UpdateBemeficialOwnerField. Contains logic for fetching and altering of resource
 */
const UpdateBeneficialOwnerFieldContainer = memo(
	(props: UpdateBeneficialOwnerFieldContainerProps) => {
		const {
			form: { setFieldValue },
			field
		} = props;
		const intl = useIntl();

		const setNumberOfBeneficialOwners = useCallback(
			(number: number) => {
				setFieldValue(props.field.name, number);
			},
			[setFieldValue, props.field.name]
		);

		const updateNumberOfBeneficialowners = useCallback(
			(int: number) => {
				setNumberOfBeneficialOwners(props.field.value + int);
			},
			[setNumberOfBeneficialOwners, props.field.value]
		);

		const validation = createValidationHelpers(intl.locale);
		const namespace = "MONEY_LAUNDERING";

		const { value: useAlternativeEntNrValidation } = useVariableData<boolean>(
			namespace,
			"UseAlternativeEntNrValidation"
		);

		const { value: shortDateFormat } = useVariableData<string>(
			namespace,
			"ShortDateFormat"
		);

		const beneficialOwnerConfig = createBeneficialOwnerConfig(
			intl,
			validation,
			useAlternativeEntNrValidation ?? false,
			shortDateFormat ?? ""
		);

		const { applicationNumber } = useAppLoad();

		const { value: epId } = useVariableData(namespace, "EpGuid");

		var isFieldVisible = useTemplateFieldIsVisible();

		var includeZip = isFieldVisible({
			name: "IncludeZip",
			namespace: namespace
		});
		var fnameAndLnameForWCService = isFieldVisible({
			name: "FnameAndLnameForWCService",
			namespace: namespace
		});

		const beneficialOwners = useResource(BeneficialOwnerResource.list(), {
			epId,
			includeFirstAndLastname: fnameAndLnameForWCService,
			includeZip,
			applicationNumber
		});

		useEffect(() => {
			setNumberOfBeneficialOwners(beneficialOwners.length);
		}, [beneficialOwners, setNumberOfBeneficialOwners]);

		const updateBeneficialOwner = useFetcher(BeneficialOwnerResource.update());
		const onUpdateBeneficialOwner = (beneficialOwner: BeneficialOwner) => {
			return updateBeneficialOwner(
				{ id: beneficialOwner.id, epId: epId },
				beneficialOwner
			);
		};

		const deleteBeneficialOwner = useFetcher(BeneficialOwnerResource.delete());
		const onDeleteBeneficialOwner = useCallback(
			(beneficialOwner: BeneficialOwner) => {
				updateNumberOfBeneficialowners(-1);
				return deleteBeneficialOwner(
					{ id: beneficialOwner.id, epId: epId },
					undefined
				);
			},
			[deleteBeneficialOwner, epId, updateNumberOfBeneficialowners]
		);

		const createBeneficialOwner = useFetcher(BeneficialOwnerResource.create());
		const onCreateBeneficialOwner = useCallback(
			(beneficialOwner: BeneficialOwner) => {
				const { id, addDate, addUser, ...rest } = beneficialOwner;
				updateNumberOfBeneficialowners(1);
				return createBeneficialOwner({ epId: epId }, rest, [
					[
						BeneficialOwnerResource.list(),
						{
							epId,
							includeFirstAndLastname: fnameAndLnameForWCService,
							includeZip,
							applicationNumber
						},
						(
							newBeneficialOwnerId: string,
							beneficialOwnerIds: string[] | undefined
						) => [...(beneficialOwnerIds || []), newBeneficialOwnerId]
					]
				]);
			},
			[
				createBeneficialOwner,
				epId,
				fnameAndLnameForWCService,
				includeZip,
				applicationNumber,
				updateNumberOfBeneficialowners
			]
		);

		const searchCustomer = useFetcher(CustomerInfoSearchResource.detail());

		const getPersonalInfoFromSsn = useCallback(
			async (beneficialOwner: BeneficialOwner) => {
				const customer = await searchCustomer({
					custNo: beneficialOwner.entNr
				});
				const beneficialOwnerNewInfo = mapCustomerResourceToBeneficialOwner(
					customer
				);
				const newBeneficialOwner = {
					...beneficialOwner,
					...beneficialOwnerNewInfo
				};
				return newBeneficialOwner as BeneficialOwner;
			},
			[searchCustomer]
		);

		return (
			<UpdateBeneficialOwnerFieldView
				fieldName={field.name}
				beneficialOwners={beneficialOwners}
				beneficialOwnerConfig={beneficialOwnerConfig}
				onCreateBeneficialOwner={onCreateBeneficialOwner}
				onUpdateBeneficialOwner={onUpdateBeneficialOwner}
				onDeleteBeneficialOwner={onDeleteBeneficialOwner}
				getPersonalInfoFromSsn={getPersonalInfoFromSsn}
			/>
		);
	}
);

UpdateBeneficialOwnerField.displayName = "UpdateBeneficialOwnerField";
UpdateBeneficialOwnerFieldContainer.displayName =
	"UpdateBeneficialOwnerFieldContainer";

export { UpdateBeneficialOwnerField };
