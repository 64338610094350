import { useCallback } from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { useFieldIsVisible } from "@ploy-lib/calculation";
import { TemplateField } from "@ploy-lib/types";
import { useHiddenFields } from "./usehiddenFields";

const excludedRoles = ["visibilityFilter", "visibilityFilterInverse"];

export function useTemplateFieldIsVisible({
	ignoreRoles
}: {
	ignoreRoles?: boolean;
} = {}) {
	const theme = useTheme();
	const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
	const hiddenFields = useHiddenFields();

	const isCalcFieldVisible = useFieldIsVisible();

	return useCallback(
		<
			T extends Pick<
				TemplateField,
				"name" | "namespace" | "hideForMobile" | "alwaysVisible" | "role"
			>
		>(
			f: T
		) => {
			if (hiddenFields.includes(f.name)) return false;
			if (f.hideForMobile && isSmDown) return false;
			if (f.alwaysVisible) return true;

			if (!ignoreRoles && f.role && excludedRoles.includes(f.role))
				return false;

			return isCalcFieldVisible(f);
		},
		[hiddenFields, isCalcFieldVisible, isSmDown, ignoreRoles]
	);
}
