import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BaseResource";
import { ID } from "../types";

export interface FormalitiesCheck {
	readonly checkItem: boolean;
	readonly formalitiesCheckId: ID;
	readonly formalitiesCheckName: string;
	readonly originalCheckedDate: string;
}

export interface ApplicationFormalities {
	readonly key: string;
	readonly applicationNumber: string;
	readonly checkpoints: FormalitiesCheck[];
}

export class ApplicationFormalitiesResource extends BaseCamelCasedResource
	implements ApplicationFormalities {
	readonly key: string;
	readonly applicationNumber: string;
	readonly checkpoints: FormalitiesCheck[];

	pk() {
		return `${this.applicationNumber}::${this.key}`;
	}

	static urlRoot = legacyApiResourceUrl(
		"FormalitiesCheck/GetFormalitiesCheckpoints"
	);
}
