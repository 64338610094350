import React from "react";
import { DployTextFieldProps, DployTextField } from "@ploy-ui/form-fields";
import { useField, FieldInputProps } from "formik";
import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete";
import { Value } from "@material-ui/lab/useAutocomplete";

export type FormikAutocompleteFieldProps<
	T,
	Multiple extends boolean | undefined,
	DisableClearable extends boolean | undefined,
	FreeSolo extends boolean | undefined
> = Omit<
	AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
	keyof Omit<FieldInputProps<any>, "multiple"> | "renderInput"
> & {
	name: string;
} & Pick<
		DployTextFieldProps,
		"label" | "helperText" | "variant" | "margin" | "fullWidth"
	>;

export function FormikAutocompleteField<
	T,
	Multiple extends boolean | undefined = undefined,
	DisableClearable extends boolean | undefined = undefined,
	FreeSolo extends boolean | undefined = undefined
>(
	props: FormikAutocompleteFieldProps<T, Multiple, DisableClearable, FreeSolo>
) {
	const { multiple, name, helperText, label, variant, margin, ...rest } = props;

	const [field, meta, helpers] = useField<
		Value<T, Multiple, DisableClearable, FreeSolo>
	>(name);

	const error = meta.error;

	const showError = Boolean(error) && meta.touched;

	return (
		<Autocomplete<T, Multiple, DisableClearable, FreeSolo>
			{...rest}
			{...(field as any)}
			value={field.value ?? (multiple ? [] : null)}
			onChange={(_, val) => helpers.setValue(val)}
			multiple={multiple}
			renderInput={params => (
				<DployTextField
					name={name}
					label={label}
					error={showError}
					helperText={showError ? error : helperText}
					variant={variant}
					margin={margin}
					{...params}
				/>
			)}
		/>
	);
}
