import React, { forwardRef } from "react";
import {
	Match as ReachRouterMatch,
	Link as ReachRouterLink,
	LinkProps as ReachRouterLinkProps
} from "@reach/router";
import MuiLink, { LinkProps as MuiLinkProps } from "@material-ui/core/Link";
import MuiButton, {
	ButtonProps as MuiButtonProps
} from "@material-ui/core/Button";
import MuiMenuItem, {
	MenuItemProps as MuiMenuItemProps
} from "@material-ui/core/MenuItem";
import MuiListItem, {
	ListItemProps as MuiListItemProps
} from "@material-ui/core/ListItem";
import { Omit } from "./types";

export type RouterLinkProps<TState = {}> = Omit<
	MuiLinkProps & ReachRouterLinkProps<TState>,
	"ref"
>;

export const RouterLink = forwardRef(
	<TState extends {}>(props: RouterLinkProps<TState>, ref) => (
		<MuiLink ref={ref} component={ReachRouterLink} {...props} />
	)
);

export type RouterMenuItemProps<TState = {}> = Omit<
	MuiMenuItemProps & ReachRouterLinkProps<TState>,
	"ref" | "button"
>;

export const RouterMenuItem = forwardRef(
	<TState extends {}>(props: RouterMenuItemProps<TState>, ref) => (
		<ReachRouterMatch path={`${props.to}/*`}>
			{({ match }) => (
				<MuiMenuItem
					selected={Boolean(match)}
					ref={ref}
					component={ReachRouterLink}
					{...props}
				/>
			)}
		</ReachRouterMatch>
	)
);

export type RouterListItemProps<TState = {}> = Omit<
	MuiListItemProps & ReachRouterLinkProps<TState>,
	"ref" | "button"
>;

export const RouterListItem = forwardRef(
	<TState extends {}>(props: RouterListItemProps<TState>, ref) => (
		<MuiListItem ref={ref} component={ReachRouterLink} {...props} />
	)
);

export type RouterButtonProps<TState = {}> = Omit<
	MuiButtonProps & ReachRouterLinkProps<TState>,
	"ref"
>;

export const RouterButton = forwardRef(
	<TState extends {}>(props: RouterButtonProps<TState>, ref) => (
		<MuiButton ref={ref} component={ReachRouterLink} {...props} />
	)
);
