import { BaseResource } from "./BaseResource";
import { ID } from "../types";
import { legacyApiResourceUrl } from "@ploy-lib/core";

export interface ProofOfDeliveryInformation {
	readonly phoneNumber: string;
	readonly deliveryDate: string | Date | null;
	readonly applicationId?: ID;
	readonly deliverySent: boolean | null;
	readonly receiver: number | null;
	readonly wrongCausalOrder: boolean;
}

export class ProofOfDeliveryInformationResource extends BaseResource
	implements ProofOfDeliveryInformation {
	readonly phoneNumber: string;
	readonly deliveryDate: string | Date | null;
	readonly applicationId?: ID;
	readonly deliverySent: boolean | null;
	readonly receiver: number | null;
	readonly wrongCausalOrder: boolean;

	pk() {
		return this.applicationId?.toString();
	}

	static urlRoot = legacyApiResourceUrl("ProofOfDelivery/Info");
}
