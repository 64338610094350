import React from "react";

import {
	Grid,
	Divider,
	Typography,
	Button,
	MuiThemeProvider,
	Hidden,
	Paper
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

import { TemplatePageProps, LayoutPage } from "../../../types";
import { addRegisteredLayout, PanelSettings } from "../layoutDescriptions";
import { Panel } from "../../../panel";
import { DirwebStepper } from "./DirwebStepper";
import { useStyles, actionsTheme, mainTheme } from "./styles";
import { TemplatePanel } from "@ploy-lib/types";
import { usePageState } from "../../../PageContext";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";

export type DirwebLongPanels = {
	main: TemplatePanel;
	actions: TemplatePanel;
};
export type DirwebLongPanelKeys = keyof DirwebLongPanels;
export type DirwebLongPage = LayoutPage<DirwebLongPanelKeys, DirwebLongPanels>;

const mainPanelSettings: PanelSettings = {
	editableOptions: {
		title: false,
		elevation: true,
		square: true
	}
};
const panelSettings: Record<DirwebLongPanelKeys, PanelSettings> = {
	main: mainPanelSettings,
	actions: {}
};

addRegisteredLayout({
	name: "DirwebLong",
	displayName: "DirwebLong",
	panels: panelSettings,
	pageDescription: true,
	displayInStepper: true,
	displayInXofY: true
});

export interface DirwebLongProps extends TemplatePageProps<DirwebLongPage> {}

export const DirwebLong = (props: DirwebLongProps) => {
	const { description, panels } = props;
	const { main, actions } = panels;
	const { step, prev } = usePageState();

	const dirwebLongClasses = useStyles();

	var myActions: TemplatePanel = {
		...actions,
		sections: [
			{
				formTemplateSectionId: "__myaction",
				fields:
					actions?.sections?.[0]?.fields?.filter(
						f => f.role !== "navigate_before"
					) ?? []
			}
		]
	};

	return (
		<>
			<Grid container className={clsx(dirwebLongClasses.root)} justify="center">
				<Grid item xs={12} md={8}>
					<DirwebStepper step={step} />
				</Grid>

				<Grid item xs={12} md={8} className={dirwebLongClasses.header}>
					<Typography
						variant="h3"
						gutterBottom
						className={dirwebLongClasses.titleText}
					>
						{props.pageTitle}
					</Typography>
					<Typography color="textSecondary" variant="body1">
						{description}
					</Typography>
				</Grid>

				{main && (
					<Grid
						item
						xs={12}
						md={8}
						className={clsx(dirwebLongClasses.mainGrid)}
					>
						<Grid item xs={12} md>
							<MuiThemeProvider theme={mainTheme}>
								<Panel {...main} transparent disableTabs={true} />
							</MuiThemeProvider>

							<MuiThemeProvider theme={actionsTheme}>
								<Panel {...myActions} transparent disableTabs={true} />
							</MuiThemeProvider>

							<Button
								className={dirwebLongClasses.cancelAndExitApplicationButton}
							>
								<Typography
									className={dirwebLongClasses.cancelAndExitApplicationText}
								>
									Avbryt søknad
								</Typography>
							</Button>
						</Grid>
					</Grid>
				)}
			</Grid>
		</>
	);
};

DirwebLong.displayName = "DployFormDirwebLong";
