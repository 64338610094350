import { useMemo, useEffect } from "react";
import qs from "qs";
import { Service, CalculationResponse, NetworkError } from "@ploy-lib/types";
import { CalculatorState, Namespaced } from "../../calculator";
import { createServiceManager } from "../../service-manager/createServiceManager";
import { ActionTypes, ServiceTrigger, NamespaceService } from "../../types";
import {
	HandlerOptions,
	HandlerResponse
} from "../../service-manager/createServiceCaller";
import { fetchData } from "../../service-manager/utils";
import {
	legacyApiResourceUrl,
	apiResourceUrl,
	alphabeticalSort
} from "@ploy-lib/core";
import { batch } from "../../utils";
import { DispatchWithPriority, DispatchPriority } from "./useRenderReducer";

type RequiredOptions = Omit<HandlerOptions, "method"> &
	Pick<Required<HandlerOptions>, "method">;

interface ServiceHandlerInterface {
	(service: Service, body: any, params: any, options: RequiredOptions): Promise<
		HandlerResponse
	>;
}

function createServiceUrl(baseUrl: string, searchParams: any) {
	let url = baseUrl.match(/^\/?api/)
		? apiResourceUrl(baseUrl.replace(/^\/?api/, ""))
		: legacyApiResourceUrl(baseUrl);

	if (searchParams && Object.keys(searchParams).length) {
		const params = qs.stringify(searchParams, {
			arrayFormat: "repeat",
			sort: alphabeticalSort
		});
		url = `${url}${url.includes("?") ? "&" : "?"}${params}`;
	}

	return url;
}

function createServiceHandler<TN extends string>(): ServiceHandlerInterface {
	return async (
		service: NamespaceService<TN>,
		body: any,
		searchParams: any,
		options: RequiredOptions
	): Promise<HandlerResponse> => {
		try {
			const params = searchParams;

			const data = await fetchData(
				options.method,
				createServiceUrl(service.url, params),
				body
					? {
							...body,
							Version: "ph" // Backend sometimes need distinction between vulcan and pheonix in order to return correct viewmodel types
					  }
					: null
			);
			return {
				data,
				ok: true
			};
		} catch (e: any) {
			if (e instanceof NetworkError) {
				try {
					return await e.response.json().then(res => {
						return {
							ok: false,
							error: {
								name: e.message,
								message: res.message,
								stack: e.stack
							}
						};
					});
				} catch (e: any) {
					return {
						ok: false
					};
				}
			}
			return {
				ok: false
			};
		}
	};
}

/**
 * Patch targets that must be calculated separately and in order
 *
 * Example:
 * There are field functions that reset Make/Model/Engine values when IsManualDefined changes.
 * This means the IsManualDefined change must be calculated before Make/Model/Engine is updated,
 * because otherwise Make/Model/Engine changes are lost.
 */
const orderDependantTargets = new Set(["IsManualDefined"]);

export function useServiceManager<TN extends string, TD>(
	services: Record<TN, Service[]>,
	additionalServiceFields: Partial<Namespaced<string, TN>> | undefined,
	calculation: Partial<CalculatorState<TN, TD>>,
	triggers: readonly ServiceTrigger<TN>[],
	dispatch: DispatchWithPriority<ActionTypes<TN, TD | number>>,
	onUpdateDataModel?: (calc: CalculationResponse) => void,
	onServiceSuccess?: (namespace: TN, service: Service) => void
) {
	const serviceManager = useMemo(() => {
		const serviceHandler = createServiceHandler<TN>();
		return createServiceManager<TN, TD | number>(
			services,
			additionalServiceFields,
			serviceHandler,
			createServiceUrl,
			(namespace, service, patches = []) => {
				batch(() => {
					/* Make sure orderDependantTargets are calculated separately and in order:

						Patches: [Field1, Field2, IsManualDefined, Make, Model, Engine]
												  ^^^^^^^^^^^^^^^
						Actions:
						1: Patch: [Field1, Field2]
						2: Calculate
						3: Patch: [IsManualDefined]
						4: Calculate
						5: Patch: [Make, Model, Engine]
						6: Calculate
						7: Service Success
						8: Calculate

						Due to performance issues with "Calculate", as many patches as possible are grouped together
						This can be changed to treat all targets as order dependant once the performance issues are resolved
						(This would be the same behavour as Vulcan/Classic)
					*/

					const patchesList: typeof patches[] = [[]];
					for (const patch of patches) {
						if (orderDependantTargets.has(patch.target)) {
							if (patchesList[patchesList.length - 1].length === 0)
								patchesList[patchesList.length - 1].push(patch);
							else patchesList.push([patch]);

							patchesList.push([]);
						} else {
							patchesList[patchesList.length - 1].push(patch);
						}
					}

					for (const patches of patchesList) {
						dispatch(
							{
								type: "patch",
								payload: { patches }
							},
							DispatchPriority.Defer
						);
						dispatch(
							{
								type: "calculate"
							},
							DispatchPriority.Defer
						);
					}

					dispatch(
						{
							type: "service_success",
							meta: { service, namespace }
						},
						DispatchPriority.Defer
					);
					dispatch(
						{
							type: "calculate"
						},
						DispatchPriority.Defer
					);

					onServiceSuccess?.(namespace, service);
				});
			},
			onUpdateDataModel
		);
	}, [
		services,
		additionalServiceFields,
		onUpdateDataModel,
		dispatch,
		onServiceSuccess
	]);

	useEffect(() => {
		serviceManager.trigger(triggers, calculation);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [triggers]);

	return serviceManager;
}
