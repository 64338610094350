import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { DployAutocomplete } from "@ploy-ui/form-fields";
import { defineMessages, injectIntl } from "react-intl";
import { BaseCamelCasedResource } from "@ploy-lib/rest-resources";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { useResource } from "@rest-hooks/core";

const processReportUri = uri => {
	return `${uri.replace("~", process.env.REACT_APP_CLASSIC_EXTERNAL_URL)}`;
};

class ReportsResource extends BaseCamelCasedResource {
	reportId;
	reportName = "";
	navigateUrl = "";

	pk() {
		return `${this.reportId}`;
	}

	static async fetch(method, url, body) {
		const data = await super.fetch(method, url, body);

		return data?.initialData ?? data;
	}

	static urlRoot = legacyApiResourceUrl("ReportSelector/Vulcan");
}

function Reports({ className, intl }) {
	const [selectedReport, setSelectedReport] = useState(null);

	const classes = useStyles();

	const reports = useResource(ReportsResource.list(), {});

	const onChangeHandler = (e, value) => {
		if (!value) return;

		const selectedItem = reports.find(item => item.reportId === value.reportId);

		if (!selectedItem) return;

		setSelectedReport(selectedItem);
	};

	return (
		<div className={clsx(classes.root, className)}>
			<Grid container>
				<Grid item xs={12} sm={6} lg={4}>
					<DployAutocomplete
						className={classes.selectField}
						items={reports}
						placeholder={intl.formatMessage(messages.Placeholder)}
						getItemLabel={item => item.reportName}
						onChange={onChangeHandler}
						value={selectedReport}
						label={intl.formatMessage(messages.Label)}
						disabled={reports.length <= 0}
						fullWidth
						disableClearable
					/>
				</Grid>
			</Grid>
			<div className={classes.reports}>
				{selectedReport && selectedReport.navigateUrl && (
					<iframe
						title="Reports Module"
						height="100%"
						width="100%"
						src={processReportUri(selectedReport.navigateUrl)}
						style={{ marginTop: "-4.5em", border: 0 }}
					/>
				)}
			</div>
		</div>
	);
}

const useStyles = makeStyles(
	theme => ({
		root: {
			flexGrow: 1,
			padding: theme.spacing(2),
			height: "100vh",
			border: 0
		},
		selectField: {
			marginTop: 15,
			fontWeight: 700
		},
		reports: {
			height: "100%",
			marginLeft: "-16em",
			overflow: "hidden",
			border: 0
		}
	}),
	{ name: "Reports" }
);

const messages = defineMessages({
	Placeholder: {
		id: "dealer.reports.dropdown.placeholder",
		description: "Placeholder for report dropdown",
		defaultMessage: "Velg..."
	},
	Label: {
		id: "dealer.reports.dropdown.label",
		description: "Label for report dropdown",
		defaultMessage: "Rapporter"
	}
});

Reports.displayName = "Reports";

export default injectIntl(Reports);
