import Competitions, { allColumns } from "./Competitions";
import { applyPropertyControls, ControlType } from "../../property-controls";
import { isNotNull } from "@ploy-ui/form-fields/es/utils";
import { CompetitionResult } from "@ploy-lib/rest-resources";

const columnNames = allColumns
	.map(c => c.field as keyof CompetitionResult)
	.filter(isNotNull);

applyPropertyControls(Competitions, {
	visibleColumns: {
		type: ControlType.Array,
		title: "Synlige kolonner",
		propertyControl: {
			type: ControlType.Enum,
			title: "",
			options: columnNames
		},
		defaultValue: columnNames,
		coerce: (_, { rawColumns }: { rawColumns?: string }) =>
			rawColumns
				?.toLowerCase()
				.split("\n")
				.map(n => {
					if (n) {
						return columnNames.find(name => name.toLowerCase() === n);
					}
					return null;
				})
				.filter(isNotNull)
	},
	scrollable: {
		type: ControlType.Boolean,
		title: "Scrollende tabell",
		defaultValue: false,
		coerce: value => (String(value) === "1" ? true : false)
	},
	boxProps: {
		type: ControlType.Object,
		title: "Standard instillinger",
		propertyControls: {
			header: {
				type: ControlType.String,
				title: "Tittel"
			},
			iconClass: {
				type: ControlType.String,
				title: "Ikon",
				description: "Fyll inn navnet til et ikon fra Font Awesome"
			},
			backgroundColor: {
				type: ControlType.Color,
				title: "Bakgrunnsfarge",
				description: "Bakgrunnsfarge for boksen",
				coerce: (_, boxProps) => {
					if (boxProps.grey) return "grey.200";
				}
			},
			headerColor: {
				type: ControlType.Color,
				title: "Tittelfarge",
				description: "Tekstfarge som blir brukt på tittel og ikon",
				coerce: (_, boxProps) => {
					if (boxProps.grey) return "grey.500";
				}
			}
		}
	}
});

export default Competitions;
