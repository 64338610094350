import React, { useMemo } from "react";

import SaveIcon from "@material-ui/icons/Save";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { PendingIconButton, PendingButton } from "@ploy-ui/core";
import { UppercaseTextField } from "@ploy-ui/form-fields";
import { Formik, Form, Field } from "formik";
import { object, string } from "yup";
import { legacyApiResourceUrl } from "@ploy-lib/core";

function ApplicationActions(props) {
	const {
		rowData: {
			applicationId,
			documentStatusId,
			field8: regNumber = "",
			productCodeExternal,
			applicationStatusId
		},
		intl
	} = props;

	const regNumberSchema = useMemo(
		() =>
			object().shape({
				regNumber: string("")
					.required("")
					.matches(
						new RegExp(intl.formatMessage(messages.regNumber_regex)),
						intl.formatMessage(messages.regNumber_invalid)
					)
			}),
		[intl]
	);

	if (documentStatusId === 70)
		return (
			<PendingButton
				onClick={async () => {
					const response = await fetch(
						legacyApiResourceUrl(
							`/SelectObject/SetRegistered?applicationId=${applicationId}`
						),
						{ method: "POST" }
					);

					if (!response.ok) {
						throw new Error("En feil har oppstått.");
					}
				}}
				color="primary"
				variant="contained"
			>
				<FormattedMessage
					id="dealer.allapplications.action.car-registered.label"
					description="Label for car registered button"
					defaultMessage="Omregistrert"
				/>
			</PendingButton>
		);

	const canChangeRegNumber =
		[5, 6, 14].includes(applicationStatusId) && // App is approved
		//[72, 77].includes(documentStatusId) && // Documents are at least partly signed
		!["LEIE"].includes(productCodeExternal) &&
		regNumber === "";

	return (
		canChangeRegNumber && (
			<Formik
				initialStatus={{}}
				initialValues={{ regNumber }}
				validationSchema={regNumberSchema}
				onSubmit={async (values, form) => {
					try {
						const payload = {
							field: "RegNumber",
							value: values.regNumber,
							applicationId
						};
						const response = await fetch(
							legacyApiResourceUrl("AppChange/UpdateFieldWithEvent"),
							{
								method: "POST",
								body: new Blob([JSON.stringify(payload)], {
									type: "application/json"
								}),
								headers: {
									accept: "application/json"
								}
							}
						);

						if (!response.ok) throw new Error(response.statusText);

						form.resetForm({ values });
						form.setStatus({ success: true });
					} catch (error) {
						form.setStatus({ failed: true });
						form.setFieldError("regNumber", "Lagring feilet");
					} finally {
						form.setSubmitting(false);
					}
				}}
			>
				{formik => (
					<Form>
						<Field
							name="regNumber"
							component={UppercaseTextField}
							variant="outlined"
							placeholder={intl.formatMessage(messages.regNumber_placeholder)}
							onClick={e => e.stopPropagation()}
							label={
								<FormattedMessage
									id="dealer.allapplications.action.reg-number.label"
									defaultMessage="Registreringsnummer"
									description="Label for registration number"
								/>
							}
							fullWidth
							InputLabelProps={{
								shrink: true
							}}
							margin="dense"
							InputProps={{
								endAdornment: (formik.dirty ||
									regNumber ||
									formik.status.success) && (
									<InputAdornment position="end">
										<PendingIconButton
											type="submit"
											disabled={!formik.isValid || !formik.dirty}
											success={
												!formik.dirty && (formik.status.success || regNumber)
											}
											error={formik.status.failed}
											pending={formik.isSubmitting}
											color="primary"
											size="small"
										>
											<SaveIcon />
										</PendingIconButton>
									</InputAdornment>
								)
							}}
						/>
					</Form>
				)}
			</Formik>
		)
	);
}

export const messages = defineMessages({
	regNumber_placeholder: {
		id: "dealer.allapplications.action.reg-number.placeholder",
		description: "Placeholder for registration number",
		defaultMessage: "AB12345"
	},
	regNumber_regex: {
		id: "dealer.allapplications.action.reg-number.regex",
		description: "Regex for validating registration number",
		defaultMessage: "^\\w'{2}'\\d'{4,5}'$"
	},
	regNumber_invalid: {
		id: "dealer.allapplications.action.reg-number.invalid",
		description: "Message for invalid registration unmber",
		defaultMessage: "Ikke gyldig"
	}
});

export default injectIntl(ApplicationActions);
