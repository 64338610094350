import { AccumulatedPayment, Payment } from "./types";

export const accumulatePayments = (
	acc: AccumulatedPayment,
	p: Payment
): AccumulatedPayment => ({
	...p,
	installment: acc.installment + p.installment,
	interest: acc.interest + p.interest,
	fee: acc.fee + p.fee,
	termAmount:
		acc.termAmount && p.termAmount
			? acc.termAmount + p.termAmount
			: acc.termAmount || p.termAmount,
	extra: acc.extra && p.extra ? acc.extra + p.extra : acc.extra || p.extra,
	payout:
		acc.payout && p.payout ? acc.payout + p.payout : acc.payout || p.payout,
	noPayFee:
		acc.noPayFee && p.noPayFee
			? acc.noPayFee + p.noPayFee
			: acc.noPayFee || p.noPayFee
});

export const initialPayment: Payment = {
	installment: 0,
	extra: 0,
	fee: 0,
	interest: 0,
	rate: 0,
	residual: 0,
	term: 0,
	year: 0
};

export const processPayments = (payments: Payment[]) => {
	payments = payments.map(p => ({
		...p,
		residual: Math.abs(p.residual) < 0.005 ? 0 : p.residual,
		termAmount: p.installment + p.interest + p.fee
	}));

	const years = payments
		.reduce<AccumulatedPayment[]>((acc, p) => {
			acc[p.year] = accumulatePayments(acc[p.year] || initialPayment, p);
			return acc;
		}, [])
		.filter(x => x != null);

	const accumulated = payments.reduce(accumulatePayments, initialPayment);

	return {
		payments,
		years,
		accumulated
	};
};
