import React from "react";
import { SectionProps } from "../Section";
import { Grid, Typography, Box, makeStyles } from "@material-ui/core";
import CommentIcon from "@material-ui/icons/Comment";
import EditIcon from "@material-ui/icons/Edit";
import { FormattedMessage } from "react-intl";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";

const useStyles = makeStyles(theme => ({
	icon: {
		fontSize: 60,
		color: theme.palette.grey[500]
	},
	separator: {
		borderTop: `3px dashed ${theme.palette.grey[500]}`,
		[theme.breakpoints.up("md")]: {
			borderTop: "none",
			borderLeft: `3px dashed ${theme.palette.grey[500]}`
		}
	},
	section: {}
}));

addRegisteredSectionLayout({
	name: "ESigningExplanationSection",
	displayName: "ESigningExplanationSection",
	settings: {}
});

const ESigningExplanationSection = (props: SectionProps) => {
	const classes = useStyles(props);
	return (
		<Box
			p={3.5}
			borderRadius="borderRadius"
			bgcolor="common.white"
			textAlign="center"
			className={props.className}
		>
			<Grid container spacing={5} onClick={props.onClick}>
				<Grid item xs={12}>
					<Box display="flex" justifyContent="center">
						<Typography variant="h5" gutterBottom>
							<FormattedMessage
								id="form.section.esign-explanation.header"
								description="Esign explanation header"
								defaultMessage="Slik fungerer e-signering"
							/>
						</Typography>
					</Box>
				</Grid>

				<Grid item xs={12} md={4}>
					<CommentIcon className={classes.icon} />
					<Typography component="div">
						<Box display="inline" fontWeight="700" mr={1}>
							1.
						</Box>
						<FormattedMessage
							id="form.section.esign-explanation.step-1"
							description="First step of esign explanation"
							defaultMessage="Kunde mottar SMS/e-post med lenke til signeringsportal."
						/>
					</Typography>
				</Grid>
				<Grid item xs={12} md={4} className={classes.separator}>
					<EditIcon className={classes.icon} />
					<Typography component="div">
						<Box display="inline" fontWeight="700" mr={1}>
							2.
						</Box>
						<FormattedMessage
							id="form.section.esign-explanation.step-2"
							description="Second step of esign explanation"
							defaultMessage="Kunde signerer avtalen med BankID eller BankID på mobil."
						/>
					</Typography>
				</Grid>
				<Grid item xs={12} md={4} className={classes.separator}>
					<CommentIcon className={classes.icon} />
					<Typography component="div">
						<Box display="inline" fontWeight="700" mr={1}>
							3.
						</Box>
						<FormattedMessage
							id="form.section.esign-explanation.step-3"
							description="Third step of esign explanation"
							defaultMessage="Ved kjøp av nytt registrerbart kjøretøy, får kunden en ny SMS/e-post
							om signering av salgspant når reg. nr. registreres på saken."
						/>
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};

ESigningExplanationSection.displayName = "DployESigningExplanationSection";

export { ESigningExplanationSection };
