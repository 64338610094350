import React, { memo } from "react";
import { Typography, makeStyles, FormHelperText } from "@material-ui/core";
import { InputFieldProps } from "../../types";
import clsx from "clsx";
import { FormattedNumber } from "react-intl";
import {
	identity,
	identityRecordOfFieldEditorOptions,
	HasItems,
	FieldEditorOptions,
	getFieldErrorProps
} from "@ploy-ui/form-fields";
import merge from "lodash/merge";

const useStyles = makeStyles(
	theme => ({
		wrapper: {
			width: "100%"
		},
		root: {
			display: "flex",
			alignItems: "flex-start",
			width: "100%",
			margin: 0
		},
		label: {
			flexGrow: 0,
			marginRight: theme.spacing(1),
			whiteSpace: "pre-line"
		},
		value: {
			flexGrow: 1,
			textAlign: "right",
			whiteSpace: "pre-line"
		},
		suffix: {
			marginLeft: theme.spacing(1),
			flexGrow: 0,
			width: 20,
			whiteSpace: "nowrap"
		},
		marginNormal: {
			marginTop: 0,
			marginBottom: theme.spacing(1)
		},
		marginDense: {
			marginTop: 8,
			marginBottom: 4
		},
		variantContained: {
			padding: theme.spacing(1),
			background: theme.palette.grey[300],
			"& > $value": {
				fontSize: 12,
				textAlign: "inherit" as "inherit"
			}
		},
		variantEmphasized: {
			// borderBottom: "1px solid #efefef",
			"& > $value": {
				fontWeight: 700
			}
		},
		variantPlain: {
			marginBottom: 0,
			alignItems: "center"
		},
		variantNormal: {
			"& > $value": {
				fontSize: 12,
				textAlign: "inherit" as "inherit"
			}
		}
	}),
	{ name: "DployFormLiteral" }
);

const literalVariants = [
	"plain",
	"emphasized",
	"contained",
	"normal",
	"none"
] as const;

export type LiteralProps = InputFieldProps & {
	literalVariant?: typeof literalVariants[number];
	error?: boolean;
	helperText?: string;
} & HasItems<any>;

export type LiteralbaseProps = Omit<
	LiteralProps,
	"form" | "field" | "options"
> &
	LiteralProps["options"];

export function getLiteralBaseProps(props: LiteralProps): LiteralbaseProps {
	const { options, form, field, helperText, errorDisplay, ...rest } = props;

	return {
		...rest,
		...options,
		...getFieldErrorProps(props, errorDisplay)
	};
}

export function LiteralBaseInternal(
	props: LiteralbaseProps & {
		children: React.ReactNode;
	}
) {
	const {
		className,
		suffix,
		prefix,
		margin,
		children,
		error,
		helperText,
		literalVariant = "emphasized"
	} = props;

	const classes = useStyles(props);

	let label = props.label && props.label.trim();
	let hideValue = false;
	if (!label && typeof children === "string") {
		label = children.toString().trim();
		hideValue = true;
	}

	const styleParameters = merge(
		{},
		props.boldText ? { fontWeight: "bold" as const } : {},
		props.italicText ? { fontStyle: "italic" as const } : {}
	);

	return (
		<div className={clsx(classes.wrapper)}>
			<Typography
				paragraph
				className={clsx(className, classes.root, {
					[classes.variantEmphasized]: literalVariant === "emphasized",
					[classes.variantPlain]: literalVariant === "plain",
					[classes.variantContained]: literalVariant === "contained",
					[classes.variantNormal]: literalVariant === "normal",
					[classes.marginNormal]: margin === "normal",
					[classes.marginDense]: margin === "dense"
				})}
			>
				{label && (
					<span className={classes.label} style={styleParameters}>
						{label}
					</span>
				)}
				{!hideValue && (
					<span className={classes.value} style={styleParameters}>
						{children}
					</span>
				)}
				<span className={classes.suffix} style={styleParameters}>
					{suffix || prefix}
				</span>
			</Typography>
			{helperText && (
				<FormHelperText error={error}>{helperText}</FormHelperText>
			)}
		</div>
	);
}

LiteralBaseInternal.displayName = "LiteralBase";
export const LiteralBase = memo(LiteralBaseInternal);

export const baseEditableOptions: FieldEditorOptions["editableOptions"] = {
	variant: literalVariants
};

export const TextLiteral = (props: LiteralProps) => {
	const {
		items = [],
		getItemValue = identity,
		getItemLabel = identity,
		multiple
	} = props;

	let value = props.field.value;

	if (multiple) {
		value =
			value
				?.map(
					(v: any) => getItemLabel(items?.find(x => getItemValue(x) === v)) || v
				)
				.join("\n") || (Number(value) === 0 ? "" : value);
	} else {
		value =
			getItemLabel(items?.find(x => getItemValue(x) === value)) ||
			(Number(value) === 0 ? "" : value);
	}

	return (
		<LiteralBase {...getLiteralBaseProps(props)}>
			{typeof value === "number" ? (
				<FormattedNumber maximumFractionDigits={0} value={value} />
			) : (
				value
			)}
		</LiteralBase>
	);
};

TextLiteral.displayName = "TextLiteral";

export const EditorTextLiterals = identityRecordOfFieldEditorOptions({
	TextLiteral: {
		editableOptions: {
			...baseEditableOptions
		}
	}
});
