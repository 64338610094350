import { ResolvedField, Namespaced, CtrlField } from "./types";
import {
	getFieldNameMatch,
	isNotNull,
	getKeysInNamespace,
	mapValueToKey,
	isResolvedCtrl,
	isVariableField
} from "./utils";
import { CalculatorState } from "./createCalculator";
import {
	ServiceBodyVariable,
	ServiceBodyField,
	ServiceBodyVariableField
} from "../types";

export function createInitialize<TData, TNamespaces extends string>(
	namespace: TNamespaces,
	ctrlFields: CtrlField[],
	serviceBodyVariables: ServiceBodyVariable[] = [],
	serviceBodyFields: ServiceBodyField[] = []
) {
	return function initialize(
		input: Partial<CalculatorState<TNamespaces, TData>> = {},
		formFields: Partial<Namespaced<any, TNamespaces>> = {}
	): CalculatorState<TNamespaces, TData> {
		const resolvedFields = ctrlFields
			.map(ctrlField => {
				const fieldNamespace = isResolvedCtrl<TNamespaces>(ctrlField)
					? ctrlField.namespace
					: namespace;
				const fieldName = getKeysInNamespace(
					formFields,
					fieldNamespace
				).find((fieldName: string) =>
					Boolean(getFieldNameMatch(fieldName, ctrlField.resolved))
				);

				return fieldName
					? ({
							...ctrlField,
							fieldName
					  } as ResolvedField<TNamespaces>)
					: undefined;
			})
			.filter(isNotNull);

		const controlFieldMap = resolvedFields.reduce((acc, resolvedField) => {
			if (
				acc[resolvedField.ctrl] != null &&
				(acc[resolvedField.ctrl].overrideIndex ?? 0) >
					(resolvedField.overrideIndex ?? 0)
			)
				return acc;

			acc[resolvedField.ctrl] = isVariableField(acc[resolvedField.ctrl])
				? acc[resolvedField.ctrl]
				: resolvedField;
			return acc;
		}, {} as Record<string, ResolvedField<TNamespaces>>);

		const resolvedVariableMap = resolvedFields.reduce((acc, resolvedField) => {
			if (
				isVariableField(resolvedField) &&
				isResolvedCtrl<TNamespaces>(resolvedField)
			) {
				const existing = acc[resolvedField.ref] || [];
				if (!existing.includes(resolvedField.variable)) {
					existing.push(resolvedField.variable);
					acc[resolvedField.ref] = existing;
				}
			}
			return acc;
		}, {} as Record<string, string[]>);

		const fieldControlMap = mapValueToKey(controlFieldMap, (resolved, map) => {
			if (isResolvedCtrl(resolved)) return undefined;

			const existingKey = map[resolved.fieldName];
			const ctrl = existingKey ? controlFieldMap[existingKey] : undefined;
			if (
				ctrl != null &&
				(ctrl.overrideIndex ?? 0) > (resolved.overrideIndex ?? 0)
			)
				return undefined;

			return resolved.fieldName;
		});

		const variableControlMap = mapValueToKey(controlFieldMap, resolved =>
			isVariableField(resolved) ? resolved.variable : undefined
		);

		const serviceBodyValues = [
			...serviceBodyVariables,
			...serviceBodyFields.map<ServiceBodyField | ServiceBodyVariableField>(
				f => {
					const controlRef = fieldControlMap[f.field];

					const resolveInfo = controlFieldMap[controlRef];

					if (isVariableField(resolveInfo)) {
						return {
							...f,
							variable: resolveInfo.variable
						};
					}

					return f;
				}
			)
		];

		const {
			controlFieldMaps = {},
			resolvedVariableMaps = {},
			fieldControlMaps = {},
			variableControlMaps = {},
			serviceBodyValuesMap = {}
		} = input;

		return {
			formValues: {},
			initialFormValues: {},
			defaultFieldVisibility: {},
			errors: {},
			isEnabled: {},
			isFieldVisible: {},
			isMissing: {},
			isWriteLocked: {},
			values: {},
			...input,
			controlFieldMaps: { ...controlFieldMaps, [namespace]: controlFieldMap },
			fieldControlMaps: { ...fieldControlMaps, [namespace]: fieldControlMap },
			variableControlMaps: {
				...variableControlMaps,
				[namespace]: variableControlMap
			},
			resolvedVariableMaps: {
				...resolvedVariableMaps,
				[namespace]: resolvedVariableMap
			},
			serviceBodyValuesMap: {
				...serviceBodyValuesMap,
				[namespace]: serviceBodyValues
			}
		};
	};
}
