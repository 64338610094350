import { LoginResource } from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";
import { useEffect } from "react";

export function CookieBotPreGtm() {
	useEffect(() => {
		CookieBotBeforeGtag();
	}, []);

	return null;
}

const CookieBotBeforeGtag = () => {
	window["dataLayer"] = window["dataLayer"] || [];

	function gtag(...args: any[]) {
		window["dataLayer"].push(args);
	}

	gtag("consent", "default", {
		ad_storage: "denied",
		analytics_storage: "denied",
		wait_for_update: 500
	});

	gtag("set", "ads_data_redaction", true);
};

export const CookieBotPostGtm = ({ cookiebotId }: { cookiebotId: string }) => {
	useEffect(() => {
		CookieBotPostGtag(cookiebotId);
	}, [cookiebotId]);

	return null;
};

const CookieBotPostGtag = (cookieBotId: any) => {
	if (!cookieBotId) return;
	const firstScriptTag = document.getElementsByTagName("script")[0];

	const existingScriptTag = document.getElementById("Cookiebot");
	if (existingScriptTag != null) return;

	const scriptTag = document.createElement("script");
	scriptTag.id = "Cookiebot";
	scriptTag.type = "text/javascript";
	scriptTag.src = "https://consent.cookiebot.com/uc.js";
	scriptTag.setAttribute("data-cbid", cookieBotId);
	scriptTag.setAttribute("data-blockingmode", "auto");

	firstScriptTag &&
		firstScriptTag.parentNode &&
		firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
};
