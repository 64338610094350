import React, { memo } from "react";
import { usePageState } from "../PageContext";
import { TemplatePageProps } from "../types";
import * as layouts from "./layouts";
import { TemplatePage } from "@ploy-lib/types";

export const FormPages = memo<{
	pages: TemplatePage[];
	header: React.ReactNode;
	modalClick?: number;
}>(({ pages, header, modalClick }) => {
	const { step } = usePageState();

	const { layout, ...page } = pages[step];

	const PageComponent: React.ComponentType<TemplatePageProps<typeof page>> =
		layout && layouts[layout] ? layouts[layout] : layouts.Wizard;

	return <PageComponent header={header} {...page} modalClick={modalClick} />;
});
