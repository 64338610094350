import React, { useMemo } from "react";
import { useResource } from "@rest-hooks/core";
import { Product, LoginResource } from "@ploy-lib/rest-resources";
import { ProductViewProps } from "./ProductView";
import { interpolateParams, getBasepath } from "../../utils";
import { usePageState } from "@ploy-ui/template-form";
import { DployAutocomplete } from "@ploy-ui/form-fields";
import { makeStyles, getContrastRatio } from "@material-ui/core/styles";
import clsx from "clsx";
import { RouterLink } from "@ploy-ui/core";
import { RouteComponentProps } from "@reach/router";

const useStyles = makeStyles(
	theme => ({
		root: {
			fontWeight: 700,
			[theme.breakpoints.down("xs")]: {
				padding: 4,
				marginTop: 8
			}
		},
		option: {
			padding: 0
		},
		optionLink: {
			paddingTop: 6,
			paddingBottom: 6,
			paddingLeft: 16,
			paddingRight: 16,
			flexGrow: 1,
			color:
				getContrastRatio(
					theme.palette.primary.main,
					theme.palette.common.white
				) > 2.5
					? theme.palette.primary.main
					: theme.palette.text.primary
		}
	}),
	{ name: "DployProductSelector" }
);

export interface ProductSelectorProps {
	products: Product[];
	selected: Product;
	disabled?: boolean;
}

export const ProductSelector = (
	props: ProductSelectorProps & RouteComponentProps<ProductViewProps>
) => {
	const { isDirectUser } = useResource(LoginResource.status(), {});

	const { products, selected, disabled, navigate, ...routeProps } = props;

	const { step } = usePageState();
	const classes = useStyles(props);

	const isHeader = disabled || step > 0 || !products || products.length === 0;

	const items = useMemo(() => (isHeader ? [selected] : products), [
		isHeader,
		products,
		selected
	]);

	const getProductRoute = (product: Product) =>
		`${getBasepath(routeProps)}/${interpolateParams({
			...routeProps,
			"*": null,
			externalCode: product.externalCode.toLowerCase()
		})}`;

	if (isDirectUser) return null;

	return (
		<DployAutocomplete
			className={clsx(classes.root)}
			AutocompleteProps={{
				classes: {
					option: classes.option
				},
				getOptionSelected: (option, value) =>
					option.externalCode === value.externalCode
			}}
			data-test-id="product-selector"
			items={items}
			getItemLabel={p => p.externalName}
			value={selected}
			onChange={(e, product) =>
				navigate && product && navigate(getProductRoute(product))
			}
			renderOption={(product, state) => (
				<RouterLink
					className={classes.optionLink}
					underline="none"
					to={getProductRoute(product)}
					data-product-external-code={product.externalCode}
				>
					{product.externalName}
				</RouterLink>
			)}
			variant="standard"
			searchable={false}
			fullWidth
			disableClearable
			readonly={isHeader || items.length === 1}
		/>
	);
};
