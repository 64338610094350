import React, { useMemo, useState } from "react";
import { WholesaleLimitDetailResource } from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";
import { ExpandableTable, ExpandableTableProps } from "@ploy-ui/core";
import {
	lighten,
	makeStyles,
	Grid,
	TablePagination,
	Theme
} from "@material-ui/core";
import { ExportWholesaleLimitButton } from "./ExportWholesaleLimitButton";
import { defineMessages, FormattedDate, useIntl, IntlShape } from "react-intl";

const useOverrideStyles = makeStyles((theme: Theme) => ({
	editColumn: {
		"&:last-child": {
			padding: 0,
			width: 0,
			minWidth: 0
		}
	},
	emptyLastColumn: {
		width: 0,
		"&:last-child": {
			padding: 0
		}
	},
	head: {
		background: `${lighten(theme.palette.secondary.light, 0.6)} !important`
	}
}));

const useStyles = makeStyles((theme: Theme) => ({
	buttonWrapper: {
		padding: 6
	},
	themedBackground: {
		background: `${lighten(theme.palette.secondary.light, 0.9)}`
	}
}));

const columnDetailsDefinition = (intl: IntlShape) =>
	[
		{
			id: "source",
			label: intl.formatMessage(messages.sourceHeader),
			hidden: { smDown: true }
		},
		{
			id: "applicationNumber",
			label: intl.formatMessage(messages.applicationNumberHeader)
		},
		{
			id: "product",
			label: intl.formatMessage(messages.productHeader),
			hidden: { smDown: true }
		},
		{
			id: "carModel",
			label: intl.formatMessage(messages.carModelHeader),
			hidden: { smDown: true }
		},
		{
			id: "regNo",
			label: intl.formatMessage(messages.regNoHeader),
			hidden: { smDown: true }
		},
		{
			id: "serial",
			label: intl.formatMessage(messages.serialHeader),
			hidden: { smDown: true }
		},
		{
			id: "prodNo",
			label: intl.formatMessage(messages.prodNoHeader),
			hidden: { smDown: true }
		},
		{
			id: "loanAmount",
			label: intl.formatMessage(messages.loanAmountHeader)
		},
		{
			id: "openDate",
			label: intl.formatMessage(messages.openDateHeader),

			component: props => (
				<FormattedDate
					value={props.rowData.openDate}
					day="numeric"
					month="long"
					year="numeric"
				/>
			)
		}
	] as ExpandableTableProps<any, any>["columnData"];

const messages = defineMessages({
	exportWholesaleLimitDetailButton: {
		id: "dealer.wholesalelimit.exportdetailbutton",
		description: "Label for exportWholesalelimitButton",
		defaultMessage: "Eksporter detaljer"
	},
	loanAmountHeader: {
		id: "dealer.wholesalelimit.detailtable.header.loanamount",
		description: "header for wholesalelimitdetail table, column loadAmount",
		defaultMessage: "Beløp"
	},
	openDateHeader: {
		id: "dealer.wholesalelimit.detailtable.header.opendate",
		description: "header for wholesalelimitdetail table, column openDate",
		defaultMessage: "Dato"
	},
	prodNoHeader: {
		id: "dealer.wholesalelimit.detailtable.header.prodno",
		description: "header for wholesalelimitdetail table, column prodNo",
		defaultMessage: "prodNo"
	},
	serialHeader: {
		id: "dealer.wholesalelimit.detailtable.header.serial",
		description: "header for wholesalelimitdetail table, column serial",
		defaultMessage: "Chassis"
	},
	regNoHeader: {
		id: "dealer.wholesalelimit.detailtable.header.regno",
		description: "header for wholesalelimitdetail table, column regNo",
		defaultMessage: "regNo"
	},
	carModelHeader: {
		id: "dealer.wholesalelimit.detailtable.header.carmodel",
		description: "header for wholesalelimitdetail table, column carmodel",
		defaultMessage: "Bilmodell"
	},
	productHeader: {
		id: "dealer.wholesalelimit.detailtable.header.product",
		description: "header for wholesalelimitdetail table, column product",
		defaultMessage: "Produkt"
	},
	applicationNumberHeader: {
		id: "dealer.wholesalelimit.detailtable.header.applicationnumber",
		description:
			"header for wholesalelimitdetail table, column applicationnumber",
		defaultMessage: "Forretningsnr"
	},
	sourceHeader: {
		id: "dealer.wholesalelimit.detailtable.header.other",
		description: "header for wholesalelimitdetail table, column source",
		defaultMessage: "Kilde"
	}
});

interface WholesaleLimitDetailProps {
	vendorId: number;
}

const WholesaleLimitDetail = (props: WholesaleLimitDetailProps) => {
	const { vendorId } = props;

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const intl = useIntl();
	const classes = useStyles();
	const expandableTableOverrideClasses = useOverrideStyles();

	var wholesaleLimitDetailData = useResource(
		WholesaleLimitDetailResource.list(),
		{
			vendorId: vendorId
		}
	);

	const mappedColumnDetailsDefinition = useMemo(
		() =>
			columnDetailsDefinition(intl).map(col => ({
				...col,
				className: classes.themedBackground
			})),
		[classes.themedBackground, intl]
	);

	return (
		<Grid container item xs={12}>
			<ExpandableTable
				idColumn="applicationNumber"
				tableData={wholesaleLimitDetailData.slice(
					page * rowsPerPage,
					(page + 1) * rowsPerPage
				)}
				columnData={mappedColumnDetailsDefinition}
				expandable={false}
				classes={expandableTableOverrideClasses}
				size={"small"}
			/>
			<Grid item className={classes.themedBackground} xs={12}>
				<TablePagination
					component="div"
					count={wholesaleLimitDetailData.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={(event, newPage) => {
						setPage(newPage);
					}}
					onChangeRowsPerPage={event => {
						setRowsPerPage(parseInt(event.target.value));
						setPage(0);
					}}
				/>
			</Grid>

			<Grid
				item
				container
				justify="center"
				xs={12}
				className={classes.themedBackground}
			>
				<Grid item className={classes.buttonWrapper}>
					<ExportWholesaleLimitButton
						details
						vendorId={vendorId}
						label={intl.formatMessage(
							messages.exportWholesaleLimitDetailButton
						)}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export { WholesaleLimitDetail };
