import { ID } from "../types";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BasePlainResource } from "./BasePlainResource";

export interface CustomerSignatureInfo {
	readonly signerStatusMessage: string;
	readonly customerSignersHTML: string;
}

export class CustomerSignatureInfoResource extends BasePlainResource
	implements CustomerSignatureInfo {
	readonly id?: ID;
	readonly signerStatusMessage: string = "";
	readonly customerSignersHTML: string = "";

	pk() {
		return this.id?.toString();
	}

	static urlRoot = legacyApiResourceUrl("Signer/GetCustomerSignatureInfo");
}
