import React from "react";
import { useIntl, defineMessages } from "react-intl";
import { TextField } from "@ploy-ui/form-fields";
import { useFetcher } from "@rest-hooks/core";
import { LoginResource } from "@ploy-lib/rest-resources";
import { FormBase } from "@ploy-ui/login";

const messages = defineMessages({
	mobilePlaceholder: {
		id: "dealer.login.field.mobile.placeholder",
		description: "Placeholder for for mobile field",
		defaultMessage: "Mobiltelefon"
	},
	mobileLabel: {
		id: "dealer.login.field.mobile.label",
		description: "Label for mobile field",
		defaultMessage: "Mobile"
	},
	submitError: {
		id: "dealer.login.update-mobile.error",
		description: "An error occurred",
		defaultMessage: "En feil har oppstått"
	},
	headerText: {
		id: "dealer.login.header.update_mobile",
		description: "Header for update mobile page",
		defaultMessage: "Skriv inn ditt mobilnummer"
	},
	submitButtonText: {
		id: "dealer.login.button.continue.label",
		description: "Label for continue button",
		defaultMessage: "Fortsett"
	}
});

const UpdateMobileForm = () => {
	const setMobile = useFetcher(LoginResource.setMobile());

	const intl = useIntl();

	const initValues = { mobile: "" };
	const fieldConfig = [
		{
			name: "mobile",
			component: TextField,
			type: "input",
			placeholder: intl.formatMessage(messages.mobilePlaceholder),
			variant: "input",
			margin: "normal",
			label: intl.formatMessage(messages.mobileLabel),
			fullWidth: true
		}
	];

	return (
		<FormBase
			submit={values => setMobile(undefined, values)}
			initValues={initValues}
			fieldConfig={fieldConfig}
			submitButtonText={intl.formatMessage(messages.submitButtonText)}
		/>
	);
};

export default UpdateMobileForm;
