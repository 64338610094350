import React from "react";

import { Grid } from "@material-ui/core";

import { TemplatePageProps, LayoutPage } from "../../../types";
import { addRegisteredLayout, PanelSettings } from "../layoutDescriptions";
import { Panel } from "../../../panel";
import {
	useStyles,
	actionsTheme,
	wizardSummaryHeaderTheme,
	wizardSummaryHeaderThemeAlternative,
	wizardSummaryTheme
} from "./styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { TemplatePanel } from "@ploy-lib/types";
import { useSticky } from "../../useSticky";

export type SimpleSidebarPanels = {
	main: TemplatePanel;
	sidebar: TemplatePanel;
	sidebarHeader: TemplatePanel;
	footerActions: TemplatePanel;
	headerActions: TemplatePanel;
	sidebarActions: TemplatePanel;
};
export type SimpleSidebarPanelKeys = keyof SimpleSidebarPanels;
export type SimpleSidebarPage = LayoutPage<
	SimpleSidebarPanelKeys,
	SimpleSidebarPanels
>;

const editablePanelSettings: PanelSettings["editableOptions"] = {
	title: false,
	elevation: true,
	square: true
};

const panelSettings: Record<SimpleSidebarPanelKeys, PanelSettings> = {
	main: {
		editableOptions: editablePanelSettings
	},
	sidebar: {
		editableOptions: editablePanelSettings,
		defaultOptions: {
			literal: true
		}
	},
	sidebarHeader: {
		editableOptions: editablePanelSettings,
		defaultOptions: {
			literal: true
		}
	},
	footerActions: {},
	headerActions: {},
	sidebarActions: {}
};

addRegisteredLayout({
	name: "SimpleSidebar",
	displayName: "SimpleSidebar",
	panels: panelSettings,
	reduceFormHeightForLaptops: true
});

export interface SimpleSidebarProps
	extends TemplatePageProps<SimpleSidebarPage> {}

export const SimpleSidebar = (props: SimpleSidebarProps) => {
	const { header, panels, reduceFormHeightForLaptops } = props;

	const classes = useStyles(props);

	const { getStickyProps } = useSticky();

	const {
		main,
		sidebar,
		sidebarHeader,
		sidebarActions,
		footerActions,
		headerActions
	} = panels;

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} container>
				<Grid
					container
					spacing={3}
					justify={reduceFormHeightForLaptops ? "space-between" : "flex-start"}
				>
					<Grid item xs={8} md={4}>
						{header}
					</Grid>
					<Grid item xs={4} container alignItems="stretch" justify="flex-end">
						<MuiThemeProvider theme={actionsTheme}>
							<Panel
								{...headerActions}
								transparent
								disableExpansion
								disableTabs
								fullWidth
								justify="flex-end"
							/>
						</MuiThemeProvider>
						{reduceFormHeightForLaptops && sidebarHeader && (
							<Grid item className={classes.panelGrid}>
								<MuiThemeProvider theme={wizardSummaryHeaderThemeAlternative}>
									<Panel
										className={classes.summaryHeaderAlternative}
										fullWidth
										disableExpansion
										disableTabs
										{...sidebarHeader}
									/>
								</MuiThemeProvider>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} md={8}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						{main && <Panel {...main} />}
					</Grid>
					{reduceFormHeightForLaptops && sidebarActions && (
						<Grid item container xs={12} justify={"flex-end"}>
							<Grid item xs={4}>
								<MuiThemeProvider theme={actionsTheme}>
									<Panel
										justify={reduceFormHeightForLaptops ? "flex-end" : "center"}
										transparent
										disableExpansion
										disableTabs
										fullWidth
										{...sidebarActions}
									/>
								</MuiThemeProvider>
							</Grid>
						</Grid>
					)}
					<Grid item xs={12}>
						{footerActions && (
							<MuiThemeProvider theme={actionsTheme}>
								<Panel
									justify="flex-end"
									transparent
									disableExpansion
									disableTabs
									fullWidth
									{...footerActions}
								/>
							</MuiThemeProvider>
						)}
					</Grid>
				</Grid>
			</Grid>
			{(sidebarHeader || sidebarActions || sidebar) && (
				<Grid item xs={12} md={4}>
					<Grid container {...getStickyProps()} spacing={3} direction="column">
						{sidebarHeader && !reduceFormHeightForLaptops && (
							<Grid item className={classes.panelGrid}>
								<MuiThemeProvider theme={wizardSummaryHeaderTheme}>
									<Panel
										className={classes.summaryHeader}
										fullWidth
										disableExpansion
										disableTabs
										{...sidebarHeader}
									/>
								</MuiThemeProvider>
							</Grid>
						)}
						{sidebarActions && !reduceFormHeightForLaptops && (
							<Grid item className={classes.panelGrid}>
								<MuiThemeProvider theme={actionsTheme}>
									<Panel
										justify="center"
										transparent
										disableExpansion
										disableTabs
										fullWidth
										{...sidebarActions}
									/>
								</MuiThemeProvider>
							</Grid>
						)}
						{sidebar && (
							<Grid item className={classes.panelGrid}>
								<MuiThemeProvider theme={wizardSummaryTheme}>
									<Panel {...sidebar} />
								</MuiThemeProvider>
							</Grid>
						)}
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};

SimpleSidebar.displayName = "DployFormSimpleSidebar";
