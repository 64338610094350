import React, { useMemo, useRef } from "react";
import {
	CompetitionResultResource,
	CompetitionResult as CompetitionResultType
} from "@ploy-lib/rest-resources";
import { ID } from "@ploy-lib/types";
import { DataTable } from "@ploy-ui/core";
import { Box, makeStyles } from "@material-ui/core";
import useResizeObserver from "use-resize-observer/polyfilled";
import { allColumns } from "./Competitions";
import Skeleton from "@material-ui/lab/Skeleton";
import { useStatefulResource } from "@rest-hooks/legacy";
import clsx from "clsx";

export interface CompetitionResultProps {
	visibleColumns?: (keyof CompetitionResultType)[];
	competitionId?: ID;
	placeholder?: boolean;
	scrollable?: boolean;
}

CompetitionResult.displayName = "CompetitionResult";

const useStyles = makeStyles(theme => ({
	scroll: {
		overflowY: "scroll"
	},
	noScroll: {
		overflow: "hidden",
		display: "grid"
	}
}));

export function CompetitionResult(props: CompetitionResultProps) {
	const { visibleColumns, competitionId, placeholder, scrollable } = props;
	const classes = useStyles();
	const animation = !placeholder && undefined;

	const placeholderData = useMemo(
		() => [
			({
				competitionId: ".",
				position: 1,
				salesPerson: (
					<Skeleton animation={animation} variant="text" width="10ch" />
				),
				vendor: <Skeleton animation={animation} variant="text" width="8ch" />
			} as unknown) as CompetitionResultType
		],
		[animation]
	);

	const { data: results = placeholderData, loading } = useStatefulResource(
		CompetitionResultResource.list(),
		!placeholder && competitionId ? { competitionId } : null
	);

	// Ordered by properties order (ordering not implemented in editor)
	// const columns = useMemo(
	// 	() =>
	// 		(!placeholder ? visibleColumns : Object.keys(placeholderData[0]))
	// 			?.map(name =>
	// 				allColumns.find(
	// 					c => c.field?.toString().toLowerCase() === name.toLowerCase()
	// 				)
	// 			)
	// 			.filter(isNotNull) ?? allColumns,
	// 	[placeholder, placeholderData, visibleColumns]
	// );

	// Ordered by allColumns order
	const columns = useMemo(() => {
		const visibleNames = (!placeholder
			? visibleColumns
			: Object.keys(placeholderData[0])
		)?.map(n => n.toLowerCase());

		return allColumns
			.filter(
				c =>
					!visibleNames ||
					(c.field != null && visibleNames?.includes(c.field.toLowerCase()))
			)
			.sort(
				(x, y) =>
					(visibleNames?.indexOf(x.field?.toLowerCase() || "") ?? 0) -
					(visibleNames?.indexOf(y.field?.toLowerCase() || "") ?? 0)
			);
	}, [placeholder, placeholderData, visibleColumns]);

	const tableRef = useRef<any>();

	const { ref } = useResizeObserver<HTMLDivElement>({
		onResize: ({ height = 0 }) => {
			const maxRows = Math.floor((height - 57) / 53);

			const pageSize = Math.max(maxRows - 1, 1);

			if (tableRef.current?.state.pageSize !== pageSize)
				tableRef.current?.onChangeRowsPerPage({ target: { value: pageSize } });
		}
	});

	return (
		<Box
			// @ts-expect-error: No overload matches this call. (https://github.com/mui-org/material-ui/issues/17010)
			ref={ref}
			flexGrow={1}
			className={clsx({
				[classes.scroll]: scrollable,
				[classes.noScroll]: !scrollable
			})}
		>
			<DataTable
				tableRef={tableRef}
				options={{
					showTitle: false,
					search: false,
					paging: !scrollable,
					toolbar: false,
					pageSize: 2,
					pageSizeOptions: [5, 10]
				}}
				isLoading={loading}
				columns={columns}
				data={results}
			/>
		</Box>
	);
}
