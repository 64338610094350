import React from "react";
import {
	LiteralBase,
	LiteralProps,
	getLiteralBaseProps,
	baseEditableOptions
} from "./TextLiteral";
import { useFormattedParts } from "./NumberLiteral";
import { identityRecordOfFieldEditorOptions } from "@ploy-ui/form-fields";

export const PercentLiteral = ({
	emptyEqualsZero,
	formatString,
	...rest
}: LiteralProps) => {
	const {
		suffix: suffixValue = undefined,
		prefix: prefixValue = undefined
	} = rest;
	const { prefix = prefixValue, str, suffix = suffixValue } = useFormattedParts(
		rest.field.value / 100,
		{
			format: "percent_input"
		},
		emptyEqualsZero,
		formatString
	);

	return (
		<LiteralBase {...getLiteralBaseProps(rest)} prefix={prefix} suffix={suffix}>
			{str}
		</LiteralBase>
	);
};

export const EditorPercentLiterals = identityRecordOfFieldEditorOptions({
	PercentLiteral: {
		editableOptions: {
			...baseEditableOptions,
			prefix: true,
			suffix: true
		}
	}
});
