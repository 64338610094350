import React from "react";
import { Link, makeStyles, Theme, Typography } from "@material-ui/core";

import { OptionValue } from "../types";
import { FormattedDate, FormattedNumber } from "react-intl";
import { isDate, isNumeric } from "../utils";
import { FieldType, TableColumnMetadata } from "../tableSchema";
import { DployTextField, IntlNumberField } from "@ploy-ui/form-fields";
import { FieldInputProps } from "formik";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
	tableCellTextValue: {
		fontWeight: "inherit"
	},
	tableCellNumericValue: {
		textAlign: "right",
		fontWeight: "inherit",
		width: "100%"
	},
	multiline: {
		whiteSpace: "pre"
	}
}));

export const SimpleTextLiteral = (props: {
	value: any | any[];
	items?: OptionValue[];
	getItemValue?: (optionValue?: OptionValue) => string;
	getItemLabel?: (optionValue?: OptionValue) => string;
	multiple?: boolean;
	hrefLink?: string;
	fieldMetadata?: TableColumnMetadata;
}) => {
	const {
		value,
		items,
		getItemValue,
		getItemLabel,
		multiple,
		hrefLink,
		fieldMetadata
	} = props;
	let valueForDisplay = value;
	const classes = useStyles();

	if (hrefLink) {
		return (
			<Typography variant="subtitle2" className={classes.tableCellTextValue}>
				<Link href={hrefLink}>{valueForDisplay}</Link>
			</Typography>
		);
	}
	if (fieldMetadata) {
		return <SchemaField {...props} />;
	}

	if (multiple) {
		valueForDisplay = Array.isArray(value)
			? value
					?.map(v => {
						const item = items?.find(
							x => getItemValue!(x) === getItemValue!(v)
						);
						return item && getItemLabel ? getItemLabel(item) : getItemLabel!(v);
					})
					.join(",\n")
			: value;
	} else if ((items?.length ?? 0) > 0) {
		const item = items?.find(x => getItemValue!(x) === getItemValue!(value));
		valueForDisplay = item ? getItemLabel!(item) : value;
	} else if (isNumeric(value)) {
		const numericFieldValue = Number(value);
		return (
			<Typography variant="subtitle2" className={classes.tableCellNumericValue}>
				<FormattedNumber value={numericFieldValue} />
			</Typography>
		);
	} else if (isDate(value)) {
		const dateFieldValue = new Date(value.toString());
		return (
			<Typography variant="subtitle2" className={classes.tableCellNumericValue}>
				<FormattedDate value={dateFieldValue} format="short_numeric" />
			</Typography>
		);
	}

	return (
		<Typography
			variant="subtitle2"
			className={clsx(classes.tableCellTextValue, {
				[classes.multiline]: multiple
			})}
		>
			{valueForDisplay}
		</Typography>
	);
};

// Select,
// Multiselect,
// Text,
// Number,
// Currency,
// Percent
const SchemaField = (props: {
	value?: any | any[];
	multiple?: boolean;
	fieldMetadata?: TableColumnMetadata;
	items?: OptionValue[];
	getItemValue?: (optionValue?: OptionValue) => string;
	getItemLabel?: (optionValue?: OptionValue) => string;
}) => {
	const {
		value,
		multiple,
		items,
		getItemValue,
		getItemLabel,
		fieldMetadata = {} as TableColumnMetadata
	} = props;
	const classes = useStyles();
	if (!value) return null;

	switch (fieldMetadata.fieldType) {
		case FieldType.Percent:
			if (!value) return null;
			return (
				<Typography
					variant="subtitle2"
					className={classes.tableCellNumericValue}
				>
					<FormattedNumber
						value={value / 100}
						format={"percent"}
						maximumFractionDigits={fieldMetadata.minimumFractionDigits ?? 2}
						minimumFractionDigits={fieldMetadata.minimumFractionDigits ?? 2}
					/>
				</Typography>
			);
		case FieldType.Currency:
		case FieldType.Number:
			//Currency is currently identical to number, but there exists posibilities format currencies differently in the future
			return (
				<Typography
					variant="subtitle2"
					className={classes.tableCellNumericValue}
				>
					<FormattedNumber
						value={value}
						maximumFractionDigits={fieldMetadata.minimumFractionDigits ?? 0}
						minimumFractionDigits={fieldMetadata.minimumFractionDigits ?? 0}
					/>
				</Typography>
			);
		case FieldType.Multiselect:
			const multiValue = Array.isArray(value)
				? value
						?.map(v => {
							const item = items?.find(
								x => getItemValue!(x) === getItemValue!(v)
							);
							return item && getItemLabel
								? getItemLabel(item)
								: getItemLabel!(v);
						})
						.join(",\n")
				: value;
			return (
				<Typography
					variant="subtitle2"
					className={clsx(classes.tableCellTextValue, {
						[classes.multiline]: multiple
					})}
				>
					{multiValue}
				</Typography>
			);
		case FieldType.Select:
			const item = items?.find(x => getItemValue!(x) === getItemValue!(value));
			const selectValue = item ? getItemLabel!(item) : value;
			return (
				<Typography
					variant="subtitle2"
					className={clsx(classes.tableCellTextValue, {
						[classes.multiline]: multiple
					})}
				>
					{selectValue}
				</Typography>
			);
		case FieldType.Date:
			const dateFieldValue = new Date(value.toString());
			return (
				<Typography
					variant="subtitle2"
					className={classes.tableCellNumericValue}
				>
					<FormattedDate value={dateFieldValue} format="short_numeric" />
				</Typography>
			);
		default:
			return (
				<Typography
					variant="subtitle2"
					className={clsx(classes.tableCellTextValue, {
						[classes.multiline]: multiple
					})}
				>
					{value}
				</Typography>
			);
	}
};

export const EditingSchemaField = (props: {
	field: FieldInputProps<any>;
	onChangeHandler: any;
	error: boolean;
	fieldMetadata?: TableColumnMetadata;
}) => {
	const { field, onChangeHandler, error, fieldMetadata } = props;
	switch (fieldMetadata?.fieldType) {
		case FieldType.Currency:
		case FieldType.Percent:
		case FieldType.Number:
			return (
				<IntlNumberField
					variant="standard"
					size="small"
					{...field}
					onChange={onChangeHandler}
					fullWidth
					error={error}
					allowNegative={fieldMetadata?.allowNegative}
					maximumFractionDigits={
						fieldMetadata?.maximumFractionDigits ??
						(fieldMetadata?.fieldType === FieldType.Percent ? 2 : 0)
					}
				/>
			);
		default:
			return (
				<DployTextField
					variant="standard"
					size="small"
					{...field}
					fullWidth
					error={error}
				/>
			);
	}
};
