import React, { memo, Fragment } from "react";
import {
	PropertyControls,
	ControlType,
	ObjectControl,
	ArrayControl
} from "./property-controls";
import { DployTextFieldProps } from "@ploy-ui/form-fields";
import {
	Divider,
	Box,
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
	Button,
	Tooltip,
	ListItemIcon,
	FormGroup,
	RadioGroup
} from "@material-ui/core";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

import { v4 as uuidv4 } from "uuid";
import { FormikTextField } from "./fields/FormikTextField";
import { FormikAutocompleteField } from "./fields/FormikAutocompleteField";
import { FormikSliderField } from "./fields/FormikSliderField";
import { FormikColorField } from "./fields/FormikColorField";
import { propertyValue } from "./propertyValues";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FormikFormControlFieldset } from "./fields/FormikFormControlFieldset";
import { FormikFormControlLabel } from "./fields/FormikFormControlLabel";
import { FieldArray, getIn } from "formik";
import { EnumControl } from ".";

type PropertyControlType = PropertyControls<any>[string];

interface ControlFieldProps {
	control: PropertyControlType;
	name: string;
	variant?: DployTextFieldProps["variant"];
	margin?: "none" | "dense" | "normal";
	onSelect: (path: string) => void;
	selected?: string;
}

function ControlField(props: ControlFieldProps) {
	const {
		name: fieldName,
		control,
		variant,
		margin,
		onSelect,
		selected
	} = props;

	if (!control) return null;

	switch (control.type) {
		case ControlType.Boolean:
			return (
				<FormikFormControlFieldset
					key={fieldName}
					name={fieldName}
					helperText={control.description}
					variant={variant as any}
					margin={margin}
					fullWidth
				>
					<FormGroup>
						<FormikFormControlLabel name={fieldName} label={control.title} />
					</FormGroup>
				</FormikFormControlFieldset>
			);
		case ControlType.Number:
			return (
				<FormikSliderField
					key={fieldName}
					name={fieldName}
					label={control.title}
					helperText={control.description}
					variant={variant as any}
					margin={margin}
					fullWidth
					min={control.min}
					max={control.max}
					step={control.step}
				/>
			);
		case ControlType.Color:
			return (
				<FormikColorField
					key={fieldName}
					name={fieldName}
					label={control.title}
					helperText={control.description}
					variant={variant as any}
					margin={margin}
					fullWidth
				/>
			);
		case ControlType.File:
		case ControlType.Image:
			return (
				<FormikTextField
					key={fieldName}
					name={fieldName}
					label={control.title}
					helperText={control.description}
					variant={variant as any}
					margin={margin}
					fullWidth
				/>
			);
		case ControlType.String:
			return (
				<FormikTextField
					key={fieldName}
					name={fieldName}
					label={control.title}
					helperText={control.description}
					variant={variant as any}
					margin={margin}
					fullWidth
					multiline={control.multiline}
				/>
			);
		case ControlType.Enum:
			const hasValidDefault =
				control.defaultValue != null &&
				(control.options as any[]).includes(control.defaultValue as any);

			const hasStringOptions = (
				ctrl: EnumControl<any>
			): ctrl is EnumControl<string> => typeof ctrl.options[0] === "string";

			if (
				control.options.length > 0 &&
				control.options.length <= 6 &&
				hasValidDefault &&
				hasStringOptions(control) // radio values will be converted to string and must still match
			) {
				return (
					<FormikFormControlFieldset
						key={fieldName}
						name={fieldName}
						helperText={control.description}
						disabled={control.options.length <= 1}
						variant={variant as any}
						margin={margin}
						fullWidth
					>
						<RadioGroup>
							{control.options.map((o, i) => (
								<FormikFormControlLabel
									key={o}
									type="radio"
									name={fieldName}
									label={control.optionTitles?.[i] ?? o}
									value={o}
								/>
							))}
						</RadioGroup>
					</FormikFormControlFieldset>
				);
			}
			return (
				<FormikAutocompleteField
					key={fieldName}
					name={fieldName}
					label={control.title}
					helperText={control.description}
					variant={variant as any}
					margin={margin}
					fullWidth
					options={control.options}
					disabled={control.options.length === (hasValidDefault ? 1 : 0)}
					disableClearable={hasValidDefault}
					openOnFocus
					getOptionLabel={option => {
						return (
							control.optionTitles?.[
								control.options.findIndex(x => x === option)
							] ?? option
						);
					}}
				/>
			);
		case ControlType.Array:
			const innerControl = control.propertyControl;
			const getName = (item: any) => {
				if (innerControl.type !== ControlType.Object) return item;

				if (item?.name) return item.name;

				const [firstValueKey] = Object.keys(
					innerControl.propertyControls
				).filter(k => item?.[k]);

				return item?.[firstValueKey];
			};

			if (innerControl.type === ControlType.Enum) {
				if (
					innerControl.options.length > 0 &&
					innerControl.options.length <= 6 &&
					// checkbox values will be converted to string and must still match
					typeof innerControl.options[0] === "string"
				) {
					return (
						<FormikFormControlFieldset
							key={fieldName}
							name={fieldName}
							label={control.title ?? innerControl.title}
							helperText={control.description}
							variant={variant as any}
							margin={margin}
							fullWidth
						>
							<FormGroup>
								{(innerControl.options as string[]).map((o, i) => (
									<FormikFormControlLabel
										key={o}
										type="checkbox"
										name={fieldName}
										label={innerControl.optionTitles?.[i] ?? o}
										value={o}
									/>
								))}
							</FormGroup>
						</FormikFormControlFieldset>
					);
				}
				return (
					<FormikAutocompleteField
						key={fieldName}
						name={fieldName}
						label={control.title ?? innerControl.title}
						helperText={control.description}
						variant={variant as any}
						margin={margin}
						fullWidth
						disabled={innerControl.options.length === 0}
						options={innerControl.options}
						getOptionLabel={option => {
							return (
								innerControl.optionTitles?.[
									innerControl.options.findIndex(x => x === option)
								] ?? option
							);
						}}
						multiple
						openOnFocus
					/>
				);
			}

			return (
				<FieldArray name={fieldName}>
					{array => {
						const fieldValue = getIn(array.form.values, array.name);
						const value = Array.isArray(fieldValue)
							? fieldValue
							: fieldValue != null
							? [fieldValue]
							: [];

						const selectedIdx = selected
							? value.findIndex(
									(_, i) =>
										selected === `${fieldName}.${i}` ||
										selected.startsWith(`${fieldName}.${i}.`)
							  )
							: -1;

						if (selectedIdx >= 0) {
							return (
								<ControlField
									name={`${array.name}.${selectedIdx}`}
									control={innerControl}
									variant={variant as any}
									margin={margin}
									onSelect={onSelect}
									selected={selected}
								/>
							);
						}

						return (
							<Box ml={1}>
								<Typography variant="h6">{control.title}</Typography>

								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="flex-end"
								>
									<div>
										<Typography variant="caption">
											Antall: {value.length ?? 0} {control.maxCount && ""}{" "}
											{control.maxCount}
										</Typography>

										{control.description && (
											<Typography component="div" variant="body2">
												{control.description}
											</Typography>
										)}
									</div>

									<Button
										variant="outlined"
										onClick={() => {
											const item = propertyValue(innerControl, {
												id: uuidv4()
											});
											array.push(item);

											if (isComplex(innerControl))
												onSelect(`${fieldName}.${value.length}`);
										}}
										startIcon={<AddIcon />}
									>
										Legg til rad
									</Button>
								</Box>

								<DragDropContext
									onDragEnd={res => {
										if (res.destination)
											array.move(res.source.index, res.destination.index);
									}}
								>
									<Droppable droppableId="droppable">
										{provided => (
											<List ref={provided.innerRef}>
												{value.map((item: any, idx) => {
													const id = item?.id ?? idx;

													return (
														<Draggable key={id} draggableId={id} index={idx}>
															{provided => (
																<ListItem
																	ref={provided.innerRef}
																	button
																	onClick={() =>
																		onSelect(`${fieldName}.${idx}`)
																	}
																	{...provided.draggableProps}
																>
																	<ListItemIcon {...provided.dragHandleProps}>
																		<Tooltip title="Flytt rad">
																			<DragHandleIcon />
																		</Tooltip>
																	</ListItemIcon>

																	{isComplex(innerControl) ? (
																		<ListItemText
																			primary={getName(item)}
																			primaryTypographyProps={{
																				noWrap: true
																			}}
																		/>
																	) : (
																		<ControlField
																			key={`${array.name}.${idx}`}
																			name={`${array.name}.${idx}`}
																			control={innerControl}
																			variant={variant as any}
																			margin={margin}
																			onSelect={onSelect}
																			selected={selected}
																		/>
																	)}

																	<ListItemSecondaryAction>
																		<IconButton
																			onClick={() => array.remove(idx)}
																		>
																			<Tooltip title="Slett rad">
																				<DeleteIcon />
																			</Tooltip>
																		</IconButton>
																	</ListItemSecondaryAction>
																</ListItem>
															)}
														</Draggable>
													);
												})}
												{provided.placeholder}
											</List>
										)}
									</Droppable>
								</DragDropContext>
							</Box>
						);
					}}
				</FieldArray>
			);
		case ControlType.Object:
			return (
				<Box>
					<Typography variant="h6">{control.title}</Typography>
					{control.description && (
						<Typography component="div" variant="body2">
							{control.description}
						</Typography>
					)}
					<ControlFields
						name={fieldName}
						propertyControls={control.propertyControls}
						variant={variant as any}
						margin={margin}
						onSelect={onSelect}
						selected={selected}
					/>
				</Box>
			);
		default:
			return null;
	}
}

function ControlFieldsImpl<P>(props: {
	name?: string;
	propertyControls: PropertyControls<P>;
	variant?: DployTextFieldProps["variant"];
	margin?: "none" | "dense" | "normal";
	onSelect: (name: string) => void;
	selected?: string;
}) {
	const {
		propertyControls,
		name,
		variant = "outlined",
		margin = "normal",
		onSelect,
		selected
	} = props;

	const controls = (Object.keys(propertyControls) as (keyof P)[]).map(
		<K extends keyof P>(controlName: K) => {
			const control = propertyControls[controlName];
			const fieldName = name ? `${name}.${controlName}` : `${controlName}`;
			return { control, fieldName };
		}
	);

	const basicControls = controls.filter(({ control }) => !isComplex(control));

	const complexControls = controls.filter(({ control }) => isComplex(control));

	const selectedItem =
		selected &&
		controls.find(
			c => selected === c.fieldName || selected.startsWith(`${c.fieldName}.`)
		);

	if (selectedItem) {
		return (
			<ControlField
				key={selectedItem.fieldName}
				name={selectedItem.fieldName}
				control={selectedItem.control}
				variant={variant}
				margin={margin}
				onSelect={onSelect}
				selected={selected}
			/>
		);
	}

	return (
		<>
			{basicControls.map(item => (
				<ControlField
					key={item.fieldName}
					name={item.fieldName}
					control={item.control}
					variant={variant}
					margin={margin}
					onSelect={onSelect}
					selected={selected}
				/>
			))}

			{basicControls.length > 0 && complexControls.length > 0 && <Divider />}

			{complexControls.length > 0 && (
				<List>
					{complexControls.map(item => (
						<ListItem
							key={item.fieldName}
							button
							onClick={() => onSelect(item.fieldName)}
						>
							<ListItemText>{item.control?.title}</ListItemText>
						</ListItem>
					))}
				</List>
			)}
		</>
	);
}

export function isComplex(
	control?: PropertyControlType
): control is ObjectControl<any> | ArrayControl<any[]> {
	return (
		control?.type === ControlType.Object ||
		(control?.type === ControlType.Array &&
			control?.propertyControl.type !== ControlType.Enum)
	);
}

ControlFieldsImpl.displayName = "ControlFields";
export const ControlFields = memo(ControlFieldsImpl);
