const marked = require("marked");
const createDOMPurify = require("dompurify");

const DOMPurify = createDOMPurify(window);

function purifyMarked(markdownString, options, callback) {
	return DOMPurify.sanitize(marked(markdownString, options, callback));
}

module.exports = Object.assign(purifyMarked, marked);
