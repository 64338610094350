import { useMemo, useEffect, useCallback, useState } from "react";
import {
	useResource,
	useInvalidator,
	useRetrieve,
	useFetcher
} from "@rest-hooks/core";
import { FormTemplate, CalculationResponse } from "@ploy-lib/types";
import {
	FormTemplateResource,
	AppLoadResource,
	AppInitResource,
	CalculationResource,
	MinimalInitialData
} from "@ploy-lib/rest-resources";
import { fromCGF, CgfResults } from "./fromCGF";
import mapValues from "lodash/mapValues";
import { useCalcRuleDebugManager } from "@ploy-lib/calculation";
import { useTemplateWithDefaults } from "./useTemplateWithDefaults";

const appLoadShape = AppLoadResource.detail();
const appInitShape = AppInitResource.init();
const calculationShape = CalculationResource.detail();
const templateShape = FormTemplateResource.detail();

const emptyArray = [];

export function useCalculationResources(
	applicationNumber?: string,
	productExternalCode?: string,
	formContext?: string,
	context?: string,
	template?: FormTemplate,
	skipInit?: boolean,
	initialData?: MinimalInitialData,
	disallowedFieldRoles: readonly string[] = emptyArray,
	appLoadPayload?: object
) {
	const appShape = applicationNumber ? appLoadShape : appInitShape;
	const appParams = useMemo(
		() =>
			applicationNumber
				? { ...appLoadPayload }
				: productExternalCode
				? {
						productExternalCode,
						fromSession: skipInit ? true : undefined,
						initialData: skipInit ? undefined : initialData
				  }
				: null,
		[
			appLoadPayload,
			applicationNumber,
			initialData,
			productExternalCode,
			skipInit
		]
	);

	// Trigger template request eagerly if we know the context
	useRetrieve(
		templateShape,
		template ||
			!(applicationNumber || productExternalCode) ||
			!formContext ||
			!context
			? null
			: { applicationNumber, productExternalCode, formContext, context }
	);

	const app = useResource(appShape, appParams);

	context = context || (app ? app.vulcanContext : undefined);
	const { applicationStatus, id } = app || {};

	const templateParams =
		template ||
		!(applicationNumber || productExternalCode) ||
		!formContext ||
		!context
			? null
			: { applicationNumber, productExternalCode, formContext, context };
	const [formTemplateCandidate, calc] = useResource(
		[templateShape, templateParams],
		[
			calculationShape,
			!id || !formContext || !context
				? null
				: {
						id,
						context,
						formContext,
						applicationStatus,
						applicationNumber
				  }
		]
	);
	const fetchTemplate = useFetcher(templateShape);
	const fetchApp = useFetcher(appShape);
	const refetchApp = () => {
		fetchTemplate(templateParams);
		fetchApp(appParams!);
	};

	const [updatedCalc, setUpdatedCalc] = useState(calc);

	const currentCalc = updatedCalc?.pk() === calc?.pk() ? updatedCalc : calc;

	const refreshNamespaces = useCallback(
		(updates: CalculationResource) => {
			setUpdatedCalc(state => {
				const old = state?.pk() === calc?.pk() ? state : calc;

				const {
					id,
					context,
					formContext,
					formTemplate,
					...mergeableUpdates
				} = updates;

				return old
					? CalculationResource.merge(old, mergeableUpdates as any)
					: state;
			});
		},
		[calc]
	);

	const formTemplate = template || formTemplateCandidate;

	const invalidateApp = useInvalidator(appShape);
	const invalidateCalc = useInvalidator(calculationShape);

	// Invalidate current cached app/calc requests as they are tied to session
	useEffect(() => () => invalidateApp(appParams), [invalidateApp, appParams]);

	useEffect(
		() => () =>
			invalidateCalc({
				id,
				context,
				formContext,
				applicationStatus,
				applicationNumber
			}),
		[
			invalidateCalc,
			context,
			formContext,
			id,
			applicationStatus,
			applicationNumber
		]
	);

	const {
		enabled: debugEnabled,
		setCalculationResponse
	} = useCalcRuleDebugManager();

	useEffect(() => {
		if (debugEnabled && currentCalc) {
			setCalculationResponse(currentCalc);
		}
	}, [debugEnabled, currentCalc, setCalculationResponse]);

	const [calcSetup, cgf] = useMemo((): [
		CalculationResponse<any> | undefined,
		CgfResults
	] => {
		if (currentCalc) {
			const cgf = fromCGF(
				currentCalc.model.namespacedCGFSections,
				currentCalc.variables,
				currentCalc.services,
				currentCalc.customSubmitFields,
				debugEnabled
			);

			const variables = mapValues(currentCalc.variables, (v, ns) =>
				v.concat(cgf.additionalVariables[ns])
			);

			const services = currentCalc.services;

			return [
				{
					...currentCalc,
					variables,
					services
				},
				cgf
			];
		}
		return [
			undefined,
			{
				cgfMap: {},
				additionalFunctions: [],
				additionalVariables: {},
				initialChecked: {},
				initialValues: {},
				initialVisible: {},
				mapTemplateFieldDefaults: identityToArray,
				serviceBodyFields: {}
			}
		];
	}, [currentCalc, debugEnabled]);

	const templateWithDefaults = useTemplateWithDefaults(
		formTemplate,
		cgf.mapTemplateFieldDefaults,
		formContext,
		disallowedFieldRoles
	);

	return [
		app,
		refetchApp,
		calcSetup,
		templateWithDefaults,
		cgf,
		refreshNamespaces
	] as const;
}

const identityToArray = <T,>(i: T) => [i];
