import React, {
	useCallback,
	useState,
	useEffect,
	useRef,
	forwardRef
} from "react";
import Button, { ButtonProps, ButtonTypeMap } from "@material-ui/core/Button";
import IconButton, {
	IconButtonProps,
	IconButtonTypeMap
} from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { textAlign } from "@material-ui/system";
import clsx from "clsx";

const useStyles = makeStyles({
	root: {
		textAlign: "center"
	},
	label: {
		minWidth: 24
	},
	errorText: {
		color: "red",
		textAlign: "center"
	},
	successText: {
		color: "green",
		textAlign: "center"
	}
});

export interface PendingOptions {
	pending?: boolean;
	success?: boolean;
	error?: boolean;
	helperText?: string;
	onClick?:
		| ((e: React.MouseEvent<any, MouseEvent>) => Promise<void | any>)
		| ((e: React.MouseEvent<any, MouseEvent>) => void);
}

export function usePendingState(props: PendingOptions) {
	const [internalPending, setInternalPending] = useState(false);
	const [internalSuccess, setInternalSuccess] = useState(false);
	const [internalError, setInternalError] = useState(false);
	const [message, setMessage] = useState("");

	const { onClick } = props;

	const mountedRef = useRef(true);
	useEffect(
		() => () => {
			mountedRef.current = false;
		},
		[]
	);

	const handleClick = useCallback(
		async (e: React.MouseEvent<any, MouseEvent>) => {
			if (onClick) {
				try {
					const maybePromise = onClick(e);

					if (maybePromise && typeof maybePromise.then === "function") {
						setInternalPending(true);
						maybePromise
							.then(res => {
								if (!res) return;
								if (mountedRef.current) {
									setInternalSuccess(res.ok);
									if (res.error && res.error.message)
										setMessage(res.error.message);
									else if (res.data && res.data.message)
										setMessage(res.data.message);
									else setMessage("");
									setInternalError(!res.ok);
								}
							})
							.catch(err => {});
					}
				} catch (e: any) {
					if (mountedRef.current) {
						setInternalSuccess(false);
						setInternalError(true);
					}
				} finally {
					if (mountedRef.current) {
						setInternalPending(false);
					}
				}
			}
		},
		[onClick]
	);

	return {
		onClick: handleClick,
		pending: props.pending ?? internalPending,
		success: props.success ?? internalSuccess,
		error: internalError || props.error,
		errorMessage: message || props.helperText
	};
}

export type PendingButtonProps<
	D extends React.ElementType = ButtonTypeMap["defaultComponent"],
	P = {}
> = ButtonProps<D, P & PendingOptions>;

function PendingButtonImpl<
	D extends React.ElementType = ButtonTypeMap["defaultComponent"],
	P = {}
>(props: PendingButtonProps<D, P>, ref: React.Ref<HTMLButtonElement>) {
	const {
		disabled,
		children,
		pending: _0,
		success: _1,
		error: _2,
		helperText: _3,
		...rest
	} = props;
	const classes = useStyles(props);

	const { pending, success, error, errorMessage, onClick } = usePendingState(
		props
	);

	const statusIcon = pending ? (
		<CircularProgress size={24} />
	) : success ? (
		<CheckIcon />
	) : error ? (
		<CloseIcon />
	) : undefined;

	let { startIcon, endIcon } = props;

	let label = children;

	if (startIcon) {
		startIcon = statusIcon ?? startIcon;
	} else if (endIcon) {
		endIcon = statusIcon ?? endIcon;
	} else {
		label = (
			<Box
				position="relative"
				display="inherit"
				alignContent="inherit"
				justifyContent="inherit"
			>
				<Box
					visibility={statusIcon ? "hidden" : "visible"}
					display="inherit"
					alignContent="inherit"
					justifyContent="inherit"
				>
					{children}
				</Box>
				{statusIcon && (
					<Box position="absolute" top={0} bottom={0} left={0} right={0}>
						{statusIcon}
					</Box>
				)}
			</Box>
		);
	}

	return (
		<>
			<Button
				ref={ref}
				{...rest}
				onClick={onClick}
				classes={classes}
				startIcon={startIcon}
				endIcon={endIcon}
				disabled={Boolean(disabled || pending || success)}
			>
				{label}
			</Button>
			{errorMessage && (
				<div
					className={clsx(
						{ [classes.errorText]: error },
						{ [classes.successText]: !error }
					)}
				>
					{errorMessage}
				</div>
			)}
		</>
	);
}

PendingButtonImpl.displayName = "PendingButton";
const PendingButton = forwardRef(PendingButtonImpl) as typeof PendingButtonImpl;

export type PendingIconButtonProps<
	D extends React.ElementType = IconButtonTypeMap["defaultComponent"],
	P = {}
> = IconButtonProps<D, P & PendingOptions>;

function PendingIconButtonImpl<
	D extends React.ElementType = IconButtonTypeMap["defaultComponent"],
	P = {}
>(props: PendingIconButtonProps<D, P>, ref: React.Ref<HTMLButtonElement>) {
	const {
		disabled,
		children,
		pending: _0,
		success: _1,
		error: _2,
		...rest
	} = props;

	const { pending, success, error, onClick } = usePendingState(props);

	return (
		<IconButton
			ref={ref}
			{...rest}
			onClick={onClick}
			disabled={Boolean(disabled || pending || success)}
		>
			{pending ? (
				<CircularProgress color="inherit" size={24} />
			) : success ? (
				<CheckIcon />
			) : error ? (
				<CloseIcon />
			) : (
				children
			)}
		</IconButton>
	);
}

PendingIconButtonImpl.displayName = "PendingIconButton";
const PendingIconButton = forwardRef(
	PendingIconButtonImpl
) as typeof PendingIconButtonImpl;

export { Button, PendingButton, PendingIconButton };
