import React from "react";

import { TemplatePageProps, LayoutPage } from "../../../types";
import { addRegisteredLayout, PanelSettings } from "../layoutDescriptions";
import { Grid, MuiThemeProvider } from "@material-ui/core";
import { Panel, PanelProps } from "../../../panel";
import { TemplatePanel } from "@ploy-lib/types";
import { useStyles, actionsTheme } from "./styles";
import { useSubmitErrorSnackbar } from "../../../hooks/useSubmitErrorSnackbar";
import FormStatus from "../../../components/FormStatus";

export type FullWidthPanels = {
	main: TemplatePanel;
	actions: TemplatePanel;
};
export type FullWidthPanelKeys = keyof FullWidthPanels;
export type FullWidthPage = LayoutPage<FullWidthPanelKeys, FullWidthPanels>;

const mainPanelSettings: PanelSettings = {
	editableOptions: {
		title: false,
		elevation: true,
		square: true
	}
};

const panelSettings: Record<FullWidthPanelKeys, PanelSettings> = {
	main: mainPanelSettings,
	actions: {}
};

addRegisteredLayout({
	name: "FullWidth",
	displayName: "FullWidth",
	panels: panelSettings,
	canDecideMaxWidth: true,
	canSetBackgroundColor: true
});

export interface FullWidthProps extends TemplatePageProps<FullWidthPage> {
	backgroundColor?: string;
}

export const FullWidth = (props: FullWidthProps) => {
	const { panels, maxWidth, backgroundColor } = props;
	const { main, actions } = panels;

	const classes = useStyles(props);
	useSubmitErrorSnackbar();

	const pageDefinedPanelSpecificProps: Partial<PanelProps> = {
		maxWidth
	};

	return (
		<Grid
			id="fullWidthWrapper"
			container
			spacing={3}
			justify="center"
			className={classes.pageMaxWidth}
			style={{
				backgroundColor: backgroundColor,
				borderRadius: 10
			}}
		>
			<Grid item xs={12} md={12}>
				{main && <Panel {...pageDefinedPanelSpecificProps} {...main} />}
			</Grid>
			<Grid item xs={12} md={12}>
				<FormStatus hideStatusMessage />
				{actions && (
					<MuiThemeProvider theme={actionsTheme}>
						<Panel
							transparent
							justify="center"
							{...pageDefinedPanelSpecificProps}
							{...actions}
						/>
					</MuiThemeProvider>
				)}
			</Grid>
		</Grid>
	);
};

FullWidth.displayName = "DployFormFullWidth";
