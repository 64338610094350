import React, { useCallback, useState } from "react";
import {
	useCalculationField,
	useUpdateSavedValues,
	useVariableData
} from "@ploy-lib/calculation";
import { InputFieldProps } from "../types";
import SearchIcon from "@material-ui/icons/Search";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Grid from "@material-ui/core/Grid";

import * as puiFormFields from "@ploy-ui/form-fields";
import * as literalFields from "./literals";

import { DployFormControl } from "@ploy-ui/form-fields";

import {
	useOptionSource,
	useServiceHandler,
	useAlternativeFormik,
	useDisabledOverride
} from "../hooks";
import { FormLabel, FormHelperText, makeStyles } from "@material-ui/core";
import { PendingButton, BisnodeSearchField } from "@ploy-ui/core";

import {
	Fulfillment,
	FulfillmentReadOnly,
	Refinancing,
	RefinancingReadOnly
} from "./Refinancing";
import { CarSummaryField } from "./CarInfoSummary";
import { CommentField } from "./Comment";
import { DriverSelect } from "./DriverSelect";
import { CommisionSection } from "../section/custom";
import ButtonField from "./Buttons/Button";
import { ButtonLink } from "./Buttons/Button";
import { OpenModalButton } from "./Buttons/OpenModalButton";
import { SubmitButton } from "./Buttons/SubmitButton";
import { useServiceHref } from "@ploy-lib/calculation";
import { getIn } from "formik";
import clsx from "clsx";
import { SearchSelectList } from "./SearchSelectList";
import { EquitySlider, TermsYearsSlider } from "./Sliders";
import * as customfields from "./customFields";
import { ErrorDisplay } from "@ploy-lib/types";
import { AppActionButton } from "./Buttons/AppActionButton";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { useIsMountedRef } from "../hooks/useIsMountedRef";
import { MarkdownField } from "./MarkdownField";

const formFields = {
	...puiFormFields,
	...customfields,
	AppActionButton,
	ButtonField,
	ButtonLink,
	OpenModalButton,
	SubmitButton,
	Refinancing,
	RefinancingReadOnly,
	Fulfillment,
	FulfillmentReadOnly,
	CarSummaryField,
	CommentField,
	CommissionSummary: CommisionSection,
	BisnodeSearch: BisnodeSearchField,
	SearchSelectList,
	DriverSelect,
	EquitySlider,
	TermsYearsSlider,
	MarkdownField
};

const formLiterals = {
	...customfields,
	...literalFields,
	CarSummaryLiteral: CarSummaryField,
	CommentLiteral: CommentField
};

const ENTER_KEY = 13;

export function CalculationField(props: InputFieldProps) {
	const {
		className,
		label,
		secondaryLabel,
		form: formikForm,
		field: formikField,
		meta,
		formTemplateFieldId,
		fieldName,
		namespace,
		literal,
		disabled: propsDisabled,
		justify,
		optionSource,
		search,
		save,
		click,
		open,
		renderAs,
		renderAsLiteral,
		margin,
		variant,
		color,
		icon,
		role,
		width,
		suffix,
		prefix,
		placeholder,
		emptyEqualsZero,
		allowEmpty,
		formatString,
		boldText,
		italicText,
		alwaysEnabled,
		errorDisplay = ErrorDisplay.Touched,
		errorDisplayLiteral = ErrorDisplay.Never,
		modalText,
		textAlign,
		mailto,
		disableValidation,
		action,
		textAreaRows,
		...rest
	} = props;

	const calculation = useCalculationField(namespace, fieldName);

	// Avoid loading resources if override has no effect
	const canOverride = !literal && calculation.enabled && !alwaysEnabled;
	const disabledOverrideState = useDisabledOverride(
		canOverride ? fieldName : null
	);

	const disabled =
		((disabledOverrideState.disabled ?? propsDisabled) ||
			!calculation.enabled) &&
		!alwaysEnabled;

	// calculation variables convert NaN to 0 with missing: true
	const variableValue =
		!emptyEqualsZero && calculation.value === 0 && calculation.missing
			? NaN
			: calculation.value;

	const classes = useStyles(props);
	const { form, field } = useAlternativeFormik(
		formikField,
		formikForm,
		variableValue
	);

	const [onClick, searchPending] = useServiceHandler(
		click,
		props.field,
		namespace
	);

	const href = useServiceHref(
		(open && open.namespace) || namespace,
		open && open.service
	);

	const { value: pendingItemsFromService } = useVariableData<string>(
		optionSource && optionSource.pendingFillOptionValues
			? optionSource.pendingFillOptionValues[0]
			: "",
		optionSource && optionSource.pendingFillOptionValues
			? optionSource.pendingFillOptionValues[1]
			: ""
	);

	const optionProps = useOptionSource(
		field,
		props.form,
		namespace,
		optionSource,
		onClick
	);

	const [saveState, setSaveState] = useState<{
		value?: any;
		loading?: boolean;
		success?: boolean;
		error?: Error;
	}>({});

	const isMountedRef = useIsMountedRef();

	const updateSavedValue = useUpdateSavedValues();
	const onSave = useCallback(async () => {
		try {
			const pristine =
				getIn(props.form.initialValues, field.name) === field.value;
			const saved = saveState.value === field.value;
			const hasErrors = getIn(props.form.errors, field.name);
			if (pristine || saved || hasErrors || !isMountedRef.current) return;

			setSaveState({ loading: true });

			const response = await fetch(
				legacyApiResourceUrl("AppChange/UpdateFieldWithEvent"),
				{
					method: "POST",
					body: new Blob(
						[
							JSON.stringify({
								value: field.value,
								field: fieldName
							})
						],
						{ type: "application/json" }
					),
					headers: new Headers({ accept: "application/json" }),
					credentials: "include"
				}
			);

			const data = response.headers.get("content-type")?.includes("json")
				? await response.json()
				: undefined;

			if (!data.error) {
				updateSavedValue({
					name: fieldName,
					namespace,
					value: field.value
				});

				if (!isMountedRef.current) return;
				setSaveState({ success: true, value: field.value });
			}
		} catch (error: any) {
			if (!isMountedRef.current) return;

			setSaveState({ error });
		}
	}, [
		props.form.initialValues,
		props.form.errors,
		field.name,
		field.value,
		saveState.value,
		isMountedRef,
		fieldName,
		updateSavedValue,
		namespace
	]);

	const { setFieldTouched } = form;

	const searchOnEnter = useCallback(
		async (e: React.KeyboardEvent) => {
			const key = e.which || e.keyCode;
			if (key === ENTER_KEY && onClick) {
				await onClick();
				setFieldTouched(field.name, true);
			}
		},
		[field.name, setFieldTouched, onClick]
	);

	let fieldComponent =
		renderAs && formFields[renderAs] ? renderAs : "TextField";

	// Override TextField with SelectField if select items list is defined
	if (
		(fieldComponent === "TextField" || fieldComponent === "NumberField") &&
		optionProps &&
		optionProps.items.length > 0
	)
		fieldComponent = "SelectField";

	// Map field component to literal component
	let literalComponent =
		renderAsLiteral || fieldComponent.replace("Field", "Literal");

	literalComponent = formLiterals[literalComponent]
		? literalComponent
		: "TextLiteral";

	let FieldComponent =
		literal && renderAs !== "AppActionButton"
			? formLiterals[literalComponent]
			: formFields[fieldComponent];

	if (!FieldComponent) {
		console.error(
			`Unknown ${literal ? "literal " : ""}field component`,
			literal ? literalComponent : fieldComponent,
			"for field",
			field,
			calculation
		);
		FieldComponent = literal ? formLiterals.TextLiteral : formFields.TextField;
	}

	const errorDisplayToUse = literal ? errorDisplayLiteral : errorDisplay;

	let isButton =
		fieldComponent === "ButtonLink" ||
		fieldComponent === "ButtonField" ||
		fieldComponent === "SubmitButton";
	const fieldElement = (
		<FieldComponent
			{...optionProps}
			alwaysEnabled={props.alwaysEnabled}
			className={classes.field}
			role={role}
			label={label}
			icon={icon}
			variant={variant}
			color={color}
			multiple={props.multiple}
			row={props.horizontal}
			manual={props.manual}
			margin={margin}
			onKeyPress={searchOnEnter}
			onBlur={save ? onSave : undefined}
			onClick={isButton ? onClick : null}
			disabled={disabled}
			pending={
				searchPending ||
				pendingItemsFromService === "1" ||
				disabledOverrideState.loading
			}
			options={{
				...rest,
				href: rest.href || href
			}}
			emptyEqualsZero={emptyEqualsZero}
			field={field}
			form={form}
			suffix={suffix === "_" ? "" : suffix}
			prefix={prefix}
			clearable={allowEmpty}
			fullWidth={width !== "auto"}
			placeholder={placeholder}
			formatString={formatString}
			boldText={boldText}
			italicText={italicText}
			secondaryLabel={secondaryLabel}
			modalText={modalText}
			errorDisplay={errorDisplayToUse}
			textAlign={textAlign}
			mailto={mailto}
			literal={literal}
			disableValidation={disableValidation}
			action={action}
			textAreaRows={textAreaRows}
		/>
	);

	let buttonElement: JSX.Element | undefined = undefined;

	if (!buttonElement && save && save.type === "button" && !literal) {
		const pristine =
			getIn(props.form.initialValues, field.name) === field.value;
		const saved = saveState.value === field.value;
		const fieldError = getIn(props.form.errors, field.name);

		buttonElement = (
			<DployFormControl
				variant={variant as any}
				margin={margin as any}
				error={Boolean(saveState.error)}
				className={classes.saveButtonContainer}
			>
				{label && <FormLabel> </FormLabel>}
				<PendingButton
					pending={saveState.loading}
					disabled={
						(pristine && !saveState.success && !alwaysEnabled) || fieldError
					}
					success={saved}
					error={Boolean(saveState.error)}
					variant="contained"
					color="primary"
					size="large"
					// onClick={onSave}
					className={classes.searchButton}
					fullWidth
				>
					{secondaryLabel && secondaryLabel.length > 0 ? (
						secondaryLabel
					) : (
						<ArrowForwardIcon />
					)}
				</PendingButton>
				{saveState.error && (
					<FormHelperText>{saveState.error.message}</FormHelperText>
				)}
			</DployFormControl>
		);
	}

	const handleMouseDown = (event: { preventDefault: () => void }) => {
		event.preventDefault();
	};

	if (!buttonElement && click && !isButton && !literal) {
		buttonElement = (
			<DployFormControl
				variant={variant as any}
				margin={margin as any}
				className={classes.searchButtonContainer}
			>
				{label && <FormLabel> </FormLabel>}
				<PendingButton
					pending={searchPending}
					variant="outlined"
					size="large"
					onClick={async () => {
						if (onClick) await onClick();
						form.setFieldTouched(field.name, true);
					}}
					success={false}
					onMouseDown={handleMouseDown}
					disabled={disabled}
					className={classes.searchButton}
					fullWidth
				>
					{secondaryLabel && secondaryLabel.length > 0 ? (
						secondaryLabel
					) : (
						<SearchIcon />
					)}
				</PendingButton>
			</DployFormControl>
		);
	}

	return (
		<Grid
			container
			wrap="nowrap"
			justify={justify}
			className={clsx(className, classes.root)}
		>
			{fieldElement && buttonElement ? (
				<>
					<Grid item xs={9}>
						{fieldElement}
					</Grid>
					<Grid item xs={3}>
						{buttonElement}
					</Grid>
				</>
			) : fieldElement ? (
				fieldElement
			) : buttonElement ? (
				buttonElement
			) : null}
		</Grid>
	);
}

const useStyles = makeStyles(
	{
		root: {},
		field: {},
		searchButton: {
			padding: 0,
			minWidth: 0
		},
		searchButtonContainer: {
			width: "100%"
		},
		saveButton: {},
		saveButtonContainer: {
			width: "100%"
		}
	},
	{ name: "DployCalculationField" }
);

CalculationField.displayName = "DployCalculationField";

export default CalculationField;
