import { useEffect } from "react";

/*declare global {
	interface Window {
		dataLayer?: any[];
	}
}*/

export default function GoogleTagManager({
	GoogleTagManagerCode,
	CustomerCategoryId
}) {
	useEffect(() => {
		initGoogleTagManager(GoogleTagManagerCode);
	}, [GoogleTagManagerCode]);

	useEffect(() => {
		gtmManagerIdentity(CustomerCategoryId);
	}, [CustomerCategoryId]);

	return null;

	// <noscript> will only have an effect if we do server side rendering
	// (if javascript is disabled react will not run)

	// const noScriptGtm =
	// 	"https://www.googletagmanager.com/ns.html?id=" + GoogleTagManagerCode;

	// return (
	// 	<noscript>
	// 		<iframe
	// 			title="noscriptGtm"
	// 			src={noScriptGtm}
	// 			height="0"
	// 			width="0"
	// 			style={{ display: "none", visibility: "hidden" }}
	// 		/>
	// 	</noscript>
	// );
}

const gtmManagerIdentity = (type: any) => {
	if (window["dataLayer"] && type) {
		//do nothing if type is falsy or 0
		let typeString;

		switch (type) {
			case 1:
				typeString = "bil";
				break;
			case 2:
				typeString = "gjenstand";
				break;
			default:
				break;
		}

		window["dataLayer"].push({
			event: "identifisering",
			userType: typeString
		});
	}
};

const initGoogleTagManager = (code: any) => {
	window["dataLayer"] = window["dataLayer"] || [];

	window["dataLayer"].push({
		"gtm.start": new Date().getTime(),
		event: "gtm.js"
	});

	const firstScriptTag = document.getElementsByTagName("script")[0];

	const scriptTag = document.createElement("script");

	scriptTag.async = true;
	scriptTag.src = `https://www.googletagmanager.com/gtm.js?id=${code}`;

	firstScriptTag &&
		firstScriptTag.parentNode &&
		firstScriptTag.parentNode.insertBefore(
			scriptTag,
			firstScriptTag.nextSibling
		);
};
