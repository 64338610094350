import React, { useCallback, useState, useMemo } from "react";
import { useFetcher } from "@rest-hooks/core";
import {
	Grid,
	Typography,
	Checkbox,
	FormControlLabel,
	FormLabel,
	FormGroup,
	FormControl,
	FormHelperText,
	IconButton,
	Icon
} from "@material-ui/core";
import { PendingButton } from "../Button";
import {
	ApplicationSignerResource,
	SigningDocument,
	ApplicationSigner,
	ID,
	ApplicationSignerDocumentGroup,
	SignerType
} from "@ploy-lib/rest-resources";
import { TextField, KeyboardDatePickerField } from "@ploy-ui/form-fields";
import { Formik, Form, Field, FormikHelpers, FieldArray } from "formik";

import HelpIcon from "@material-ui/icons/Help";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { object, string, StringSchema, array } from "yup";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { createValidationHelpers } from "@ploy-lib/validation-helpers";

const validationMessages = defineMessages({
	required: {
		id: "core.application-signing.signer-form.required",
		defaultMessage: "Må angis",
		description: "Application signing form field required error"
	},
	illegalChangeSsn: {
		id: "core.application-signing.signer-form.illegal-change-ssn",
		defaultMessage: "Kan ikke endres",
		description: "Application signing form illegal SSN change error"
	},
	invalidFirstName: {
		id: "core.application-signing.signer-form.invalid-firstname",
		defaultMessage: "Må angi fornavn",
		description: "Application signing form invalid firstname error"
	},
	invalidLastName: {
		id: "core.application-signing.signer-form.invalid-lastname",
		defaultMessage: "Må angi etternavn",
		description: "Application signing form invalid lastaname error"
	},
	invalidSsn: {
		id: "core.application-signing.signer-form.invalid-ssn",
		defaultMessage: "Ugyldig personnummer",
		description: "Application signing form invalid SSN error"
	},
	invalidBirthDate: {
		id: "core.application-signing.signer-form.invalid-birthdate",
		defaultMessage: "Ugyldig fødselsdato (dd.mm.åååå)",
		description: "Application signing form invalid SSN error"
	},
	invalidPhone: {
		id: "core.application-signing.signer-form.invalid-phone",
		defaultMessage: "Ugyldig telefonnummer",
		description: "Application signing form invalid phonenumber error"
	},
	invalidEmail: {
		id: "core.application-signing.signer-form.invalid-email",
		defaultMessage: "E-postadressen er ugyldig",
		description: "Application signing form invalid email error"
	},
	invalidPeriod: {
		id: "core.application-signing.signer-form.invalid-period",
		defaultMessage: "Gydlig til dato er ugyldig",
		description: "Application signing form invalid valid period error"
	},
	requiredPhoneOrEmail: {
		id: "core.application-signing.signer-form.required-phone-or-email",
		defaultMessage: "Må ha telefonnummer eller E-post",
		description: "Application signing form field required error"
	}
});
const messages = defineMessages({
	ssnMessage: {
		id: "core.application-signing.signer-form.fetch-ssn-error",
		defaultMessage: "Fant ikke personnummer for kunde"
	},
	firstname: {
		id: "core.application-signing.signer-form.field-firstname",
		defaultMessage: "Fornavn"
	},
	lastname: {
		id: "core.application-signing.signer-form.field-lastname",
		defaultMessage: "Etternavn"
	},
	role: {
		id: "core.application-signing.signer-form.field-role",
		defaultMessage: "Rolle"
	},
	birthdate: {
		id: "core.application-signing.signer-form.field-birthdate",
		defaultMessage: "Fødselsdato"
	},
	ssn: {
		id: "core.application-signing.signer-form.field-ssn",
		defaultMessage: "Fødselsnummer"
	},
	ssnHelperText: {
		id: "core.application-signing.signer-form.fetch-ssn-helper-text",
		defaultMessage:
			"Fødselsnummer kan lastes ned ved å trykke på knappen 'Hent'. Etternavn, fornavn og fødselsdato må i forkant være utfylt. Fødselsnummer er nødvendig for å kunne starte signering med BankID."
	},
	ssnPlaceholder: {
		id: "core.application-signing.signer-form.field-ssn-placeholder",
		defaultMessage: "ddmmåååånnnnn"
	},
	mobile: {
		id: "core.application-signing.signer-form.field-mobile",
		defaultMessage: "Mobil"
	},
	email: {
		id: "core.application-signing.signer-form.field-email",
		defaultMessage: "E-postadresse"
	},
	validTo: {
		id: "core.application-signing.signer-form.invalid-to-date",
		defaultMessage: "Gyldig til"
	},
	validToPlaceholder: {
		id: "core.application-signing.signer-form.invalid-to-date-placeholder",
		defaultMessage: "dd.mm.åååå"
	},
	mustSign: {
		id: "core.application-signing.signer-form.should-sign",
		defaultMessage: "Må signere"
	},
	hasSigned: {
		id: "core.application-signing.signer-form.has-signed",
		defaultMessage: "Har signert"
	},
	update: {
		id: "core.application-signing.signer-form.update",
		defaultMessage: "Oppdater"
	},
	add: {
		id: "core.application-signing.signer-form.append",
		defaultMessage: "Legg til"
	}
});

export interface AppSignerFormProps {
	applicationNumber: ID;
	vendor?: boolean;
	signer?: ApplicationSigner;
	documents: SigningDocument[];
	canEditDocuments: boolean;
	canEditIndividualDocuments: boolean;
	onSuccess?: () => void;
	esignType: number;
	ssnServiceEnabled: boolean;
	disabled: boolean;
	requirePhoneOrEmail: boolean;
	showValidPeriod?: boolean;
	mustSignDefaultChecked: boolean;
	guarantorId?: number;
}

function AppSignerForm(props: AppSignerFormProps) {
	const {
		applicationNumber,
		documents,
		vendor = false,
		onSuccess,
		esignType,
		ssnServiceEnabled,
		canEditDocuments,
		canEditIndividualDocuments,
		disabled = false,
		showValidPeriod,
		mustSignDefaultChecked,
		requirePhoneOrEmail,
		guarantorId
	} = props;

	const intl = useIntl();
	const validation = createValidationHelpers(intl.locale);

	let signer = props.signer
		? props.signer
		: {
				...ApplicationSignerResource.fromJS(
					mustSignDefaultChecked ? { documents: documents } : {}
				)
		  };

	const [ssnRegisteredValue, setSsnRegisteredValue] = useState(
		signer.ssnIsHidden ? signer.ssn : undefined
	);
	const [applicationSignerId, setApplicationSignerId] = useState(
		signer.applicationSignerId
	);

	const ssnRegistered = Boolean(ssnRegisteredValue);

	const unknownSsnAllowed = esignType !== 0;
	const disableName = unknownSsnAllowed && !vendor && signer.copiedFromCustomer;
	const hideRoleAndSsn = !vendor && unknownSsnAllowed;
	const hideBirthDate = vendor || !ssnServiceEnabled || unknownSsnAllowed;

	const signerSchema = useMemo(
		() =>
			object().shape(
				{
					firstName: string()
						.trim()
						.required(intl.formatMessage(validationMessages.required))
						.min(1, intl.formatMessage(validationMessages.invalidFirstName)),
					lastName: string()
						.trim()
						.required(intl.formatMessage(validationMessages.required))
						.min(1, intl.formatMessage(validationMessages.invalidLastName)),
					birthDate: hideBirthDate
						? string()
						: string().when("ssn", (ssn, schema) => {
								return ssn === undefined
									? schema.required(
											intl.formatMessage(validationMessages.required)
									  )
									: schema;
						  }),
					ssn: hideRoleAndSsn
						? string()
						: ssnRegistered
						? string().oneOf(
								[ssnRegisteredValue],
								intl.formatMessage(validationMessages.illegalChangeSsn)
						  )
						: string()
								.required(intl.formatMessage(validationMessages.required))
								.test(
									"Validate SSN",
									intl.formatMessage(validationMessages.invalidSsn),
									ssn => validation.validSsn(ssn)
								),
					phone: requirePhoneOrEmail
						? string().when(
								["email", "documents"],
								(
									email: string,
									documents: ApplicationSignerDocumentGroup[],
									schema: StringSchema
								) => {
									return !email && documents.length > 0
										? schema
												.required(
													intl.formatMessage(
														validationMessages.requiredPhoneOrEmail
													)
												)
												.test(
													"Validate phone number",
													intl.formatMessage(validationMessages.invalidPhone),
													phoneNumber => validation.phoneValidation(phoneNumber)
												)
										: schema.matches(
												/\d{8}/,
												intl.formatMessage(validationMessages.invalidPhone)
										  );
								}
						  )
						: string()
								.required(intl.formatMessage(validationMessages.required))
								.test(
									"Validate phone number",
									intl.formatMessage(validationMessages.invalidPhone),
									phoneNumber => validation.phoneValidation(phoneNumber)
								),
					email: requirePhoneOrEmail
						? string().when(
								["phone", "documents"],
								(
									phone: string,
									documents: ApplicationSignerDocumentGroup[],
									schema: StringSchema
								) => {
									return !phone && documents.length > 0
										? schema
												.email()
												.required(
													intl.formatMessage(
														validationMessages.requiredPhoneOrEmail
													)
												)
										: schema.email(
												intl.formatMessage(validationMessages.invalidEmail)
										  );
								}
						  )
						: string()
								.required(intl.formatMessage(validationMessages.required))
								.email(intl.formatMessage(validationMessages.invalidEmail)),
					validToDate: string(),
					documents: array()
				},
				[["email", "phone"]]
			),
		[
			intl,
			hideBirthDate,
			hideRoleAndSsn,
			ssnRegistered,
			ssnRegisteredValue,
			requirePhoneOrEmail,
			validation
		]
	);

	const createSigner = useFetcher(ApplicationSignerResource.create());
	const updateSigner = useFetcher(ApplicationSignerResource.update());

	const onSubmit = useCallback(
		async (values: ApplicationSigner, formik: FormikHelpers<any>) => {
			const signer = {
				...values,
				isVendorSigner: vendor,
				parentCustomerId: guarantorId,
				signerType: guarantorId
					? SignerType.GuarantorSigner
					: values.signerType,
				applicationNumber,
				applicationSignerId,
				firstName: values.firstName.trim(),
				lastName: values.lastName.trim()
			};
			const params = { applicationSignerId };

			try {
				let result: any;
				signer.mustSign = signer.documents.length > 0;
				if (applicationSignerId == null)
					result = await createSigner({}, signer);
				else result = await updateSigner(params, signer);

				if (result && result.message)
					formik.setStatus({ success: true, message: result.message });

				if (onSuccess) onSuccess();
			} catch (e: any) {
				formik.setStatus({ success: false, message: e.message });
			}

			formik.setSubmitting(false);
		},
		[
			vendor,
			guarantorId,
			applicationNumber,
			applicationSignerId,
			createSigner,
			updateSigner,
			onSuccess
		]
	);

	const [pendingSsn, setPendingSsn] = useState(false);

	const createSsSigner = useFetcher(ApplicationSignerResource.ssnCreate());
	const updateSsnSigner = useFetcher(ApplicationSignerResource.ssnUpdate());

	const classes = useStyles(props);

	const onSubmitSsn = useCallback(
		async (values: ApplicationSigner, formik: FormikHelpers<any>) => {
			const signer = {
				...values,
				isVendorSigner: vendor,
				applicationNumber,
				applicationSignerId
			};

			const errors = await formik.validateForm();
			if (errors.name || errors.birthDate) {
				return;
			}

			setPendingSsn(true);
			try {
				let result: any;

				if (applicationSignerId == null)
					result = await createSsSigner({}, signer);
				else result = await updateSsnSigner({ applicationSignerId }, signer);

				setSsnRegisteredValue(result.ssn);
				setApplicationSignerId(result.applicationSignerId);
				formik.setFieldValue("ssn", result.ssn);
				formik.setStatus({ ssnSuccess: true, ssnMssage: result.message });
			} catch (e: any) {
				formik.setStatus({
					ssnSuccess: false,
					ssnMssage: intl.formatMessage(messages.ssnMessage)
				});
			}

			setPendingSsn(false);
		},
		[
			vendor,
			applicationNumber,
			applicationSignerId,
			createSsSigner,
			updateSsnSigner,
			intl
		]
	);
	const [showSsnHelperText, setShowSsnHelperText] = useState(false);

	return (
		<Formik
			validationSchema={signerSchema}
			initialValues={signer}
			onSubmit={onSubmit}
			enableReinitialize
		>
			{formik => (
				<Form>
					<Grid container spacing={1}>
						<Grid container spacing={1}>
							<Grid item xs={12} md={6}>
								<Field
									name="firstName"
									label={intl.formatMessage(messages.firstname)}
									variant="outlined"
									margin="dense"
									component={TextField}
									disabled={signer.lockPersonalInfo || disableName || disabled}
									fullWidth
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<Field
									name="lastName"
									label={intl.formatMessage(messages.lastname)}
									component={TextField}
									fullWidth
									variant="outlined"
									margin="dense"
									disabled={signer.lockPersonalInfo || disableName || disabled}
								/>
							</Grid>
						</Grid>
						{hideRoleAndSsn ? null : (
							<Grid container spacing={1}>
								<Grid item xs={12} md={6}>
									<Field
										name="role"
										label={intl.formatMessage(messages.role)}
										component={TextField}
										fullWidth
										variant="outlined"
										margin="dense"
										disabled={signer.lockPersonalInfo || disabled}
									/>
								</Grid>
							</Grid>
						)}
						{hideBirthDate ? null : (
							<Grid container spacing={1}>
								<Grid item xs={6}>
									<Field
										name="birthDate"
										label={intl.formatMessage(messages.birthdate)}
										component={KeyboardDatePickerField}
										variant="outlined"
										margin="dense"
										fullWidth
										disabled={disabled}
									/>
								</Grid>
							</Grid>
						)}
						{hideRoleAndSsn ? null : (
							<Grid container spacing={1}>
								<Grid item xs={6}>
									<Field
										name="ssn"
										variant="outlined"
										margin="dense"
										label={intl.formatMessage(messages.ssn)}
										placeholder={intl.formatMessage(messages.ssnPlaceholder)}
										component={TextField}
										disabled={ssnRegistered || disabled}
										fullWidth
									/>
								</Grid>
								{ssnRegistered || !ssnServiceEnabled ? null : (
									<Grid item xs={6}>
										<FormControl
											className={classes.ssnContainer}
											margin="dense"
											variant="outlined"
											fullWidth
											error={
												!showSsnHelperText &&
												formik.status &&
												formik.status.ssnSuccess === false
											}
										>
											<PendingButton
												type="button"
												variant="contained"
												color="primary"
												size="large"
												pending={pendingSsn}
												success={
													formik.status && formik.status.ssnSuccess === true
												}
												disabled={disabled}
												onClick={() => onSubmitSsn(formik.values, formik)}
											>
												<FormattedMessage
													id="core.application-signing.signer-form.fetch-ssn-button"
													defaultMessage="Hent"
												/>
											</PendingButton>
											<IconButton
												onClick={() => setShowSsnHelperText(!showSsnHelperText)}
											>
												<Icon>
													{showSsnHelperText ? (
														<HelpIcon />
													) : (
														<HelpOutlineIcon />
													)}
												</Icon>
											</IconButton>
											{(formik.status && formik.status.ssnMssage) ||
											showSsnHelperText ? (
												<FormHelperText className={classes.helperText}>
													{showSsnHelperText
														? intl.formatMessage(messages.ssnHelperText)
														: formik.status.ssnMssage}
												</FormHelperText>
											) : null}
										</FormControl>
									</Grid>
								)}
							</Grid>
						)}
						<Grid container spacing={1}>
							<Grid item xs={6}>
								<Field
									name="phone"
									variant="outlined"
									margin="dense"
									label={intl.formatMessage(messages.mobile)}
									component={TextField}
									fullWidth
									disabled={disabled || signer.lockContactInfo}
								/>
							</Grid>
						</Grid>

						<Grid container spacing={1}>
							<Grid item xs={12} md={8}>
								<Field
									name="email"
									variant="outlined"
									margin="dense"
									label={intl.formatMessage(messages.email)}
									component={TextField}
									fullWidth
									disabled={disabled || signer.lockContactInfo}
								/>
							</Grid>
						</Grid>

						{showValidPeriod && vendor && (
							<Grid container spacing={1}>
								<Grid item xs={12} md={8}>
									<Field
										name="validToDate"
										variant="outlined"
										margin="dense"
										label={intl.formatMessage(messages.validTo)}
										placeholder={intl.formatMessage(
											messages.validToPlaceholder
										)}
										component={TextField}
										fullWidth
										disabled={disabled}
									/>
								</Grid>
							</Grid>
						)}
						<FieldArray
							name="documents"
							render={arrayHelpers =>
								documents.map(d => {
									const index = formik.values.documents.findIndex(
										x => x.documentCode === d.documentCode
									);
									const docIsSignedByThisSigner = Boolean(
										applicationSignerId &&
											d.signed &&
											d.signers.includes(applicationSignerId)
									);

									return (
										<Grid item xs={12} key={d.documentCode}>
											<FormControl
												component={"fieldset"}
												margin="dense"
												variant="outlined"
											>
												<FormLabel component="legend">
													{d.documentName}
												</FormLabel>
												<FormGroup row>
													{!canEditDocuments ? null : (
														<FormControlLabel
															label={intl.formatMessage(messages.mustSign)}
															disabled={
																disabled ||
																!canEditIndividualDocuments ||
																!canEditDocuments ||
																docIsSignedByThisSigner ||
																!signer.canSetMustSign
															}
															control={
																<Checkbox
																	checked={index >= 0}
																	onChange={() => {
																		if (index >= 0) {
																			arrayHelpers.remove(index);
																		} else {
																			arrayHelpers.push(d);
																		}
																	}}
																/>
															}
														/>
													)}
													<FormControlLabel
														disabled={!docIsSignedByThisSigner}
														label={intl.formatMessage(messages.hasSigned)}
														control={
															<Checkbox
																checked
																indeterminate={!docIsSignedByThisSigner}
															/>
														}
													/>
												</FormGroup>
											</FormControl>
										</Grid>
									);
								})
							}
						/>
						{!canEditIndividualDocuments && (
							<Grid item xs={12}>
								<FormControl
									component="fieldset"
									margin="dense"
									variant="outlined"
								>
									<FormLabel component="legend">
										<FormattedMessage
											id="core.application-signing.signer-form.should-sign-all.legend"
											defaultMessage="Alle dokumenter"
										/>
									</FormLabel>
									<FormGroup row>
										{!canEditDocuments ? null : (
											<FormControlLabel
												label={intl.formatMessage(messages.mustSign)}
												disabled={
													disabled ||
													!canEditDocuments ||
													documents.every(d =>
														Boolean(
															applicationSignerId &&
																d.signed &&
																d.signers.includes(applicationSignerId)
														)
													) ||
													!signer.canSetMustSign
												}
												control={
													<Checkbox
														checked={documents.every(d =>
															formik.values.documents.some(
																x => x.documentCode === d.documentCode
															)
														)}
														onChange={() => {
															if (
																!documents.every(d =>
																	formik.values.documents.some(
																		x => x.documentCode === d.documentCode
																	)
																)
															) {
																formik.setFieldValue("documents", documents);
															} else {
																formik.setFieldValue("documents", []);
															}
														}}
													/>
												}
											/>
										)}
										<FormControlLabel
											disabled={
												!documents.some(d =>
													Boolean(
														applicationSignerId &&
															d.signed &&
															d.signers.includes(applicationSignerId)
													)
												)
											}
											label={intl.formatMessage(messages.hasSigned)}
											control={
												<Checkbox
													checked
													indeterminate={
														!documents.every(d =>
															Boolean(
																applicationSignerId &&
																	d.signed &&
																	d.signers.includes(applicationSignerId)
															)
														)
													}
												/>
											}
										/>
									</FormGroup>
								</FormControl>
							</Grid>
						)}

						<Grid item xs={12} sm={8} md={4}>
							<PendingButton
								type="submit"
								variant="contained"
								color="primary"
								fullWidth
								disabled={pendingSsn || disabled}
								success={formik.status && formik.status.success}
								pending={formik.isSubmitting}
								size="large"
							>
								{applicationSignerId
									? intl.formatMessage(messages.update)
									: intl.formatMessage(messages.add)}
							</PendingButton>
						</Grid>

						<Grid item xs={12} md={8}>
							{formik.status && (
								<Typography color={formik.status.success ? "error" : "inherit"}>
									{formik.status.message}
								</Typography>
							)}
						</Grid>
					</Grid>
				</Form>
			)}
		</Formik>
	);
}

const useStyles = makeStyles({
	ssnContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "left"
	},
	helperText: {
		margin: 0
	}
});

AppSignerForm.displayName = "DployAppSignerForm";

export { AppSignerForm };
