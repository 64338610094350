import React, { useMemo, useState, useCallback } from "react";
import {
	Formik,
	Form,
	FastField,
	useFormikContext,
	FormikHelpers
} from "formik";
import { useIntl, defineMessages } from "react-intl";

import {
	Grid,
	FormControl,
	FormHelperText,
	makeStyles
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import {
	TextField,
	KeyboardDatePickerField,
	SelectField
} from "@ploy-ui/form-fields";
import { PendingButton, PendingButtonProps } from "@ploy-ui/core";
import { object, string } from "yup";
import { RowProps } from "@ploy-ui/core";
import { ApplicationStatusId } from "@ploy-lib/types";
import {
	ApplicationInfo,
	CarOrderDetails,
	Driver
} from "@ploy-lib/rest-resources";

import { Edit as EditIcon } from "@material-ui/icons/";
import { AddDriver } from "./CarOrderAddDriver";
import { legacyApiResourceUrl } from "@ploy-lib/core";

interface CarOrderConfirmation {
	deliveryDate: Date | string;
	carProdNr: string;
	selectedDriver: number | string;
	applicationId: number | string;
}

interface CarRegNr {
	regNr: string;
	applicationId: number | string;
}

const messages = defineMessages({
	invalidDate: {
		id: "form_fields.datetimepicker.missing",
		defaultMessage: "Dato mangler",
		description: "text for missing date"
	},
	invalidCarProdNumber: {
		id: "form_fields.carProdNumber.missing",
		defaultMessage: "Mangler Ordrenummer",
		description: "text for missing production number"
	},
	invalidRegnr: {
		id: "form_fields.carRegNr.missing",
		defaultMessage: "Regnr er ugyldig",
		description: "registration number is invalid"
	}
});

const useStyles = makeStyles({
	editButton: {
		marginLeft: "-1em"
	}
});

const CarOrderActions = (
	props: RowProps<ApplicationInfo, CarOrderDetails> & {
		reload?: () => Promise<any>;
		reloadData?: (params: object, body?: object) => Promise<any>;
	}
) => {
	const { loadedData, reload, rowData, reloadData } = props;

	const intl = useIntl();

	const [addDriver, setAddDriver] = useState(false);

	const classes = useStyles();

	const CarOrderSchema = useMemo(
		() =>
			object<Partial<CarOrderConfirmation>>().shape({
				deliveryDate: string().required(
					intl.formatMessage(messages.invalidDate)
				),
				carProdNr: string().ensure()
			}),
		[intl]
	);
	const CarRegSchema = useMemo(
		() =>
			object<Partial<CarRegNr>>().shape({
				regNr: string()
					.required(intl.formatMessage(messages.invalidRegnr))
					.matches(/^([A-Z]{2})([0-9]{5})$/)
			}),
		[intl]
	);

	const addDriverButton = useCallback(() => {
		setAddDriver(!addDriver);
	}, [addDriver]);

	const submitCarOrder = useCallback(
		async (data, formik) => {
			await onConfirm(data, formik);
			await reload?.();
			await reloadData?.({ applicationId: rowData.applicationId });
		},
		[rowData, reloadData, reload]
	);

	const submitRegNr = useCallback(
		async (data, formik) => {
			await onConfirmRegNr(data, formik);
			await reload?.();
			await reloadData?.({ applicationId: rowData.applicationId });
		},
		[reload, rowData, reloadData]
	);

	if (!loadedData) return null;

	if (addDriver)
		return (
			<AddDriver
				loadedData={loadedData}
				addDriver={addDriverButton}
				reloadData={reloadData}
			/>
		);

	return (
		<div>
			<Formik<Partial<CarOrderConfirmation>>
				initialValues={{
					deliveryDate: loadedData.deliveryDate || "",
					carProdNr: loadedData.car.ordNr || "",
					selectedDriver: loadedData.selectedDriver.salepersonId || "",
					applicationId: loadedData.applicationId || ""
				}}
				onSubmit={submitCarOrder}
				validationSchema={CarOrderSchema}
				validateOnMount
			>
				{({ isSubmitting, status = {} }) => (
					<Form>
						<Grid container spacing={2} alignItems="flex-end">
							{loadedData.avaiableDrivers.length >= 1 && (
								<Grid item xs={12} md={6} lg={3}>
									<FastField
										name="selectedDriver"
										label={
											<FormattedMessage
												id="dealer.driver"
												description="text for driver"
												defaultMessage="Fører"
											/>
										}
										variant="outlined"
										helperText=" "
										items={loadedData.avaiableDrivers}
										getItemValue={(item: Driver) => item.salepersonId}
										getItemLabel={(item: Driver) => item.fullName}
										component={SelectField}
										disabled={
											isSubmitting || loadedData.disableSendOrderSection
										}
										margin="normal"
										fullWidth
									/>
								</Grid>
							)}

							{!loadedData.disableSendOrderSection ? (
								<Grid item>
									<FormControl margin="normal">
										<PendingButton
											className={classes.editButton}
											type="button"
											size="large"
											onClick={addDriverButton}
										>
											<EditIcon />
										</PendingButton>
										<FormHelperText
											color={status && status.success ? "inherit" : "error"}
										>
											{""}
										</FormHelperText>
									</FormControl>
								</Grid>
							) : null}
							<Grid item xs={12} md={6} lg={3}>
								<FastField
									name="deliveryDate"
									label={
										<FormattedMessage
											id="dealer.delivery.date"
											description="text for deliverydate"
											defaultMessage="Leveringsdato"
										/>
									}
									component={KeyboardDatePickerField}
									disabled={isSubmitting || loadedData.disableSendOrderSection}
									margin="normal"
									variant="outlined"
									helperText=" "
									fullWidth
									autoOk
									allowKeyboardControl
									format="long"
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={3}>
								<FastField
									name="carProdNr"
									label={
										<FormattedMessage
											id="dealer.production.number"
											description="text for productionnumber"
											defaultMessage="Ordrenummer"
										/>
									}
									component={TextField}
									disabled={
										isSubmitting ||
										(loadedData.car.ordNr !== "" &&
											loadedData.car.ordNr !== undefined &&
											loadedData.car.ordNr !== null) ||
										loadedData.disableSendOrderSection
									}
									margin="normal"
									variant="outlined"
									helperText=" "
									fullWidth
								/>
							</Grid>

							{!loadedData.disableSendOrderSection ? (
								<Grid item xs={12} md={4} lg={3}>
									<FormControl margin="normal" fullWidth>
										<SubmitButton
											type="button"
											variant="contained"
											color="secondary"
											size="large"
											fullWidth
										>
											Bekreft bilbestilling
										</SubmitButton>
										<FormHelperText
											color={
												status.success && !status.error ? "inherit" : "error"
											}
										>
											{status.error || status.message || " "}
										</FormHelperText>
									</FormControl>
								</Grid>
							) : null}
						</Grid>
					</Form>
				)}
			</Formik>
			{loadedData.disableSendOrderSection ? (
				<h3>
					{rowData.applicationStatusId === ApplicationStatusId.Paid
						? "Bilbestilling er sendt"
						: loadedData.carOrderConfirmed
						? `Bilbestilling er sendt den ${loadedData.confirmDate} av ${loadedData.confirmUser}`
						: null}
				</h3>
			) : null}
			<Formik<Partial<CarRegNr>>
				initialValues={{
					regNr: rowData.field8 || "",
					applicationId: rowData.applicationId || ""
				}}
				onSubmit={submitRegNr}
				validationSchema={CarRegSchema}
			>
				{({ status = {}, isSubmitting }) => (
					<Form>
						<Grid container spacing={2} alignItems="flex-end">
							<Grid item xs={12} md={6} lg={3}>
								<FastField
									name="regNr"
									label={
										<FormattedMessage
											id="dealer.production.regNr"
											description="text for registrationnumber"
											defaultMessage="Registreringsnummer"
										/>
									}
									component={TextField}
									disabled={
										isSubmitting || loadedData.disableEditRegNumberButton
									}
									margin="normal"
									variant="outlined"
									helperText=" "
									fullWidth
								/>
							</Grid>
							{!loadedData.disableEditRegNumberButton ? (
								<Grid item xs={12} md={4} lg={3}>
									<FormControl margin="normal" fullWidth>
										<SubmitButtonRegNR
											type="button"
											variant="contained"
											color="secondary"
											size="large"
											fullWidth
										>
											Endre regnr
										</SubmitButtonRegNR>
										<FormHelperText
											color={
												status.success && !status.error ? "inherit" : "error"
											}
										>
											{status.error || status.message || " "}
										</FormHelperText>
									</FormControl>
								</Grid>
							) : (
								<></>
							)}
						</Grid>
					</Form>
				)}
			</Formik>
		</div>
	);
};

const onConfirm = async (
	data: CarOrderConfirmation,
	formik: FormikHelpers<any>
) => {
	const response = await fetch(legacyApiResourceUrl("CarOrder/Confirm"), {
		method: "POST",
		body: new Blob([JSON.stringify(data)], { type: "application/json" }),
		headers: {
			accept: "application/json"
		}
	});
	if (response.ok) {
		formik.setSubmitting(false);
	} else {
		formik.setStatus({ error: "" });
	}
};
const onConfirmRegNr = async (data: CarRegNr, formik: FormikHelpers<any>) => {
	const response = await fetch(
		legacyApiResourceUrl("CarOrder/ChangeRegistrationNumber"),
		{
			method: "POST",
			body: new Blob([JSON.stringify(data)], { type: "application/json" }),
			headers: {
				accept: "application/json"
			}
		}
	);
	if (response.ok) {
		formik.setSubmitting(false);
	} else {
		formik.setStatus({ error: "" });
	}
};

export function SubmitButton(props: PendingButtonProps) {
	const formik = useFormikContext();

	return (
		<PendingButton
			pending={formik.isSubmitting}
			disabled={!formik.isValid}
			onClick={() => {
				if (window.confirm("Er du sikker på bilbestillingen?"))
					formik.submitForm();
			}}
			{...props}
		/>
	);
}

export function SubmitButtonRegNR(props: PendingButtonProps) {
	const formik = useFormikContext();
	return (
		<PendingButton
			pending={formik.isSubmitting}
			disabled={!formik.isValid}
			onClick={() => {
				if (window.confirm("Er du sikker du ønsker å endre regnr?"))
					formik.submitForm();
			}}
			{...props}
		/>
	);
}

export default CarOrderActions;
