import React, { useState, useMemo } from "react";
import CheckIcon from "@material-ui/icons/Check";
import {
	Paper,
	Grid,
	Typography,
	Avatar,
	Box,
	Hidden
} from "@material-ui/core";
import { useFetcher, useResource } from "@rest-hooks/core";
import {
	PasswordResetResource,
	ProfileResource
} from "@ploy-lib/rest-resources";
import { TextField } from "@ploy-ui/form-fields";
import { Formik, Field, Form } from "formik";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { PendingButton } from "@ploy-ui/core";
import { object, string, ref } from "yup";

const messages = defineMessages({
	required: {
		id: "dealer.profile.validation.required",
		defaultMessage: "Må angis"
	},
	confirmMustBeEqual: {
		id: "dealer.profile.validation.confirm-password-not-equal",
		defaultMessage: "Bekreftet passord er ikke likt nytt passord"
	}
});

const ChangePasswordForm = () => {
	const updatePassword = useFetcher(PasswordResetResource.updatePassword());

	const intl = useIntl();

	const passwordChangeSchema = useMemo(
		() =>
			object().shape({
				oldPassword: string().required(intl.formatMessage(messages.required)),
				newPassword: string().required(intl.formatMessage(messages.required)),
				newPasswordConfirm: string()
					.oneOf(
						[ref("newPassword"), null],
						intl.formatMessage(messages.confirmMustBeEqual)
					)
					.required(intl.formatMessage(messages.required))
			}),
		[intl]
	);

	return (
		<Formik
			initialValues={{
				oldPassword: "",
				newPassword: "",
				newPasswordConfirm: ""
			}}
			validationSchema={passwordChangeSchema}
			onSubmit={async (values, form) => {
				const result = await updatePassword(undefined, values);
				form.setSubmitting(false);
				form.setStatus({ result });
			}}
		>
			{({ isValid, isSubmitting, status }) => (
				<Box maxWidth={300} clone>
					<Grid component={Form} container spacing={3}>
						<Grid item xs={12}>
							<Field
								id="profile-old-password"
								name="oldPassword"
								component={TextField}
								type="password"
								autoComplete="current-password"
								label={
									<FormattedMessage
										id="dealer.profile.old-password.label"
										defaultMessage="Gammelt passord"
									/>
								}
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Field
								id="profile-new-password"
								name="newPassword"
								component={TextField}
								type="password"
								autoComplete="new-password"
								label={
									<FormattedMessage
										id="dealer.profile.new-password.label"
										defaultMessage="Nytt passord"
									/>
								}
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Field
								id="profile-new-password-repeat"
								name="newPasswordConfirm"
								component={TextField}
								type="password"
								autoComplete="new-password"
								label={
									<FormattedMessage
										id="dealer.profile.new-password-repeat.label"
										defaultMessage="Bekreft nytt passord"
									/>
								}
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Box display="flex" alignItems="center">
								<PendingButton
									type="submit"
									disabled={!isValid}
									pending={isSubmitting}
									variant="outlined"
									color="secondary"
								>
									<FormattedMessage
										id="dealer.profile.button.change-password.label"
										defaultMessage="Bytt passord"
									/>
								</PendingButton>
								{status && status.result && status.result.message ? (
									<Box p={1} color="green" display="flex" alignItems="center">
										{status.result.success && <CheckIcon />}
										<Typography
											component="span"
											color={status.result.success ? "inherit" : "error"}
										>
											{status.result.message}
										</Typography>
									</Box>
								) : null}
							</Box>
						</Grid>
					</Grid>
				</Box>
			)}
		</Formik>
	);
};

const RequestNewPassword = () => {
	const requestPasswordChange = useFetcher(
		PasswordResetResource.requestPasswordChange()
	);

	const [changeRequestResponse, setChangeRequestResponse] = useState();

	return (
		<Box p={4} component={Paper}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h4">
						<FormattedMessage
							id="dealer.profile.change-password-header"
							defaultMessage="Passord"
						/>
					</Typography>
				</Grid>
				{changeRequestResponse && changeRequestResponse.direct ? (
					<Grid item xs={12}>
						<ChangePasswordForm />
					</Grid>
				) : (
					<>
						<Grid item xs={12}>
							<PendingButton
								variant="outlined"
								color="secondary"
								error={changeRequestResponse && !changeRequestResponse.success}
								onClick={async () => {
									const response = await requestPasswordChange(undefined, {
										channelType: "email"
									});
									setChangeRequestResponse(response);
								}}
							>
								<FormattedMessage
									id="dealer.profile.button.new-password.label"
									defaultMessage="Nytt passord"
								/>
							</PendingButton>
							{changeRequestResponse && (
								<Box p={1} color="green" display="flex" alignItems="center">
									{changeRequestResponse.success && <CheckIcon />}
									<Typography
										component="span"
										color={changeRequestResponse.success ? "inherit" : "error"}
									>
										{changeRequestResponse.message}
									</Typography>
								</Box>
							)}
						</Grid>
					</>
				)}
			</Grid>
		</Box>
	);
};

const Profile = ({ className }) => {
	const profile = useResource(ProfileResource.detail(), {});

	return (
		<Box p={4} className={className}>
			<Grid container spacing={3}>
				<Grid item md={4} xs={12}>
					<Box p={4} component={Paper}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Box component={Avatar} height={136} width={136}>
									{profile.initials}
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="h5">{profile.fullName}</Typography>
								<Typography>{profile.roleName}</Typography>
							</Grid>
						</Grid>
					</Box>
				</Grid>

				<Grid item md={8} xs={12}>
					<Box p={4} component={Paper}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography gutterBottom variant="h4">
									<FormattedMessage
										id="dealer.profile.header"
										defaultMessage="Profil"
									/>
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="caption">
									<FormattedMessage
										id="dealer.profile.first-name.label"
										defaultMessage="Fornavn"
									/>
								</Typography>
								<Typography variant="h6">{profile.firstName}</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="caption">
									<FormattedMessage
										id="dealer.profile.last-name.label"
										defaultMessage="Etternavn"
									/>
								</Typography>
								<Typography variant="h6">{profile.lastName}</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="caption">
									<FormattedMessage
										id="dealer.profile.email.label"
										defaultMessage="E-postadresse"
									/>
								</Typography>
								<Typography variant="h6">{profile.email}</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="caption">
									<FormattedMessage
										id="dealer.profile.mobile.label"
										defaultMessage="Mobilnumner"
									/>
								</Typography>
								<Typography variant="h6">{profile.mobileNo}</Typography>
							</Grid>
						</Grid>
					</Box>
				</Grid>

				<Hidden smDown>
					<Grid item md={4} xs="auto" />
				</Hidden>

				<Grid item md={8} xs={12}>
					<RequestNewPassword />
				</Grid>
			</Grid>
		</Box>
	);
};

export default Profile;
