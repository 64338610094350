import React from "react";
import { SectionProps } from "../Section";
import { Grid, Typography, Icon } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { useVariableData } from "@ploy-lib/calculation";
import {
	FormattedMessage,
	injectIntl,
	WrappedComponentProps
} from "react-intl";

import { useAppLoad } from "../../appLoadContext";
import { ApplicationStatus } from "@ploy-lib/types";

const useStyles = makeStyles(theme => ({
	fullWidth: {
		width: "100%"
	},
	card: {
		backgroundColor: "#fff",
		borderRadius: 8,
		paddingTop: 8,
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: 16,
		[theme.breakpoints.down("sm")]: {
			paddingTop: 8,
			paddingLeft: 0,
			paddingRight: 0
		}
	},
	svgStyle: {
		width: "48px",
		height: "48px",
		marginLeft: 8
	},
	verticalAlignCenter: {
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		aligntItems: "center"
	},
	headerStyle: {
		paddingLeft: 24,
		[theme.breakpoints.down("sm")]: {
			fontSize: "1em",
			paddingLeft: 48
		}
	},
	salesText: {
		fontSize: "0.9rem",
		lineHeight: "16px",
		whiteSpace: "pre-line"
	},
	fontWeightBold: {
		fontWeight: 700
	},
	insurancePriceText: {
		fontWeight: "bold",
		fontSize: "150%"
	}
}));

addRegisteredSectionLayout({
	name: "PaymentInsuranceSection",
	displayName: "PaymentInsuranceSection",
	settings: {}
});

const PaymentInsuranceSection = injectIntl(
	(props: SectionProps & WrappedComponentProps) => {
		const { className, onClick } = props;
		const classes = useStyles();

		const { applicationStatus, settings } = useAppLoad();

		const paymentInsurancePdfUrl =
			(settings &&
				settings.distributionValues["distributionPaymentInsurancePdfUrl"]) ||
			"";

		let { value: price = 0 } = useVariableData<number>(
			"Calculator",
			"EP_TermAmount_PAYMENT_INSURANCE"
		);

		const appIsApproved =
			applicationStatus === ApplicationStatus.ManuallyApproved ||
			applicationStatus === ApplicationStatus.AutoApproved ||
			applicationStatus === ApplicationStatus.ApprovedWithConditions;

		// Temp handling of rounding decimal numbers, needs to be clarified with ASF:
		if (price > 0) {
			price = Math.round(price);
		}

		// Hide PaymentInsuranceSection if application is not approved:
		return appIsApproved ? (
			<Grid
				container
				spacing={2}
				className={clsx(className, classes.card)}
				onClick={onClick}
			>
				<Grid container>
					<Grid item xs={1} className={classes.verticalAlignCenter}>
						<Icon
							className={clsx(classes.svgStyle, "far fa-smile")}
							color="secondary"
						/>
					</Grid>
					<Grid
						item
						xs={11}
						className={clsx(classes.verticalAlignCenter, classes.headerStyle)}
					>
						<h2>
							<FormattedMessage
								id="template-form.sections.payment-insurance.header-text"
								defaultMessage="En tryggere betalingsopplevelse"
							/>
						</h2>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Typography className={classes.salesText}>
						<FormattedMessage
							id="template-form.sections.payment-insurance.description-text"
							defaultMessage="Med betalingsforsikring på ditt lån i AS Financiering er du og din familie godt sikret om noe uforutsett skulle inntreffe.

							Vår betalingsforsikring dekker hele terminbeløpet ved arbeidsledighet og sykmelding i opptil 12 betalingsmåneder.

							Vi dekker også restgjeld ved kritisk sykdom eller ved tap av liv - gir økonomisk trygghet og én bekymring mindre i en ellers vanskelig situasjon."
						/>
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography className={classes.fontWeightBold}>
						<FormattedMessage
							id="template-form.sections.payment-insurance.info-text"
							defaultMessage="Betalingsforsikring bestiller du enkelt når du signerer lånedokumentene."
						/>
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<a
						href={paymentInsurancePdfUrl}
						target="_blank"
						rel="noopener noreferrer"
					>
						<Typography>
							<FormattedMessage
								id="template-form.sections.payment-insurance.info-link"
								defaultMessage="Les mer om vår forsikring"
							/>
						</Typography>
					</a>
				</Grid>
				{price > 0 && (
					<Grid item xs={12} container spacing={2}>
						<Grid item xs={4} className={classes.verticalAlignCenter}>
							<Typography className={classes.insurancePriceText}>
								<FormattedMessage
									id="template-form.sections.payment-insurance.insurance-cost"
									defaultMessage="+ {insuranceCost} kr/ mnd"
									values={{
										insuranceCost: price
									}}
								/>
							</Typography>
						</Grid>
					</Grid>
				)}
			</Grid>
		) : null;
	}
);

PaymentInsuranceSection.displayName = "DployPaymentInsuranceSection";

export { PaymentInsuranceSection };
