import React, { memo, useLayoutEffect } from "react";
import { Login } from "./components/pages";
import { ThirdPartyScripts } from "@ploy-ui/core";
import LayoutSwitcher from "./components/LayoutSwitcher";
import { useFetcher } from "@rest-hooks/core";

import { useTenant } from "@ploy-ui/tenants";
import { Router } from "@ploy-lib/routing";
import { Authenticated } from "./components/Authenticated";
import { LoginResource } from "@ploy-lib/rest-resources";
import { SnackbarProvider } from "notistack";
import TokenLogin from "./components/pages/Login/TokenLogin";
import { Helmet } from "react-helmet";

const App = memo(({ basepath }) => {
	return (
		<>
			<Helmet htmlAttributes={{ lang: useTenant().locale, translate: "no" }} />
			<SnackbarProvider maxSnack={3}>
				<Router basepath={basepath}>
					<TokenLogin path="/tokenLogin" basepath={basepath} />
					<Login path="/login/*" basepath={basepath} />
					<Logout path="/logout" basepath={basepath} />
					<Authenticated default authenticate={`${basepath}/login`}>
						<LayoutSwitcher default />
					</Authenticated>
				</Router>
				<ThirdPartyScripts />
			</SnackbarProvider>
		</>
	);
});

const Logout = ({ basepath }) => {
	const logout = useFetcher(LoginResource.logout());

	useLayoutEffect(() => {
		// reload whole page to clear cache after logout
		// rest-hooks does not support invalidating the whole cache yet
		async function logoutAndRedirect() {
			const { isAuthenticated } = await logout();
			if (!isAuthenticated) window.location.href = `${basepath}/login`;
		}

		logoutAndRedirect();
	}, [basepath, logout]);

	// return success ? <Redirect noThrow to={`${basepath}/login`} /> : null;

	return null;
};

export default App;
