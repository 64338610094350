import React from "react";
import { useResource } from "@rest-hooks/core";
import { LoginResource, StyleResource } from "@ploy-lib/rest-resources";
import { TenantProvider, TenantProviderProps } from "@ploy-ui/tenants";

export const BrandTenantProvider = <TDate extends {}>(
	props: TenantProviderProps<TDate>
) => {
	let login: LoginResource | undefined = undefined;

	try {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		login = useResource(LoginResource.detail(), {});
	} catch (e: any) {
		if (e instanceof Promise || typeof (e as any).then === "function") throw e;
	}

	const style: Partial<StyleResource> =
		useResource(
			StyleResource.detail(),
			login?.style
				? {
						url: login.style
				  }
				: null
		) ?? {};

	const { theme, images } = style;

	return (
		<TenantProvider
			{...props}
			theme={{
				...theme,
				...props.theme
			}}
			images={{ ...images, ...props.images }}
			dashboardImageCropMode={props.dashboardImageCropMode}
		/>
	);
};
