import React, { Suspense } from "react";
import { FormattedMessage } from "react-intl";
import ContentBox from "../../ContentBox";
import ContentListItem from "../../ContentListItem";

import ChatIcon from "@material-ui/icons/Chat";
import { DashboardContentProps } from "../../types";
import { List } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { TaskFilter, TaskListItem } from "./TaskListItem";
import { useTasks } from "./useTasks";

export interface TodosProps extends DashboardContentProps {
	tasks?: TaskFilter[];
	showEmptyResult?: boolean;
}

const Todos = (props: TodosProps) => {
	const {
		boxProps,
		applicationsSearchPath,
		setApplicationsSearchParams,
		showEmptyResult,
		placeholder
	} = props;

	const animation = !placeholder && undefined;

	const tasks = useTasks(
		!placeholder
			? props.tasks
			: [
					{
						icon: (
							<Skeleton
								animation={animation}
								variant="circle"
								width={40}
								height={40}
							/>
						),
						filter: {},
						id: ".",
						name: ".",
						primaryMessage: ".",
						secondaryMessage: "."
					}
			  ],
		placeholder
	);

	return (
		<ContentBox
			avatar={<ChatIcon />}
			header={
				<FormattedMessage
					id="dealer.dashboard.todolist.header"
					defaultMessage={`Gjøremål`}
					description="Header for todolist on dashboard."
				/>
			}
			{...boxProps}
		>
			<List component="nav" dense>
				{tasks.map(task => {
					const fallback = (
						<ContentListItem
							key={task.id ?? task.name}
							iconClass={task.iconClass}
							icon={task.icon}
							primary={
								<Skeleton animation={animation} variant="text" width="60%" />
							}
							secondary={
								task.secondaryMessage?.trim() && (
									<Skeleton animation={animation} variant="text" width="90%" />
								)
							}
							inset
							hideChevron
						/>
					);

					if (placeholder) return fallback;

					return (
						<Suspense key={task.id ?? task.name} fallback={fallback}>
							<TaskListItem
								{...task}
								inset
								showEmptyResult={showEmptyResult}
								applicationsSearchPath={applicationsSearchPath}
								setApplicationsSearchParams={setApplicationsSearchParams}
							/>
						</Suspense>
					);
				})}
			</List>
		</ContentBox>
	);
};

Todos.displayName = "DployDashboardTodos";

export default Todos;
