import { TemplatePanel } from "@ploy-lib/types";

const _registeredLayouts: Record<string, RegisteredLayout> = {};

export interface PanelSettings {
	editableOptions?: {
		title?: boolean;
		elevation?: boolean;
		square?: boolean;
		hideForMobile?: boolean;
	};
	defaultOptions?: Partial<Omit<TemplatePanel, "sections" | "formPanelId">>;
}

/**
 * Intended to help interface-designer (and possibly others) with determining how to treat various propeties on Panel
 */
export interface RegisteredLayout {
	name: string;
	displayName: string;
	panels: Record<string, PanelSettings>;
	pageDescription?: boolean;
	displayInStepper?: boolean;
	displayInXofY?: boolean;
	hideFunctionButtons?: boolean;
	reduceFormHeightForLaptops?: boolean;
	canDecideMaxWidth?: boolean;
	canSetBackgroundColor?: boolean;
}

export function getRegisteredLayouts(): Readonly<typeof _registeredLayouts> {
	return _registeredLayouts;
}

export function addRegisteredLayout(registeredLayout: RegisteredLayout) {
	// if (_registeredLayouts[registeredLayout.name]) {
	// 	console.warn(
	// 		`_registeredLayouts already contain a layout named ${
	// 			registeredLayout.name
	// 		}, will override`
	// 	);
	// }

	_registeredLayouts[registeredLayout.name] = registeredLayout;
}
