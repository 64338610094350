import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { render } from "react-dom";
import {
	NetworkErrorBoundary,
	SubscriptionManager,
	PollingSubscription,
	DevToolsManager
} from "rest-hooks";
import { CacheProvider, NetworkManager } from "@rest-hooks/core";
import { AuthManager } from "@ploy-lib/rest-resources";

import * as serviceWorker from "./serviceWorker";

import App from "./App";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/css/v4-shims.css";
import { EnvProvider, ErrorHandler } from "@ploy-lib/core";
import {
	BrandTenantProvider,
	DefaultLinearProgress,
	ErrorPage,
	GlobalSuspenseProvider
} from "@ploy-ui/core";
import { LinearProgress, CssBaseline, Box } from "@material-ui/core";
import { HiddenInProductionTenantSelector } from "@ploy-ui/tenants";

const basepath = process.env.PUBLIC_URL;

const managers = [
	new AuthManager(),
	new NetworkManager(15 * 60 * 1000),
	new SubscriptionManager(PollingSubscription)
];

if (process.env.NODE_ENV !== "production")
	managers.unshift(new DevToolsManager());

function renderApp() {
	render(
		<EnvProvider
			envFilter={env => env.includes("portal") || env.includes("internal")}
		>
			<CacheProvider managers={managers}>
				<GlobalSuspenseProvider
					// Fallback before the actual theme is fully loaded
					fallback={
						<>
							<DefaultLinearProgress />
							<CssBaseline />
							<HiddenInProductionTenantSelector />
						</>
					}
				>
					<NetworkErrorBoundary
						fallbackComponent={props => (
							<>
								<ErrorPage {...props} />
								<CssBaseline />
								<HiddenInProductionTenantSelector />
							</>
						)}
					>
						<BrandTenantProvider defaultLocale="nb-NO" defaultCurrency="NOK">
							<GlobalSuspenseProvider
								fallback={
									<Box zIndex="tooltip" clone>
										<LinearProgress color="primary" />
									</Box>
								}
							>
								<ErrorHandler
									fallback={error => (
										<Box
											p={3}
											display="flex"
											alignItems="center"
											justifyContent="center"
										>
											<ErrorPage error={error} />
										</Box>
									)}
								>
									<App basepath={basepath} />
								</ErrorHandler>
							</GlobalSuspenseProvider>
							<CssBaseline />
							<HiddenInProductionTenantSelector />
						</BrandTenantProvider>
					</NetworkErrorBoundary>
				</GlobalSuspenseProvider>
			</CacheProvider>
		</EnvProvider>,
		document.getElementById("root")
	);
}

renderApp();

if (process.env.NODE_ENV === "development") {
	// Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
	if (module.hot) {
		module.hot.accept("./App", () => renderApp());
	}
	if (module.hot) {
		module.hot.accept("@ploy-ui/tenants", () => renderApp());
	}
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
