import React from "react";

import {
	Dialog,
	Card,
	CardHeader,
	CardContent,
	CardActions
} from "@material-ui/core";

import { FormattedMessage } from "react-intl";

import { Button } from "..";

export function UnsavedChangesCard(props) {
	const { open, onCancel, onDiscard } = props;

	const FormattedHeaderText = (
		<FormattedMessage
			id="form.unsavedChanges.headerText"
			description="Unsaved changes warning header text"
			defaultMessage="Du har ulagrede endringer"
		/>
	);

	return (
		<Dialog open={open}>
			<Card>
				<CardHeader title={FormattedHeaderText} />
				<CardContent>
					<FormattedMessage
						id="form.unsavedChanges.mainText"
						description="Unsaved changes warning main text"
						defaultMessage="Om du går tilbake uten å lagre vil endringene bli forkastet. Vil
									du avbryte?"
					/>
				</CardContent>
				<CardActions disableSpacing>
					<Button onClick={() => onDiscard()}>
						<FormattedMessage
							id="form.unsavedChanges.discard"
							description="unsaved changes discard button text"
							defaultMessage="Forkast"
						/>
					</Button>
					<Button style={{ marginLeft: "auto" }} onClick={() => onCancel()}>
						<FormattedMessage
							id="form.unsavedChanges.cancel"
							description="unsaved changes cancel button text"
							defaultMessage="Avbryt"
						/>
					</Button>
				</CardActions>
			</Card>
		</Dialog>
	);
}
