import { ID, BrowserDeprecation } from "@ploy-lib/types";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { SimpleRecord, AbstractInstanceType } from "@rest-hooks/rest";
import { BasePlainResource, BaseCamelCasedResource } from "./BasePlainResource";

interface User {
	name: string;
	username: string;
}

interface Vendor {
	id: ID;
	name: string;
}

export type LoginStatus =
	| "NotSet"
	| "ChangePassword"
	| "SupplyMobile"
	| "ChooseVendor"
	| "AccountStatusOK"
	| "TwoFactorAuthValidation";

export enum LoginStatusEnum {
	NotSet = "NotSet",
	ChangePassword = "ChangePassword",
	SupplyMobile = "SupplyMobile",
	ChooseVendor = "ChooseVendor",
	AccountStatusOK = "AccountStatusOK",
	TwoFactorAuthValidation = "TwoFactorAuthValidation"
}

export class LoginResource extends BasePlainResource {
	readonly status: LoginStatusEnum = LoginStatusEnum.NotSet;
	readonly style?: string;
	readonly user?: User;
	readonly vendor?: Vendor;
	readonly vendors?: Vendor[];
	readonly isInternal: boolean = false;
	readonly isDirectUser: boolean = false;
	readonly browserDeprecation: BrowserDeprecation = {};
	readonly googleTagManagerCode?: string;
	readonly cookiebotId?: string;

	get isAuthenticated(): boolean {
		return this.status === LoginStatusEnum.AccountStatusOK;
	}

	pk() {
		return "Login";
	}

	static merge<T extends typeof SimpleRecord>(
		this: T,
		first: AbstractInstanceType<T>,
		second: AbstractInstanceType<T>
	): AbstractInstanceType<T> {
		// Always overwrite
		return second;
	}

	static status(this: typeof LoginResource) {
		return this.detail().extend({
			url: () => `${this.urlRoot}/Login`
		});
	}

	static login(this: typeof LoginResource) {
		return this.create().extend({
			url: () => `${this.urlRoot}/Login`
		});
	}

	static setVendor(this: typeof LoginResource) {
		return this.create().extend({
			url: () => `${this.urlRoot}/Login`
		});
	}

	static setMobile(this: typeof LoginResource) {
		return this.create().extend({
			url: () => `${this.urlRoot}/Login`
		});
	}

	static setPassword(this: typeof LoginResource) {
		return this.create().extend({
			url: () => `${this.urlRoot}/Login`
		});
	}

	static logout(this: typeof LoginResource) {
		return this.create().extend({
			url: () => `${this.urlRoot}/Logout`
		});
	}

	static urlRoot = legacyApiResourceUrl("Login");
}

export class TokenLoginResource extends BasePlainResource {
	readonly status: LoginStatusEnum = LoginStatusEnum.NotSet;
	readonly style?: string;
	readonly user?: User;
	readonly vendor?: Vendor;
	readonly isInternal = false;
	readonly isDirectUser = false;
	readonly browserDeprecation: BrowserDeprecation = {};
	readonly googleTagManagerCode?: string;
	readonly cookiebotId?: string;

	get isAuthenticated(): boolean {
		return this.status === LoginStatusEnum.AccountStatusOK;
	}

	pk() {
		return "TokenLogin";
	}

	static merge<T extends typeof SimpleRecord>(
		this: T,
		first: AbstractInstanceType<T>,
		second: AbstractInstanceType<T>
	): AbstractInstanceType<T> {
		// Always overwrite
		return second;
	}

	static tokenLogin(this: typeof TokenLoginResource) {
		const endpoint = this.create();
		return endpoint.extend({
			url: () => `${this.urlRoot}/tokenLogin`,
			fetch: params => endpoint.fetch({}, params)
		});
	}

	static urlRoot = legacyApiResourceUrl("Login");
}

export class PasswordResetResource extends BaseCamelCasedResource {
	readonly success?: boolean;
	readonly message?: string;
	readonly error?: string;
	readonly direct?: boolean;

	static requestPasswordChange(this: typeof PasswordResetResource) {
		return this.create().extend({
			url: () => `${this.urlRoot}/RequestPasswordChange`
		});
	}

	static updatePassword(this: typeof PasswordResetResource) {
		return this.create().extend({
			url: () => `${this.urlRoot}/ChangePassword`
		});
	}

	static resetPassword(this: typeof PasswordResetResource) {
		return this.create().extend({
			url: () => `${this.urlRoot}/ResetPwd`
		});
	}

	pk() {
		return "ResetPwd";
	}

	static urlRoot = legacyApiResourceUrl("Login");
}

export class LoginInfoResource extends BaseCamelCasedResource {
	readonly header?: boolean;
	readonly message?: string;

	pk() {
		return "GetLoginInfoMessage";
	}

	static urlRoot = legacyApiResourceUrl("Login");
}
