import Links, { sources, sourceMapTitles } from "./Links";
import { applyPropertyControls, ControlType } from "../../property-controls";
import { LinkItemResource } from "@ploy-lib/rest-resources";

applyPropertyControls(Links, {
	source: {
		type: ControlType.Enum,
		title: "Datakilde",
		options: sources,
		optionTitles: sources.map(k => sourceMapTitles[k]),
		defaultValue: sources[0],
		coerce: (value, values) => {
			const source = sources.find(name =>
				LinkItemResource[name]?.()?.getFetchKey({}).includes(values.loadUrl)
			);
			return source;
		}
	},
	emptyMessage: {
		type: ControlType.String,
		title: "Tom liste tekst",
		description: "Alternativ tekst med som forklarer at listen er tom",
		defaultValue: "Ingen lenker",
		multiline: true
	},
	boxProps: {
		type: ControlType.Object,
		title: "Standard instillinger",
		propertyControls: {
			header: {
				type: ControlType.String,
				title: "Tittel"
			},
			iconClass: {
				type: ControlType.String,
				title: "Ikon",
				description: "Fyll inn navnet til et ikon fra Font Awesome"
			},
			backgroundColor: {
				type: ControlType.Color,
				title: "Bakgrunnsfarge",
				description: "Bakgrunnsfarge for boksen",
				coerce: (_, boxProps) => {
					if (boxProps.grey) return "grey.200";
				}
			},
			headerColor: {
				type: ControlType.Color,
				title: "Tittelfarge",
				description: "Tekstfarge som blir brukt på tittel og ikon",
				coerce: (_, boxProps) => {
					if (boxProps.grey) return "grey.500";
				}
			}
		}
	}
});

export default Links;
