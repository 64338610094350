import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BaseResource";

export class CustomerResource extends BaseCamelCasedResource {
    readonly key: string;
    readonly customerNumber: string;
    readonly value: string;
    readonly customerTypeId: number;

	pk() {
		return this.key || "";
	}

	static urlRoot = legacyApiResourceUrl("SelectCustomer/FindV2");
}
