import React from "react";
import { Redirect } from "@reach/router";
import { useResource } from "@rest-hooks/core";
import { ProductResource, AppLoadResource } from "@ploy-lib/rest-resources";
import { legacyApiResourceUrl } from "@ploy-lib/core";

export const navMigration = {
	FrontPage: "dashboard",
	ProductCalculator: "form/calculator",
	AppLoanLeasing: "form/finance",
	AllApplications: "applications",
	Reports: "reports",
	NewService: "form/service",
	"ExternalView/KmReading": "external/KmReading",
	"ExternalView/Commission": "external/Commission",
	"ExternalView/Fulfillment": "external/Fulfillment"
};

const basepath = process.env.PUBLIC_URL || "";

function ProductIdRedirect({ id, to }) {
	const products = useResource(ProductResource.list(), {});

	const product = products.find(p => p.id.toString() === id);

	return (
		<Redirect
			to={to.replace(
				":productCode",
				product ? product.externalCode.toLowerCase() : ""
			)}
		/>
	);
}

function ApplicationIdRedirect({ id, to }) {
	const appLoad = useResource(AppLoadResource.detail(), {
		url: legacyApiResourceUrl(`AppLoad/Load/${id}`)
	});

	return (
		<Redirect
			to={to.replace(
				":applicationNumber",
				appLoad ? appLoad.applicationNumber : ""
			)}
		/>
	);
}

/* Route migration from v1 */
export const v1routes = [
	<Redirect key="/FrontPage" from="/FrontPage" to={`${basepath}/dashboard`} />,
	<Redirect
		key="/AppLoanLeasing/Load"
		from="/AppLoanLeasing/Load"
		to={`${basepath}/form/application`}
	/>,
	<ApplicationIdRedirect
		key="/AppLoanLeasing/Load/:id"
		path="/AppLoanLeasing/Load/:id"
		to={`${basepath}/form/application/:applicationNumber`}
	/>,
	<Redirect
		key="/AppLoanLeasing"
		from="/AppLoanLeasing"
		to={`${basepath}/form/finance`}
	/>,
	<Redirect
		key="/AppLoanLeasing/CreateWithSelector"
		from="/AppLoanLeasing/CreateWithSelector"
		to={`${basepath}/form/finance`}
	/>,
	<ProductIdRedirect
		key="/AppLoanLeasing/CreateWithSelector/:id"
		path="/AppLoanLeasing/CreateWithSelector/:id"
		to={`${basepath}/form/finance/:productCode`}
	/>,
	<Redirect
		key="/NewService"
		from="/NewService"
		to={`${basepath}/form/service`}
	/>,
	<Redirect
		key="/NewService/CreateWithSelector"
		from="/NewService/CreateWithSelector"
		to={`${basepath}/form/service`}
	/>,
	<ProductIdRedirect
		key="/NewService/CreateWithSelector/:id"
		path="/NewService/CreateWithSelector/:id"
		to={`${basepath}/form/service/:productCode`}
	/>,
	<Redirect
		key="/ProductCalculator"
		from="/ProductCalculator"
		to={`${basepath}/form/calculator`}
	/>,
	<ProductIdRedirect
		key="/ProductCalculator/:id"
		path="/ProductCalculator/:id"
		to={`${basepath}/form/calculator/:productCode`}
	/>,
	<Redirect
		key="/AllApplications"
		from="/AllApplications"
		to={`${basepath}/applications`}
	/>,
	<Redirect
		key="/ExternalView"
		from="/ExternalView"
		to={`${basepath}/external`}
	/>,
	<Redirect
		key="/ExternalView/:view"
		from="/ExternalView/:view"
		to={`${basepath}/external/:view`}
	/>,
	<Redirect key="/Reports" from="/Reports" to={`${basepath}/reports`} />
];
